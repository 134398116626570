<template>
    <layout-main
        :title="$t('ユーザー新規追加')"
        :navs="navs"
        :type="'terms'"
    >
        <template #default>
            <accounts-form :form-type="'register'" :is-form-error="isFormError" :prop-errors="formErrorMsg" @save="save" @cancel="cancel" />
            <div class="manual">
                <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
            </div>            
        </template>
    </layout-main>
</template>

<script>
    import layoutMain from '@/layouts/main'
    import AccountService from '@/services/accountService'
    import ManualService from '@/services/manualService'
    import gxIconText from '@/components/atoms/icon-text'

    import accountsForm from '@/components/organisms/accounts/form'

    import i18n from '@/i18n'

    export default {
        name: 'accounts-register',
        components: {
            layoutMain,
            gxIconText,
            accountsForm
        },
        data() {
            return {
                isFormError: false,
                formErrorMsg: {}
            }
        },
        computed: {
            navs() {
                return [
                    {
                        to: '/accounts',
                        label: i18n.t('ユーザー設定')
                    }, {
                        to: '/accounts/register',
                        label: i18n.t('ユーザー新規追加')
                    }
                ]
            }
        },
        methods: {
            cancel() {
                this.$router.push('/accounts')
            },
            async save(register_account) {
                this.isFormError = false
                this.$store.dispatch('displayLoading')
                const data = Object.assign({}, register_account)
                if (data.notification_flg == 0) {
                    data.notification_interval = null
                }
                await AccountService.regist(data).then(() => {
                    this.$store.dispatch('openToast', {type: 'success', label: i18n.t('登録しました')})
                    this.$router.push('/accounts')
                }).catch(err => {
                    this.formErrorMsg = err.response.data
                    const message = err.response && err.response.data && err.response.data.length ? err.response.data.join('\n') : i18n.t('登録に失敗しました')
                    this.$store.dispatch('openToast', {type: 'error', label: message})
                })
                this.$store.dispatch('hideLoading')
            },
            manual(){
                return '/manual/wiki/User_edit_' + i18n.locale
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import '~@/assets/scss/_variable.scss';
    .notification_field {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
        &__label {
            font-weight: bold;
            margin-right: 12px;
        }
    }
</style>