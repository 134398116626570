<template>
    <validation-observer ref="form" v-slot="{ invalid }">
        <modal :size="'large'">
            <template #title>{{$t(modalTitle)}}</template>
            <div class="actions">
                <template v-if="detailType == 'detail' && isEditable()">
                    <gx-button
                        :text="$t('編集')"
                        :type="'common'"
                        :size="'small'"
                        class="actions__item"
                        @onClick="toggleType" />
                    <gx-icon
                        :tag="'a'"
                        :text="$t('削除')"
                        :icon="'trash'"
                        class="actions__item"
                        @onClick="() => $emit('delete', ruleData)"
                        href="#" />
                </template>
                <template v-else>
                    <span>{{$t('有効')}}:</span>
                    <gx-box v-if="detailType == 'detail'">
                        {{setenabled(editableItem.is_active)}}
                    </gx-box>
                    <gx-box v-else>
                        <gx-form-switch
                            :checked="editableItem.is_active"
                            @change="e => setInput('is_active', e)" />
                    </gx-box>
                </template>
            </div>

            <data-list :data="terms">
                <template #title={item}>
                    <gx-tag
                        :text="isRequiredTerm(item) ? $t('必須') : $t('任意')"
                        :type="isRequiredTerm(item) ? 'required' : 'common'"
                        class="required-tag"
                    />
                    <span v-html="$t(getTerms(item))" />
                </template>

                <!-- 詳細時の表示 -->
                <template v-if="detailType == 'detail'" #data={item}>
                    <template v-if="item == 'source'">
                        <div v-for="(item, index) in sourceStr.split('\n') " :key=index>
                            <div v-if ="index < 3">
                                {{$t(item)}}
                            </div>
                            <div v-else-if ="isShowed">
                                {{$t(item)}}
                            </div>
                            <div v-if="index >= 3 && String(index) == sourceStr.split('\n').length -1">
                                <template v-if="isShowed">
                                    <gx-icon-text class="dashboard-box__title toggle-button"
                                                  icon="arrow-down" @onClick="toggleBox()" />
                                </template>
                                <template v-else>
                                    <gx-icon-text 
                                        class="dashboard-box__title toggle-button"
                                        :text="$t('…')"
                                        icon="arrow-up" @onClick="toggleBox()" />
                                        
                                </template>
                            </div>
                        </div>
                        <div v-if="ruleData.exclusion">
                            {{$t('以外')}}
                        </div>
                    </template>
                    <template v-else-if="item == 'url'">
                        <gx-tag v-if="ruleData.dynamic_only" :text="$t('動的のみ')" class="path-tag" />
                        <gx-tag v-if="ruleData.use_regex_path" :text="$t('正規表現')" class="path-tag" />
                        {{ruleData.url_path}}
                    </template>
                    <template v-else-if="item == 'permission'">
                        <gx-label
                            :text="$t(permissionStyle.str)"
                            :type="permissionStyle.color" />
                    </template>
                    <template v-else-if="item == 'action'">
                        <gx-icon-text
                            v-if="!ruleData.permission"
                            :text="$t(actionStyle.str)"
                            :icon="actionStyle.icon"
                            :color="colors.SUCCESS" />
                    </template>
                    <template v-else-if="item == 'request_threshold'">
                        <!-- @TODO: データ無しで「未設定」などを表示 -->
                        {{$t('{0}秒間に{1}回以上のリクエストでブロックする', [ruleData.threshold_seconds, ruleData.threshold_counts])}}
                    </template>
                    <template v-else-if="item == 'block_time'">
                        <!-- @TODO: データ無しで「未設定」などを表示 -->
                        {{$t('{0}秒', [ruleData.block_time])}}
                    </template>
                    <template v-else-if="item == 'user_agent'">
                        {{ruleData.user_agent | checkNull}}
                    </template>
                    <template v-else-if="item == 'comment'">
                        <div v-for="(item, index) in ruleData.comment.split('\n') " :key=index>
                                {{$t(item)}}
                        </div>
                    </template>
                </template>

                <!-- 詳細以外の時の表示(≒編集) -->
                <template v-else #data={item}>
                    <template v-if="item == 'source'">
                        <span class="source-type">
                            <gx-form-radio
                                ref="source_type_radio_all"
                                class="source-type__radio"
                                :name="'source_type'"
                                :value="sourceTypeAll"
                                :checked="isSourceTypeAll"
                                @change="onSourceTypeRadioChange"
                            >
                                {{$t('全て')}}
                            </gx-form-radio>
                        </span>
                        <span class="source-type">
                            <gx-form-radio
                                ref="source_type_radio_ip"
                                class="source-type__radio"
                                :name="'source_type'"
                                :value="sourceTypeIp"
                                :checked="isSourceTypeIp"
                                @change="onSourceTypeRadioChange"
                            >
                                {{$t('IPアドレスで指定')}}
                            </gx-form-radio>
                            <gx-form-textarea
                                class="source-type__input"
                                v-model="editableItem.source_ip"
                                :errors="errors.source_ip"
                                term="IPアドレス"
                                :placeholder="'162.0.0.1/32'"
                                :note="$t('CIDR記法で範囲指定ができます／30件まで同時指定可能／複数指定をする際は、指定ごとに改行してください')"
                                :patterns=ipaddress(editableItem.source_ip)
                                :required="isSourceTypeIp"
                                :disabled="!isSourceTypeIp" />
                        </span>
                        <span class="source-type">
                            <gx-form-radio
                                ref="source_type_radio_bot"
                                class="source-type__radio"
                                :name="'source_type'"
                                :value="sourceTypeBot"
                                :checked="isSourceTypeBot"
                                @change="onSourceTypeRadioChange"
                            >
                                {{$t('Botで指定')}}
                            </gx-form-radio>
                            <gx-form-select
                                class="source-type__input"
                                value-attr="bot_id"
                                str-attr="bot_name"
                                v-model="editableItem.source_bot"
                                :options="getBots()"
                                :required="isSourceTypeBot"
                                :disabled="!isSourceTypeBot" />
                        </span>
                        <span class="source-type">
                            <gx-form-radio
                                ref="source_type_radio_country"
                                class="source-type__radio"
                                :name="'source_type'"
                                :value="sourceTypeCountry"
                                :checked="isSourceTypeCountry"
                                @change="onSourceTypeRadioChange"
                            >
                                {{$t('国名で指定')}}
                            </gx-form-radio>
                            <gx-form-select
                                class="source-type__input"
                                value-attr="iso"
                                str-attr="name"
                                v-model="editableItem.source_country"
                                :options="getCountries()"
                                :required="isSourceTypeCountry"
                                :disabled="!isSourceTypeCountry" />
                        </span>
                        <span class="excepting">
                            <gx-form-checkbox :checked="editableItem.exclusion" :disabled="isSourceTypeAll" @check="e => setInput('exclusion', e.target.checked)">{{$t('以外を対象にする')}}</gx-form-checkbox>
                        </span>
                    </template>
                    <template v-else-if="item == 'url'">
                        <div class="url">
                            <gx-form-text
                                v-model="editableItem.url_path"
                                :term="$t(getTerms(item))"
                                :placeholder="'/hoge/fuga or /hoge/fuga.html'"
                                :note="!editableItem.use_regex_path ? $t('完全一致') : ''"
                                :patterns="editableItem.use_regex_path ? 'REGEX' : 'URL_PATH'"
                                :errors="errors.url_path"
                                class="url__input"
                                required />
                            <div class="url__annotation"><gx-form-checkbox :checked="editableItem.dynamic_only" @check="e => setInput('dynamic_only', e.target.checked)">{{$t('動的ページのみを対象にする')}}</gx-form-checkbox></div>
                            <div class="url__annotation"><gx-form-checkbox :checked="editableItem.use_regex_path" @check="e => setInput('use_regex_path', e.target.checked)">{{$t('正規表現を使用する')}}</gx-form-checkbox></div>
                        </div>
                    </template>
                    <template v-else-if="item == 'permission'">
                        <gx-form-toggle
                            :name="'action'"
                            :left="{label: $t('拒否'), value: false}"
                            :right="{label: $t('許可'), value: true}"
                            :propValue="editableItem.permission"
                            @change="v => setInput('permission', v)" />
                            <gx-form-checkbox class="permission_white"
                                :checked="editableItem.permission_white"
                                :disabled="!editableItem.permission"
                                @check="e => setInput('permission_white', e.target.checked)">
                                {{$t('安全')}}
                            </gx-form-checkbox>
                    </template>
                    <template v-else-if="item == 'action'">
                        <term-list :items="actions">
                            <template #item={termItem}>
                                <gx-form-radio
                                    :value="termItem.value"
                                    :name="item"
                                    :checked="termItem.value === editableItem.action"
                                    :disabled="editableItem.permission"
                                    @change="e => setInput('action', e)">
                                    <gx-icon-text :text="$t(termItem.str)" :icon="termItem.icon" :color="termItem.color" />
                                </gx-form-radio>
                            </template>
                        </term-list>
                    </template>
                    <template v-else-if="item == 'request_threshold'">
                        <gx-form-text
                            class="request-threshold-input"
                            v-model.number="editableItem.threshold_seconds"
                            :errors="errors.threshold_seconds"
                            :required="!!editableItem.threshold_counts"
                            :disabled="editableItem.permission"
                            :term="$t('秒数')"
                            :placeholder="'3600'"
                            :type="'number'"
                            :max="3600"
                            :min="1"
                            :max-length="4"
                            :suffix="$t('秒間に')"
                            :note="$t('半角整数：{0}〜{1}', [1, 3600])" />
                        <gx-form-text
                            class="request-threshold-input"
                            v-model.number="editableItem.threshold_counts"
                            :errors="errors.threshold_counts"
                            :required="!!editableItem.threshold_seconds"
                            :disabled="editableItem.permission"
                            :term="$t('回数')"
                            :placeholder="'3600'"
                            :type="'number'"
                            :max="3600"
                            :min="1"
                            :max-length="4"
                            :suffix="$t('回以上の')"
                            :note="$t('半角整数：{0}〜{1}', [1, 3600])" />
                        <span class="request-threshold-note">{{$t('リクエストでブロックする')}}</span>
                    </template>
                    <template v-else-if="item == 'block_time'">
                        <gx-form-text
                            class="block-time-input"
                            v-model.number="editableItem.block_time"
                            :errors="errors.block_time"
                            :required="!!editableItem.threshold_seconds || !!editableItem.threshold_counts"
                            :disabled="editableItem.permission"
                            :term="$t('ブロック時間')"
                            :placeholder="'3600'"
                            :type="'number'"
                            :max="3600"
                            :min="60"
                            :max-length="4"
                            :suffix="$t('秒')"
                            :note="$t('半角整数：{0}〜{1}', [60, 3600])" />
                    </template>
                    <template v-else-if="item == 'user_agent'">
                        <gx-form-text
                            v-model="editableItem.user_agent"
                            patterns="USER_AGENT"
                            term="User-Agent"
                            :errors="errors.user_agent"
                            :note="$t('半角英数スペースおよび!()+,-./:;_が使用可能／部分一致／大文字と小文字を区別しない')"
                            :maxlength="1000"
                            :placeholder="'sample text'" />
                    </template>
                    <template v-else-if="item == 'comment'">
                        <gx-form-textarea
                            v-model="editableItem.comment"
                            :errors="errors.comment"
                            :placeholder="'comment'"
                            :ref="`input-${item}`"
                            :maxlength="200"
                        />
                    </template>                    
                </template>
            </data-list>
            <div class="manual">
                <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
            </div>
            <template #footer><button-wrap :buttons="buttons(invalid)[detailType]" @closeModal="closeModal" @saveItem="saveItem" @toggleType="toggleType" /></template>
        </modal>
    </validation-observer>
</template>

<script>
    import Const from '@/static/constants'

    import gxFormTextarea from '@/components/atoms/form/textarea'
    import gxFormText from '@/components/atoms/form/text'
    import gxFormRadio from '@/components/atoms/form/radio'
    import gxFormSelect from '@/components/atoms/form/select'
    import gxFormCheckbox from '@/components/atoms/form/checkbox'
    import gxFormToggle from '@/components/atoms/form/toggle'
    import gxFormSwitch from '@/components/atoms/form/switch'
    import gxButton from '@/components/atoms/button'
    import gxTag from '@/components/atoms/tag'
    import gxIcon from '@/components/atoms/icon'
    import gxIconText from '@/components/atoms/icon-text'
    import gxLabel from '@/components/atoms/label'

    import buttonWrap from '@/components/molecules/button-wrap'
    import modal from '@/components/molecules/modal'
    import dataList from '@/components/molecules/data-list'
    import termList from '@/components/molecules/form/term-list'

    import masterService from "@/services/masterService";
    import groupService from "@/services/groupService";
    import ManualService from '@/services/manualService'

    import i18n from '@/i18n'
        import {mapGetters} from 'vuex'

    const REQUIRED_TERMS = {
        source: true,
        url: true,
        permission: false,
        action: false,
        request_threshold: false,
        block_time: false,
        user_agent: false,
    }

    export default {
        name: 'group-access-detail-modal',
        components: {
            gxFormTextarea,
            gxFormText,
            gxFormRadio,
            gxFormSelect,
            gxFormCheckbox,
            gxFormToggle,
            gxFormSwitch,
            gxButton,
            gxTag,
            gxIcon,
            gxIconText,
            gxLabel,
            buttonWrap,
            modal,
            dataList,
            termList,
        },
        data() {
            const sourceTypeAll = Const.website.access_sourceType.ALL
            const sourceTypeIp = Const.website.access_sourceType.IP
            const sourceTypeCountry = Const.website.access_sourceType.COUNTRY
            const sourceTypeBot = Const.website.access_sourceType.BOT

            return {
                colors: Const.common.colors,
                detailType: this.$store.state.modalParam.detailType,
                editableItem: {
                    is_active: true,
                    permission_white: false,
                    source_type: null,
                    action: Const.website.action.BLOCK,
                },
                errors: {},
                sourceTypeAll: sourceTypeAll,
                sourceTypeAllLabel: "全て",
                sourceTypeIp: sourceTypeIp,
                sourceTypeIpLabel: Const.website.access_sourceTypeLabel[sourceTypeIp],
                sourceTypeCountry: sourceTypeCountry,
                sourceTypeCountryLabel: Const.website.access_sourceTypeLabel[sourceTypeCountry],
                sourceTypeBot: sourceTypeBot,
                sourceTypeBotLabel: Const.website.access_sourceTypeLabel[sourceTypeBot],
                country: [],
                isShowed: false,
                old_source_ip: "",
            }
        },
        computed: {
                        ...mapGetters([
                'userAccount'
            ]),
            modalTitle() {
                return i18n.t('フィルタリングルール{0}', [i18n.t(Const.website.access_detailModalType[this.detailType])])
            },
            terms() {
                //詳細の場合、設定されていない値は表示しない
                if(this.detailType == 'detail'){
                    let newlist = {}
                    for (let key in Const.website.access_detailTermsType) {
                        // リクエストしきい値とブロック時間は、値がない場合は表示しない
                        if(Const.website.access_detailTermsType[key] == Const.website.access_detailTermsType.request_threshold && !this.ruleData.threshold_counts){
                        }else if(Const.website.access_detailTermsType[key] == Const.website.access_detailTermsType.block_time && !this.ruleData.block_time){
                        }else{
                            newlist[key] = Const.website.access_detailTermsType[key];
                        }
                    } 
                    return Object.keys(newlist)
                }else{
                    return Object.keys(Const.website.access_detailTermsType)
                }
            },
            ruleData() {
                return this.$store.state.modalParam.rule ? this.$store.state.modalParam.rule : {}
            },
            isSourceTypeAll() {
                return this.editableItem.source_type === this.sourceTypeAll
            },
            isSourceTypeIp() {
                return this.editableItem.source_type == this.sourceTypeIp
            },
            isSourceTypeCountry() {
                return this.editableItem.source_type == this.sourceTypeCountry
            },
            isSourceTypeBot() {
                return this.editableItem.source_type == this.sourceTypeBot
            },
            sourceStr() {
                if (this.ruleData.source_type === this.sourceTypeAll) {
                    return "全て"
                }
                if (this.ruleData.source_type == this.sourceTypeIp) {
                    return this.ruleData.source_ip
                }
                if (this.ruleData.source_type == this.sourceTypeBot) {
                    return this.getBotName(this.ruleData.source_bot)
                }

                return i18n.getCountryName(this.ruleData.source_country)
            },
            permissionStyle() {
                let num = Number(this.ruleData.permission)
                if(num == 1 && Number(this.ruleData.permission_white) == 1){
                    num = 2
                }
                return Const.website.permissionStyle[num]
            },
            actions() {
                const actionStyle = Const.website.actionStyle
                const actions = {}
                Object.keys(actionStyle).forEach((index) => {
                    const action = actionStyle[index]
                    const colorLabel = action.color.toUpperCase()
                    actions[index] = {
                        str: action.str,
                        icon: action.icon,
                        value: action.value,
                        color: Const.common.colors[colorLabel]
                    }
                })
                return actions
            },
            actionStyle() {
                return Const.website.actionStyle[this.ruleData.action]
            },
        },
        filters: {
            checkNull(value) {
                return value?value:' - '
            }
        },
        methods: {
            async fetch() {
                await this._fetchCountry()
            },
            _fetchCountry() { // FIXME: 多分ここじゃない
                masterService.fetchCountry()
                    .then(res => this.country = res)
            },
            getCountries() {
                return i18n.translateCountryNames(this.country)
            },
            getBots() {
                return Object.entries(Const.website.access_botTypeLabel).map(([id, name]) => (
                    {'bot_id': parseInt(id), 'bot_name': name}
                ));
            },
            getBotName(bot_id) {
                return Const.website.access_botTypeLabel[bot_id]
            },
            getTerms(value) {
                const termType = Const.website.access_detailTermsType[value]
                return Array.isArray(termType)
                    ? termType.join('<br>')
                    : termType
            },
            isEditable() {
                if(this.userAccount.role == Const.accounts.roleAdmin ||
                   this.userAccount.role == Const.accounts.roleGroupAdmin ||
                   this.userAccount.role == Const.accounts.roleSupport
                ){
                    return true
                }else{
                    return false
                }
            },
            toggleType() {
                if(this.detailType == 'edit') {
                    this.detailType = 'detail'
                    this.editableItem = {}
                } else {
                    this.detailType = 'edit'
                    this.editableItem = Object.assign({}, this.ruleData)
                }
            },
            setInput(key, value) {
                this.$set(this.editableItem, key, value)
            },  
            ipaddress(datas){
                // バック側のエラーを表示させるため、データ変更があるときのみ処理する
                if (this.old_source_ip != this.editableItem.source_ip && datas){
                    this.old_source_ip = ""
                    let datalist = datas.split('\n')
                    if(datalist.length>30){
                        this.errors.source_ip = "IPアドレスの入力は30件までです"
                        return "IP_ADDRESS_CIDR"
                    }else{
                        for (const data of datalist){
                            let reg = data.split(".").length
                            if(reg > 4){
                                this.errors.source_ip = "IPアドレスのフォーマットが正しくありません"
                                return "IP_ADDRESS"
                            }
                        }
                        this.errors.source_ip =""
                        return "IP_ADDRESS_CIDR"
                    }
                }
                return "IP_ADDRESS_CIDR"
            },
            isRequiredTerm(item) {
                const res = REQUIRED_TERMS[item]
                return !!res
            },
            onSourceTypeRadioChange(value) {
                if (value === "") {
                    // :value="null"で空文字化するので、nullに戻す
                    this.$set(this.editableItem, "source_type", null)
                    // IPアドレス、国名、以外を対象にするを空にする
                    this.$set(this.editableItem, "exclusion", false)
                    this.$set(this.editableItem, "source_country", null)
                    this.$set(this.editableItem, "source_ip", null)
                    this.$set(this.editableItem, "source_bot", null)
                } else if (value === "1") {
                    this.$set(this.editableItem, "source_type", value)
                    // 国名を空にする
                    this.$set(this.editableItem, "source_country", null)
                    // BOTを空にする
                    this.$set(this.editableItem, "source_bot", null)
                } else if (value === "2") {
                    this.$set(this.editableItem, "source_type", value)
                    // IPアドレスを空にする
                    this.$set(this.editableItem, "source_ip", null)
                    // BOTを空にする
                    this.$set(this.editableItem, "source_bot", null)
                } else {
                    this.$set(this.editableItem, "source_type", value)
                    // IPアドレスを空にする
                    this.$set(this.editableItem, "source_ip", null)
                    // 国名を空にする
                    this.$set(this.editableItem, "source_country", null)
                }
            },
            async saveItem() {
                this.$store.dispatch('displayLoading')
                this.old_source_ip = this.editableItem.source_ip

                const default_params = {
                    website_id: this.$route.params.uuid,
                    action: Const.website.action.BLOCK,
                    exclusion: false,
                    dynamic_only: false,
                    permission: false,
                    threshold_seconds: null,
                    threshold_counts: null,
                    block_time: null,
                }
                const uuid = this.editableItem.group_rule_id
                const params = Object.entries(this.editableItem).reduce((newObj, [key, val]) =>
                    {
                        // '' false nullなどの偽値の形式を統一する
                        return !val && newObj.hasOwnProperty(key) ? newObj : Object.assign(newObj, {[key]: val})
                    },
                    Object.assign({}, default_params),
                )

                // サニタイズ
                if (params.permission) {
                    params.action = default_params.action
                    params.threshold_counts = default_params.threshold_counts
                    params.threshold_seconds = default_params.threshold_seconds
                    params.block_time = default_params.block_time
                }
                if (this.isSourceTypeAll) {
                    // 参照元が"全て"の場合、IPと国の内容を含めて全てnullにして送信する
                    params.source_type = null;
                    params.source_country = null;
                    params.source_ip = null;
                    params.source_bot = null;
                } else if (this.isSourceTypeIp) {
                    if (params.hasOwnProperty('source_country')) {
                        delete params.source_country
                    }
                    if (params.hasOwnProperty('source_bot')) {
                        delete params.source_bot
                    }
                } else if (this.isSourceTypeCountry) {
                    if (params.hasOwnProperty('source_ip')) {
                        delete params.source_ip
                    }
                    if (params.hasOwnProperty('source_bot')) {
                        delete params.source_bot
                    }
                } else if (this.isSourceTypeBot) {
                    if (params.hasOwnProperty('source_ip')) {
                        delete params.source_ip
                    }
                    if (params.hasOwnProperty('source_country')) {
                        delete params.source_country
                    }
                }

                // 送信
                return await groupService.saveAccessRule(this.$route.params.uuid, uuid, params)
                    .then(res => {
                        this.$store.dispatch('openToast', {type: 'success', label: i18n.t('更新しました')})
                        this.closeModal()
                        this.$emit('update', res)
                    })
                    .catch(err => {
                        this.errors = err.response.data
                        const message = ('data' in err) ? err.response.data.join('\n') : i18n.t('更新に失敗しました')
                        this.$store.dispatch('openToast', {type: 'error', label: message})
                    })
                    .finally(() => this.$store.dispatch('hideLoading'))
            },
            buttons(d) {
                let disabled
                if (
                    !this.$refs['source_type_radio_all']
                    && !this.$refs['source_type_radio_ip']
                    && !this.$refs['source_type_radio_country']
                    && !this.$refs['source_type_radio_bot']
                ) {
                    disabled = d
                } else {
                    disabled = d
                        || (
                            !this.$refs['source_type_radio_country'].checked
                            && !this.$refs['source_type_radio_ip'].checked
                            && !this.$refs['source_type_radio_bot'].checked
                            && !this.$refs['source_type_radio_all'].checked
                        )
                }
                
                return {
                    detail: [{
                        text: i18n.t('閉じる'),
                        type: 'common',
                        onClick: 'closeModal'
                    }],
                    new: [{
                        text: i18n.t('保存'),
                        onClick: 'saveItem',
                        disabled,
                    }, {
                        text: i18n.t('キャンセル'),
                        type: 'common',
                        onClick: 'closeModal'
                    }],
                    edit: [{
                        text: i18n.t('保存'),
                        onClick: 'saveItem',
                        disabled,
                    }, {
                        text: i18n.t('キャンセル'),
                        type: 'common',
                        onClick: 'toggleType'
                    }]
                }
            },
            setenabled(bool){
                if(bool){
                    return i18n.t('ON')
                }else{
                    return i18n.t('OFF')
                }
            },
            closeModal() {
                this.$store.dispatch('closeModal')
            },
            toggleBox() {
                this.isShowed = !this.isShowed
            },            
            manual(){
  		        if(this.detailType == 'detail'){
                    return '/manual/wiki/Group_access_control_detail_' + i18n.locale
  		        }else{
                    return '/manual/wiki/Group_access_control_edit_' + i18n.locale                
                }
            },               
        },
        created() {
            this.fetch()
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';

    .required-tag {
        margin-right: 8px;
    }
    .actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 16px;

        &__item:not(:first-child) {
            margin-left: 24px;
        }
    }
    .source-type {
        display: block;
        max-width: 426px;
        margin-bottom: 16px;

        &__radio {
            display: block;
            margin-bottom: 16px;
        }
        &__input {
            display: block;
            margin-left: 26px;
            width: calc(100% - 26px);
        }
    }
    .excepting {
        display: block;
        border-top: 1px solid $color-border;
        padding-top: 16px;
    }
    .url {
        width: 100%;

        &__input {
            margin-bottom: 16px;
        }
        &__annotation {
            margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .request-threshold-input {
        width: 170px;
        margin-right: 16px;
    }
    .request-threshold-note {
        display: block;
        margin-top: 16px;
    }
    .block-time-input {
        width: 138px;
        margin-right: 16px;
    }
    .excepting-label {
        font-weight: bold;
        margin-left: 16px;
    }
    .path-tag {
        margin-right: 8px;
    }
    .permission_white {
        margin-left: 30px;
        vertical-align: text-top;
    }
</style>
