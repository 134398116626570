<template>
    <validation-observer ref="commentEditForm" v-slot="{ invalid }">
        <modal :size="'large'" @cancel="closeModal">
            <gx-section>
                <multi-notice type="error" :terms="terms" :messages="setmessages(errors)" :change="setinternalstatus()"  />
                <div class="status_edit">
                     <gx-form-select
                        v-model="editComment.status"
                        :options="getSelectorValues()"
                        value-attr="value"
                        str-attr="str"
                        class="actions__item"
                        :prefix="$t('ステータス')"
                        required
                    />
                </div>
                <div v-if="userAccount.is_support" class="status_edit">
                     <gx-form-select
                        v-model="editComment.internal_status"
                        :options="internalStatus"
                        value-attr="id"
                        str-attr="internal_status"
                        class="actions__item"
                        :prefix="$t('内部ステータス')"
                        required
                        :disabled="editComment.status == Const.support.status.CLOSE.value"
                    />
                </div>
                <div class="content_edit">
                    <gx-form-textarea
                        :placeholder="$t('内容')"
                        v-model="editComment.content"
                        :name="$t('内容')"
                        :label="$t('内容')"
                        class="setting_value"
                        rules="required"
                        required
                    />
                </div>
                <div v-if="userAccount.is_support && Array.isArray(tags)" class="tag_edit">
                    <gx-form-text
                        class="text"
                        :placeholder="$t('タグ')"
                        :label="$t('タグ')"
                        v-model="editTag"
                    />
                    <gx-button 
                        class="button"
                        :text="$t('追加')"
                        :type="'common'"
                        :size="'small'"
                        :disabled="!editTag"
                        @onClick="onAddTagClick"
                    />
                    <div class="tag">
                        <span class="selected_tags" v-for="tag in tags" :key="tag.tag_id">
                            <gx-tag
                                :text="tag.tag_name"
                            />
                            <span @click="onTagClick(tag)">✖</span>
                        </span>
                    </div>
                </div>
                <div class="file_edit">
                    <gx-form-file
                        ref="commentFiles"
                        :label="$t('ファイル')"
                        multiple
                        @change="onCommentFileChange"
                    />
                </div>
                <div v-if="(editComment.comment_file_set.length > 0)" v-for="(file, index) in editComment.comment_file_set" :key="index" class="file">
                    <dev v-if="file in file > 0">
                        {{file.file_name}} … {{Math.ceil(file.file.size / 1024)}}[KB]  
                    </dev>
                    <dev v-else>
                        {{file.file_name}}
                    </dev>
                    <small>
                        <gx-icon :tag="'a'" href="#" :size="1.2" :icon="'close'" @onClick="clearfile"/>
                    </small>
                </div>
                <button-wrap :buttons="buttons(invalid)" class="button-wrap" @submit="onEditCommentCheck" />
                <div class="manual">
                    <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
                </div>  
            </gx-section>
        </modal>
    </validation-observer>
</template>
<script>
    import Const from '@/static/constants'
    import i18n from '@/i18n'

    import {
        TicketTag,
        EditComment, EditCommentFile,
    } from '@/services/supportService'

    import multiNotice from '@/components/molecules/multi-notice'
    import gxSection from '@/components/atoms/section'
    import gxFormText from '@/components/atoms/form/text'
    import gxFormTextarea from '@/components/atoms/form/textarea'
    import gxFormSelect from '@/components/atoms/form/select'
    import gxFormFile from '@/components/atoms/form/file'
    import gxButton from '@/components/atoms/button'
    import gxIconText from '@/components/atoms/icon-text'
    import gxTag from '@/components/atoms/tag'
    import buttonWrap from '@/components/molecules/button-wrap'
    import modal from '@/components/molecules/modal'
    import ManualService from '@/services/manualService'
    import gxIcon from '@/components/atoms/icon'

    export default {
        name: "support-comment-modal",
        components: {
            multiNotice,
            gxSection,
            gxFormText,
            gxFormTextarea,
            gxFormSelect,
            gxFormFile,
            gxButton,
            gxIconText,
            gxTag,
            buttonWrap,
            modal,
            gxIcon,
        },
        props: {
            userAccount: {
                type: Object,
                "default": function() {
                    return {}
                }
            },
            errors: {
                type: Object,
                "default": function() {
                    return {}
                }
            },
            editComment: {
                type: Object,
                "default": function() {
                    return new EditComment({
                        content: "",
                        status: Const.support.status.USER.value,
                        comment_file_set: [],
                    })
                },
            },
            internalStatus: {
                type: Array,
                default: () => []
            },
            tags: {
                type: Array,
                "default": undefined,
            },
            buttonDisabled: {
                type: Boolean,
                "default": false,
            },
        },
        data: function() {
            return {
                Const: Const,
                terms: Const.support.messageModalTerms,
                editTag: "",
            }
        },
        computed: {
        },
        methods: {
            buttons(disabled) {
                let d = disabled
                if (this.editComment.content.length <= 0) {
                    d = true
                }
                if (!d && this.$refs.commentFiles) {
                    // トータル3MB以上はNG
                    d = Math.ceil(this.$refs.commentFiles.size / 1024) > 3000
                }
                if (!d && this.buttonDisabled) {
                    d = true
                }
                return [
                    {
                        text: i18n.t('送信'),
                        type: 'primary',
                        size: 'large',
                        disabled: d,
                        onClick: 'submit',
                    },
                ]
            },
            onAddTagClick() {
                if (!this.$data.editTag) return

                if (Array.isArray(this.tags)) {
                    this.tags.push(new TicketTag({tag_name: this.$data.editTag}))
                    this.$data.editTag = ""
                }
            },
            onTagClick(tag) {
                const removeIdx = this.tags.findIndex((obj, idx, arr)=>{return (obj.tag_id == tag.tag_id)})
                if (removeIdx >= 0) {
                    this.tags.splice(removeIdx, 1)
                }
            },
            onCommentFileChange() {
                if (this.$refs.commentFiles.size / 1024 > 3000) {
                    this.$emit('totalFileSizeOver', {
                        message: i18n.t('添付ファイルは合計3MB以下となるようにしてください')
                    })
                    return
                }
                const files = this.$refs.commentFiles.files
                this.editComment.comment_file_set = []
                for (let i in files) {
                    this.editComment.comment_file_set.push(new EditCommentFile({
                        file: files[i],
                        file_name: files[i].name,
                        file_content: files[i].base64,
                    }))
                }
            },
            onEditCommentCheck() {
                this.editComment.ticket_tag_set = Array.from(this.tags)
                this.$emit('submit', this.editComment)
            },
            clearfile(){
                this.editComment.comment_file_set = []
            },
            manual(){
  		        return '/manual/wiki/Support_comment_' + i18n.locale
  		    },
            getSelectorValues(){
                var statuslist = i18n.translateOptionValues(Const.support.statusArray)
                // 検索用の空欄を削除
                statuslist.splice(0, 1)
                if (!this.userAccount.is_support){
                    // ユーザーの項目を削除
                    statuslist.splice(0, 1)
                }
                return statuslist
            },
            setinternalstatus(){
                if(this.editComment.status == Const.support.status.CLOSE.value){
                    // ステータスがクローズの場合、内部ステータスもクローズにする
                    this.editComment.internal_status = Const.support.internalStatus.CLOSE.value
                } else if(!this.userAccount.is_support){
                    // ユーザーアカウントの場合、内部ステータスを未処理にする
                    this.editComment.internal_status = Const.support.internalStatus.UNTREATED.value
                }
            },
            setmessages(errors){
                // 内容のエラーメッセージのみ階層が違うため、データを抽出して表示させる
                var error = errors[`comment_set`]
                if(error){
                    return error[0]
                }else{
                    return errors
                }
            }
        },
        mounted() {
            if (this.userAccount.is_support){
                // サポートアカウントの場合、ステータス初期値をユーザーにする
                this.editComment.status = Const.support.status.USER.value
            }else{
                // ユーザーアカウントの場合、ステータス初期値をEGSSにする
                this.editComment.status = Const.support.status.EGSS.value
            }
            return this.editComment.status
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .setting_value ::v-deep {
        display: block;

        .input {
            max-width: 100%;
        }
        .field {
            max-width: 100%;
        }
    }
    .selected_tags {
  		padding: 0.5em 0.25em;
  	}.status_edit{
        padding: 0.5em 0.25em;
    }
</style>
