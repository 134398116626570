var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-main',{attrs:{"title":_vm.$t('イベント詳細'),"navs":_vm.navs},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.isLoading)?[_c('loading')]:(_vm.isLoadError)?_c('notice',{attrs:{"type":'error',"label":_vm.$t('データが取得出来ませんでした')}}):[(_vm.event)?[_c('gx-section',[_c('gx-title',{attrs:{"level":2}},[_vm._v(_vm._s(_vm.$t('概要')))]),_c('data-list',{attrs:{"data":_vm.summaryTerms},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$t(_vm.getTerms(item))))]}},{key:"data",fn:function(ref){
var item = ref.item;
return [(_vm.event)?[(item == 'attack_src_country')?[_vm._v(_vm._s(_vm.getAttackSrcCountry(_vm.event[item])))]:(item == 'attack_type')?[_vm._v(_vm._s(_vm.$t(_vm.getAttackType(_vm.event[item]))))]:(item == 'res_action')?[_c('gx-icon-text',{attrs:{"text":_vm.$t(_vm.getActionType(_vm.event[item])),"icon":_vm.getActionIcon(_vm.event[item]),"color":_vm.colors.success}})]:(item == 'req_protocol')?[_vm._v(_vm._s(_vm.$t(_vm.getProtocolType(_vm.event[item]))))]:(item == 'timestamp')?[_vm._v(_vm._s(_vm.formatLocalDateTime(_vm.event[item])))]:(item == 'attack_src')?[_c('div',{staticClass:"action-wrap"},[_c('div',{staticClass:"label_2col"},[_vm._v(_vm._s(_vm.$t('国名')))]),_c('span',{staticClass:"data_2col"},[_vm._v(_vm._s(_vm.getAttackSrcCountry(_vm.event.attack_src_country)))])]),_c('div',{staticClass:"label_2col"},[_vm._v(_vm._s(_vm.$t('IP')))]),_c('span',{staticClass:"data_2col"},[_vm._v(_vm._s(_vm.event.attack_src_ip))])]:[_vm._v(_vm._s(_vm.event[item]))]]:_vm._e()]}}],null,false,554019772)})],1),_c('gx-section',[_c('gx-title',{attrs:{"level":2}},[_vm._v(_vm._s(_vm.$t('検出')))]),_c('data-list',{attrs:{"data":_vm.detectionTerms},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$t(_vm.getTerms(item))))]}},{key:"data",fn:function(ref){
var item = ref.item;
return [(item == 'sig')?[_c('div',{staticClass:"action-wrap"},[_c('div',{staticClass:"label_2col"},[_vm._v(_vm._s(_vm.$t('ID')))]),_c('span',{staticClass:"data_2col"},[_vm._v(_vm._s(_vm.event.sig_id))])]),_c('div',{staticClass:"label_2col"},[_vm._v(_vm._s(_vm.$t('名前')))]),_c('span',{staticClass:"data_2col"},[_vm._v(_vm._s(_vm.event.sig_name))])]:[[_vm._v(_vm._s(_vm.event[item]))]]]}}],null,false,2489821604)})],1),_c('gx-section',[_c('gx-title',{attrs:{"level":2}},[_vm._v(_vm._s(_vm.$t('リクエスト')))]),_c('gx-box',[_c('gx-title',{attrs:{"level":3}},[_vm._v(_vm._s(_vm.$t('ヘッダー')))]),_c('gx-code',[_vm._v(_vm._s(_vm.event.req_headers))]),_c('gx-title',{attrs:{"level":3}},[_vm._v(_vm._s(_vm.$t('ボディ')))]),_c('gx-code',[_vm._v(_vm._s(_vm.event.req_body))])],1)],1),_c('gx-section',[_c('gx-title',{attrs:{"level":2}},[_vm._v(_vm._s(_vm.$t('レスポンス')))]),_c('gx-box',[_c('gx-title',{attrs:{"level":3}},[_vm._v(_vm._s(_vm.$t('ヘッダー')))]),_c('gx-code',[_vm._v(_vm._s(_vm.event.res_headers))])],1)],1)]:_vm._e()],(!_vm.userAccount.is_support)?_c('gx-button',{attrs:{"text":_vm.$t('サポート')},on:{"onClick":function($event){return _vm.$router.push(("/support/register/?event_id=" + (_vm.$route.params.uuid)))}}}):_vm._e(),_c('page-back',{attrs:{"text":_vm.$t('イベント一覧に戻る')},on:{"onClick":function($event){return _vm.$router.back()}}}),_c('div',{staticClass:"manual"},[_c('router-link',{attrs:{"target":"_blank","to":{path: _vm.manual()}}},[_vm._v(_vm._s(_vm.$t('マニュアル')))])],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }