<template>
    <nav class="nav">
        <div class="nav__inner">
            <ul class="nav__list">
                <li class="nav__item dashboard"><router-link to="/dashboard/" class="nav__link">{{$t('ダッシュボード')}}</router-link></li>
                <li class="nav__item event"><router-link to="/event/" class="nav__link">{{$t('イベント')}}</router-link></li>
                <li class="nav__item group"><router-link to="/group/" class="nav__link">{{$t('グループ設定')}}</router-link></li>
                <li class="nav__item certificate"><router-link to="/certificate/" class="nav__link">{{$t('証明書一覧')}}</router-link></li>
                <li class="nav__item website"><router-link to="/website/" class="nav__link">{{$t('ウェブサイト')}}</router-link></li>
                <li class="nav__item support"><router-link to="/support/" class="nav__link">{{$t('サポート')}}</router-link></li>
            </ul>
        </div>
    </nav>
</template>

<script>
    export default {
        name: 'global-nav'
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .nav {
        width: 56px;
        overflow: hidden;
        background-color: $color-parts-accent;
        transition: $transition-common;
        z-index: 100;

        &:hover {
            width: 240px;
            transition-delay: 0.5s, 0ms;
            background-color: rgba($color-parts-accent, .9);
        }
        &__inner {

        }
        &__list {

        }
        &__item {

            &.dashboard {

                .nav__link::before {
                    @include icon(dashboard);
                }
            }
            &.event {
                .nav__link::before {
                    @include icon(event);
                }
            }
            
            &.group {
                .nav__link::before {
                    @include icon(ip);
                }
            }
            &.website {
                .nav__link::before {
                    @include icon(website);
                }
            }
            &.certificate {
                .nav__link::before {
                    @include icon(sensor);
                }
            }
            &.support {
                .nav__link::before {
                    @include icon(question_circle_fill);
                }
            }
        }
        &__link {
            display: flex;
            width: 240px;
            height: 48px;
            line-height: 48px;
            align-items: center;
            text-decoration: none;
            border-left: 4px solid transparent;

            &::before {
                font-size: 2.2rem;
                width: 52px;
                text-align: center;
            }
            &:hover {
                opacity: 1;
                border-color: $color-primary;
            }
            &.is-current {
                background: $color-bg;
                border-color: $color-primary;
                font-weight: bold;
            }
        }
    }
</style>