<template>
    <layout-main
        :title="$t('新規チケット登録')"
        :navs="navs"
    >
        <div>
            <multi-notice type="error" :terms="Const.support.registerTerms" :messages="errors" />
            <validation-observer v-slot="{ invalid }">
                <gx-section class="ticket_info">
                    <div v-if="data.editData.waf_event" class="item event_id">
                        <div class="label">{{$t('イベントID')}}</div>
                        <div class="data">
                            {{data.editData.waf_event}}
                        </div>
                    </div>
                    <div class="item status">
                        <div class="label">{{$t('ステータス')}}</div>
                        <div class="data">
                            <gx-form-select
                                :name="$t('ステータス')"
                                v-model="data.editData.status"
                                :options="getSelectorValues(data.support.statusArray)"
                                value-attr="value"
                                str-attr="str"
                                :disabled="!userAccount.is_support"
                                rules="required"
                                required
                            />
                        </div>
                    </div>
                    <div v-if="userAccount.is_support" class="item status">
                        <div class="label">{{$t('内部ステータス')}}</div>
                        <div class="data">
                            <gx-form-select
                                v-model="data.editData.internal_status"
                                :options="internalStatus"
                                value-attr="id"
                                str-attr="internal_status"
                                required
                            />
                        </div>
                    </div>
                    <div class="item email">
                        <div class="label">{{$t('メール宛先')}}</div>
                        <div class="data">
                            <gx-form-select
                                :name="$t('メール宛先')"
                                v-model="data.selectedMailAddr"
                                :options="data.mailList"
                                value-attr="value"
                                str-attr="str"
                            />
                            <ul class="selected_mail_addr_list" :v-if="!!data.choosedMailAddrList">
                                <li class="selected_mail_addr" v-for="mailAddr in data.choosedMailAddrList" :key="mailAddr.id">
                                    <gx-tag
                                        class="selected_mail_addr_tag"
                                        :text="mailAddr.username"
                                    />
                                    <span class="selected_mail_addr_remove" @click="onMailAddrRemoveClick(mailAddr.id)">✖</span>
                                </li>
                            </ul>
                            <gx-button
                                :text="$t('追加')"
                                @onClick="onAddMailAddrButtonClick()"
                            />
                        </div>
                    </div>
                    <div class="item title">
                        <div class="label">{{$t('タイトル')}}</div>
                        <div class="data">
                            <gx-form-text
                                :name="$t('タイトル')"
                                :placeholder="$t('タイトル')"
                                :maxlength="100"
                                :minlength="1"
                                v-model="data.editData.title"
                                rules="required"
                                required
                            />
                        </div>
                    </div>
                    <div class="item content">
                        <div class="label">{{$t('内容')}}</div>
                        <div class="data">
                            <gx-form-textarea
                                :name="$t('内容')"
                                :placeholder="$t('内容')"
                                v-model="data.editData.content"
                                rules="required"
                                required
                            />
                        </div>
                    </div>
                    <div v-if="userAccount.is_support" class="tag_edit">
                        <gx-form-text
                            class="text"
                            :placeholder="$t('タグ')"
                            :label="$t('タグ')"
                            v-model="editTag"
                        />
                        <gx-button 
                            class="button"
                            :text="$t('追加')"
                            :type="'common'"
                            :size="'small'"
                            :disabled="!editTag"
                            @onClick="onAddTagClick"
                        />
                        <div class="tag">
                            <span class="selected_tags" v-for="tag in tags" :key="tag.tag_id">
                                <gx-tag
                                    :text="tag.tag_name"
                                />
                                <span @click="onTagClick(tag)">✖</span>
                            </span>
                        </div>
                    </div>
                    <div class="file_edit">
                        <gx-form-file
                            ref="commentFiles"
                            :label="$t('ファイル')"
                            multiple
                            @change="onCommentFileChange"
                        />
                    </div>
                    <div v-if="(data.editData.ticket_file_set.length > 0)" v-for="(file, index) in data.editData.ticket_file_set" :key="index" class="file">
                        {{file.file_name}} … {{Math.ceil(file.file.size / 1024)}}[KB]  
                        <small>
                            <gx-icon :tag="'a'" href="#" :size="1.2" :icon="'close'" @onClick="clearfile"/>
                        </small>
                    </div>
                </gx-section>
                <button-wrap :buttons="buttons(invalid)" class="button-wrap" @submit="onEditCommentCheck" @cancel="onCancel" />
            </validation-observer>
        </div>
        <div class="manual">
            <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
        </div>
        <template v-if="$store.state.isModal" #modal>
            <support-register-check-modal
                v-if="$store.state.modalTarget == 'support-register-check-modal'"
                :user-account="userAccount"
                :edit-data="data.editData"
                :addr-list="data.choosedMailAddrList"
                :internalStatus="internalStatus"
                :tags="tags"
                :errors="errors"
                @submit="onEditCommentCheck"
                @cancel="oncancel"
            />
        </template>
    </layout-main>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import layoutMain from '@/layouts/main'

    import CommonMixin from '@/mixin/common'
    import multiNotice from '@/components/molecules/multi-notice'
    import gxSection from '@/components/atoms/section'
    import gxFormText from '@/components/atoms/form/text'
    import gxFormTextarea from '@/components/atoms/form/textarea'
    import gxFormSelect from '@/components/atoms/form/select'
    import gxTag from '@/components/atoms/tag'
    import gxFormFile from '@/components/atoms/form/file'
    import gxButton from '@/components/atoms/button'
    import gxIconText from '@/components/atoms/icon-text'
    import gxIcon from '@/components/atoms/icon'
    import buttonWrap from '@/components/molecules/button-wrap'
    import ManualService from '@/services/manualService'
    import supportRegisterCheckModal from '@/components/organisms/support/registerCheckModal'
    import accountService from '@/services/accountService'

    import Const from '@/static/constants'
    import {
        supportService,
        TicketCreateParam,
        TicketSearchParam,
        TicketTag,
        TicketMailUser,
        TicketFile,
        EditCommentFile,
    } from '@/services/supportService'

    import i18n from '@/i18n'

    export default {
        name: 'support-register',
        components: {
            layoutMain,
            multiNotice,
            gxSection,
            gxFormText,
            gxFormTextarea,
            gxFormSelect,
            gxTag,
            gxFormFile,
            gxButton,
            gxIconText,
            gxIcon,
            buttonWrap,
            supportRegisterCheckModal,
        },
        props: {
            tags: {
                type: Array,
                "default": [],
            },
        },
        mixins: [CommonMixin],
        data() {
            return {
                Const: Const,
                data: {
                    support: Const.support,
                    // @TODO: イベント時のデフォルト値
                    editData: new TicketCreateParam({
                        status: Const.support.status.EGSS.value,
                    }),
                    mailList: [],  // 表示用メアドリスト
                    selectedMailAddr: undefined,
                    choosedMailAddrList: [],
                },
                mailAddrList: [],  // fetchしたメアドリスト元データ
                errors: {},
                isLoading: true,
                isLoadError: false,
                editTag: "",
                internalStatus: "0"
            }
        },
        computed: {
            ...mapGetters([
                'userAccount'
            ]),
            navs() {
                return [
                    {
                        to: '/support',
                        label: i18n.t('サポートチケット')
                    },
                    {
                        to: '/support/register',
                        label: i18n.t('新規チケット登録')
                    }
                ]
            },

        },
        methods: {
            ...mapActions([
                'fetchUserAccount'
            ]),
            reload() {
                this.fetchUserAccount()
                this.fetch()
            },
            async fetch() {
                this.isLoading = true
                this.isLoadError = false

                // メールアドレス送付先一覧を取得
                this.$data.mailAddrList = await supportService.getMailAddressList({})
                // 名前順でソート
                this.$data.mailAddrList.sort((obj0, obj1)=>{
                    if (obj0.username < obj1.username) {
                        return -1
                    } else if (obj0.username == obj1.username) {
                        return 0
                    } else {
                        return 1
                    }
                })
                // メールリスト(表示用)へ写す
                this.$data.data.mailList.splice(0, this.$data.data.mailList.length)
                for (let i in this.$data.mailAddrList) {
                    const addr = this.$data.mailAddrList[i]
                    this.$data.data.mailList.push({
                        str: `${addr.username} <${addr.email}>`,
                        value: addr.id,
                    })
                }
                // メールリスト(表示用)の先頭項目を選択
                if (this.$data.data.mailList.length > 0) {
                    this.$data.data.selectedMailAddr = this.$data.data.mailList[0].value
                }

                // ログインユーザー情報を取得
                const account = await accountService.userInfo()

                // メールアドレスを選択状態にする
                this.addUserMailAddr(account.id)

                if (this.userAccount.is_support){
                    this.addInternalStatus()
                }
                this.isLoading = false
            }, 
            async addInternalStatus() {
                // 内部ステータス一覧を取得
                let internalStatus = await supportService.getInternalStatus()
                this.internalStatus = internalStatus.results
                // 空欄を追加する
                this.internalStatus.push({id:undefined,internal_status:""})
                // 項目の順序を入れ替える
                this.internalStatus = this.internalStatus.reverse()
            },
            addUserMailAddr(userId) {
                const initialMailFilter = (obj, idx, arr) => {
                    return (obj.id == userId)
                }
                if (!this.$data.data.choosedMailAddrList.find(initialMailFilter)) {
                    const addrData = this.$data.mailAddrList.find(initialMailFilter)
                    if (addrData) {
                        this.$data.data.choosedMailAddrList.push(addrData)
                    }
                }
            },
            onCancel() {
                this.$data.data.editData.reset()
                this.$router.back()
            },
            onAddMailAddrButtonClick() {
                const selectedMailAddr = this.$data.data.selectedMailAddr
                const mailAddrFilter = (obj, idx, arr) => {
                    return (obj.id == selectedMailAddr)
                }
                if (selectedMailAddr) {
                    if (!this.$data.data.choosedMailAddrList.find(mailAddrFilter)) {
                        const addrData = this.$data.mailAddrList.find(mailAddrFilter)
                        this.$data.data.choosedMailAddrList.push(addrData)
                    }
                }
            },
            onMailAddrRemoveClick(id) {
                const removeIdx = this.$data.data.choosedMailAddrList.findIndex((obj, idx, arr)=>{
                    return (obj.id == id)
                })
                if (removeIdx >= 0) {
                    this.$data.data.choosedMailAddrList.splice(removeIdx, 1)
                }
            },
            onAddTagClick() {
                if (!this.editTag) return

                if (Array.isArray(this.tags)) {
                    this.tags.push(new TicketTag({tag_name: this.editTag}))
                    this.editTag = ""
                }
            },
            onTagClick(tag) {
                const removeIdx = this.tags.findIndex((obj, idx, arr)=>{return (obj.tag_id == tag.tag_id)})
                if (removeIdx >= 0) {
                    this.tags.splice(removeIdx, 1)
                }
            },
            onCommentFileChange() {
                if (this.$refs.commentFiles.size / 1024 > 3000) {
                    this.$emit('totalFileSizeOver', {
                        message: i18n.t('添付ファイルは合計3MB以下となるようにしてください')
                    })
                    return
                }
                const files = this.$refs.commentFiles.files
                this.$data.data.editData.ticket_file_set = []
                for (let i in files) {
                    this.$data.data.editData.ticket_file_set.push(new EditCommentFile({
                        file: files[i],
                        file_name: files[i].name,
                        file_content: files[i].base64,
                    }))
                }
            },
            buttons(disabled) {
                let d = disabled
                if (!this.$data.data.editData.content) {
                    d = true
                }
                if (this.$data.data.choosedMailAddrList.length <= 0) {
                    d = true
                }

                return [
                    {
                        text: i18n.t('OK'),
                        type: 'primary',
                        size: 'large',
                        disabled: d,
                        onClick: 'submit'
                    },
                    {
                        text: i18n.t('キャンセル'),
                        type: 'common',
                        size: 'large',
                        onClick: 'cancel'
                    },
                ]
            },
            getSelectorValues(options) {
                return i18n.translateOptionValues(options)
            },        
            /** 送信前の確認 **/
            async onEditCommentCheck() {
                this.data.editData.ticket_tag_set = Array.from(this.tags)
                this.$store.dispatch('openModalWithParam', {target: 'support-register-check-modal'})
            },  
            clearfile(){
                this.data.editData.ticket_file_set = []
            },            
            manual(){
  		        return '/manual/wiki/Support_new_' + i18n.locale
  		    },  
        },
        mounted() {
            if (this.$route.query.event_id) {
                this.$data.data.editData.waf_event = this.$route.query.event_id
            }
        },
        created() {
            this.fetch()
        },
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';

    .ticket_info {
        background-color: $color-parts-bg;
        border-radius: 15px;

        .item {
            padding: 0.5em 1em;
            display:flex;
            flex-flow: column;
            flex-direction: row;
            align-items: center;
            
            .label {
                font-weight: bold;
                width: 7em;
            }
            .data {
                flex: 1;
            }
        }

        .status {
            width: 300px;
        }

        .email {
            ul {
                display: inline-flex;
                flex-wrap: wrap;
                flex-direction: row;
                align-items: center;
                vertical-align: middle;

                height: 40px;

                li{
                    float: left;

                    .selected_mail_addr_tag {
                        font-size: 1em;
                    }
                }
            }
        }
        .title {
            display: flex;
            .data {
                .wrap {
                    max-width: inherit;
                }
            }
        }
        .content {
            display: flex;
            .data {
                .wrap {
                    max-width: inherit;
                }
            }
        }
    }
</style>
<style lang="scss">
    .ticket_info {
        .content {
            .data {
                .wrap {
                    textarea.input {
                        max-width: inherit;
                    }
                }
            }
        }
    }
    .selected_mail_addr {
        padding: 0.5em 1em;
    }
</style>
