<template>
    <validation-observer ref="commentEditForm" v-slot="{ invalid }">
            <gx-section>
                <multi-notice type="error" :terms="terms" :messages="setmessages(errors)"  />
                <div class="comment">
                    <div v-if="userAccount.is_support"  class="comment-box status">
                        <h2 class="comment-box__title">{{$t('ステータス')}}</h2>
                        <div class="comment-box__body">
                            <gx-form-select
                                v-model="editComment.status"
                                :options="getSelectorValues()"
                                value-attr="value"
                                str-attr="str"
                                class="actions__item"
                                :disabled="editComment.status == Const.support.status.CLOSE.value"
                                required
                            />
                        </div>
                    </div>
                    <div v-if="userAccount.is_support" class="comment-box internal_status">
                        <h2 class="comment-box__title">{{$t('内部ステータス')}}</h2>
                        <div class="comment-box__body">
                             <gx-form-select
                                v-model="editComment.internal_status"
                                :options="internalStatus"
                                value-attr="id"
                                str-attr="internal_status"
                                class="actions__item"
                                required
                                :disabled="editComment.status == Const.support.status.CLOSE.value"
                            />
                        </div>
                    </div>
                    <div class="comment-box content">
                        <h2 class="comment-box__title">{{$t('内容')}}</h2>
                        <div class="comment-box__body">
                            <gx-form-textarea
                                :placeholder="$t('内容')"
                                v-model="editComment.content"
                                :name="$t('内容')"
                                class="setting_value"
                                rules="required"
                                type="comment"
                                style= "heights:100%"
                                :required="!closeflg"
                            />
                        </div>
                    </div>
                    <div class="comment-box close">
                        <div>
                            <gx-form-checkbox
                                class="label"
                                @check="setCloseFlg()"
                            >
                            {{$t('チケットをクローズする')}}
                        </gx-form-checkbox>
                        </div>
                    </div>
                    <div v-if="userAccount.is_support && Array.isArray(tags)" class="comment-box tags">
                        <h2 class="comment-box__title">{{$t('タグ')}}</h2>
                        <div class="comment-box__body">
                            <gx-form-text
                                class="text"
                                :placeholder="$t('タグ')"
                                v-model="editTag"
                            />
                            <gx-button 
                                class="button"
                                :text="$t('追加')"
                                :type="'common'"
                                :size="'small'"
                                :disabled="!editTag"
                                @onClick="onAddTagClick"
                            />
                        </div>
                            <div class="tag">
                                <span class="selected_tags" v-for="tag in tags" :key="tag.tag_id">
                                    <gx-tag
                                        :text="tag.tag_name"
                                        />
                                    <span @click="onTagClick(tag)">✖</span>
                                </span>
                            </div>
                    </div>
                    <div class="comment-box file">
                        <h2 class="comment-box__title">{{$t('ファイル')}}</h2>
                        <div class="comment-box__body">
                            <gx-form-file
                                ref="commentFiles"
                                multiple
                                @change="onCommentFileChange"
                            />
                            <div v-if="(editComment.comment_file_set.length > 0)" v-for="(file, index) in editComment.comment_file_set" :key="index" class="file">
                                <dev v-if="file in file > 0">
                                    {{file.file_name}} … {{Math.ceil(file.file.size / 1024)}}[KB]  
                                </dev>
                                <dev v-else>
                                    {{file.file_name}}
                                </dev>
                                <small>
                                    <gx-icon :tag="'a'" href="#" :size="1.2" :icon="'close'" @onClick="clearfile"/>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
                <button-wrap :buttons="buttons(invalid)" class="button-wrap" @submit="onEditCommentCheck" @close="onCloseSubmit" />
                <div class="manual">
                    <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
                </div>  
            </gx-section>
    </validation-observer>
</template>
<script>
    import Const from '@/static/constants'
    import i18n from '@/i18n'

    import {
        TicketTag,
        EditComment, EditCommentFile,
    } from '@/services/supportService'

    import multiNotice from '@/components/molecules/multi-notice'
    import gxSection from '@/components/atoms/section'
    import gxFormText from '@/components/atoms/form/text'
    import gxFormTextarea from '@/components/atoms/form/textarea'
    import gxFormCheckbox from '@/components/atoms/form/checkbox'
    import gxFormSelect from '@/components/atoms/form/select'
    import gxFormFile from '@/components/atoms/form/file'
    import gxButton from '@/components/atoms/button'
    import gxIconText from '@/components/atoms/icon-text'
    import gxTag from '@/components/atoms/tag'
    import buttonWrap from '@/components/molecules/button-wrap'
    import ManualService from '@/services/manualService'
    import gxIcon from '@/components/atoms/icon'

    export default {
        name: "support-comment",
        components: {
            multiNotice,
            gxSection,
            gxFormText,
            gxFormTextarea,
            gxFormCheckbox,
            gxFormSelect,
            gxFormFile,
            gxButton,
            gxIconText,
            gxTag,
            buttonWrap,
            gxIcon,
        },
        props: {
            userAccount: {
                type: Object,
                "default": function() {
                    return {}
                }
            },
            errors: {
                type: Object,
                "default": function() {
                    return {}
                }
            },
            editComment: {
                type: Object,
                "default": function() {
                    return new EditComment({
                        content: "",
                        status: Const.support.status.USER.value,
                        comment_file_set: [],
                    })
                },
            },
            internalStatus: {
                type: Array,
                default: () => []
            },
            tags: {
                type: Array,
                "default": undefined,
            },
            buttonDisabled: {
                type: Boolean,
                "default": false,
            },
        },
        data: function() {
            return {
                Const: Const,
                terms: Const.support.messageModalTerms,
                editTag: "",
                closeflg:false
            }
        },
        computed: {
        },
        methods: {
            buttons(disabled) {
                let d = disabled
                if (this.editComment.content.length <= 0) {
                    d = true
                }
                if (!d && this.$refs.commentFiles) {
                    // トータル3MB以上はNG
                    d = Math.ceil(this.$refs.commentFiles.size / 1024) > 3000
                }
                if (!d && this.buttonDisabled) {
                    d = true
                }
                if (this.closeflg){
                    return [
                    {
                        text: i18n.t('クローズ'),
                        type: 'primary',
                        size: 'large',
                        disabled: false,
                        onClick: 'close',
                    },
                ]
                }
                return [
                    {
                        text: i18n.t('送信'),
                        type: 'primary',
                        size: 'large',
                        disabled: d,
                        onClick: 'submit',
                    },
                ]
            },
            onAddTagClick() {
                if (!this.$data.editTag) return

                if (Array.isArray(this.tags)) {
                    this.tags.push(new TicketTag({tag_name: this.$data.editTag}))
                    this.$data.editTag = ""
                }
            },
            onTagClick(tag) {
                const removeIdx = this.tags.findIndex((obj, idx, arr)=>{return (obj.tag_id == tag.tag_id)})
                if (removeIdx >= 0) {
                    this.tags.splice(removeIdx, 1)
                }
            },
            onCommentFileChange() {
                if (this.$refs.commentFiles.size / 1024 > 3000) {
                    this.$emit('totalFileSizeOver', {
                        message: i18n.t('添付ファイルは合計3MB以下となるようにしてください')
                    })
                    return
                }
                const files = this.$refs.commentFiles.files
                this.editComment.comment_file_set = []
                for (let i in files) {
                    this.editComment.comment_file_set.push(new EditCommentFile({
                        file: files[i],
                        file_name: files[i].name,
                        file_content: files[i].base64,
                    }))
                }
            },
            onEditCommentCheck() {
                this.editComment.ticket_tag_set = Array.from(this.tags)
                if(this.editComment.internal_status == Const.support.internalStatus.CLOSE.value ){
                    this.editComment.status = Const.support.status.CLOSE.value
                }
                this.$emit('submit', this.editComment)
            },
            onCloseSubmit() {
                if(this.editComment.content.length == 0 ){
                    this.editComment.content = "チケットをクローズしました"
                }
                this.$emit('close', this.editComment)
            },
            clearfile(){
                this.editComment.comment_file_set = []
            },
            manual(){
                  return '/manual/wiki/Support_comment_' + i18n.locale
              },
            getSelectorValues(){
                var statuslist = i18n.translateOptionValues(Const.support.statusArray)
                // 検索用の空欄を削除
                statuslist.splice(0, 1)
                if (this.userAccount.is_support){
                    // ユーザーの項目を削除
                    statuslist.splice(2, 1)
                }
                return statuslist
            },
            setCloseFlg(){
                this.closeflg = !this.closeflg
                if (this.closeflg){
                    // クローズフラグがtrueの場合、ステータス、内部ステータスをクローズにする
                    this.editComment.status = Const.support.status.CLOSE.value
                    this.editComment.internal_status = Const.support.internalStatus.CLOSE.value
                }else{
                    // 内部ステータスを未処理にする
                    this.editComment.internal_status = Const.support.internalStatus.UNTREATED.value
                    if (this.userAccount.is_support){
                        // サポートアカウントの場合、ステータスをユーザーにする
                        this.editComment.status = Const.support.status.USER.value
                    }else{
                        // ユーザーアカウントの場合、ステータスをEGSSにする
                        this.editComment.status = Const.support.status.EGSS.value
                    }
                }
            },
            setmessages(errors){
                // 内容のエラーメッセージのみ階層が違うため、データを抽出して表示させる
                var error = errors[`comment_set`]
                if(error){
                    return error[0]
                }else{
                    return errors
                }
            }
        },
        mounted() {
            if (this.editComment.status != Const.support.status.CLOSE.value){
                if (this.userAccount.is_support){
                    // サポートアカウントの場合、ステータス初期値をユーザーにする
                    this.editComment.status = Const.support.status.USER.value
                }else{
                    // ユーザーアカウントの場合、ステータス初期値をEGSSにする
                    this.editComment.status = Const.support.status.EGSS.value
                    this.editComment.internal_status = Const.support.internalStatus.UNTREATED.value
                }
            }
            return this.editComment.status
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .comment {
        width: 100%;
        overflow: hidden;
        padding-left: 20px;
        padding-right: 20px;
        display: grid;
        grid-gap: 24px;
        grid-template-columns: 1fr 1fr 1fr ;
        grid-template-areas:
            "content_area content_area file"
            "content_area content_area status"
            "content_area content_area internal_status "
            "content_area content_area tags "
            "close . ."
        ;
        @include mq(sm) {
            grid-template-areas:
                "status"
                "internal_status"
                "content_area"
                "tags"
                "file"
                "close"
            ;
        }
        @include mq(md) {
            grid-template-areas:
            "content_area content_area file"
            "content_area content_area status"
            "content_area content_area internal_status "
            "content_area content_area tags "
            "close . ."
            ;
        }
        @include mq(xl) {
            grid-template-areas:
            "content_area content_area file"
            "content_area content_area status"
            "content_area content_area internal_status "
            "content_area content_area tags "
            "close . ."
            ;
        }


        &-box {
            background-color: $color-parts-bg;

            display: flex;
            flex-direction: column;
            width: 100%;
            overflow: hidden;

            &.status {
                grid-area: status;
            }
            &.internal_status {
                grid-area: internal_status;
            }
            &.content {
                grid-area: content_area;
            }
            &.close {
                grid-area: close;
            }
            &.tags {
                grid-area: tags;
            }
            &.file {
                grid-area: file;
            }
            &__title {
                width: 100%;
                height: 32px;
                line-height: 32px;
                padding: 0 16px;
                font-size: 1.6rem;
                font-weight: bold;
                background: $color-parts-accent;
                &__link {
                    float: right;
                }
            }
            &__body {
                flex: 1;

                display:flex;
                flex-direction: row;
//                align-items: center;
                flex-wrap: nowrap;
            }

        }
        &-graf {
            padding: 16px;
        }
    }
    .setting_value ::v-deep {
        display: block;

        .input {
            max-width: 100%;
        }
        .field {
            max-width: 100%;
        }
    }
    .selected_tags {
          padding: 0.5em 0.25em;
      }.status_edit{
        padding: 0.5em 0.25em;
    }
</style>