<template>
    <layout-main
        :title="$t('証明書一覧')"
        :navs="navs"
    >
        <template #page-header>
            <table v-if=" (!userAccount.is_support || userAccount.role == 80) && (displayAuto || displayUpload)">
                <tbody>
                    <tr>
                        <td colspan="2"><div>{{ $t('新規追加') }}</div></td>
                    </tr>
                    <tr>
                        <td>
                            <gx-button
                                v-if=displayAuto
                                :text="$t('自動')"
                                @onClick="register('auto')"
                            />
                        </td>
                        <td>
                            <gx-button
                                v-if=displayUpload
                                :text="$t('アップロード')"
                                @onClick="register('upload')"
                            />
                        </td>
                    </tr>      
                </tbody>  
            </table>             
        </template>
        <div class="support-main">
            <certificate-search :params="searchParam" :userAccount="userAccount" @updateQuery="updateQuery" @search="doSearch" />
            <loading v-if="isLoading" />
            <div v-else>
                <template>
                    <table>
                        <tbody>
                            <tr>
                              <td style="text-align: left">
                                {{$t('自動証明書') + ' :　'}}
                              </td>
                              <td class = "title">
                                {{$t('{0}件', [cnt.auto_cert_count]) + "  " + $t('※最大{0}件まで登録可能です', [cnt.auto_cert_max])}}  
                              </td>
                            </tr>
                            <tr>
                              <td style="text-align: left">
                                {{$t('アップロード証明書') + ' :　'}}
                              </td>
                              <td>
                                {{$t('{0}件', [cnt.upload_cert_count]) + "  " + $t('※最大{0}件まで登録可能です', [cnt.upload_cert_max])}}  
                              </td>
                            </tr>
                        </tbody>
                     </table>
                </template>
                <certificate-table
                    :checked="checked"
                    :certificates="certificates"
                    :sort="sort"
                    @updateOrder="updateOrder"
                />
                <pager :pager="pager" @changePage="changePage" @changePageSize="changePageSize" />
            </div>
        </div>
        <template v-if="$store.state.isModal" #modal>
            <certificate-detail-modal v-if="$store.state.modalTarget == 'certificate-detail'" @reload="reload_register"/>
            <certificate-delete-modal v-if="$store.state.modalTarget == 'certificate-delete'" @reload="reload" />
            <certificate-upload-modal v-if="$store.state.modalTarget == 'certificate-upload'" @reload="reload" />
            <certificate-auto-modal v-if="$store.state.modalTarget == 'certificate-auto'"  @reload="reload" />
            <certificate-confirmation-auto-modal v-if="$store.state.modalTarget == 'certificate-confirmation-auto'" @reload="reload" />
            <certificate-confirmation-upload-modal v-if="$store.state.modalTarget == 'certificate-confirmation-upload'" @reload="reload" />

        </template>
        <div class="manual">
            <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
        </div>
    </layout-main>
</template>
<script>
    import { diff } from 'deep-object-diff'
    import { mapGetters, mapActions } from 'vuex'

    import layoutMain from '@/layouts/main'
    import loading from '@/components/atoms/loading'
    import pager from '@/components/molecules/pager'
    import gxFormSelect from '@/components/atoms/form/select'
    import gxButton from '@/components/atoms/button'
    import gxIconText from '@/components/atoms/icon-text'

    import certificateTable from '@/components/organisms/certificate/table'
    import certificateSearch from '@/components/organisms/certificate/search'
    import certificateDetailModal from '@/components/organisms/certificate/detail-modal'
    import certificateDeleteModal from '@/components/organisms/certificate/delete-modal'
    import certificateUploadModal from '@/components/organisms/certificate/upload-modal'
    import certificateAutoModal from '@/components/organisms/certificate/auto-modal'
    import certificateConfirmationAutoModal from '@/components/organisms/certificate/confirmation-auto-modal'
    import certificateConfirmationUploadModal from '@/components/organisms/certificate/confirmation-upload-modal'
    import ManualService from '@/services/manualService'
    import accountService from '@/services/accountService'

    import DateMixin from '@/mixin/date'

    import i18n from '@/i18n'

    import Const from '@/static/constants'
    import {
        certificateService,
        TicketCreateParam, CertificateSearchParam, TicketUpdateParam, BulkUpdateParam, 
        EditComment,
    } from '@/services/certificateService'

    export default {
        name: 'certificate',
        components: {
            layoutMain,
            loading,
            gxFormSelect,
            gxButton,
            gxIconText,
            certificateTable,
            certificateSearch,
            certificateDetailModal,
            certificateDeleteModal,
            certificateUploadModal,
            certificateAutoModal,
            certificateConfirmationAutoModal,
            certificateConfirmationUploadModal,
            pager,
        },
        mixins: [DateMixin],
        data() {
            return {
                searchParam: new CertificateSearchParam({}),
                errors: {},
                commentErrors: {},
                sort: {
                    field: '',
                    order: ''
                },
                checked: [],
                certificates: [],
                isLoading: true,
                isLoadError: false,
                pager: {},
                displayAuto: true,
                displayUpload: true,
            }
        },
        computed: {
            ...mapGetters([
                'userAccount'
            ]),
            buttons() {
                return [{
                    text: i18n.t('有効化'),
                    size: 'large'
                }]
            },
        },
        methods: {
            ...mapActions([
                'fetchUserAccount'
            ]),
            reload() {
                this.fetchUserAccount()
                this.fetch()
            },
            reload_register() {
                this.fetchUserAccount()
                this.fetch()
            },
            async fetch(isSearching) {
                let _this = this;

                this.isLoading = true
                this.isLoadError = false
                this.cnt = await certificateService.getCnt()
                this.disablebutton("auto")
                this.disablebutton("upload")
                try {
                    // Get certificate list
                    let params = {}

                    const p = (!isSearching) ? this.urlQuery() : this.$data.searchParam
                    for (let key in p) {
                        let v = p[key]
                        // 空文字とundefinedは値に含めない
                        if (!(typeof v == "string" && v.length <= 0) && (v != undefined)) {
                            params[key] = v
                        }
                    }

                    if (this.$data.sort) {
                        let orderParam = this.$data.sort.field
                        if(this.$data.sort.order == 'desc') orderParam = `-${orderParam}`
                        params.o = orderParam
                    }
                    let res = await certificateService.list(params)
                    this.$data.certificates.splice(0, this.$data.certificates.length)
                    for (let i in res.results) {
                        this.$data.certificates.push(res.results[i])
                    }
                    this.$set(this.$data, 'pager', res.page ? res.page : {})

                    this.$data.checked.splice(0, this.$data.checked.length)
                } catch(e) {
                    this.isLoadError = true
                } finally {
                    this.isLoading = false
                }
            },
            updateQuery(key, val) {
                this.$set(this.searchParam, key, val)
            },
            doSearch() {
                let query = {}
                Object.assign(query, this.searchParam)
                if (Object.keys(diff(query, this.urlQuery())).length !== 0) {
                    this.$router.replace({path: "/certificate", query: query})
                }
                this.fetch(true)
            },
            urlQuery() {
                return this.$route.query
            },
            updateOrder(field, order) {
                this.sort.field = field
                this.sort.order = order
                this.fetch(true)
            },
            changePage(page) {
                this.updateQuery('page', page)
                this.fetch(true)
            },
            changePageSize(size) {
                this.updateQuery('page_size', size)
                this.updateQuery('page', 1)
                this.fetch(true)
            },
            async onSearchButtonClick() {
                await this.fetch(true)
            },
            async save(params) {
                this.$store.dispatch('displayLoading')
                this.errors = {}

                await certificateService.registerUpload(params)
                    .then(() => {
                        this.$store.dispatch('openToast', {type: 'success', label: i18n.t('保存しました')})
                        this.locationToWebsiteSearch()
                    }).catch(err => {
                        if (err.response.status == 400) {
                            this.errors = err.response.data
                        } else {
                            this.$store.dispatch('openToast', {type: 'error', label: i18n.t('保存に失敗しました')})
                        }
                    })
                this.$store.dispatch('hideLoading')
            },
            async register(type) {
                if(type == "auto"){
                    this.$store.dispatch('openModalWithParam', {target: 'certificate-auto', param: {uuid: "", isEdit: false, detailType:"new"}}) 
                }else{
                    this.$store.dispatch('openModalWithParam', {target: 'certificate-upload', param: {uuid: "", isEdit: false, detailType:"new"}})
                }
            },
            async deleteItem() {
                this.$store.dispatch('openModalWithParam', {target: 'certificate-delete', param: {uuid: this.certificateId}})
            },
            disablebutton(type){
                let sumcnt = this.cnt.upload_cert_count + this.cnt.auto_cert_count
                if (type == "auto"){
                    if (this.cnt.auto_cert_count < this.cnt.auto_cert_max &&
                        sumcnt < this.cnt.cert_max
                    ){
                        this.displayAuto = true
                    }else{
                        this.displayAuto = false
                    }
                }else if (type == "upload"){
                    if (this.cnt.upload_cert_count < this.cnt.upload_cert_max &&
                        sumcnt < this.cnt.cert_max
                    ){
                        this.displayUpload = true
                    }else{
                        this.displayUpload = false
                    }
                }
            },
            /** 送信前の確認 **/
            async onEditCommentCheck() {
                this.$store.dispatch('openModalWithParam', {target: 'support-comment-check-modal', param: this.$route.params.uuid})
            },      
            cancel() {
                this.$store.dispatch('openModalWithParam', {target: 'support-commentAll-modal', param: this.$route.params.uuid})
            },
            manual(){
  		        return '/manual/wiki/Certificate_list_' + i18n.locale
  		    },              
        },
        async created() {  		
            const urlQuery = JSON.parse(JSON.stringify(this.urlQuery()))
            Object.entries(urlQuery).forEach(([key, value]) => {
                this.$set(this.searchParam, key, value)
            })
            this.doSearch() 
        }
    }
</script>
<style lang="scss" scoped>

</style>
