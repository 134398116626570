<template>
    <validation-observer ref="form" v-slot="{ invalid }">
        <notice v-if="isFormError" class="notice" :type="'error'" :label="$t('入力内容に不備があります')" >
            {{$t('メッセージを確認の上もう一度入力してください')}}
        </notice>

        <data-list :data="terms">
            <template #title={item}>
                <term-title v-if="isEdit && item !== 'username'" :required="requiredItem(item)">{{$t(getTerms(item))}}</term-title>
                <template v-else>{{$t(getTerms(item))}}</template>
            </template>
            <template #data={item}>
                <template v-if="item == 'username'">{{ username }}</template>

                <template v-else-if="item == 'protocol'">
                    <term-list v-if="isEdit" :items="fmtTypeToSelection(protocolTypes)">
                        <template #item={termItem}>
                            <span class="upload-wrap">
                                <gx-form-radio
                                    :text="$t(termItem.str)"
                                    :value="termItem.value"
                                    :name="item"
                                    :checked="termItem.value == editData[item]"
                                    @change="updateValue(item, $event)" />

                            </span>
                        </template>
                    </term-list>
                    <template v-if="isEdit">
                        <gx-form-checkbox
                            class="label"
                            value="'ON'"
                            :disabled="!isSSL"
                            :checked="editData.redirect_http_to_https_flg"
                            @check="updateValue('redirect_http_to_https_flg', $event.target.checked)">
                            {{$t('HTTPをHTTPSにリダイレクトする')}}
                        </gx-form-checkbox>
                            <gx-button
                                class="certificate"
                                size="certificate"
                                type="common"
                                :disabled="!isSSL || !certificateSize"
                                :text="$t('証明書選択')"
                                v-model="certificate"
                                @onClick="selectCertificate()"
                            />
                            <div v-if="isSSL && !certificateSize" class="error">
                                {{$t("選択可能な証明書がありません")}}
                            </div>
                            <div v-if="isSSL">
                                {{$t(getHostName(editData['certificate']))}}
                            </div>

                        <website-select-certificate-modal v-if="$store.state.modalTarget == 'website-select-certificate'" @certificate="data => certificate(data)"/>
                        <certificate-detail-modal v-if="$store.state.modalTarget == 'certificate-detail'" />

                    </template>
                    <template v-else>
                        {{ $t(getProtocolType(propData[item])) }}
                        <span v-if="isSSL" class="file">
                            {{$t(certificateDate())}}
                        </span>
                        <div v-if="isSSL" >
                            {{$t(getHostName(propData['certificate']))}}
                        </div>
                        <div v-if="isSSL" class="redirect-wrap">
                            <span class="icon">
                                <gx-icon-text
                                    :text="$t(redirectHttpToHttpsStyles[propData.redirect_http_to_https_flg].str)"
                                    :icon="redirectHttpToHttpsStyles[propData.redirect_http_to_https_flg].icon"
                                    :color="getColor(redirectHttpToHttpsStyles[propData.redirect_http_to_https_flg].color)" />
                            </span>
                            <span class="label">{{$t('HTTPをHTTPSにリダイレクトする')}}</span>
                        </div>
                    </template>
                </template>

                <template v-else-if="item == 'hostname'">
                    <template v-if="isEdit">
                        <gx-form-text
                            v-if="isEdit"
                            v-model="hostName"
                            required
                            :placeholder="'www.example.com'"
                            :term="$t(getTerms(item))"
                            :name="item"
                            :errors="errors['non_field_errors'] || errors[item] "
                            :patterns="'WILDCARD_HOST'"
                            :ref="`input-${item}`" />
                    </template>
                    <template v-else>
                        {{ propData[item] }}
                    </template>
                </template>

                <template v-else-if="item == 'real_protocol'">
                    <term-list v-if="isEdit" :items="fmtTypeToSelection(protocolTypes)">
                        <template #item={termItem}>
                            <gx-form-radio
                                :text="$t(termItem.str)"
                                :value="termItem.value"
                                :name="item"
                                :checked="termItem.value == editData[item]"
                                @change="updateValue(item, $event)" />
                        </template>
                    </term-list>
                    <template v-else>{{ $t(getProtocolType(propData[item])) }}</template>
                </template>

                <template v-else-if="item == 'real_server'">
                    <template v-if="isEdit">
                        <gx-form-textarea
                            class="real_server"
                            v-model="realServer"
                            :placeholder="'www.example.com'"
                            :term="$t(getTerms(item))"
                            :name="item"
                            :errors="errors[item]"
                            :note="$t('5件まで同時指定可能／複数指定をする際は、指定ごとに改行してください')"
                            :patterns=getrealServer(realServer)
                            required
                            :ref="`input-${item}`" />
                            <div class="data-new-line">
                            <gx-form-checkbox
                                class="label"
                                :value="'ON'"
                                :disabled="!isProxySSL"
                                :checked="editData.proxy_ssl_server_name_flg"
                                @check="updateValue('proxy_ssl_server_name_flg', $event.target.checked)">
                                {{$t('SNI')}}
                            </gx-form-checkbox>
                            <gx-form-text
                                class="sni-wrap vertical-align-top"
                                name="proxy_ssl_name"
                                :term="$t('SNI')"
                                v-model="editData.proxy_ssl_name"
                                :disabled="!isProxySSL || !editData.proxy_ssl_server_name_flg"
                                :errors="errors.proxy_ssl_name"
                                :placeholder="'www.example.com'"
                                :note="$t('サーバー名を省略した場合、サーバー名にはホスト名が設定されます')"
                                :patterns="['HOST']"
                                :maxlength="100" />
                        </div>                            
                    </template>
                    <template v-else>
                        <div v-for="(value, index) in propData[item].split('\n') " :key=index>
                            {{ value }}
                        </div>
                        <div class="data-new-line">
                            <span class="icon">
                                <gx-icon-text
                                    :text="$t(sniSwitchStyles[propData.proxy_ssl_server_name_flg].str)"
                                    :icon="sniSwitchStyles[propData.proxy_ssl_server_name_flg].icon"
                                    :color="getColor(sniSwitchStyles[propData.proxy_ssl_server_name_flg].color)" />
                                <span v-if="propData.proxy_ssl_name" class="server_name">
                                    {{ propData.proxy_ssl_name }}
                                </span>
                            </span>
                            <span>{{$t('SNI')}}</span>
                        </div>
                    </template>
                </template>

                <template v-else-if="item == 'protection'">
                    <gx-form-switch
                        v-if="isEdit"
                        :checked="protectionValue"
                        @change="updateSwitchValue(item, $event)" />
                    <gx-icon-text
                        v-else
                        :text="$t(protectionStyles[propData[item]].str)"
                        :icon="protectionStyles[propData[item]].icon"
                        :color="getColor(protectionStyles[propData[item]].color)" />
                </template>

                <template v-else-if="item == 'action'">
                    <term-list v-if="isEdit" :items="actionStyles">
                        <template #item={termItem}>
                            <gx-form-radio
                                :value="termItem.value"
                                :name="item"
                                :disabled="editData.protection != 1"
                                :checked="termItem.value == editData[item]"
                                @change="updateValue(item, $event)">
                                <gx-icon-text
                                    :text="$t(termItem.str)"
                                    :icon="termItem.icon"
                                    :color="getColor(termItem.color)" />
                            </gx-form-radio>
                        </template>
                    </term-list>
                    <gx-icon-text
                        v-else-if="propData[item] != null"
                        :text="$t(actionStyles[propData[item]].str)"
                        :icon="actionStyles[propData[item]].icon"
                        :color="getColor(actionStyles[propData[item]].color)" />
                </template>

                <template v-else-if="item == 'block_action'">
                    <div class="action-wrap">
                        <div class="label">{{$t('リクエスト')}}</div>
                        <term-list v-if="isEdit" :items="fmtTypeToSelection(reqBlockActionTypes)">
                            <template #item={termItem}>
                            <span class="upload-wrap">
                                <gx-form-radio
                                    :value="termItem.value"
                                    :name="`req_${item}`"
                                    :disabled="isDisableAction"
                                    :checked="termItem.value == editData[`req_${item}`]"
                                    :text="$t(termItem.str)"
                                    @change="updateValue(`req_${item}`, $event)" />
                                <gx-form-file
                                    v-if="termItem.value == blockActions.REDIRECT"
                                    :disabled="isDisableRedirectFile"
                                    :required="editData[`req_${item}`] == blockActions.REDIRECT && propData.req_block_action != blockActions.REDIRECT"
                                    :label="$t('リダイレクトファイル')"
                                    :accept="'.html'"
                                    class="file"
                                    :errors="item.res_html"
                                    @change="updateValue('res_html', $event)">
                                    <template #note v-if="!propData.has_res_html">
                                        {{$t('リダイレクトはファイル選択が必須です')}}
                                    </template>
                                </gx-form-file>

                            </span>
                            </template>
                        </term-list>
                        <template v-else-if="propData[`req_${item}`]">
                            {{ $t(getReqBlockActionType(propData[`req_${item}`])) }}
                            <span v-if="propData[`req_${item}`] == blockActions.REDIRECT" class="file">{{$t('リダイレクトファイル設定済')}}</span>
                        </template>
                    </div>

                </template>
                <template v-else-if="item == 'state'">
                    {{ $t(getStateType(propData[item])) }}
                </template>
                <template v-else-if="item == 'advance_settings'">
                    <template v-if="isEdit && isShowed">
        				<gx-icon-text 
                            :text="$t('{0}を閉じる', [$t('高度な設定')])"
	        				icon="arrow-down" @onClick="toggleBox()" />
		        	</template>
			        <template v-else-if="isEdit && !isShowed">
				        <gx-icon-text 
                            :text="$t('{0}を開く', [$t('高度な設定')])"
	    				    icon="arrow-up" @onClick="toggleBox()" />
        			</template>
                    <template v-if="isEdit && isShowed">
                        <div class="advance_title">{{$t('ホスト名')}}</div>
                        <div class="data-new-line">
                            <gx-form-checkbox
                                value="'ON'"
                                :disabled="!startsWithWww"
                                :checked="editData.allow_naked_domain_flg"
                                @check="updateValue('allow_naked_domain_flg', $event.target.checked)">
                                {{$t('wwwなしのドメインにも対応する')}}
                            </gx-form-checkbox>
                        </div>
                        <div class= "advance_title">{{$t('実サーバー')}}</div>
                        <div class="data-new-line">
                            <gx-form-checkbox
                                class="label"
                                value="'ON'"
                                :checked="editData.use_host_header"
                                @check="updateValue('use_host_header', $event.target.checked)">
                                {{$t('Hostヘッダを指定する')}}
                            </gx-form-checkbox>
                            <gx-form-text
                                class="sni-wrap"
                                name="host_header"
                                :term="$t('Hostヘッダを指定する')"
                                v-model="editData.host_header"
                                :disabled="!editData.use_host_header"
                                :errors="errors.host_header"
                                :placeholder="'www.example.com'"
                                :patterns="['IP_ADDRESS','HOST']"
                                :maxlength="100" />
                            <div>
                                <gx-form-checkbox
                                    class="label"
                                    value="'ON'"
                                    :checked="editData.use_ip_hash"
                                    :disabled="realServer_host_flg"
                                    @check="updateValue('use_ip_hash', $event.target.checked)">
                                    {{$t('クライアントIPアドレスに基づいて実サーバーに振り分ける')}}
                                </gx-form-checkbox>
                            </div>
                            <div>{{$t('レスポンスタイムアウト値')}}</div>
                            <gx-form-text
                                class="proxy_time"
                                v-model.number="editData.proxy_read_timeout"
                                :errors="errors.proxy_read_timeout"
                                :term="$t('レスポンスタイムアウト値')"
                                :placeholder="'600'"
                                :type="'number'"
                                :max="600"
                                :min="5"
                                :max-length="3"
                                :suffix="$t('秒')"
                                :note="$t('半角整数：{0}〜{1}', [5, 600])" />                     
                            </div>
                        <div class= "advance_title">{{$t('X-Forwarded-For')}}
                            <span class="note">{{$t('※本項目を編集したい場合はサポートにお問い合わせください')}}</span>
                        </div>
                        <div class="data-new-line">
                                <gx-form-checkbox
                                    value="'ON'"
                                    :checked="editData.real_ip_header_xff"
                                    :disabled="!userAccount.is_support"
                                    @check="updateValue('real_ip_header_xff', $event.target.checked)">
                                    {{$t('クライアントIPアドレスをX-Forwarded-Forから取得')}}
                                </gx-form-checkbox>
                            <div  class="data-new-line">
                            <span :class=getclass(userAccount.is_support)>{{$t('クライアントIPアドレスの読み込み順')}}</span>
                            <term-list v-if="isEdit" :items="IPRecursiveTypes">
                                <template #item={termItem}>
                                    <span class="upload-wrap">
                                        <gx-form-radio
                                        :text="$t(termItem.str)"
                                        :value="termItem.value"
                                        name="real_ip_recursive"
                                        :disabled=isIPRecursive
                                        :checked="termItem.value == editData['real_ip_recursive']"
                                        @change="updateValue('real_ip_recursive', $event)" />
                                    </span>
                                </template>
                            </term-list>
                            </div>
                        </div>
                    </template>
                    <template v-if="!isEdit">
                        <div v-if="editData.allow_naked_domain_flg">
                            <div>{{$t('ホスト名')}}</div>
                            <div class="data-new-line">
                                <span class="icon">
                                    <gx-icon-text
                                        :text="$t(allowNakedDomainStyles[propData.allow_naked_domain_flg].str)"
                                        :icon="allowNakedDomainStyles[propData.allow_naked_domain_flg].icon"
                                        :color="getColor(allowNakedDomainStyles[propData.allow_naked_domain_flg].color)" />
                                </span>
                                <span>{{$t('wwwなしのドメインにも対応する')}}</span>
                            </div>
                        </div>
                        <div  v-if="editData.use_host_header || editData.use_ip_hash || editData.proxy_read_timeout != 60" >
                            <div v-if="editData.allow_naked_domain_flg" class= "advance_title">{{$t('実サーバー')}}</div>
                            <div v-else>{{$t('実サーバー')}}</div>
                            <div v-if="editData.use_host_header" class="data-new-line">
                                <span class="icon">
                                    <gx-icon-text
                                        :text="$t(sniSwitchStyles[propData.use_host_header].str)"
                                        :icon="sniSwitchStyles[propData.use_host_header].icon"
                                        :color="getColor(sniSwitchStyles[propData.use_host_header].color)" />
                                    <span v-if="propData.host_header" class="server_name">
                                        {{ propData.host_header }}
                                    </span>
                                </span>
                                <span>{{$t('Hostヘッダを指定する')}}</span>
                            </div>
                            <div v-if="editData.use_ip_hash" class="data-new-line">
                                <span class="icon">
                                    <gx-icon-text
                                        :text="$t(realIpHeaderXffStyles[propData.use_ip_hash].str)"
                                        :icon="realIpHeaderXffStyles[propData.use_ip_hash].icon"
                                        :color="getColor(realIpHeaderXffStyles[propData.use_ip_hash].color)" />
                                </span>
                                <span>{{$t('クライアントIPアドレスに基づいて実サーバーに振り分ける')}}</span>
                            </div>
                            <div v-if="editData.proxy_read_timeout != 60" class="data-new-line">
                                <span class="icon">
                                    <gx-icon-text
                                        :text="$t(editData.proxy_read_timeout) + $t('秒')"
                                        :color="white" />
                                </span>
                                <span>{{$t('レスポンスタイムアウト値')}}</span>
                            </div>
                        </div>
                        <div  v-if="editData.real_ip_header_xff" >
                            <div v-if="editData.allow_naked_domain_flg || editData.use_host_header || editData.proxy_read_timeout != 60" class= "advance_title">{{$t('X-Forwarded-For')}}</div>
                            <div v-else>{{$t('X-Forwarded-For')}}</div>
                            <div class="data-new-line">
                                <span class="icon">
                                    <gx-icon-text
                                        :text="$t(realIpHeaderXffStyles[propData.real_ip_header_xff].str)"
                                        :icon="realIpHeaderXffStyles[propData.real_ip_header_xff].icon"
                                        :color="getColor(realIpHeaderXffStyles[propData.real_ip_header_xff].color)" />
                                </span>
                                
                                <span>
                                    {{$t('クライアントIPアドレスをX-Forwarded-Forから取得')}}
                                </span>
                            </div>
                            <div class= "data-new-line" >
                                {{ $t(getIPRecursive(propData["real_ip_recursive"])) }}
                            </div>
                        </div>
                    </template>
                </template>              
            </template>
        </data-list>

        <button-wrap v-if="isEdit" :buttons="buttons(invalid)" class="button-wrap" @submit="submit" @cancel="$emit('cancel')" />
        <div class="manual">
            <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
        </div>   
    </validation-observer>
</template>

<script>
    import Const from '@/static/constants'
    import {mapGetters} from "vuex";
    import CommonMixin from '@/mixin/common'
    import DateMixin from '@/mixin/date'

    import gxFormRadio from '@/components/atoms/form/radio'
    import gxFormFile from '@/components/atoms/form/file'
    import gxFormText from '@/components/atoms/form/text'
    import gxFormTextarea from '@/components/atoms/form/textarea'
    import gxFormSwitch from '@/components/atoms/form/switch'
    import gxFormCheckbox from "@/components/atoms/form/checkbox";
    import gxIconText from '@/components/atoms/icon-text'
    import gxButton from '@/components/atoms/button'
    import gxLabel from '@/components/atoms/label'
    import dataList from '@/components/molecules/data-list'
    import termList from '@/components/molecules/form/term-list'
    import termTitle from '@/components/molecules/form/term-title'
    import buttonWrap from '@/components/molecules/button-wrap'
    import notice from "@/components/molecules/notice";
    import websiteSelectCertificateModal from '@/components/organisms/website/select-certificate-modal'
    import certificateDetailModal from '@/components/organisms/certificate/detail-modal'

    import {
        certificateService,
    } from '@/services/certificateService'


    import i18n from '@/i18n'

    export default {
        name: 'website-basic-form',
        mixins: [CommonMixin, DateMixin],
        components: {
            gxFormCheckbox,
            gxFormRadio,
            gxFormFile,
            gxFormText,
            gxFormTextarea,
            gxFormSwitch,
            gxIconText,
            gxButton,
            gxLabel,
            dataList,
            termList,
            termTitle,
            notice,
            buttonWrap,
            websiteSelectCertificateModal,
            certificateDetailModal
        },
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            propData: {
                type: Object,
                default: () => ({})
            },
            certificateList: {
                type: Array,
                default: () => ({})
            },
            errors: {
                type: Object,
                default: () => ({})
            },
        },
        data() {
            return {
                editData: {},
                isShowed: false,
                isShowConfirmModal: false,
                CertificateID: "",
                certificateSize: false,
            }
        },
        created() {
            this.initializeEditData()
        },
        watch: {
            isEdit(b) {
                if (b) this.initializeEditData()
            },
            isFormError(b) {
                if (b) this.scrollToTop()
            }
        },
        computed: {
            ...mapGetters([
                'userAccount'
            ]),
            isFormError() {
                return Object.keys(this.errors).length > 0
            },
            isNew() {
                return Object.keys(this.propData).length == 0
            },
            terms() {
                const terms = [
                    'username',
                    'protocol',
                    'hostname',
                    'real_protocol',
                    'real_server',
                    'protection',
                    'action',
                    'block_action',
                    'advance_settings',
                ]
                if (!this.isEdit) {
                    terms.splice(8,0,'state')
                }
                return terms
            },
            buttons() {
                return disabled => [{
                    text: i18n.t('保存'),
                    type: 'primary',
                    size: 'large',
                    disabled: disabled || (this.realServerCheck && this.isNew && !this.userAccount.is_website_creatabl) || (this.isSSL && !this.editData.certificate),
                    onClick: 'submit',
                }, {
                    text: i18n.t('キャンセル'),
                    type: 'common',
                    size: 'large',
                    onClick: 'cancel',
                }]
            },
            username() {
                if (this.propData.username) return this.propData.username
                return this.userAccount ? this.userAccount.username : ''
            },
            hostName: {
                set(val) {
                    this.updateValue('hostname', val.toLowerCase())
                },
                get() {
                    return this.editData.hostname
                }
            },
            realServer: {
                set(val) {
                    this.updateValue('real_server', val.toLowerCase())
                },
                get() {
                    return this.editData.real_server
                }
            },
            protectionValue() {
                return this.propData.protection == Const.website.protection.ON;
            },
            protocolTypes: () => Const.website.protocolType,
            redirectHttpToHttpsStyles: () => Const.website.redirectHttpToHttpsStyle,
            IPRecursiveTypes: () => Const.website.IPRecursiveType,
            allowNakedDomainStyles: () => Const.website.allowNakedDomainStyle,
            realIpHeaderXffStyles:() => Const.website.realIpHeaderXffStyles,
            sniSwitchStyles: () => Const.website.sniSwitchStyle,
            setHeaderRealHostSwitchStyles: () => Const.website.setHeaderRealHostSwitchStyle,
            protectionStyles: () => Const.website.protectionStyle,
            actionStyles: () => Const.website.actionStyle,
            reqBlockActionTypes: () =>  Const.website.reqBlockActionType,
            blockActions: () => Const.website.blockAction,
            startsWithWww() {
                return this.editData.hostname.startsWith(Const.website.sub_domain.WWW) == true
            },
            isSSL() {
                return this.editData.protocol == Const.website.protocol.HTTPS
            },
            isProxySSL() {
                return this.editData.real_protocol == Const.website.protocol.HTTPS
            },
            isDisableAction() {
                return this.editData.protection == Const.website.protection.OFF ||
                    this.editData.action != Const.website.action.BLOCK
            },
            isIPRecursive() {
                if (!this.userAccount.is_support){
                    return true
                }else{
                    if (!this.editData.real_ip_header_xff){
                        return true
                    }else{
                        return false
                    }
                }
                
            },
            isDisableRedirectFile() {
                return this.isDisableAction || this.editData.req_block_action != Const.website.blockAction.REDIRECT
            }
        },
        methods: {
            
            getSelectorValues() {
                return Object.entries(Const.website.XFFLevelType).map(([str, name]) => (
                    {'str': str, 'name': name}
                ));
            },
            async initializeEditData() {
                this.editData = {
                    protocol: this.propData.protocol || Const.website.protocol.HTTP,
                    certificate: this.propData.certificate || '',
                    certificate_name: this.propData.certificate_name || '',
                    hostname: this.propData.hostname || '',
                    real_protocol: this.propData.real_protocol || Const.website.protocol.HTTPS,
                    real_server: this.propData.real_server || '',
                    real_ip_header_xff: this.propData.real_ip_header_xff || false,
                    real_ip_recursive:  this.propData.real_ip_recursive || false,
                    redirect_http_to_https_flg: this.propData.redirect_http_to_https_flg || false,
                    allow_naked_domain_flg: this.propData.allow_naked_domain_flg || false,
                    proxy_ssl_server_name_flg: this.propData.proxy_ssl_server_name_flg,
                    use_host_header: this.propData.use_host_header || false,
                    host_header : this.propData.host_header  || '',
                    use_ip_hash : this.propData.use_ip_hash || false,
                    proxy_ssl_name: this.propData.proxy_ssl_name || '',
                    protection: this.propData.protection || Const.website.protection.OFF,
                    action: this.propData.action || Const.website.action.BLOCK,
                    req_block_action: this.propData.req_block_action || Const.website.blockAction.SERVER_ERROR,
                    res_html: null,
                    res_block_action: 1,
                    proxy_read_timeout: this.propData.proxy_read_timeout || 60,
                    isShowed: false
                }
                // 初期登録の時、SNIにはtrueを設定する
                if(this.editData.proxy_ssl_server_name_flg == undefined ){
                    this.editData.proxy_ssl_server_name_flg = true
                }
                // 証明書一覧のデータを取得する
                await certificateService.selectList({page: 1, page_size: "1000"}).then(res => {
                    this.certificateList = res.results
                }).catch(() => {
                    this.isDetailLoadError = true
                })
                if(this.certificateList.length){
                    this.certificateSize = true
                }
            },
            updateValue(key, val) {
                this.$set(this.editData, key, val)
            },
            updateSwitchValue(key, val) {
                this.$set(this.editData, key, val ? 1 : 0)
            },
            requiredItem(item) {
                switch (item) {
                    case 'protocol':
                    case 'hostname':
                    case 'real_protocol':
                    case 'real_server':
                    case 'protection':
                        return true
                    default:
                        return false
                }
            },
            getTerms(value) {
                return Const.website.basicTermsType[value]
            },
            getColor(color) {
                return Const.common.colors[color.toUpperCase()]
            },
            getProtocolType(value) {
                return Const.website.protocolType[value]
            },
            getIPRecursive(value) {
                return i18n.t(Const.website.IPRecursiveType.find(obj => obj.value == value).str)
            },
            getReqBlockActionType(value) {
                return Const.website.reqBlockActionType[value]
            },
            getProxyTimeout(){
                return Object.entries(Const.website.TimeoutType).map(([value, str]) => (
                    {'value': value, 'str': str}
                ));
            },
            getclass(role){
                if(role){
                    return null
                }else{
                    return "is-disabled"
                }
            },
            getStateType: (value) => Const.website.stateType[value],
            async submit() {
                const params = {
                    protocol: this.editData.protocol,
                    hostname: this.editData.hostname,
                    real_protocol: this.editData.real_protocol,
                    real_server: this.editData.real_server,
                    real_ip_header_xff: this.editData.real_ip_header_xff,
                    real_ip_recursive: this.editData.real_ip_recursive,
                    redirect_http_to_https_flg: false,
                    allow_naked_domain_flg: false,
                    proxy_ssl_server_name_flg: false,
                    use_host_header: false,
                    host_header : '',
                    use_ip_hash: false,
                    proxy_ssl_name: '',
                    protection: this.editData.protection,
                    action: null,
                    req_block_action: null,
                    res_block_action: null,
                    ssl_key: null,
                    ssl_cert: null,
                    res_html: null,
                    certificate: '',
                    certificate_name: '',
                    proxy_read_timeout: this.editData.proxy_read_timeout,
                    isShowed: false,
                }
                // 証明書の更新確認
                if (params.protocol == Const.website.protocol.HTTPS) {
                    params.redirect_http_to_https_flg = this.editData.redirect_http_to_https_flg
                    if (this.editData.certificate != this.propData.certificate) {
                        params.certificate = this.editData.certificate
                        params.certificate_name = this.editData.certificate_name
                    } else {
                        // 更新なし
                        delete params.certificate
                        delete params.certificate_name
                    }
                }

                if (this.isProxySSL) {
                    params.proxy_ssl_server_name_flg = this.editData.proxy_ssl_server_name_flg
                    if (params.proxy_ssl_server_name_flg) {
                        params.proxy_ssl_name = this.editData.proxy_ssl_name
                    }
                }

                if (this.editData.hostname.startsWith(Const.website.sub_domain.WWW)) {
                    params.allow_naked_domain_flg = this.editData.allow_naked_domain_flg
                }

                // Hostヘッダを指定する
                params.use_host_header = this.editData.use_host_header
                params.host_header  = this.editData.host_header 
                if (!this.realServer_host_flg){
                    params.use_ip_hash = this.editData.use_ip_hash
                }

                // 保護
                if (params.protection == Const.website.protection.ON) {
                    params.action = this.editData.action

                    if (params.action == Const.website.action.BLOCK) {
                        params.req_block_action = this.editData.req_block_action
                        params.res_block_action = this.editData.res_block_action

                        // htmlファイルアップロード可能
                        if (params.req_block_action == Const.website.blockAction.REDIRECT) {
                            if (this.editData.res_html) {
                                params.res_html = this.editData.res_html
                            } else {
                                // 更新なし
                                delete params.res_html
                            }
                        }
                    }
                }
                // real_ip_header_xffがfalseなら、real_ip_recursiveもfalseにする
                if (!params.real_ip_header_xff) {
                    params.real_ip_recursive = false
                }
                this.$emit('save', params)
            },
            certificateDate(){
                if(this.propData.ssl_certificate_expire_date){
                    return i18n.t('SSL証明書の期限：{0}まで', [this.formatLocalDateTime(this.propData.ssl_certificate_expire_date)]) 
                }else{
                    return i18n.t('SSL証明書未アップロード')
                }
            },
            getrealServer(datas){
                this.realServer_host_flg = false
                this.realServerCheck = false
                if(datas){
                    let datalist = datas.split('\n')
                    // 6件以上の登録がある場合、エラーとする
                    if(datalist.length>5){
                        this.errors.real_server = "実サーバーの入力は5件までです"
                        return ""
                    }else{
                        if(this.errors.real_server == "実サーバーの入力は5件までです") {
                            this.errors.real_server = ""                            
                        }
                        // 重複データの確認を行う
                        const setData = new Set(datalist)
                        if(setData.size != datalist.length && datalist.length > 0){
                            this.errors.real_server = "データが重複しています"
                            return ""
                        }else if(this.errors.real_server == "データが重複しています") {
                            this.errors.real_server = ""                            
                        }
                        // 1件ずつバリデーションチェックを行う
                        for (const data of datalist){
                            this.val_IP = false
                            this.val_HOST = false
                            if (data.match(Const.validate.pattern.IP_ADDRESS_PORT)) {
                                this.val_IP = true
                            }
                            if (data.match(Const.validate.pattern.HOST)) {
                                this.val_HOST = true
                            }
                            // IPとホストのどちらのバリデーションにも当てはまらない場合、エラーを表示させる
                            if(!this.val_HOST && !this.val_IP){
                                this.realServerCheck = true
                                this.errors['real_server'] = ""
                                return 'REALSERVERS'
                            }
                            // ホストのデータがある場合、クライアントIPアドレスに基づいて実サーバーに振り分けるを非活性にする
                            if(this.val_HOST || datalist.length<1){
                                this.realServer_host_flg = true
                            }
                        }
                        return 'REALSERVERS'
                    }
                }
            },            
            async selectCertificate(){
                this.$store.dispatch('openModalWithParam', {target: 'website-select-certificate'})
            },
            certificate(certificateId){
                this.editData.certificate = certificateId
                this.certificateId = certificateId
            },
            getHostName(certificateId){
                if (this.editData.certificate){
                    // 証明書のリストを取得する
                    const lists = Object.values(this.certificateList)
                    for (const certificate of lists){
                        if (certificate.certificate_id == certificateId){
                            let displayhostname = certificate.hostname ? certificate.hostname : i18n.t("ホスト名なし")
                            if (certificate.comment){
                                return displayhostname + "-" + i18n.t(Const.certificate.is_auto[certificate.is_auto]) + "(" + certificate.comment + ")" 
                            }else{
                                return displayhostname + "-" + i18n.t(Const.certificate.is_auto[certificate.is_auto])
                            }
                        }
                    }
                }
            },            
            toggleBox() {
  		        this.isShowed = !this.isShowed
  		    },                
            manual(){
  		        if(this.isEdit){
  		            return '/manual/wiki/Website_edit_' + i18n.locale
  		        }else{
  		            return '/manual/wiki/Website_detail_' + i18n.locale
                }
  		    },
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';

    .notice {
        margin-bottom: $notice-margin;
    }
    .is-disabled {
        opacity: $opacity-disabled;
        cursor: not-allowed;
    }
    .file {
        margin-left: 22px;
    }
    .certificate_name {
        margin-left: 11px;
    }
    .upload-wrap {
        display: flex;
    }
    .redirect-wrap {
        margin-top: 24px;
    }
    .allow-naked-domain-wrap {
        margin-top: 24px;
    }
    .horizontal {
        display: flex;
        align-items: center;
        &__item {
            margin-left: 8px;
            &:first-child {
                margin-left: 0;
            }
        }
    }
    .icon {
        display: inline-block;
        margin-right: 1.5em;
        ::after{
            margin-left: 1.5em;
            content: "-";
        }
    }
    .label {
        font-weight: bold;
        display: inline-block;
        margin-bottom: 16px;
    }
    .action-wrap {
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 16px;
        }
        .term-list {
            margin-left: 24px;
        }
    }
    .sni-wrap {
        margin-left: 10px;
    }
    .vertical-align-top {
        vertical-align: top;
    }
    .data-new-line {
        margin-top: 18px;
        margin-left: 24px;
        .server_name {
            margin-left: 16px;
        }
        .proxy_time {
            margin-bottom: 18px;
            width: 130px;
        }        
    }

    .advance_title {
        border-top: 1px solid #252525;
        margin-top: 18px;
        padding-top: 16px;
    }
    .real_server{
        height: 130px;
    }
    .certificate{
        margin-right: 16px;
        margin-bottom: 16px;
        width:6em;
        height: 2em;
    }
    .error{
        color: $color-error;
    }
    .note {
        display: block;
        margin-top: 8px;
        color: $color-note;
    }
</style>
