<template>
    <footer class="footer">
        <div class="footer-nav">
            <ul class="footer-nav__list">
                <li class="footer-nav__item"><gx-icon-text :tag="'a'" :text="$t('プライバシーポリシー')" :icon="'new_window'" :posi="'right'" href="https://www.eg-secure.co.jp/privacy/" class="footer-nav__link" target="_blank" /></li>
                <li class="footer-nav__item"><gx-icon-text :tag="'a'" :text="$t('お問い合わせ')" :icon="'new_window'" :posi="'right'" href="https://siteguard.jp-secure.com/inquiry" class="footer-nav__link" target="_blank" /></li>
            </ul>
        </div>
        <p class="footer-copy"><small>{{$t('&copy; EG Secure Solutions Inc.')}}</small></p>
    </footer>
</template>

<script>
    import gxIconText from '@/components/atoms/icon-text'
    export default {
        name: 'globalFooterLogin',
        components: {
            gxIconText
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .footer {
        border-top: 1px solid $color-hr;
        padding: 32px 0 24px;

        &-nav {
            margin-bottom: 16px;

            &__list {
                display: flex;
                justify-content: center;
            }
            &__item:not(:first-child) {
                padding-left: 8px;
                margin-left: 8px;
                border-left: 1px solid;
            }
            &__link {
            }
        }
        &-copy {
            text-align: center;
        }
    }
</style>
