<template>
    <layout-main
        :title="$t('お知らせ')"
        :navs="navs"
        :type="'terms'"
    >
        <template #default>
            <template v-if="isLoading"><loading /></template>
            <notice v-else-if="isLoadError" :type="'error'" :label="$t('データが取得出来ませんでした')" />
            <notice v-else-if="!news.length" :type="'success'" :label="$t('お知らせはありません')" class="notice" />
            <template v-else>
                <div v-if="news.length" class="list-wrap">
                    <news-list :list="news" />
                    <pager :pager="pager" @changePage="changePage" @changePageSize="changePageSize" />
                </div>
            </template>
        </template>
    </layout-main>
</template>

<script>
    import layoutMain from '@/layouts/main'
    import loading from '@/components/atoms/loading'
    import pager from '@/components/molecules/pager'
    import notice from '@/components/molecules/notice'
    import newsList from '@/components/organisms/news/list'
    import NewsService from '@/services/newsService'
    import DateMixin from '@/mixin/date'

    import i18n from '@/i18n'

    export default {
        name: 'news',
        mixins: [DateMixin],
        components: {
            layoutMain,
            loading,
            pager,
            notice,
            newsList
        },
        data() {
            return {
                data: {},
                isLoading: true,
                isLoadError: false,
                page: '1',
                page_size: '20'
            }
        },
        computed: {
            navs() {
                return [
                    {
                        to: '/news',
                        label: i18n.t('お知らせ')
                    }
                ]
            },
            news() {
                return this.data.results
            },
            pager() {
                return this.data.page
            },
            listRequestParams() {
                return {
                    page: this.page,
                    page_size: this.page_size
                }
            }
        },
        methods: {
            async search() {
                this.isLoading = true
                this.isLoadError = false
                this.data = await NewsService.fetchList(this.listRequestParams).then(res => {
                    return res
                }).catch(() => {
                    this.isLoadError = true
                    return {
                        results: [],
                        pager: {}
                    }
                })
                this.isLoading = false
            },
            changePage(page) {
                this.page = page
                this.search()
            },
            changePageSize(size) {
                this.page_size = size
                this.page = '1'
                this.search()
            },
        },
        created() {
            this.search()
        }
    }
</script>

<style lang="scss" scoped>

</style>
