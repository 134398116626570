<template>
    <div :class="classes">
        <div class="loader">
            <div></div>
            <div></div>
            <div></div>
        </div>
        <p class="text">{{$t('処理中...')}}</p>
    </div>
</template>

<script>
    export default {
        name: 'gx-loading',
        props: {
            type: {
                type: String,
                validator: (value) => {
                    return ['inline', 'page'].indexOf(value) !== -1
                },
                default: 'inline'
            }
        },
        computed: {
            classes() {
                return ['loading', `is-${this.type}`]
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .loading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 5px 1px 10px;

        &.is-page {
            position: fixed;
            width: 100vw;
            height: 100vh;
            background-color: rgba($color-parts-accent, .6);
            top: 0;
            left: 0;
            z-index: 100;
        }
        .loader {
            width: 56px;
            height: 56px;
            position: relative;

            & > div {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 100%;
                border: 2px solid $color-border;
                animation: loading-animation 1.25s 0s infinite cubic-bezier(.21,.53,.56,.8);

                &:nth-child(1) {
                    animation-delay: -.6s;
                }
                &:nth-child(1) {
                    animation-delay: -.4s;
                }
                &:nth-child(3) {
                    animation-delay: -.2s;
                }
            }
        }
        .text {
            font-size: 1.6rem;
            color: $color-font;
            margin-top: 2px;
        }
    }

    @keyframes loading-animation {
        0% {
            transform: scale(0.1);
            opacity: 1;
        }
        70% {
            transform: scale(1);
            opacity: 0.7;
        }
        100% {
            opacity: 0.0;
        }
    }
</style>