<template>
    <span class="toggle">
        <input type="radio" class="input left" :value="left.value" :id="`radio-${name}-left`" :name="`radio-${name}`" v-model="value" @change="setInput">
        <input type="radio" class="input right" :value="right.value" :id="`radio-${name}-right`" :name="`radio-${name}`" v-model="value" @change="setInput">
        <span class="labels">
            <label class="label left" :for="`radio-${name}-left`">{{left.label}}</label>
            <span class="switch" @click.prevent="toggleInput" />
            <label class="label right" :for="`radio-${name}-right`">{{right.label}}</label>
        </span>
    </span>
</template>

<script>
    export default {
        name: 'gx-form-toggle',
        model: {
            prop: 'prop-value',
            event: 'change'
        },
        props: {
            name: {
                type: String,
                default: 'group'
            },
            left: {
                type: Object,
                default: () => {}
            },
            right: {
                type: Object,
                default: () => {}
            },
            propValue: {
                type: [String, Number, Boolean],
                default: ''
            }
        },
        data() {
            let initialValue = ''
            if(this.propValue === this.left.value || this.propValue === this.right.value) {
                initialValue = this.propValue
            } else {
                initialValue = this.left.value
            }

            return {
                value: initialValue
            }
        },
        methods: {
            setInput() {
                this.$emit('change', this.value)
            },
            toggleInput() {
                if(this.value === this.left.value) {
                    this.value = this.right.value
                } else {
                    this.value = this.left.value
                }
                this.setInput()
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .toggle {
        padding: 1px;
        display: inline-block;

        .input {
            display: none;

            &.left:checked ~ .labels {

                .switch::before {
                    flex: 0;
                }
                .label.right {
                    opacity: 0.35;
                }
            }
            &.right:checked ~ .labels {

                .switch::before {
                    flex: 1;
                }
                .label.left {
                    opacity: 0.35;
                }
            }
        }
        .labels {
            display: flex;
            align-items: center;
        }
        .switch {
            cursor: pointer;
            display: flex;
            width: 80px;
            height: 32px;
            border: 1px solid $color-border;
            border-radius: 6px;
            margin: 0 8px;
            padding: 3px;

            &::before {
                content: "";
                transition: $transition-common;
            }

            &::after {
                content: "";
                width: 32px;
                background-color: $color-current;
                border-radius: 6px;
            }
        }
        .label {
            cursor: pointer;
            transition: $transition-common;
        }
    }
</style>