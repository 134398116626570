<template>
    <div class="terms">
        <breadcrumb class="breadcrumb" :origin="origin" :navs="navs" />
        <div class="terms-header">
            <h1 class="terms-header__title">利用規約</h1>
        </div>
        <term-content />
    </div>
</template>

<script>
    import breadcrumb from '@/components/molecules/breadcrumb'
    import termContent from '@/components/organisms/terms/content'

    import i18n from '@/i18n'

    export default {
        name: 'terms',
        layout: 'single',
        components: {
            breadcrumb,
            termContent
        },
        computed: {
            origin() {
                return {
                    to: '/login',
                    label: i18n.t('ログイン')
                }
            },
            navs() {
                return [
                    {
                        to: '/login/terms',
                        label: i18n.t('利用規約')
                    }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .breadcrumb {
        margin-bottom: 40px;
    }
    .terms {
        z-index: 1;
        margin-top: 66px;
        margin-bottom: 118px;
        padding: 20px;
        width: 95%;
        background-color: $color-bg;
        opacity: 0.9;
        overflow-y: scroll;
    }
    .terms-header {
        margin-bottom: 56px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        &__title {
            font-size: 2.4rem;
        }
    }

</style>