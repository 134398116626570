// FIXME:
//  命名規則整理
//  XXXs ... key: value
//  XXXSelection ... value: label
//  XXXVerbose ... key: <日本語>
//  ※Typeは意味が曖昧すぎるので禁止

export const settingTerms = Object.freeze({
    name: 'シグネチャ名',
    comment: 'コメント',
    priority: 'シグネチャ優先度',
    action: '状態',
    url_path: 'パス名',
    req_method: 'リクエストメソッド',
    search_str: '検査文字',
})

export const targetTerms = Object.freeze({
    param_name: 'パラメータ名',
    param_value: 'パラメータ値',
    file_name: '送信ファイル名',
    req_header_field_name: 'リクエストヘッダフィールド名',
    req_header_field_value: 'リクエストヘッダフィールド値',
    cookie_name: 'Cookie名',
    cookie_value: 'Cookie値'
})

export const doesExcCondSelectValues = [
    {str: "OFF", value: 0},
    {str: "ON", value: 1},
]

export const excSelectValues = [
    {str: "ID", value: 1},
    {str: "タグ", value: 2}
]

export const excSelectStrs = [
    "",
    "ID",
    "タグ"
]

export const excTargetSelectValues = [
    {str: "指定しない", value: null},
    {str: "パラメータ値", value: 1},
    {str: "リクエストヘッダフィールド値", value: 2},
    {str: "Cookie値", value: 3}
]

export const excTargetSelectStrs = [
    "指定しない",
    "パラメータ値",
    "リクエストヘッダフィールド値",
    "Cookie値",
]

export const excTerms = Object.freeze({
    exc_select: '除外対象',
    // exc_use_regex_target_value: '除外対象値(正規表現使用可)',
    // exc_value: '除外対象値(正規表現使用不可)',
    exc_target_select: '除外対象詳細指定',
    // exc_target_value: '除外対象詳細指定値',
    exc_source_ip: 'アクセス元IP',
})

export const actions = Object.freeze({
    BLOCK: 1,
    DETECTION: 2,
    ACCEPTION: 3,
    EXCEPTION: 5
})

export const actionsType = Object.freeze({
    5: '既存シグネチャ除外',
    1: 'ブロック',
    3: '検出のみ',
    4: '許可',
})

export const actionsIcon = Object.freeze({
    1: 'block',
    3: 'search',
    4: 'check_circle'
})

export const methodsType = Object.freeze({
    0: 'ANY',
    1: 'GET',
    2: 'POST',
    3: 'PUT',
    4: 'DELETE',
    5: 'HEAD',
    6: 'OPTIONS',
    7: 'PATCH',
    8: 'CONNECT',
    9: 'TRACE'
})

export const signaturePositions = Object.freeze({
    UP: 1,
    DOWN: 2
})

export const signaturePositionsType = Object.freeze({
    1: '上にする',
    2: '下にする'
})

export const valueSelections = Object.freeze({
    ALL: 1,
    SPECIFY: 2
})

export const paramValuesType = Object.freeze({
    1: 'すべてのパラメータ',
    2: 'パラメータを指定'
})

export const reqHeaderFieldValuesType = Object.freeze({
    1: 'すべてのフィールド',
    2: 'フィールドを指定'
})

export const cookieValuesType = Object.freeze({
    1: 'すべてのCookie',
    2: 'Cookieを指定'
})
