<template>
    <layout-main
        :title="$t('イベント詳細')"
        :navs="navs"
    >
        <template #default>
            <template v-if="isLoading"><loading /></template>
            <notice v-else-if="isLoadError" :type="'error'" :label="$t('データが取得出来ませんでした')" />
            <template v-else>
                <template v-if="event">
                    <gx-section>
                        <gx-title :level="2">{{$t('概要')}}</gx-title>
                        <data-list :data="summaryTerms">
                            <template #title={item}>{{$t(getTerms(item))}}</template>
                            <template #data={item}>
                                <template v-if="event">
                                    <template v-if="item == 'attack_src_country'">{{getAttackSrcCountry(event[item])}}</template>
                                    <template v-else-if="item == 'attack_type'">{{$t(getAttackType(event[item]))}}</template>
                                    <template v-else-if="item == 'res_action'">
                                        <gx-icon-text :text="$t(getActionType(event[item]))" :icon="getActionIcon(event[item])" :color="colors.success" />
                                    </template>
                                    <template v-else-if="item == 'req_protocol'">{{$t(getProtocolType(event[item]))}}</template>
                                    <template v-else-if="item == 'timestamp'">{{formatLocalDateTime(event[item])}}</template>
                                    <template v-else-if="item == 'attack_src'">
                                        <div class="action-wrap">
                                            <div class="label_2col">{{$t('国名')}}</div>
                                                <span class = "data_2col">{{getAttackSrcCountry(event.attack_src_country)}}</span>                                            
                                       </div>
                                            <div class="label_2col">{{$t('IP')}}</div>
                                                <span class = "data_2col">{{event.attack_src_ip}}</span>
                                    </template>
                                    <template v-else>{{event[item]}}</template>
                                </template>
                            </template>
                        </data-list>
                    </gx-section>
                    <gx-section>
                        <gx-title :level="2">{{$t('検出')}}</gx-title>
                        <data-list :data="detectionTerms">
                            <template #title={item}>{{$t(getTerms(item))}}</template>
                            <template #data={item}>
                                <template v-if="item == 'sig'">
                                    <div class="action-wrap">
                                        <div class="label_2col">{{$t('ID')}}</div>
                                        <span class = "data_2col">{{event.sig_id}}</span>                                            
                                    </div>
                                    <div class="label_2col">{{$t('名前')}}</div>
                                    <span class = "data_2col">{{event.sig_name}}</span>            
                                </template>
                                <template v-else>
                                    <template>{{event[item]}}</template>
                                </template>
                            </template>
                        </data-list>
                    </gx-section>
                    <gx-section>
                        <gx-title :level="2">{{$t('リクエスト')}}</gx-title>
                        <gx-box>
                            <gx-title :level="3">{{$t('ヘッダー')}}</gx-title>
                            <gx-code>{{event.req_headers}}</gx-code>
                            <gx-title :level="3">{{$t('ボディ')}}</gx-title>
                            <gx-code>{{event.req_body}}</gx-code>
                        </gx-box>
                    </gx-section>
                    <gx-section>
                        <gx-title :level="2">{{$t('レスポンス')}}</gx-title>
                        <gx-box>
                            <gx-title :level="3">{{$t('ヘッダー')}}</gx-title>
                            <gx-code>{{event.res_headers}}</gx-code>
                        </gx-box>
                    </gx-section>
                </template>
            </template>
            <gx-button v-if="!userAccount.is_support" :text="$t('サポート')" @onClick="$router.push(`/support/register/?event_id=${$route.params.uuid}`)" />
            <page-back :text="$t('イベント一覧に戻る')" @onClick="$router.back()" />
            <div class="manual">
                <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
            </div>
        </template>
    </layout-main>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'

    import Const from '@/static/constants'
    import MixinCommon from '@/mixin/common'
    import MixinDate from '@/mixin/date'
    import countries from 'i18n-iso-countries'

    import layoutMain from '@/layouts/main'
    import gxLabel from '@/components/atoms/label'
    import gxIconText from '@/components/atoms/icon-text'
    import gxBox from '@/components/atoms/box'
    import gxButton from '@/components/atoms/button'
    import gxSection from '@/components/atoms/section'
    import gxCode from '@/components/atoms/code'
    import gxTitle from '@/components/atoms/title'
    import loading from '@/components/atoms/loading'
    import pageBack from '@/components/molecules/page-back'
    import dataList from '@/components/molecules/data-list'
    import notice from '@/components/molecules/notice'

    import MasterService from '@/services/masterService'
    import EventService from '@/services/eventService'
    import ManualService from '@/services/manualService'

    import i18n from '@/i18n'

    export default {
        name: 'event-detail',
        mixins: [MixinCommon, MixinDate],
        components: {
            layoutMain,
            gxLabel,
            gxIconText,
            gxBox,
            gxSection,
            gxCode,
            gxTitle,
            loading,
            pageBack,
            dataList,
            gxButton,
            notice
        },
        data() {
            return {
                event: {},
                countries: [],
                isLoadError: false,
                isLoading: true,
                groupid:"",
            }
        },
        computed: {
            ...mapGetters([
                'userAccount'
            ]),
            navs() {
                return [
                    {
                        to: '/event',
                        label: i18n.t('イベント')
                    }, {
                        to: '/event',
                        label: i18n.t('イベント詳細')
                    }
                ]
            },
            summaryTerms() {
                return [
                    'timestamp',            // 日付
                    'attack_src',           // 攻撃元
                    'attack_type',          // 種別
                    'req_header_host',      // ホスト
                    'req_method',           // メソッド
                    'req_protocol',         // プロトコル
                    'req_uri',              // パス
                    'res_action',           // 動作
                    'event_id',             // イベントID
                ]
            },
            detectionTerms() {
                return [
                    'sig',                  // シグネチャ
                    'matched_at',           // 検出箇所
                    'matched_strings',      // 検出文字列
                    'detection_strings',    // 検出文字列（全体）
                ]
            }
        },
        methods: {
            ...mapActions([
                'fetchUserAccount'
            ]),
            reload() {
                this.fetchUserAccount()
                this.fetch()
            },
            getTerms(value) {
                return Const.event.detailTermsType[value]
            },
            getAttackSrcCountry(value) {
                return i18n.getCountryName(value)
            },
            getAttackType(value) {
                if(Const.event.attackType.find(obj => obj.value === value) === undefined){
                    return "不明"
                }else{
                    return Const.event.attackType.find(obj => obj.value === value).str
                }
            },
            getProtocolType(value) {
                return Const.event.protocolType[value]
            },
            getActionType(value) {
                return Const.event.actionType[value]
            },
            getActionIcon(value) {
                return Const.event.actionIcon[value]
            },
            manual(){
  		        return '/manual/wiki/Event_detail_' + i18n.locale
  		    },
            async fetch() {
                this.isLoading = true
                this.isLoadError = false

                this.countries = await MasterService.fetchCountry()
                this.event = await EventService.fetchDetail(this.$route.params.uuid).catch(err => {
                    this.isLoadError = true
                    return {}
                })
                this.isLoading = false
                this.groupid = this.userAccount.group_id
            }
        },
        created() {
            this.fetch()
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    
    .label_2col {
        font-weight: bold;
        display: inline-block;
        width:100px;
        margin-bottom: 10px;
    }
        .data_2col  {
            padding: 10px 24px;
            min-height: 56px;

        }
</style>