import api from '@/services/api'

export default {
    API_ENDPOINT: '/api/dashboard',
    fetchEventList(params) {
        return api.get(`${this.API_ENDPOINT}/`, {params: params})
            .then(response => response.data)
            .catch(error => error.response)
    },
    getPerformance() {
        return api.get(`${this.API_ENDPOINT}/performance/`)
            .then(response => response.data)
            .catch(error => error.response)
    },  
    downloadReport(params) {
        return api.get(`${this.API_ENDPOINT}/download/`, {params: params,  responseType: 'arraybuffer'})
            .then(response => response)
            .catch(error => error.response)
    },
}
