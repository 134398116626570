 <template>
     <layout-manual
         :title="manual.title || ''"
         :navs="navs"
         :type="'terms'"
     >     
         <template #default>
             <template v-if="isLoading"><loading /></template>
             <notice v-else-if="isLoadError" :type="'error'" :label="$t('データが取得出来ませんでした')" />
             <p class="close-button"><gx-icon :tag="'a'" href="#" :text="'閉じる'" :icon="'close'" :size="2.4" @onClick="windowclose()" /></p>
             <div class="manual_body" v-html="manual.body"></div>
         </template>
     </layout-manual>
 </template>
 
 <script>
     import MixinDate from '@/mixin/date'
 
     import layoutManual from '@/layouts/manual'
     import gxBox from '@/components/atoms/box'
     import gxIcon from '@/components/atoms/icon'
     import loading from '@/components/atoms/loading'
     import pageBack from '@/components/molecules/page-back'
     import notice from '@/components/molecules/notice'
 
     import i18n from '@/i18n'
 
     import ManualService from '@/services/manualService'
 
     export default {
         name: 'manual',
         mixins: [MixinDate],
         components: {
            layoutManual,
             gxBox,
             gxIcon,
             loading,
             pageBack,
             notice
         },
         data() {
             return {
                 pathname: '',
                 manual: {},
             }
         },
         computed: {
             navs() {
                 return [
                     {
                         to: '/manual/wiki',
                         label: i18n.t('マニュアル')
                     },
                 ]
             }
         },
         methods: {
             async fetch() {
                 // http://localhost:9999/manual/wiki?page=hogehoge
                 if (!this.$route.query || !this.$route.query.page) {
                     // @TODO: 未指定だった場合の処理をどうするか相談して決めてください(インデックスを表示する？→リンクの処理についての話になるかも→文字列でcontent.replace(/http:\/\/WIKIURL\//g, "http://localhost:9999/manual/wiki")みたいな感じで置換する必要があるかも？)
                     // https://zenn.dev/crayfisher_zari/articles/d890673933167d  // ← aタグを取得してクリックイベントを上書きしてVueの処理に書き換えちゃうパターン
 //                    console.log(this.$route)
 //                    console.log(this.$route.query)
                 }
                 const page = this.$route.query.page
                 const pageWithLocale = `${page}_${i18n.locale}`
 
 //                console.log(pageWithLocale)
 //                console.log(ManualService.fetch('Dashboard_ja'))
                 this.isLoading = true
                 this.pathname = location.pathname
                 this.pathname = this.pathname.replace('/manual/wiki/','')
                 this.$i18n.locale = this.pathname.substring(this.pathname.length - 2, this.pathname.length)
                 await this.fetchManual()
                 this.isLoading = false
             },
             async fetchManual() {
                 this.isLoadError = false
                 this.isLoading = false
                 this.manual = await ManualService.fetch(this.pathname).catch(err => {
                     Object.clone(err)
                     this.isLoadError = true
                     return {}
                 })
             },
             windowclose(){
                window.close()
             }
         },
             
         created() {
             this.fetch()
         }
     }
 </script>
 
 <style lang="scss">
     .terms {
         width: 100%;
     }
 </style>
 
 <style lang="scss" scoped>
    @import '@/assets/scss/_manual.scss';
    .close-button {
        position: absolute;
        right: 35px;
        text-align: right;
        line-height: 1;
    }     
 </style>