<template>
    <modal @cancel="closeModal" @submit="submit" :size="'large'">
        <template #title>{{$t(modalTitle)}}</template>
        <div>
            <multi-notice type="error" :terms="Const.certificate.autoTerms" :messages="errors" />
            <gx-section class="certificate">
                <template v-if="isShowedWithNoData">
                    <gx-icon-text class="dashboard-box__explanation toggle-button"
                        :text="$t($t('閉じる'))"
                        icon="arrow-down" @onClick="toggleBoxWithNoData" 
                    />
                </template>
                <template v-else>
                    <gx-icon-text class="dashboard-box__explanation toggle-button"
                        :text="$t('Let’s Encrypt からの証明書取得について')"
                        icon="arrow-up" @onClick="toggleBoxWithNoData" 
                    />
                </template>
                <div>
                    <template v-if="isShowedWithNoData">
                        <div class="explanation">
                            {{$t('Let’s Encrypt から証明書を取得する際、DNSを利用してドメインを検証します。')}}
                        </div>
                        <div class="explanation">
                            {{$t('検証に利用するレコードをCNAMEでSiteGuardで管理しているホストゾーンに委任していただく必要があります。')}}
                        </div>
                        <div class="explanation">
                            {{$t('具体的には以下の設定が必要です。')}}
                         </div>
                         
                         <div class="exlabel">{{$t('ホスト名 www.example.com の証明書を発行する場合')}}</div>
                         <div class="exdata">
                             {{'_acme-challenge.www.example.com CNAME _acme-challenge.www.example.com.' + userAccount.record_name}}
                         </div>
                         
                         <div class="exlabel">{{$t('ホスト名 www.example.com (www無しも含む) の証明書を発行する場合')}}</div>
                         <div class="exdata">
                         <div>
                             {{'_acme-challenge.www.example.com CNAME _acme-challenge.www.example.com.' + userAccount.record_name}}
                             <br>
                             {{'_acme-challenge.example.com CNAME _acme-challenge.www.example.com.' + userAccount.record_name}}
                         </div>
                         </div>
                                                 
                         <div class="exlabel">{{$t('ホスト名 *.example.com のワイルドカード証明書を発行する場合')}}</div>
                         <div class="exdata">
                             {{'_acme-challenge.example.com CNAME _acme-challenge.example.com.' + userAccount.record_name}}
                        </div>

                    </template>
                </div>
            </gx-section>
            <validation-observer v-slot="{ invalid }">
                <gx-section class="certificate">
                    <div class="item">
                        <div class="label">{{$t('ホスト名')}}</div>
                        <div class="data">
                            <gx-form-text
                                :name="$t('ホスト名')"
                                :placeholder="$t('example.com')"
                                :maxlength="100"
                                :minlength="1"
                                :disabled="isEdit"
                                v-model="editData.hostname"
                                rules="required"
                                required
                            />
                        </div>
                    </div>
                    <div class="checkbox">
                        <gx-form-checkbox
                            value="'ON'"
                            :checked="editData.allow_naked_domain_flg"
                            :disabled="!startsWithWww"
                            @check="updateValue('allow_naked_domain_flg', $event.target.checked)">
                            {{$t('www無しも含む')}}
                        </gx-form-checkbox>
                    </div>
                    <div class="item">
                        <div class="label">{{$t('コメント')}}</div>
                        <div class="data">
                            <gx-form-text
                                :name="$t('コメント')"
                                :placeholder="$t('コメント')"
                                :maxlength="100"
                                v-model="editData.comment"
                            />
                        </div>
                    </div>
                </gx-section>
                <div v-if="!isEdit" >
                    {{$t('利用には提供元の')}}
                    <gx-icon-text :tag="'a'" :text="$t('ポリシー')" :posi="'right'" href="https://letsencrypt.org/repository/" class="footer-nav__link" target="_blank" />
                    {{$t('に同意する必要があります')}}
                    <div class="checkbox" >
                        <gx-form-checkbox
                            value="'ON'"
                            :checked="ruleflg"
                            @check="ruleflgcheck" >
                            {{$t('同意して利用')}}
                        </gx-form-checkbox>
                    </div>
                </div>
                <button-wrap :buttons="buttons(invalid)" class="button-wrap" @submit="submit" @cancel="closeModal" />
            </validation-observer>
        </div>
        <div class="manual">
            <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
        </div>
    </modal>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import layoutMain from '@/layouts/main'
    import modal from '@/components/molecules/modal'

    import CommonMixin from '@/mixin/common'
    import multiNotice from '@/components/molecules/multi-notice'
    import gxSection from '@/components/atoms/section'
    import gxFormText from '@/components/atoms/form/text'
    import gxFormFile from '@/components/atoms/form/file'
    import gxButton from '@/components/atoms/button'
    import gxIconText from '@/components/atoms/icon-text'
    import gxIcon from '@/components/atoms/icon'  
    import gxFormCheckbox from '@/components/atoms/form/checkbox';
    import buttonWrap from '@/components/molecules/button-wrap'
    import ManualService from '@/services/manualService'
    import accountService from '@/services/accountService'

    import Const from '@/static/constants'
    import {
        certificateService,
        AutoCreateParam,
    } from '@/services/certificateService'

    import i18n from '@/i18n'

    export default {
        name: 'certificate_auto',
        components: {
            layoutMain,
            modal,
            multiNotice,
            gxSection,
            gxFormText,
            gxFormFile,
            gxButton,
            gxIconText,
            gxIcon,
            gxFormCheckbox,
            buttonWrap,
        },
        props: {
            propData: {
                type: Object,
                default: () => ({})
            },
        },
        mixins: [CommonMixin],
        data() {
            return {
                Const: Const,
                isShowedWithNoData: false,
                editData: new AutoCreateParam({}),
                errors: {},
                isLoading: true,
                isLoadError: false,
                ruleflg: false
            }
        },
        computed: {
            ...mapGetters([
                'userAccount'
            ]),
            uuid() {
                return this.$store.state.modalParam.uuid
            },
            isEdit() {
                return this.$store.state.modalParam.isEdit
            },
            startsWithWww() {
                if (this.isEdit){
                    return false
                }
                else{
                    // 非活性にする際、www無しを含むのチェックを外す
                    this.updateValue('allow_naked_domain_flg', false)
                    return this.editData.hostname.startsWith(Const.website.sub_domain.WWW) == true
                }
            },
            modalTitle() {
                if (this.$store.state.modalParam.detailType){
                    return i18n.t('自動証明書{0}', [i18n.t(Const.website.access_detailModalType[this.$store.state.modalParam.detailType])])
                }else{
                    return i18n.t('自動証明書{0}', [i18n.t(Const.website.access_detailModalType["new"])])
                }
            },
        },
        methods: {
            ...mapActions([
                'fetchUserAccount'
            ]),
            reload() {
                this.fetchUserAccount()
                this.fetch()
            },
            async fetch() {
                this.isLoading = true
                if(this.isEdit){
                    // 登録データを取得する
                    this.propData = await certificateService.getAuto(this.uuid)
                    this.editData.comment = this.propData.comment
                    this.editData.hostname = this.propData.hostname
                    this.editData.allow_naked_domain_flg = this.propData.allow_naked_domain_flg
                }
                this.isLoadError = false
                this.isLoading = false
            },
            updateValue(key, val) {
                this.$set(this.editData, key, val)
            },
            async submit() {
                this.$store.dispatch('displayLoading')
                this.errors = {}

                if(this.isEdit){
                    // 更新時
                    const params = {
                        comment: this.editData.comment,
                    }
                    await certificateService.updateAuto(this.uuid, params)
                    .then(() => {
                        this.$store.dispatch('openToast', {type: 'success', label: i18n.t('保存しました')})
                        this.$emit('reload')
                        this.closeModal()
                        this.$store.dispatch('hideLoading')
                    }).catch(err => {
                        if (err.response.status == 400) {
                            this.errors = err.response.data
                        } else {
                            this.$store.dispatch('openToast', {type: 'error', label: i18n.t('保存に失敗しました')})
                        }
                    })
                }else{
                    // 新規登録時
                    const params = {
                        comment: this.editData.comment ? this.editData.comment : null,
                        hostname: this.editData.hostname,
                        allow_naked_domain_flg: this.editData.allow_naked_domain_flg,
                    }
                    await certificateService.registerAuto(params)
                    .then(() => {
                        this.$store.dispatch('openToast', {type: 'success', label: i18n.t('保存しました')})
                        this.$emit('reload')
                        this.$store.dispatch('openModalWithParam', {target: 'certificate-confirmation-auto'}) 
                    }).catch(err => {
                        if (err.response.status == 400) {
                            this.errors = err.response.data
                        } else {
                            this.$store.dispatch('openToast', {type: 'error', label: i18n.t('保存に失敗しました')})
                        }
                    })
                }
                this.$store.dispatch('hideLoading')
            },
            closeModal() {
                this.$store.dispatch('closeModal')
            },
            toggleBoxWithNoData() {
                this.isShowedWithNoData = !this.isShowedWithNoData
            },
            ruleflgcheck() {
                this.ruleflg = !this.ruleflg
            },
            buttons(disabled) {
                let d = disabled
                if (!this.isEdit && !this.ruleflg) {
                    d = true
                }

                return [
                    {
                        text: i18n.t('保存'),
                        type: 'primary',
                        size: 'large',
                        disabled: d,
                        onClick: 'submit'
                    },
                    {
                        text: i18n.t('キャンセル'),
                        type: 'common',
                        size: 'large',
                        onClick: 'cancel'
                    },
                ]
            },
            manual(){
  		        return '/manual/wiki/Certificate_auto_' + i18n.locale
  		    },  
        },
        mounted() {
            if (this.$route.query.event_id) {
                this.$data.editData.waf_event = this.$route.query.event_id
            }
        },
        created() {
            this.fetch()
        },
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';

    .certificate {
        background-color: $color-parts-bg;
        border-radius: 15px;

        .item {
            padding: 1em 1em;
            display:flex;
            flex-flow: column;
            flex-direction: row;
            align-items: center;
            
            .label {
                font-weight: bold;
                width: 7em;
            }
            .data {
                flex: 1;
            }
        }
        .checkbox {
            padding: 0.5em 4em;
            display:flex;
            flex-flow: column;
            flex-direction: row;
            align-items: center;
            
            .label {
                font-weight: bold;
                width: 7em;
            }
            .data {
                flex: 1;
            }
        }
        .explanation {
            display: flex;
            padding: 10px 0px;
            background-color: $color-gray3;

            .data {
                .wrap {
                    max-width: inherit;
                }
            }
        }
        .toggle-button {
            cursor: pointer;
            padding: 10px 10px;
            
        }
        .exlabel {
            font-weight: bold;
            padding-left: 25px;
        }
        .exdata {
            background-color: $color-gray1;
            line-height: 38px;
            border-radius: 6px;
            padding: 10px;
            border: 1px solid $color-border;
            margin: 10px 25px;
            display: flex;
            white-space: nowrap;
            overflow-x: scroll;
        }
        .explanation{
            padding: 5px 10px;
        }
    }
</style>
