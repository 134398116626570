<template>
    <span :class="['tag', `tag--${type}`]">{{text}}</span>
</template>

<script>
    export default {
        name: 'gx-tag',
        props: {
            text: {
                type: String,
                required: true,
                default: ''
            },
            type: {
                type: String,
                validator: (value) => {
                    return ['common', 'required', 'error'].indexOf(value) !== -1
                },
                default: 'common'
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .tag {
        font-size: 1.1rem;
        font-weight: bold;
        display: inline-block;
        border-radius: 4px;
        height: 16px;
        line-height: 16px;
        padding: 0 4px;
        vertical-align: middle;
        margin-top: -.2em;

        &.tag--common {
            background-color: $color-gray4;
            color: $color-gray2;
        }
        &.tag--error {
            background-color: $color-error;
            color: $color-font;
        }
        &.tag--required {
            background-color: $color-required;
            color: $color-font;
        }
    }
</style>
