<template>
    <ul class="message_obj" v-for="(messageArray, term) in messages">
        <li class="message" v-if="Array.isArray(messageArray)" v-for="message in messageArray">
            <notice :type="type" :label="`${$t(getTerm(term))}: ${$t(message)}`" />
        </li>
    </ul>
</template>
<script>
    /**
     * <multi-notice :type="type" :terms="terms" :messages="messages" />
     * 
     * @attr type noticeカスタムタグのtypeアトリビュート。'success', 'error', 'warning', 'info'のいずれか。デフォルトは 'success'。
     * @attr terms {`${項目物理名}`: "項目名ja文字列", ...} の形のオブジェクト。 (例: {exc_select: '除外対象', exc_target_select: '除外対象詳細指定', exc_source_ip: 'アクセス元IP'} )
     * @attr messages [{`${項目物理名}`: ["メッセージ", ...]}, ...] の形の配列 (例: [{content: ["この項目は空にできません。"]}])
     */
    import i18n from '@/i18n'

    import notice from '@/components/molecules/notice'

    export default {
        name: "multi-notice",
        components: {
            notice,
        },
        props: {
            type: {
                type: String,
                validator: (value) => {
                    return ['success', 'error', 'warning', 'info'].indexOf(value) !== -1
                },
                default: 'success'
            },
            terms: {
                type: Object,
                "default": function() {
                    return {}
                }
            },
            messages: {
                type: Array,
                "default": function() {
                    return []
                }
            },
        },
        data: function() {
            return {
            }
        },
        methods: {
            getTerm(term) {
                return this.terms[term]
            },
        },
    }
</script>
<style lang="scss" scoped>
</style>
