<script>
    import MixinCommon from '@/mixin/common'
    import { Doughnut, mixins } from 'vue-chartjs'
    import Chart from 'chart.js'
    const { reactiveProp } = mixins

    export default {
        name: 'dashboard-severity-chart',
        extends: Doughnut,
        mixins: [MixinCommon, reactiveProp],
        computed: {
            options() {
                return {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    tooltips:{
                        enabled: false
                    }
                }
            }
        },
        mounted () {
            const self = this
            this.addPlugin({
                afterDraw(chart) {
                    const ctx = chart.ctx
                    chart.data.datasets.forEach((dataset, setIndex) => {
                        const meta = chart.getDatasetMeta(setIndex)
                        if(!meta.hidden) {
                            meta.data.forEach((elem, elemIndex) => {
                                ctx.font = Chart.helpers.fontString(16, 'normal')
                                ctx.fillStyle = self.colors.white

                                const dataString = dataset.data[elemIndex].toString()
                                const position = elem.tooltipPosition()
                                ctx.fillText(dataString, position.x, position.y)
                            })
                        }
                    })
                }
            })
            this.renderChart(this.chartData, this.options)
        }
    }
</script>

<style>
    #doughnut-chart {
    }
</style>