<template>
    <modal  @cancel="submit" @submit="submit">
    <layout-main>
        <div>
            {{$t('証明書発行をスケジュールしました。')}}
        </div>
        <div>
            {{$t('新しい証明書が発行されたら利用可能となります。')}}
        </div>
        <div>
            {{$t('一日経過しても利用可能とならない場合はお問い合わせください。')}}
        </div>
        <button-wrap :buttons="buttons()" class="button-wrap" @submit="submit"/>
    </layout-main>
    </modal>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import layoutMain from '@/layouts/main'
    import modal from '@/components/molecules/modal'

    import CommonMixin from '@/mixin/common'
    import multiNotice from '@/components/molecules/multi-notice'
    import gxSection from '@/components/atoms/section'
    import gxFormText from '@/components/atoms/form/text'
    import gxFormFile from '@/components/atoms/form/file'
    import gxButton from '@/components/atoms/button'
    import gxIconText from '@/components/atoms/icon-text'
    import gxIcon from '@/components/atoms/icon'  
    import gxFormCheckbox from '@/components/atoms/form/checkbox';
    import buttonWrap from '@/components/molecules/button-wrap'
    import ManualService from '@/services/manualService'
    import accountService from '@/services/accountService'

    import Const from '@/static/constants'
    import {
        certificateService,
        AutoCreateParam,
    } from '@/services/certificateService'

    import i18n from '@/i18n'

    export default {
        name: 'certificate_confirmation_auto',
        components: {
            layoutMain,
            modal,
            multiNotice,
            gxSection,
            gxFormText,
            gxFormFile,
            gxButton,
            gxIconText,
            gxIcon,
            gxFormCheckbox,
            buttonWrap,
        },
        props: {
            propData: {
                type: Object,
                default: () => ({})
            },
        },
        mixins: [CommonMixin],
        data() {
            return {
                Const: Const,
                editData: new AutoCreateParam({}),
                errors: {},
                isLoading: true,
                isLoadError: false,
            }
        },
        computed: {
            ...mapGetters([
                'userAccount'
            ]),
        },
        methods: {
            ...mapActions([
                'fetchUserAccount'
            ]),
            reload() {
                this.fetchUserAccount()
                this.fetch()
            },
            async fetch() {
                this.isLoading = true
                this.isLoadError = false
                this.isLoading = false
            },
            updateValue(key, val) {
                this.$set(this.editData, key, val)
            },
            async submit() {
                this.$store.dispatch('hideLoading')
                this.closeModal()
            },
            closeModal() {
                this.$store.dispatch('closeModal')
            },
            buttons() {;
                return [
                    {
                        text: i18n.t('一覧画面へ'),
                        type: 'primary',
                        size: 'large',
                        onClick: 'submit'
                    },
                ]
            },
        },
        mounted() {
            if (this.$route.query.event_id) {
                this.$data.editData.waf_event = this.$route.query.event_id
            }
        },
        created() {
            this.fetch()
        },
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
</style>
