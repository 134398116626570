<template>
    <main class="main">
        <article :class="type">
            <div class="page-header">
                <h1 class="page-header__title"><small v-if="titleSub" class="page-header__title__sub">{{titleSub}}</small>{{ title }}</h1>
                <div v-if="$slots['page-header']" class="page-header__actions">
                    <slot name="page-header" />
                </div>
            </div>
            <notice
                v-for="msg in warnings"
                :label="msg"
                class="user-notification-warning"
                type="warning" />
            <slot name="default" />

        </article>

        <div class="modal" v-if="($slots.modal) || (($store.state.isModal) && ($store.state.modalTarget == 'group-change-modal'))">
            <section class="contents">
                <p class="close-button"><gx-icon :tag="'a'" href="#" :text="'閉じる'" :icon="'close'" :size="2.4" :color="colors.white" @onClick="closeModal" /></p>
                <div class="inner">
                    <slot name="modal" />
                    <group-change-modal v-if="$store.state.modalTarget == 'group-change-modal'"
                        :user-account="userAccount"
                        @groupChanged="onGroupChanged"
                    />
                </div>
            </section>
        </div>

        <loading v-if="$store.state.isLoading" :type="'page'" />

        <div v-if="$store.state.toastLabel" class="toast_wrap">
            <notice :type="$store.state.toastType" :label="$store.state.toastLabel" class="toast" />
        </div>
    </main>
</template>

<script>
    import MixinCommon from '@/mixin/common'

    import gxIcon from '@/components/atoms/icon'
    import loading from '@/components/atoms/loading'
    import breadcrumb from '@/components/molecules/breadcrumb'
    import notice from '@/components/molecules/notice'
    import { mapGetters } from 'vuex'

    import groupChangeModal from '@/components/organisms/common/groupChangeModal'

    import i18n from '@/i18n'

    export default {
        name: 'layout-manual',
        mixins: [MixinCommon],
        components: {
            gxIcon,
            loading,
            breadcrumb,
            notice,
            groupChangeModal,
        },
        data() {
            return {
            }
        },
        props: {
            title: {
                type: String,
                required: true
            },
            titleSub: {
                type: String,
                default: ''
            },
            navs: {
                type: Array,
                required: []
            },
            half: {
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                validator: (value) => {
                    return ['default', 'half', 'terms'].indexOf(value) !== -1
                },
                default: 'default'
            }
        },
        computed: {
            ...mapGetters([
                'userAccount',
            ]),
            warnings() {
                if (!this.userAccount.errors || !this.userAccount.errors.length) return []
                const warnings = []
                for (const msg of this.userAccount.errors) {
                    if (msg) {
                        warnings.push(msg)
                    }
                }
                return warnings
            },
            origin() {
                return {
                    to: '/dashboard',
                    label: i18n.t('ダッシュボード')
                }
            }
        },
        methods: {
            onGroupChanged() {
                // @TODO: $sotre.dispatchにしたい...
                location.reload()
            },
            closeModal() {
                this.$store.dispatch('closeModal')
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '~@/assets/scss/_variable.scss';
    .main {
    }
    .page-header {
        margin: 10px 0 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__title {
            font-size: 2.4rem;

            &__sub {
                display: block;
                font-weight: normal;
                font-size: .65em;
            }
        }
        &__actions {
            display: flex;

            :not(:first-child) {
                margin-left: 16px;
            }
        }
    }
    .default {

    }
    .half {
        width: 640px;
    }
    .terms {
        width: 100%;
    }
    .hd {
        width: 1280px;
    }

    .modal {
        background-color: rgba($color-black, .35);
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 100;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        $closeSize: 24px;
        $closeSpacing: 8px;

        .contents {
            position: relative;
            background-color: $color-bg;
            max-height: calc(100vh - #{2 * ($closeSize + $closeSpacing + 36px)});
        }
        .inner {
            padding: 40px;
            overflow: auto;
            height: 100%;
        }
        .close-button {
            position: absolute;
            right: 0;
            bottom: calc(100% + #{$closeSpacing});
            text-align: right;
            line-height: 1;
            height: $closeSize;
        }
    }

    .user-notification-warning {
        margin-bottom: $notice-margin;
    }

    .toast_wrap {
        position: fixed;
        top: 0;
        left: 0;
        
        display: flex;
        flex-direction: row;
        align-content: space-around;
        justify-content: center;

        z-index: 100;
        width: 100%;
        height: 0;
        overflow: visible;

        .toast {
            margin-top: 100px;
        }
    }

</style>