import api from '@/services/api'

export default {
    API_ENDPOINT: '/api-auth',
    login(groupname, username, password) {
        const params = {
            groupname: groupname,
            username: username,
            password: password
        }
        return api.post(`${this.API_ENDPOINT}/login`, params)
    },
    logout() {
        return api.post(`${this.API_ENDPOINT}/logout`)
    }
}
