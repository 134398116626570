/**
 * エラーメッセージの設定
 */
import { localize } from "vee-validate";

import ja from 'vee-validate/dist/locale/ja.json';
import en from 'vee-validate/dist/locale/en.json';

localize({
    ja: {
        messages: {
            ...ja.messages,
            regex_s: ja.messages.regex
        },
    },
    en: {
        messages: {
            ...en.messages,
            regex_s: en.messages.regex
        },
    },
})

localize('ja')
