export const excludedCountryCode = Object.freeze([
    {alpha3: 'ALA'},
    {alpha3: 'ATF'},
    {alpha3: 'ATG'},
    {alpha3: 'BES'},
    {alpha3: 'BVT'},
    {alpha3: 'CCK'},
    {alpha3: 'CUW'},
    {alpha3: 'CXR'},
    {alpha3: 'GUF'},
    {alpha3: 'HMD'},
    {alpha3: 'IOT'},
    {alpha3: 'MAC'},
    {alpha3: 'MAF'},
    {alpha3: 'MTQ'},
    {alpha3: 'NFK'},
    {alpha3: 'SGS'},
    {alpha3: 'SJM'},
    {alpha3: 'SSD'},
    {alpha3: 'SXM'},
    {alpha3: 'TKL'},
    {alpha3: 'UMI'},
    {alpha3: 'XKX'}
])

export const plan = Object.freeze({
    0: {
        label: ' GB',
        value: 0,
    },
    1: {
        label: ' TB',
        value: 1,
    },

})