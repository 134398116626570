<template>
    <gx-section>
        <gx-title :level="2">
            <template #default>{{$t('トラステッド・シグネチャ')}}</template>
            <template #actions>
                <gx-button v-if="!isEdit" :text="$t('編集')" :size="'small'" @onClick="startEdit" :disabled="!isEditable" />
            </template>
        </gx-title>

        <template v-if="isLoading"><loading /></template>
        <notice v-else-if="isLoadError" :type="'error'" :label="$t('通信に失敗しました')" />
        <template v-else>
            <gx-section class="list">
                <gx-title :level="3">
                    <template #default>{{$t('CMS選択')}}</template>
                </gx-title>
                <website-waf-trusted-settings class="row" :isEdit="isEdit" :terms="cmsTerms" :data="propsData" :defaultPaths="defaultPaths"/>
                <button-wrap v-if="isEdit" class="button-wrap" :buttons="buttons" @cancel="cancel" @save="save" />
            </gx-section>
            <div class="manual">
                <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
            </div>               
        </template>
    </gx-section>
</template>

<script>
    import Const from '@/static/constants'

    import gxSection from '@/components/atoms/section'
    import gxButton from '@/components/atoms/button'
    import gxTitle from '@/components/atoms/title'
    import gxIconText from '@/components/atoms/icon-text'
    import loading from '@/components/atoms/loading'
    import buttonWrap from '@/components/molecules/button-wrap'
    import notice from '@/components/molecules/notice'
    import websiteWafTrustedSettings from '@/components/organisms/website/waf-trusted-settings'
    import WebsiteService from '@/services/websiteService'
    import ManualService from '@/services/manualService'
    import gxFormText from '@/components/atoms/form/text'    

    import i18n from '@/i18n'

    export default {
        name: 'website-waf-trusted',
        components: {
            gxSection,
            gxButton,
            gxTitle,
            gxIconText,
            loading,
            buttonWrap,
            notice,
            gxFormText,   
            websiteWafTrustedSettings
        },
        props: {
            isEditable: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            const defaultData = {}
            const cmsTerms = ['eccube','wordpress','movabletype']
            const defaultPaths = {}
            Const.event.CMSType.forEach(CMSType => {
                const attackName = CMSType.name
                        defaultData[attackName] = CMSType.check
                        defaultPaths[attackName + "_path"] = CMSType.path
            })

            return {
                isEdit: false,
                data: defaultData,
                requestParams: {},  // dataをコピーする
                cmsTerms: cmsTerms,
                defaultPaths: defaultPaths,
                isLoading: true,
                isLoadError: false
            }
        },
        computed: {
            propsData() {
                return this.isEdit ? this.requestParams : this.data
            },
            buttons() {
                return [{
                    text: i18n.t('保存'),
                    size: 'large',
                    onClick: 'save'
                }, {
                    text: i18n.t('キャンセル'),
                    type: 'common',
                    size: 'large',
                    onClick: 'cancel'
                }]
            }
        },
        methods: {
            fetchData() {
                this.isLoading = true
                this.isLoadError = false
                WebsiteService.fetchWafTrustedDetail(this.$route.params.uuid).then(res => {
                    Object.keys(this.data).forEach(key => {
                        this.$set(this.data, key, !!res[key])
                    })
                    Object.keys(this.defaultPaths).forEach(key => {
                        this.$set(this.defaultPaths, key, res[key])
                    })
                    this.isLoading = false
                }).catch(() => this.isLoadError = true)
            },
            startEdit() {
                this.requestParams = Object.assign({}, this.data)
                this.isEdit = true
            },
            cancel() {
                this.isEdit = false
            },
            async save() {
                this.$store.dispatch('displayLoading')
                let params={}
                for(let key in this.propsData){
                    params[key] = this.propsData[key]
                }
                for(let key in this.defaultPaths){
                    params[key] = !this.defaultPaths[key] ? "^." : this.defaultPaths[key]
                }
                await WebsiteService.updateWafTrustedDetail(this.$route.params.uuid, params).then(() => {
                    this.$store.dispatch('openToast', {type: 'success', label: i18n.t('保存しました')})
                    this.fetchData()
                    this.isEdit = false
                }).catch(err => {
                    const message = err && err.response && err.response.data && err.response.data.length ? err.response.data.join('\n') : i18n.t('保存に失敗しました')
                    this.$store.dispatch('openToast', {type: 'error', label: message})
                })
                this.$store.dispatch('hideLoading')
            },
            manual(){
  		        return '/manual/wiki/Trusted_sig_' + i18n.locale
  		    },     
        },
        created() {
            this.fetchData()
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .settings {

        .validity {
            font-weight: bold;

            &::before {
                color: $color-success;
            }
        }
        .invalid {
            margin-left: 26px;
            opacity: $opacity-disabled;
        }
    }
    .list{
        display: flex;
        height: 400px;
        flex-direction: column;
        justify-content: flex-start;
        .row{
            flex: 1;
        }
    }
</style>