<template>
    <div class="page-wrapper">
        <global-header class="global-header" />
        <global-nav v-if = menudisplay() class="global-nav" />
        <router-view class="contents" />
        <global-footer class="global-footer" />
    </div>
</template>

<script>
    import globalHeader from '@/components/molecules/global/header'
    import globalNav from '@/components/molecules/global/nav'
    import globalFooter from '@/components/molecules/global/footer'

    export default {
        name: 'layouts-default',
        components: {
            globalHeader,
            globalNav,
            globalFooter
        },
        methods: {
            menudisplay(){
                if(this.$route.path.includes('manual')){
                    return false
                }else{
                    return true
                }
            }
        }
    }

</script>

<style lang="scss" scoped>
    .page-wrapper {
        min-height: 100vh;
        max-height: 100vh;
        padding: 55px 0 0 55px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .global-header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        z-index: 60;
    }
    .global-nav {
        position: fixed;
        top: 56px;
        left: 0;
        height: calc(100vh - 56px);
        z-index: 50;
    }
    .contents {
        max-height: calc(100vh - 56px);
        overflow: auto;
        padding: 0 5px 0 10px;
    }
    .global-footer {
        margin-top: 0;
    }
</style>
