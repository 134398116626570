<template>
    <div class="box"><slot /></div>
</template>

<script>
    export default {
        name: 'gx-box'
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .box {
        padding: 32px;
        background-color: $color-parts-bg;

        :first-child {
            margin-top: 0;
        }
        :last-child {
            margin-bottom: 0;
        }
    }
</style>