<template>.
    <gx-section class="modal">
        <template #title>{{$t('証明書選択')}}</template>
            <certificate-table
                :certificates="certificateList"
                :sort="sort"
                @updateOrder="updateOrder"
                @closeModal="closeModal"
                @selectCertificate="onSelectCertificate"
            />
        <pager :pager="pager" @changePage="changePage" @changePageSize="changePageSize" />
    </gx-section>
</template>
<script>
    import Const from '@/static/constants'
    import i18n from '@/i18n'

    import certificateTable from '@/components/organisms/website/certificateTable'
    import gxSection from '@/components/atoms/section'
    import gxIcon from '@/components/atoms/icon'
    import pager from '@/components/molecules/pager'

    import accountService from '@/services/accountService'
    import {
        certificateService,
        list, CertificateSearchParam, TicketUpdateParam, BulkUpdateParam, 
        EditComment,
    } from '@/services/certificateService'

    export default {
        name: 'website-select-certificate',
        components: {
            gxSection,
            gxIcon,
            certificateTable,
            pager,
        },
        props: {
            userAccount: {
                type: Object,
                "default": function() {
                    return {}
                }
            },
        },
        data: function() {
            return {
                Const: Const,
                searchParam: {},
                sort: {
                    field: '',
                    order: ''
                },
                certificateList: [],
                pager: {},
            }
        },
        computed: {
        },
        created() {
            this.fetch();
        },
        methods: {
            async onSelectCertificate(certificateId) {
                this.$emit('certificate', certificateId)
                this.closeModal()
            },
            async fetch(isSearching) {
                let params = {}

                this.isLoading = true
                this.isLoadError = false
                try {
                const p = (!isSearching) ? this.urlQuery() : this.searchParam
                    for (let key in p) {
                        let v = p[key]
                        // 空文字とundefinedは値に含めない
                        if (!(typeof v == "string" && v.length <= 0) && (v != undefined)) {
                            params[key] = v
                        }
                    }
                if (this.sort) {
                    let orderParam = this.sort.field
                    if(this.$data.sort.order == 'desc') orderParam = `-${orderParam}`
                    params.o = orderParam
                }
                let res = await certificateService.selectList(params)
                this.certificateList.splice(0,  this.certificateList.length)
                for (let i in res.results) {
                    this.certificateList.push(res.results[i])
                }
                this.$set(this.$data, 'pager', res.page ? res.page : {})
            } catch(e) {
                    this.isLoadError = true
                } finally {
                    this.isLoading = false
                }
            },
            changePage(page) {
                this.updateQuery('page', page)
                this.fetch(true)
            },
            changePageSize(size) {
                this.updateQuery('page_size', size)
                this.updateQuery('page', 1)
                this.fetch(true)
            },
            updateOrder(field, order) {
                this.sort.field = field
                this.sort.order = order
                this.fetch()
            },
            urlQuery() {
                return this.$route.query
            },
            updateQuery(key, val) {
                this.$set(this.searchParam, key, val)
            },
            closeModal() {
                this.$store.dispatch('closeModal')
            }
        },
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .modal-inner {

        &--large {
            width: 760px;
        }
        &--medium {
            width: 560px;
        }
        &--small {
        }
    }
    .title {
        text-align: center;
        font-weight: bold;
        font-size: 2.4rem;
        margin-bottom: 56px;
    }
    .body {
    }
    .footer {
        margin-top: 40px;
    }
    .modal{
    background-color: rgba(0, 0, 0, .75);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

}

</style>