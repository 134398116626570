import api from '@/services/api'

export default {
    API_ENDPOINT: '/api/news',
    fetchList(params) {
        return api.get(`${this.API_ENDPOINT}/`, { params: params })
            .then(response => response.data)
    },
    fetchDetail(id) {
        return api.get(`${this.API_ENDPOINT}/${id}/`)
            .then(response => response.data)
    }
}