<template>
    <validation-provider tag="div" :vid="name" :rules="rules" :class="['wrap', {'is-disabled' : disabled}]" v-slot="{ errors }">
        <em v-if="setErrors(errors) && !valid" class="error">{{errorText}}</em>
        <div class="field-wrap">
            <span v-if="prefix || $slots.prefix" class="prefix"><slot name="prefix">{{prefix}}</slot></span>
            <select
                :name="name"
                :class="classes"
                :value="value"
                :disabled="disabled"
                :required="!disabled && required"
                @change="setInput"
            >
                <option v-for="option in options" v-bind:key="option[valueAttr]"
                    :value="option[valueAttr]">{{option[strAttr]}}</option>
            </select>
            <span v-if="suffix || $slots.suffix" class="suffix"><slot name="suffix">{{suffix}}</slot></span>
        </div>
        <small v-if="note" class="note">{{note}}</small>
    </validation-provider>
</template>

<script>
    export default {
        name: 'gx-form-select',
        props: {
            name: {
                type: String
            },
            rules: {
                type: [String, Object],
            },
            options: {
                type: Array,
                default: () => []
            },
            disabled: {
                type: Boolean,
                default: false
            },
            value: {
                type: [String, Number],
                default : ""
            },
            valueAttr: {
                type: String,
                default: 'value',
            },
            strAttr: {
                type: String,
                default: 'str',
            },
            isAutoWidth: {
                type: Boolean,
                default: false
            },
            note: {
                type: String,
                default: ''
            },
            // FIXME: 冗長。propsError, propsErrorMsgはerrorsひとつで置き換える
            propsError: {
                type: Boolean,
                default: false
            },
            propsErrorMsg: {
                type: [String, Array],
                default: ''
            },
            prefix: {
                type: String,
                default: ''
            },
            suffix: {
                type: String,
                default: ''
            },
            required: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                valid: !this.propsError,
                internalErrors: [],
                externalErrors: this.propsErrorMsg
            }
        },
        computed: {
            classes() {
                const classes = ['select', {'is-autoWidth' : this.isAutoWidth}]
                if(!this.valid) classes.push('is-error')
                return classes
            },
            errorText() {
                const errors = this.internalErrors.length > 0 ? this.internalErrors : this.externalErrors
                return Array.isArray(errors) ? errors.join(' / ') : errors
            },
    },
    watch: {
        propsError() {
            this.valid = !this.propsError
        },
        propsErrorMsg(errors) {
            this.externalErrors = errors
        }
    },
    methods: {
        setErrors(errors) {
            this.internalErrors = errors
            return true
        },
        setInput(event) {
            this.externalErrors = []
            this.$emit('input', event.target.value)
        },
    }
}
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    $max-width: 400px;
    .wrap {
        width: 100%;
        max-width: $max-width;
        display: inline-block;

        &.is-disabled {
            opacity: $opacity-disabled;
        }
    }
    .field-wrap {
        display: flex;
        align-items: center;
    }
    .prefix {
        white-space: nowrap;
        margin-right: 8px;
    }
    .suffix {
        white-space: nowrap;
        margin-left: 8px;
    }
    .select {
        width: 100%;
        height: 40px;
        line-height: 38px;
        border-radius: 6px;
        border: 1px solid $color-border;
        padding: 0 calc(16px + 16px + 8px) 0 16px;
        background: url("~@/assets/image/icon/arrow-down.svg") no-repeat right 16px center;
        background-size: 16px;
        -webkit-appearance: none;
        background-color: initial;
        color: $color-font;
        cursor: pointer;
        flex: 1 1 auto;

        &:disabled {
            cursor: not-allowed;
        }
        &.is-autoWidth {
            width: auto;
        }
        &.is-error {
            border-color: $color-error;
            background-color: rgba($color-error, .1);
        }
        option {
            background-color: $color-parts-bg;
        }
    }
    .note {
        display: block;
        margin-top: 8px;
        color: $color-note;
    }
    .error {
        display: block;
        margin-bottom: 8px;
        color: $color-error;
        font-weight: bold;
    }
</style>