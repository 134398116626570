<template>
    <modal @cancel="closeModal" @deleteItem="deleteItem">
        <template #title>{{$t('証明書を削除します')}}</template>
        <template><p>{{$t('この操作は取り消せません')}}</p></template>
        <template #footer><button-wrap :buttons="buttons" @cancel="closeModal" @deleteItem="deleteItem" /></template>
    </modal>
</template>

<script>
    import buttonWrap from '@/components/molecules/button-wrap'
    import modal from '@/components/molecules/modal'
    import {
        certificateService,
        deleteUpload,
    } from '@/services/certificateService'

    import i18n from '@/i18n'

    export default {
        name: 'delete-modal',
        components: {
            buttonWrap,
            modal
        },
        computed: {
            buttons() {
                return [{
                    text: i18n.t('削除'),
                    type: 'error',
                    onClick: 'deleteItem'
                }, {
                    text: i18n.t('キャンセル'),
                    type: 'common',
                    onClick: 'cancel'
                }]
            },
            uuid() {
                return this.$store.state.modalParam.uuid
            },
            isAuto() {
                return this.$store.state.modalParam.isAuto
            },
        },
        methods: {
            async deleteItem() {
                this.$store.dispatch('displayLoading')
                if (this.isAuto){
                    await certificateService.deleteAuto(this.uuid).then(() => {
                        this.$store.dispatch('openToast', {type: 'success', label: i18n.t('削除しました')})
                        this.$emit('reload')
                        this.closeModal()
                    }).catch(err => {
                        const message = err && err.response && err.response.data && err.response.data.length ? err.response.data.join('\n') : i18n.t('削除に失敗しました')
                        this.$store.dispatch('openToast', {type: 'error', label: message})
                    }).finally(()=>{
                        this.$store.dispatch('hideLoading')
                    })
                }else{
                    await certificateService.deleteUpload(this.uuid).then(() => {
                        this.$store.dispatch('openToast', {type: 'success', label: i18n.t('削除しました')})
                        this.$emit('reload')
                        this.closeModal()
                    }).catch(err => {
                        const message = err && err.response && err.response.data && err.response.data.length ? err.response.data.join('\n') : i18n.t('削除に失敗しました')
                        this.$store.dispatch('openToast', {type: 'error', label: message})
                    }).finally(()=>{
                        this.$store.dispatch('hideLoading')
                    })
                }
            },
            closeModal() {
                this.$store.dispatch('closeModal')
            }
        }
    }
</script>

<style>

</style>