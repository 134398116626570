<template>
    <table-base class="dashboard_group_table">
        <template #body>
            <table-tr>
                <gx-table-cell center class="label">{{$t('DNSのCNAME設定用ドメイン名')}}</gx-table-cell>
                <gx-table-cell center>{{group.record_name}}</gx-table-cell>
            </table-tr>
            <table-tr>
                <gx-table-cell center class="label">{{$t('DNSのCNAME設定用ドメイン名のIPアドレス')}}</gx-table-cell>
                <gx-table-cell center>{{group.nlb_ip}}</gx-table-cell>
            </table-tr>
            <table-tr>
                <gx-table-cell center class="label">{{$t('実サーバーで通信を許可するIPアドレス')}}</gx-table-cell>
                <gx-table-cell center>
                    <div v-for="ip in getDispNatGatewayIp(group.nat_gateway_ip)" :key="ip">
                        {{ip}}
                    </div>
                </gx-table-cell>
            </table-tr>
        </template>
    </table-base>
</template>

<script>
    import gxTableCell from '@/components/atoms/table/cell'
    import tableBase from '@/components/molecules/table/base'
    import tableTr from '@/components/molecules/table/tr'

export default {
    name: 'dashboard-group-table',
    props: {
        group: {
            type: Object,
            default: () => { return {nlb_ip: "", record_name: "", nat_gateway_ip: ""}; }
        }
    },
    components: {
        gxTableCell,
        tableBase,
        tableTr
    },
    data() {
        return {
            toggleTitle: "WAF導入時設定情報",
        }
    },
    methods: {
        getDispNatGatewayIp(ips) {
            let result = [];
            ips.split(',').forEach(elem => result.push(elem.trim()));
            return result;
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .dashboard_group_table {
        table-layout: fixed;
            .label {
                font-weight: bold;
            }
    }
</style>
