<template>
    <ul class="news-list">
        <li v-for="(item, index) in list" :key="index" class="news-list__item">
            <router-link :to="`/news/${item.id}`" class="news-list__link">
                <span class="news-list__date">{{formatLocalDate(item.released_at)}}</span>
                <gx-label v-if="isNew(item.released_at)"  class="news-list__label" text="NEW" type="success" />
                <gx-label v-if= "item.title.includes('【お知らせ】')" class="news-list__label" text="お知らせ" type="low" />
                <gx-label v-else-if="item.title.includes('【メンテナンス】')" class="news-list__label" text="メンテナンス" type="middle" />
                <gx-label v-else-if="item.title.includes('【障害発生】')" class="news-list__label" text="障害発生" type="error" />
                <strong class="news-list__title">{{setTitle(item.title)}}</strong>
            </router-link>
        </li>
    </ul>
</template>

<script>
    import MixinDate from '@/mixin/date'
    import gxLabel from '@/components/atoms/label'

    export default {
        name: 'news-list',
        mixins: [MixinDate],
        components: {
            gxLabel
        },
        props: {
            list: {
                type: Array,
                default: () => []
            }
        },
        computed: {
            oneWeekBefor() {
                return this.$moment().subtract(7, 'days')
            }
        },
        methods: {
            isNew(released_at) {
                return this.toLocalDate(released_at).isAfter(this.oneWeekBefor)
            },
            setTitle(title) {
                // タグを表示する場合、タグと重複する内容は削除する
                return title.replace("【お知らせ】", "").replace("【メンテナンス】", "").replace("【障害発生】", "")
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .news-list {
        background: $color-parts-bg;

        &__item {

            &:not(:last-child) {
                border-bottom: 1px solid $color-parts-accent;
            }
        }
        &__link {
            padding: 16px 24px;
            min-height: 56px;
            display: flex;
            text-decoration: none;

            &::after {
                @include icon(arrow-right);
                margin-left: auto;
                align-self: center;
            }
            &:hover {
                background: rgba($color-white, .15);
                color: #56d19e;
                color: mix($color-white, $color-primary, 35);
                opacity: 1;
            }
        }
        &__label {
            margin-right: 8px;
            flex: 0 0 auto;
        }
        &__date {
            flex: 0 0 146px;
            white-space: nowrap;
        }
        &__title {
            overflow-wrap: break-word;
            flex: 1 1 auto;
            margin-right: 32px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
</style>
