<template>
    <component :is="tag" :class="['icon-text', icon, posi]" :style="styles" :to="to" @click="$emit('onClick')">{{text}}</component>
</template>

<script>
    import Const from '@/static/constants'
    export default {
        name: 'gx-icon-text',
        props: {
            tag: {
                type: String,
                default: 'span'
            },
            text: {
                type: String,
                default: ''
            },
            icon: {
                type: String,
                default: ''
            },
            to: {
                type: String,
                default: ''
            },
            posi: {
                type: String,
                validator: (value) => {
                    return ['right', 'left'].indexOf(value) !== -1
                },
                default: 'left'
            },
            size: {
                type: Number,
                default: 1.125
            },
            color: {
                type: String,
                default: Const.common.colors.ICON
            }
        },
        computed: {
            styles() {
                return {
                    '--size': `${this.size}em`,
                    '--color': this.color
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .icon-text {

        &.left::before,
        &.right::after {
            color: var(--color);
            font-size: var(--size);
            vertical-align: middle;
            text-decoration: none;
        }
        &.left::before {
            margin-right: 8px;
        }
        &.right::after {
            margin-left: 8px;
        }
        @each $icon, $code in $gx-icons {
            &.left.#{$icon}::before,
            &.right.#{$icon}::after {
                @include iconInline($icon);
            }
        }
    }
</style>