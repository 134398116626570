<template>
    <span class="wrap">
        <label v-if="label || this.$slots.label" :for="id" class="label"><slot name="label">{{label}}</slot></label>
        <validation-provider
            v-slot="{ errors }"
            :vid="name"
            :name="this.$attrs.term || label || name"
            :rules="rules"
            tag="span"
            :class="['wrap', {'is-disabled' : disabled}]">
            <em v-if="setErrors(errors) && errorText" class="error">
                {{errorText}}<br>
                <em >
                    <small>{{ examples }}</small>
                </em>
            </em>
            <textarea
                :id="id"
                :name="name"
                :class="classes"
                :type="type"
                :disabled="disabled"
                :placeholder="placeholder"
                :pattern="disabled ? undefined : patternRegex"
                :required="!disabled && required"
                :value="value"
                @input="setInput"
            ></textarea>
            <small v-if="note" class="note">{{note}}</small>
        </validation-provider>
</span>
</template>

<script>
    import Const from '@/static/constants'
    export default {
        name: 'gx-form-textarea',
        props: {
            id: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: ''
            },
            label: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: 'placeholder text'
            },
            disabled: {
            type: Boolean,
            default: false
            },            
            type: {
                type: String,
                validator: (value) => {
                    return ['text', 'password', 'date', 'email'].indexOf(value) !== -1
                },
                default: 'text'
            },
            error: {
                type: Boolean,
                default: false
            },
            note: {
                type: String,
                default: ''
            },
            patterns: {
                type: [String, Array],
                default: ''
            }, 
            value: {
                type: String,
                default: ''
            },
            required: {
                type: Boolean,
                default: false
            },
            errors: {
                type: [String, Array],
                default: ''
            },
        },
        data() {
        return {
            formErrors: [],
            validatorId: null,
            focus: false,
            errorText: ''
        }
    },
        watch: {
            errors(val) {
                if (val.length) {
                    this.parseTempErrorText()
                }
            },
            formErrors() {
                this.cancelValidate()
                if (this.focus) {
                    // 入力中値が切り替わりすぎないようエラーの更新を遅延させる
                    this.validatorId = setTimeout(this.parseErrorText, 200)
                } else {
                    this.parseErrorText()
                }
            }
        },
        computed: {
            examples() {
                let examples = ''
                if(Array.isArray(this.patterns)) {
                    const rulesArray = []
                    this.patterns.forEach((item) => {
                        rulesArray.push(Const.validate.patternType[item])
                    })
                    examples = rulesArray.join(' もしくは ')
                } else {
                    examples = Const.validate.patternType[this.patterns]
                }
                return examples
            },
            patternRegex() {
                if (this.type === 'number') {
                    return '\\d*'
                }
                let validators = ''
                if(Array.isArray(this.patterns)) {
                    const validatorsArray = []
                    this.patterns.forEach((item) => {
                        validatorsArray.push('(' + Const.validate.pattern[item] + ')')
                    })
                    validators = validatorsArray.join('|')
                } else {
                    validators = Const.validate.pattern[this.patterns]
                }
                return validators
            },            
            classes() {
                let classes = []
                if(this.type == 'comment'){
                    classes = ['comment']
                }else{
                    classes = ['input']
                }
                if(this.type == 'date') classes.push('date')
                if(this.error) classes.push('is-error')
                return classes
            },
            setErrors() {
                return errors => {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    if (errors.length > 0){
                        this.formErrors = errors
                    }else{
                        this.formErrors = this.errors
                    }
                    return true
                }
            }            
        },
        methods: {
            setInput(event) {
                this.$emit('input', event.target.value)
            },
            cancelValidate() {
                if (this.validatorId) {
                    clearTimeout(this.validatorId)
                    this.validatorId = null
                }
            },
            parseTempErrorText() {
                this.cancelValidate()
                this.parseErrors(this.errors)
            },
            parseErrorText() {
                this.parseErrors(this.formErrors)
            },
            parseErrors(errors) {
                this.errorText = Array.isArray(errors) ? errors.join(' / ') : errors
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .wrap {
        width: 100%;
        display: inline-block;
    }
    .label {
        display: block;
        margin-bottom: 8px;
    }
    .input {
        width: 100%;
        max-width: 400px;
        height: 120px;
        border-radius: 6px;
        border: 1px solid $color-border;
        padding: 8px 16px;

        &.is-error {
            border-color: $color-error;
            background-color: rgba($color-error, .1);
        }
        &:disabled {
            cursor: not-allowed;
            opacity: $opacity-disabled;
        }        
    }
    .comment {
        width: 100%;
        height: 300px;
        border-radius: 6px;
        border: 1px solid $color-border;
        padding: 8px 16px;
    }    
    .note {
        display: block;
        margin-top: 8px;
        color: $color-note;
    }
    .error {
        display: block;
        margin-bottom: 8px;
        color: $color-error;
        font-weight: bold;
    }    
</style>