<template>
    <pre class="code"><code><slot /></code></pre>
</template>

<script>
    export default {
        name: 'gx-code'
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .code {
        overflow: auto;
        padding: 16px;
        background-color: $color-parts-accent;

        &:not(:last-child) {
            margin-bottom: 32px;
        }
    }
</style>