<template>
    <label class="switch">
        <input type="checkbox" class="input" name="protection" :checked="checked" @change="setInput">
        <span class="labels">
            <span class="label on" >{{on}}</span>
            <span class="label off" >{{off}}</span>
        </span>
    </label>
</template>

<script>
    export default {
        name: 'gx-form-switch',
        props: {
            on: {
                type: String,
                default: 'ON'
            },
            off: {
                type: String,
                default: 'OFF'
            },
            checked: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            setInput(event) {
                this.$emit('change', event.target.checked)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .switch {
        cursor: pointer;
        overflow: hidden;
        border: 1px solid $color-border;
        border-radius: 12px;
        width: 64px;
        height: 24px;
        padding: 1px;
        display: inline-block;

        .input {
            display: none;

            &:checked {
                &+.labels {
                    left: 0;

                    &::after {
                        border-color: $color-current;
                        background-color: $color-current;
                    }
                }
            }
        }
        .labels {
            transition: $transition-common;
            left: -40px;
            display: flex;
            justify-content: space-between;
            position: relative;
            width: 100px;
            height: 100%;

            &::after {
                transition: $transition-common;
                content: "";
                width: 18px;
                height: 18px;
                border-radius: 50%;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                background-color: transparent;
                border: 1px solid $color-border;
            }
        }
        .label {
            width: 40px;
            text-align: center;
            font-size: 1.6rem;
        }
    }
</style>