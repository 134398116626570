<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  name: 'chart',
  props: {
    chartdata: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      data: {
        labels: this.getchartdata("date"),      
        datasets: [
          {
            type: "line",
            label: "最大値", // 凡例とツールチップに表示されるデータセットのラベル
            backgroundColor: "rgba(255, 99, 132, 0.2)", // 線の下の塗りつぶしの色
            borderColor: "rgba(255, 99, 132)", // 線の色
            borderWidth: 1, // 線の幅
            pointBackgroundColor: "rgba(255, 99, 132, 0.2)", // 点の塗りつぶしの色
            pointBorderColor: "rgba(255, 99, 132)", // 点の境界線の色
            data: this.getchartdata("max")
          },
          {
            type: "line",
            label: "平均値", // 凡例とツールチップに表示されるデータセットのラベル
            backgroundColor: "rgba(75, 192, 192, 0.2)", // 線の下の塗りつぶしの色
            borderColor: "rgba(75, 192, 192)", // 線の色
            borderWidth: 1, // 線の幅
            backgroundColor: 'rgba(54, 162, 235, 0.2)', // 点の塗りつぶしの色
            borderColor: 'rgba(54, 162, 235, 1)', // 点の境界線の色
            data: this.getchartdata("avg")
          },
        ]
      },
      options: {
        title: {
          display: true,
          text: "CPU使用率",
          fontColor: "white",
          font: {
            size: 16
          }
        },
        legend:{
          labels:{
            fontColor: "white",
          }
        },
        tooltips: {
          // ツールチップに関する設定
          // See https://misc.0o0o.org/chartjs-doc-ja/configuration/tooltip.html
          display: true,
          mode: 'index',  // ツールチップに、2つのデータを表示させる
          callbacks:{title: function(tooltipItems, data) {
                        return data.labels[tooltipItems[0].index].tooltip;
                    },
                    label: function(tooltipItem, data) {
                        return `${data.datasets[tooltipItem.datasetIndex].label} ： ${tooltipItem.value}%`;
                    }
          }
        },        
        scales: {
          xAxes: [{
            scaleLabel: {
               display: true,
               labelString: '測定日時'
             },            
            ticks: {
              callback: function(label){
                  return label.label;
              },
              fontColor: "white"
            }
          }],
          yAxes: [{    
            ticks: {
              min: 0,
              max: 100,
              beginAtZero: true,
              stepSize: 10,
              fontColor: "white",
              callback: (value) => `${value}%`,
            }
          }]
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.data, this.options)
  },
  methods: {
    getchartdata(type) {
      this.getvalue = []
      for (let i = 0; i < this.chartdata.length; i++) {
        if(type == "max"){
          this.getvalue.push(this.chartdata[i].volume_max)
        }else if(type == "avg"){
          this.getvalue.push(this.chartdata[i].volume_avg)        
        }else if(type == "date"){
          this.getvalue.push({label: this.chartdata[i].date, tooltip: this.chartdata[i].date})
        }
       }
      return this.getvalue
    }
  }
}
</script>