<template>
    <section :class="['section', {'section--half': half}]"><slot /></section>
</template>

<script>
    export default {
        name: 'gx-section',
        props: {
            half: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .section--half {
        width: 50%;
    }
    .section:not(:last-child) {
        margin-bottom: 56px;
    }
</style>