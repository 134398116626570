<template>
    <table class="table">
        <thead class="thead">
            <slot name="head" />
        </thead>
        <tbody class="tbody">
            <slot name="body" />
        </tbody>
    </table>
</template>

<script>
    export default {
        name: 'table-base'
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';

    .table {
        width: 100%;
        border: none;
        border-spacing: 0;
        border-collapse: collapse;
    }
    .thead {
        background-color: $color-parts-accent;
    }
</style>