import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {createStore} from '@/store'

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import {VueMasonryPlugin} from 'vue-masonry'

import i18n from '@/i18n'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '@/validations'

Vue.use(VueMoment, { moment })
Vue.use(VueMasonryPlugin)

Vue.config.productionTip = false
Vue.config.devtools = true

const store = createStore()

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

window.gxvm = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
