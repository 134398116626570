<template>
    <table-base>
        <template #head>
            <table-tr type="head">
                <gx-table-cell :type="'th'" :sort="formatSort(sort, 'is_auto')" min @onSort="onSort">
                    {{$t('種別')}}
                </gx-table-cell>
                <gx-table-cell
                    :type="'th'"
                    :sort="formatSort(sort, 'hostname')"
                    min
                    @onSort="onSort"
                >
                    {{$t('ホスト名')}}
                </gx-table-cell>
                <gx-table-cell
                    :sort="formatSort(sort, 'allow_naked_domain_flg')"
                    :type="'th'"
                    min
                    @onSort="onSort"
                >
                    {{$t('www無し')}}
                </gx-table-cell>
                <gx-table-cell
                    :type="'th'"
                    min
                >
                    {{$t('ステータス')}}
                </gx-table-cell>
                <gx-table-cell
                    :type="'th'"
                    min
                >
                    {{$t('有効期限')}}
                </gx-table-cell>
                <gx-table-cell
                    :type="'th'"
                    min
                >
                    {{$t('最新登録日')}}
                </gx-table-cell>
                <gx-table-cell
                    :type="'th'"
                    min
                >
                    {{$t('ウェブサイト')}}
                </gx-table-cell>
                <gx-table-cell
                    :type="'th'"
                    :sort="formatSort(sort, 'comment')"
                    min
                    @onSort="onSort"
                >
                    {{$t('コメント')}}
                </gx-table-cell>
                <gx-table-cell :type="'th'" min></gx-table-cell>
            </table-tr>
        </template>
        <template #body>
            <table-tr v-for="certificate in certificates" :key="certificate.certificate_id">
                <gx-table-cell center>
                    {{$t(getType(certificate.is_auto))}}
                </gx-table-cell>
                <gx-table-cell center>{{certificate.hostname}}</gx-table-cell>
                <gx-table-cell center>
                    <gx-icon v-if="certificate.is_auto && certificate.allow_naked_domain_flg"
                        :icon="allowNakedDomainStyles[certificate.allow_naked_domain_flg].icon"
                        :color="getColor(allowNakedDomainStyles[certificate.allow_naked_domain_flg].color)" 
                    />
                </gx-table-cell>
                <gx-table-cell center>{{ $t(getStatusStr(certificate.status)) }}</gx-table-cell>
                <gx-table-cell center :class=getOverdue(certificate.expiration_date)>
                    {{formatLocalDateTime(certificate.expiration_date)}}
                </gx-table-cell>
                <gx-table-cell center>{{formatLocalDateTime(certificate.created_at)}}</gx-table-cell>
                <gx-table-cell center>
                    <div v-for="website in certificate.websites " :key=index>
                        {{website[`hostname`]}}
                    </div>
                </gx-table-cell>
                <gx-table-cell center class="comment">{{certificate.comment}}</gx-table-cell>
                
                <gx-table-cell min>
                    <span class="actions">
                        <gx-button
                            v-if ="!certificate.is_auto"
                            class="actions__item"
                            :tag="'a'"
                            :text="$t('詳細')"
                            :type="'common'"
                            :size="'small'"
                            @onClick="certificateDetail(certificate.certificate_id)"
                        />
                        <gx-button
                            class="actions__item"
                            :tag="'a'"
                            :text="$t('編集')"
                            :type="'common'"
                            :size="'small'"
                            :disabled="certificate.status != Const.certificate.status.USEBLE.value"
                            @onClick="editItem(certificate.certificate_id, certificate.is_auto)"
                        />
                        <gx-icon
                            class="actions__item"
                            :tag="'a'"
                            :text="$t('削除')"
                            :icon="'trash'"
                            :disabled="certificate.websites.length|| (userAccount.is_support && userAccount.role != Const.accounts.roleSupport
                                    || (certificate.status != Const.certificate.status.USEBLE.value && certificate.status != Const.certificate.status.ERROR.value) ) "
                            @onClick="deleteItem(certificate.certificate_id, certificate.is_auto)"
                            href="#" />
                    </span>
                </gx-table-cell>
            </table-tr>
        </template>
    </table-base>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    
    import Const from '@/static/constants'
    import MixinCommon from '@/mixin/common'
    import MixinDate from '@/mixin/date'

    import gxTableCell from '@/components/atoms/table/cell'
    import gxFormCheckbox from '@/components/atoms/form/checkbox'
    import gxButton from '@/components/atoms/button'
    import gxIcon from '@/components/atoms/icon'
    import gxLabel from '@/components/atoms/label'
    import tableBase from '@/components/molecules/table/base'
    import tableTr from '@/components/molecules/table/tr'

    import accountService from '@/services/accountService'

    export default {
        name: 'certificate-table',
        mixins: [MixinCommon, MixinDate],
        components: {
            gxTableCell,
            gxFormCheckbox,
            gxButton,
            gxIcon,
            gxLabel,
            tableBase,
            tableTr,
            accountService
        },
        computed: {
            ...mapGetters([
                'userAccount'
            ]),
            allowNakedDomainStyles: () => Const.website.allowNakedDomainStyle,
        },
        data() {
            return {
                Const: Const,
            }
        },
        props: {
            certificates: {
                type: Array,
                default: () => []
            },
            status: {
                type: Array,
                default: () => []
            },
            sort: {
                type: Object,
                default: () => {}
            }
        },
        filters: {
            abbreviation(value) {
                return value && value.length > 16 ? `${value.substr(0,15)}…` : value
            }
        },
        methods: {
            onSort(field, order) {
                this.$emit('updateOrder', field, order)
            },
            getType(is_auto) {
                return Const.certificate.is_auto[is_auto]
            }, 
            getOverdue(expirationdate){
                let today = new Date().toLocaleDateString("ja-JP", {year: "numeric",month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit", millisecond: "3-digit"})
                
                let month  = new Date()
                month = new Date(month.setDate(month.getDate() + 30)).toLocaleDateString("ja-JP", {year: "numeric",month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit", millisecond: "3-digit"})

                if (this.formatLocalDateTime(expirationdate) < today){
                    return 'overdue'
                }else if (this.formatLocalDateTime(expirationdate) < month){
                    return 'neardue'
                }
            },
            editItem(uuid, is_auto) {
                if(is_auto){
                    this.$store.dispatch('openModalWithParam', {target: 'certificate-auto', param: {uuid: uuid, isEdit: true, detailType:"edit"}})       
                }else {
                    this.$store.dispatch('openModalWithParam', {target: 'certificate-upload', param: {uuid: uuid, isEdit: true, detailType:"edit"}})
                }
            },
            async certificateDetail(certificate_id){
                this.$store.dispatch('openModalWithParam', {target: 'certificate-detail', param: {uuid: certificate_id, type: "certificate"}})
            },
            deleteItem(uuid, is_auto) {
                this.$store.dispatch('openModalWithParam', {target: 'certificate-delete', param: {uuid: uuid, isAuto: is_auto}})
            },
            getStatusStr(value) {
                const obj = Const.certificate.statusArray.find((obj)=>{
                    return obj.value == value
                })
                return obj ? obj.str : value
            },
            getColor: (color) => Const.common.colors[color.toUpperCase()],

        }
    }
</script>

<style lang="scss" scoped>
    @import '~@/assets/scss/_variable.scss';
    .actions {
        display: flex;
        justify-content: flex-end;

        &__item:not(:first-child) {
            margin-left: 24px;
        }
    }
    .overdue{
        color: $color-error;
    }
    .neardue{
        color: $color-warning;
    }
    .comment{
        word-break:break-word;
    }
</style>

