<template>
    <div :class="['titleWrap', `titleWrap__lv${level}`]">
        <component
            :is="tag"
            :class="`title__lv${level}`"
        >
            <slot name="default" />
        </component>
        <div v-if="this.$slots.actions" class="titleWrap__action">
            <slot name="actions" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'gx-title',
        props: {
            level: {
                type: Number,
                default: 1
            }
        },
        computed: {
            tag() {
                return `h${this.level}`
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .titleWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__lv2 {
            margin-bottom: 40px;
        }
        &__lv3 {
            margin-bottom: 16px;
        }
        &__action {
            display: flex;

            & > *:not(:last-child) {
                margin-right: 16px;
            }
        }
    }
    .title {

        &__lv2 {
            font-size: 2.2rem;
            font-weight: bold;
        }
        &__lv3 {
            font-size: 1.8rem;
            font-weight: bold;
        }
    }
</style>