<template>
    <ul class="term-list">
        <li v-for="(item, index) in items" :key="index" class=term-list__item><slot name="item" :termItem="item">{{item}}</slot></li>
    </ul>
</template>

<script>
    export default {
        name: 'term-list',
        props: {
            items: {
                type: [Array, Object],
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .term-list {
        display: flex;
        flex-wrap: wrap;
        margin: -8px;

        &__item {
            margin: 8px;
        }
    }
</style>