<template>
    <table-base>
        <template #body>
            <table-tr v-for="(item, index) in transfer" :key=index>
              <div v-if="item.month.substr(5,2) == String(new Date().getMonth() + 1).padStart(2, '0')" >
                <gx-table-cell center v-if="Number(item.volume) < 0" >{{$t('転送量取得に失敗しました')}}</gx-table-cell>
                <gx-table-cell center v-else>
                    <transferredgraph
                        :class=transfe
                        :transferreddata="item"
                        :plandata="cal_plan_volume(plan_volume, plan_unit)"/>
                        <table>
                          <tbody>
                            <tr v-if="plan_volume == 0">
                              <th colspan="2" class = "title" style="text-align: center">
                                {{$t('トライアル')}}
                              </th>
                            </tr>
                            <tr v-else>
                              <th class = "title">
                                {{$t('契約プラン')}}
                              </th>
                              <th class = "title">
                                {{plan_volume + plan_unit}}                                
                              </th>
                            </tr>
                            <tr>
                              <td style="text-align: left">
                                {{$t('使用量')}}
                              </td>
                              <td>
                                {{getused(item.volume)}}
                              </td>
                            </tr>
                            <tr v-if="plan_volume != 0">
                              <td v-if="overflg" style="text-align: left">
                                {{$t('超過量')}}
                              </td>
                              <td v-else style="text-align: left">
                                {{$t('残り')}}
                              </td>
                              <td>
                                {{getdatas(plan_volume, plan_unit, item.volume)}}
                              </td>
                            </tr>
                          </tbody>

                        </table>
                </gx-table-cell>
              </div>
              <div v-else >
                <gx-table-cell center>
                  {{item.month}}
                </gx-table-cell>
                <gx-table-cell>
                  {{$t('使用量') + ' : ' + getused(item.volume)}}
                </gx-table-cell>
              </div>
            </table-tr>
        </template>
    </table-base>
</template>

<script>
    import gxTableCell from '@/components/atoms/table/cell'
    import tableBase from '@/components/molecules/table/base'
    import tableTr from '@/components/molecules/table/tr'
    import transferredgraph from '@/components/organisms/dashboard/transferred-graph'
    import Const from '@/static/constants'


export default {
    name: 'transfered-table-p',
    props: {
        transfer: {
            type: Array,
            default: () => []
        },
        plan_volume: {
            type: String,
            default: null
        },
        plan_unit: {
            type: String,
            default: null
        }
    },
    components: {
        gxTableCell,
        tableBase,
        tableTr,
        transferredgraph,
    },
    data() {
            return {
              overflg: false
            }
    },
    methods: {
        getused(volume){
          // 1TB以下はGBで表記する
          if(volume >= 1024){
           var retvalue =  (Math.round(volume * 1000 / 1024) / 1000).toFixed(3).toLocaleString() + Const.dashboard.plan[1].label
          }else{
              var retvalue =  Math.floor(volume).toFixed(0) + Const.dashboard.plan[0].label
          }
          return retvalue
        },
        getdatas(planvolume, planunit, volume){
          if (planunit == Const.dashboard.plan[1].label){
            // 契約プランの単位がTBの場合、GBの値を直す
            var value = planvolume * 1024 - Math.floor(volume)
          }else{
            var value = planvolume - Math.floor(volume)
          }
          if(value < 0){
            // 契約量を超過している場合は、マイナスを補正して小数点を切り捨てる
            this.overflg = true
            retvalue = Math.floor(value * -1).toLocaleString() + Const.dashboard.plan[0].label
          }
          else if(value >= 1024){
            // 1TB以上になる場合はTBで表記する
            var retvalue =  (Math.round(value * 1000 / 1024) / 1000).toFixed(3).toLocaleString() + Const.dashboard.plan[1].label
          }else{
            // 1TB以下はGBで表記する
            var retvalue =  value + Const.dashboard.plan[0].label
          }
          return retvalue
        },
        cal_plan_volume(value, unit){
            // TBのときは、GBに直す
            if (unit == Const.dashboard.plan[1].label){
              return value * 1024
            }else{
              return value
            }
        }
    }
}
</script>
<style lang="scss" scoped>
  table {
    margin-top: 30px;
    font-size: 1.2em;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    border-width: 5px;
  }
  .title{
    padding-bottom: 15px;
  }
</style>