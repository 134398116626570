import axios from 'axios'
import Cookies from 'js-cookie'
import qs from "qs"

const api = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
    // TODO: Nginxリロード処理見直し後に戻す
    // timeout: 20000,
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    },
    paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "repeat" })
    }
})

api.interceptors.response.use((response) => {
    return response
}, (error) => {
    const status = error.response ? error.response.status : 500
    if(status === 401) {
        window.gxvm.$store.dispatch('openToast', {type: 'error', label: 'ログアウトしました'})
        window.gxvm.$router.push({path: "/login"});
        return Promise.reject()
    } else {
        return Promise.reject(error)
    }
})

export default api
