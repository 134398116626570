<template>
    <div :class="['modal-inner', `modal-inner--${size}`]">
        <h1 class="title"><slot name="title" /></h1>
        <div class="body"><slot name="default" /></div>
        <div class="footer"><slot name="footer" /></div>
    </div>
</template>

<script>
    export default {
        name: 'modal',
        props: {
            size: {
                type: String,
                validator: (value) => {
                    return ['large', 'medium', 'small'].indexOf(value) !== -1
                },
                default: 'medium'
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .modal-inner {

        &--large {
            width: 760px;
        }
        &--medium {
            width: 560px;
        }
        &--small {
        }
    }
    .title {
        text-align: center;
        font-weight: bold;
        font-size: 2.4rem;
        margin-bottom: 56px;
    }
    .body {
    }
    .footer {
        margin-top: 40px;
    }
</style>