<template>
    <layout-main
        :title="$t('ユーザー設定')"
        :navs="navs"
        :type="'terms'"
    >
        <template #page-header>
            <gx-button v-if="userAccount.is_account_creatable" :tag="'router-link'" :text="$t('新規追加')" to="/accounts/register" />
            <div class="manual">
                <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
            </div>    
        </template>
        <template #default>
            <template v-if="isLoading"><loading /></template>
            <notice v-else-if="isError" :type="'error'" :label="$t('通信に失敗しました')" />
            <template v-else>
                <accounts-table :accounts="data.list" :sort="sort" @updateOrder="updateOrder" />
                <pager :pager="data.pager" @changePage="changePage" @changePageSize="changePageSize" />
            </template>
        </template>
        <template v-if="$store.state.isModal" #modal>
            <accounts-delete v-if="$store.state.modalTarget == 'accounts-delete'" @reload="reload" />
        </template>
    </layout-main>
</template>

<script>
    import layoutMain from '@/layouts/main'
    import gxButton from '@/components/atoms/button'
    import gxIconText from '@/components/atoms/icon-text'
    import loading from '@/components/atoms/loading'
    import pager from '@/components/molecules/pager'
    import notice from '@/components/molecules/notice'
    import accountsTable from '@/components/organisms/accounts/table'
    import accountsDelete from '@/components/organisms/accounts/delete'
    import AccountService from '@/services/accountService'
    import ManualService from '@/services/manualService'
    import { mapGetters, mapActions } from 'vuex'

    import i18n from '@/i18n'

    export default {
        name: 'accounts',
        components: {
            layoutMain,
            gxButton,
            gxIconText,
            loading,
            pager,
            notice,
            accountsTable,
            accountsDelete
        },
        data() {
            return {
                data: {
                    list: [],
                    pager: {},
                },
                requestParams: {
                    page: 1,
                    page_size: 20
                },
                isLoading: true,
                isError: false,
                sort: {
                    field: '',
                    order: ''
                }
            }
        },
        computed: {
            ...mapGetters([
                'userAccount',
                'hasAdminRole'
            ]),
            navs() {
                return [
                    {
                        to: '/accounts',
                        label: i18n.t('ユーザー設定')
                    }
                ]
            },
        },
        methods: {
            ...mapActions([
                'fetchUserAccount'
            ]),
            reload() {
                this.fetchUserAccount()
                this.fetch()
            },
            async fetch() {
                this.isError = false
                this.isLoading = true

                if(this.sort) {
                    let orderParam = this.sort.field
                    if(this.sort.order == 'desc') orderParam = `-${orderParam}`
                    this.requestParams.o = orderParam
                }

                await AccountService.fetchList(this.requestParams).then(res => {
                    this.$set(this.data, 'list', res.results ? res.results : [])
                    this.$set(this.data, 'pager', res.page ? res.page : {})
                }).catch(err => {
                    this.isError = true
                }).finally(() => {
                    this.isLoading = false
                })
            },
            updateQuery(key, val) {
                this.$set(this.requestParams, key, val)
            },
            changePage(page) {
                this.updateQuery('page', page)
                this.fetch()
            },
            changePageSize(size) {
                this.updateQuery('page_size', size)
                this.updateQuery('page', 1)
                this.fetch()
            },
            updateOrder(field, order) {
                this.sort.field = field
                this.sort.order = order
                this.fetch()
            },
            manual(){
                return '/manual/wiki/User_list_' + i18n.locale
            },       
        },
        created() {
            this.fetch()
        }
    }
</script>