<template>
    <div :v-masonry="masonry" item-selector=".item" class="settings" ref="common_list">
        <div v-for="item in items" v-bind:key="item" :v-masonry-tile="masonry" class="item"><slot :item="item" /></div>
    </div>
</template>

<script>
    export default {
        name: 'common-list',
        props: {
            masonry: {
                type: Boolean,
                default: true,
                required: false
            },
            items: {
                type: Array,
                required: true
            }
        },
        mounted: function() {
            if (typeof this.$redrawVueMasonry === 'function') {
                this.$redrawVueMasonry()
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .settings {
        width: 100%;
        transition-duration: 1s;

        .item {
            float: left;
            min-width: 390px;
            width: 100%;
            margin-right: 0.5em;
            margin-bottom: 0.25em;

            display: flex;
            flex-wrap: wrap;
            align-items: center;
            min-height: 56px;
            padding: 16px;
            background-color: $color-parts-bg;
            border-radius: 5px;

            &:not(:first-child) {
                border-top: 1px solid $color-hr;
            }
        }
    }
</style>