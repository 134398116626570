<template>
    <span :class="['wrap', {'is-disabled' : disabled}]">
        <validation-provider v-slot="{ errors, validate }" :rules="fileRules" ref="provider" :name="label">
            <label v-if="label || $slots.label" :for="id" class="label"><slot name="label">{{label}}</slot></label>
            <em v-if="errors.length || tempErrors.length" class="error">{{ errorText(errors) }}</em>
            <label :for="id" class="button">{{$t('ファイルを選択する')}}</label>
            <small v-if="!(!(fileName instanceof String) && Array.isArray(fileName))" class="fileName">{{fileName | abbreviation}}</small>
            <input
                type="file"
                :id="id"
                class="field"
                :disabled="disabled"
                :accept="accept"
                :multiple="multiple"
                @change="change"
            >
        </validation-provider>
        <validation-provider>
            <input type="hidden" v-model="base64" :required="!disabled && required">
        </validation-provider>
        <small v-if="note || $slots.note" class="note"><slot name="note">{{note}}</slot></small>
    </span>
</template>

<script>
    import gxIcon from '@/components/atoms/icon'
export default {
    name: 'gx-form-file',
    components: {
            gxIcon
    },
    props: {
        id: {
            type: String,
            default() {
                return `upload_${this._uid}`
            }
        },
        label: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        accept: {
            type: [String, Array],
            default: ''
        },
        multiple: {
            type: Boolean,
            default: false
        },
        maxsize: {  // kilobyte
            type: Number,
            default: null
        },
        errors: {
            type: Array,
            default: () => []
        },
        note: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            fileName: '',
            base64: null,
            fileErrors: [],
            tempErrors: this.errors,
            files: [],
            size: 0,
        }
    },
    watch: {
        base64() {
            this.$emit('change', this.base64)
        },
        errors(val) {
            this.tempErrors = val
        }
    },
    computed: {
        errorText() {
            return errors => {
                return (errors.length ? errors : this.tempErrors).join(' / ')
            }
        },
        fileRules() {
            const rules = {}
            if (this.accept && this.accept.length) {
                rules.ext = this.accept
            }
            if (this.maxsize) {
                rules.size = this.maxsize
            }
            return rules
        },
        inputElement() {
            return this.$el.getElementsByTagName('input')[0];
        },
        getErrorText() {
            return errors => Array.isArray(errors) ? errors.join(' / ') : errors
        }
    },
    methods: {
        async change(e) {
            this.tempErrors = []
            this.files.splice(0, this.files.length)

            await this.$refs.provider.validate(e)

            if (!this.multiple) {
                const file = this.inputElement.files.length ? this.inputElement.files[0] : null
                this.fileName = file ? file.name : null
                this.size = file ? file.size : 0

                if (file) {
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        this.base64 = e.target.result.split(/^data:.+;base64,/).join("")
                        file.base64 = this.base64
                        this.files.push(file)
                    }
                    reader.readAsDataURL(file)
                } else {
                    this.base64 = null
                }
            } else {
                const fileArray = []
                this.size = 0
                for (let i = 0; i < this.inputElement.files.length; i++) {
                    let f = this.inputElement.files[i]
                    fileArray.push(f)
                    this.size = this.size + f.size
                }
                let fileNameArray = []
                let base64Array = []
                
                await Promise.all(fileArray.map((file) => {
                    return new Promise((resolve, reject)=>{
                        fileNameArray.push(file.name)

                        const reader = new FileReader()
                        reader.onload = (e) => {
                            const base64Data = e.target.result.split(/^data:.+;base64,/).join("")
                            base64Array.push(base64Data)
                            file.base64 = base64Data
                            resolve(base64Data)
                        }
                        reader.readAsDataURL(file)
                    })
                }))

                for (let i in fileArray) {
                    this.files.push(fileArray[i])
                }
                this.fileName = fileNameArray
                this.base64 = base64Array
            }
        },
        clearfile(){
            this.fileName = []
            this.files = []
            this.size = 0
            this.base64 = []
        }
    },
    filters: {
        abbreviation(value) {
            return value.length>20 ? `${value.substr(0,20)}…` : value
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variable.scss';
.wrap {
    display: inline-block;
    vertical-align: top;

    &.is-disabled {
        opacity: .35;
        cursor: not-allowed;
        &>* {
            cursor: not-allowed;
        }
    }
}
.label {
    display: block;
    margin-bottom: 8px;
}
.note {
    display: block;
    margin-top: 8px;
    color: $color-note;
}
.error {
    display: block;
    margin-bottom: 8px;
    color: $color-error;
    font-weight: bold;
}
.field {
    display: none;
}
.button {
    display: block;
    width: 160px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: $size-radius;
    border: 1px solid $color-border;
    cursor: pointer;
}
.fileName {
    display: block;
    margin-top: 8px;
}
</style>
