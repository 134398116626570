<template>
    <span class="datetime-range">
        <gx-form-input :type="'datetime-local'" :placeholder="'YYYY/MM/DD'" :value="startDate" :max="endDate" class="date start" @input="setInput('startDate', $event)" />
        <span class="symbol">〜</span>
        <gx-form-input :type="'datetime-local'" :placeholder="'YYYY/MM/DD'" :value="endDate" :min="startDate" class="date end" @input="setInput('endDate', $event)" />
    </span>
</template>

<script>
    import gxFormInput from '@/components/atoms/form/input'
    export default {
        name: 'datetime-range',
        components: {
            gxFormInput
        },
        props: {
            startDate: {
                type: String,
                default: ''
            },
            endDate: {
                type: String,
                default: ''
            }
        },
        methods: {
            setInput(key, value) {
                this.$emit('input', value, key)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .datetime-range {
        display: flex;
        align-items: center;

        .date {
            width: 210px;
        }
        .start {

        }
        .symbol {

            margin: 0 16px;
        }
        .end {

        }
    }
</style>