<template>
    <modal  @cancel="submit" @submit="closeModal">
        <template #default>
            <notice class="notice" :type="info" :label="$t('この内容で登録しました。')" />
            <gx-section>
                <data-list :data="detectionTerms">
                    <template #title={item}>{{$t(getTerms(item))}}</template>
                    <template #data={item}>
                        <template v-if="item == 'issuer_country'">
                            {{getAttackSrcCountry(details[item])}}
                        </template>
                        <template v-else>
                            {{details[item]}}
                        </template>
                    </template>
                </data-list>
            </gx-section>
            <button-wrap :buttons="buttons()" class="button-wrap" @submit="closeModal" @cancel="closeModal" />
        </template>
    </modal>
</template>
<script>
    import layoutMain from '@/layouts/main'
    import modal from '@/components/molecules/modal'

    import CommonMixin from '@/mixin/common'
    import multiNotice from '@/components/molecules/multi-notice'
    import gxSection from '@/components/atoms/section'
    import gxFormText from '@/components/atoms/form/text'
    import gxFormFile from '@/components/atoms/form/file'
    import gxButton from '@/components/atoms/button'
    import gxIconText from '@/components/atoms/icon-text'
    import gxIcon from '@/components/atoms/icon'  
    import gxFormCheckbox from '@/components/atoms/form/checkbox';
    import buttonWrap from '@/components/molecules/button-wrap'
    import dataList from '@/components/molecules/data-list'
    import MasterService from '@/services/masterService'
    import notice from "@/components/molecules/notice"

    import Const from '@/static/constants'

    import i18n from '@/i18n'

    export default {
        name: 'certificate_confirmation_upload',
        components: {
            layoutMain,
            modal,
            multiNotice,
            gxSection,
            gxFormText,
            gxFormFile,
            gxButton,
            gxIconText,
            gxIcon,
            dataList,
            gxFormCheckbox,
            buttonWrap,
            MasterService,
            notice,
        },
        mixins: [CommonMixin],
        data() {
            return {
                countries: [],
                isLoadError: false,
                isLoading: true,
                groupid:"",
            }
        },
        computed: {
            details() {
                return this.$store.state.modalParam.details
            },
            type() {
                return this.$store.state.modalParam.type
            },
            detectionTerms() {
                return [
                    'serial_number',        // シリアル番号
                    'not_before',           // 有効期限（開始）
                    'not_after',            // 有効期限（終了）
                    'issuer_country',       // 発行者：国
                    'issuer_organization',  // 発行者：組織
                    'issuer_common_name',   // 発行者：コモンネーム
                    'common_name',          // 所有者：コモンネーム
                    'comment',              // コメント
                ]
            }
        },
        methods: {
            reload() {
                this.fetch()
            },
            getTerms(value) {
                return Const.certificate.detailTermsType[value]
            },
            getAttackSrcCountry(value) {
                return i18n.getCountryName(value)
            },
            async fetch() {
                this.isLoading = true
                this.isLoadError = false
                //this.event = await EventService.fetchDetail(this.$route.params.uuid).catch(err => {
                //    this.isLoadError = true
                //    return {}
                //})
                this.isLoading = false
            },
            closeModal() {
                this.$store.dispatch('closeModal')
            },
            buttons() {
                return [
                    {
                        text: i18n.t('OK'),
                        type: 'primary',
                        size: 'large',
                        onClick: 'submit'
                    },
                ]
            },
        },
        created() {
            this.fetch()
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .notice {
        margin-bottom: $notice-margin;
    }
</style>
