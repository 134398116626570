import Vue from 'vue'
import VueI18n from 'vue-i18n'
import camelCase from 'lodash/camelCase'
import countries from 'i18n-iso-countries'

// 言語選択セレクタを表示するかどうかのフラグ
const DOES_SHOW_LANGUAGE_SELECTOR = true
// デフォルトで選択される言語(ブラウザで設定されているロケールの頭2文字 ex."en - US" -> "en")
const DEFAULT_LANGUAGE = navigator.language.replace(/\s*-\s*.*(?=$)/g, '')

// 言語設定を ./language から読み込む
const requireModule = require.context('./language', false, /\.js$/)
const messages = {}
requireModule.keys().forEach(fileName => {
    // index.jsは無視
    if (fileName === './index.js') return

    // ex: "./en.js" -> "en"
    const moduleName = camelCase(
        fileName.replace(/(\.\/|\.js)/g, '')
    )
    
    // vue i18nの設定に追加
    messages[moduleName] = { ...requireModule(fileName).default }

    // i18n-iso-countriesの設定を読込
    countries.registerLocale(require(`i18n-iso-countries/langs/${moduleName}.json`))
})

Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: DEFAULT_LANGUAGE,
    fallbackLocale: 'ja',
    messages: messages
})
i18n.getCountryName = (alpha3) => {
    const locale = i18n.locale
    const iso = (alpha3.length >= 3) ? countries.alpha3ToAlpha2(alpha3) : alpha3
    const name = countries.getName(iso, locale);

    let ret
    if (!!name) {
        ret = name
    } else if (!!iso) {
        ret = iso
    } else {
        ret = alpha3
    }

    return ret
}
i18n.translateCountryNames = (countries) => {
    let ret = []
    for (let i in countries) {
        let item = {}
        let country = countries[i]
        for (let key in country) {
            item[key] = country[key]
            if (key == "name") {
                item[key] = i18n.getCountryName(country.iso)
            }
        }
        ret.push(item)
    }
    return ret
}
i18n.translateOptionValues = (options) => {
    const ret = []
    for (let i in options) {
        let item = {}
        for (let key in options[i]) {
            item[key] = (key == "str") ? i18n.t(options[i][key]) : options[i][key]
        }
        ret.push({
            value: options[i].value,
            str: i18n.t(options[i].str)
        })
    }
    return ret
}
i18n.DOES_SHOW_LANGUAGE_SELECTOR = DOES_SHOW_LANGUAGE_SELECTOR

export default i18n
