// @TODO:多言語化対応

const convObjToArr = (obj) => {
  const res = [];
  for (let key in obj) {
    res.push(obj[key]);
  }
  return res;
}

// ステータス
export const status = Object.freeze({
  EMPTY: {
    value: null,
    str: '',
    color: 'SUCCESS',
    class: 'middle',
  },
  USER: {
    value: 1,
    str: 'ユーザー',
    color: 'SUCCESS',
    class: 'middle',
  },
  EGSS: {
    value: 2,
    str: 'EGSS',
    color: 'SUCCESS',
    class: 'middle',
  },
  CLOSE: {
    value: 99,
    str: 'クローズ',
    color: 'SUCCESS',
    class: 'middle',
  },
})
export const statusArray = convObjToArr(status);

// ステータス
export const internalStatus = Object.freeze({
  UNTREATED: {
    value: 1,
    str: '未処理',
    color: 'SUCCESS',
    class: 'middle',
  },
  SENTALL: {
    value: 4,
    str: '一斉返信済',
    color: 'SUCCESS',
    class: 'middle',
  },
  NOSUPPORT: {
    value: 7,
    str: '対応不要',
    color: 'SUCCESS',
    class: 'middle',
  },
  CLOSE: {
    value: 8,
    str: 'クローズ',
    color: 'SUCCESS',
    class: 'middle',
  },
})
export const internalStatusArray = convObjToArr(status);

export const ticketTerms = {
  status: "ステータス",
  ticket_tag_set: "タグ",
  comment_set: "コメント",  
}

export const registerTerms = {
  status: "ステータス",
  title: "タイトル",
  content: "内容",
  waf_event: "イベントID",
  // ticket_tag_set: "タグ",
  ticket_file_set: "ファイル",
  ticket_mail_user_set: "メール宛先",
}

export const messageModalTerms = {
  content: "内容",
  status: "ステータス",
  comment_file_set: "ファイル",
}
