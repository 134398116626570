<script>
import mime from 'mime-types'
import i18n from '@/i18n'

export default {
    name: 'DownloadMixin',
    methods: {
        async downloadRawData(byteArray, fileName) {
            const mimeType = mime.lookup(fileName)

            const blob = new Blob([byteArray], {type: mimeType})
            const blobURL = window.URL.createObjectURL(blob)

            const link = document.createElement('a')
            link.href=blobURL
            link.setAttribute('download', fileName)
            link.click()

            window.URL.revokeObjectURL(blobURL)
        },

        async download(connection, type) {
            this.$store.dispatch('displayLoading')

            await connection.then(res => {
                const blob = new Blob([res.data], {type: 'application/msword'})
                const blobURL = window.URL.createObjectURL(blob)
                const filename = this.getFileName(res.headers['content-disposition'])
                if (!filename) {
                    const message = ''
                    if(type == 'report'){
                        this.message = i18n.t('レポートが存在しません')
                    }else{
                        this.message = i18n.t('CSVが存在しません')
                    }
                    this.$store.dispatch('openToast', {type: 'error', label: this.message})
                    return
                }
                const tempLink = document.createElement('a')
                tempLink.href = blobURL
                tempLink.setAttribute('download', filename)
                tempLink.click()

                setTimeout(function() {
                    window.URL.revokeObjectURL(blobURL)
                }, 0)
                this.$store.dispatch('openToast', {type: 'success', label: 'ダウンロードしました'})
            }).catch(err => {
                const message = err.response && err.response.data && err.response.data.length ? err.response.data.join('\n') : '通信に失敗しました'
                this.$store.dispatch('openToast', {type: 'error', label: message})
                return {}
            }).finally(() => {
                this.$store.dispatch('hideLoading')
            })
        },
        getFileName(contentDisposition) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/ // 正規表現でfilenameを抜き出す
            const matches = filenameRegex.exec(contentDisposition)
            if (matches != null && matches[1]) {
                const fileName = matches[1].replace(/['"]/g, '')
                return decodeURI(fileName) // 日本語対応
            } else {
                return null
            }
        },
    }
}
</script>
