<template>
    <layout-main
        :title="$t('ユーザー詳細')"
        :navs="navs"
        :type="'terms'"
    >
        <template #page-header>
            <gx-button v-if="!isEdit && isEditable" :text="$t('編集')" :size="'small'" :type="'common'" @onClick="startEdit" />
            <gx-icon v-if="isDeletable" :tag="'a'" :text="$t('削除')" :icon="'trash'" @onClick="deleteAccount" href="#" />
        </template>
        <template #default>
            <template v-if="isLoading"><loading /></template>
            <notice v-else-if="isLoadError" :type="'error'" :label="$t('データが取得出来ませんでした')" />
            <template v-else>
                <template v-if="isEdit">
                    <accounts-form :form-type="'update'" :prop-data="original_account_data" :prop-errors="formErrorMsg" @save="save" @cancel="cancel" />
                </template>
                <data-list v-else :data="terms">
                    <template #title={item}>{{$t(getTerm(item))}}</template>
                    <template #data={item}>
                        <template v-if="item == 'username'">{{original_account_data[item]}}</template>

                        <template v-else-if="item == 'email'">{{original_account_data[item]}}</template>

                        <template v-else-if="item == 'telephone'">{{original_account_data[item]}}</template>

                        <template v-else-if="item == 'password'">********</template>

                        <template v-else-if="item == 'expire_date'">
                            <gx-icon-text v-if="isExpired(original_account_data[item])" :icon="'exclamation_tryangle_fill'" :color="colors.warning" />
                            {{formatLocalDateTime(original_account_data[item])}}
                        </template>

                        <template v-else-if="item == 'tz'">{{tzList[original_account_data[item]]}}</template>

                        <template v-else-if="item == 'notification'">
                            <div class="notification_field">
                                <span class="notification_field__label">{{$t('対象')}}</span>
                                <span>{{$t(notificationFlg)}}</span>
                            </div>
                            <div class="notification_field">
                                <span class="notification_field__label">{{$t('間隔')}}</span>
                                <span>{{$t(notificationInterval)}}</span>
                            </div>
                        </template>

                        <template v-else-if="item == 'role'">{{$t(getRoleType(original_account_data[item]))}}</template>

                        <template v-else-if="item == 'is_active_user'">{{original_account_data[item] ? $t('有効') : $t('無効')}}</template>

                        <template v-else-if="item == 'is_delivery'">{{original_account_data[item] ? $t('有効') : $t('無効')}}</template>

                    </template>
                </data-list>
            </template>
            <page-back :text="$t('ユーザー一覧に戻る')" :to="'/accounts'" />
            <div class="manual">
                <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
            </div>            
        </template>
        <template v-if="$store.state.isModal" #modal>
            <accounts-delete v-if="$store.state.modalTarget == 'accounts-delete'" />
        </template>
    </layout-main>
</template>

<script>
    import Const from '@/static/constants'
    import DateMixin from '@/mixin/date'
    import CommonMixin from '@/mixin/common'
    import { mapGetters } from 'vuex';

    import layoutMain from '@/layouts/main'
    import gxButton from '@/components/atoms/button'
    import gxIcon from '@/components/atoms/icon'
    import gxIconText from '@/components/atoms/icon-text'
    import loading from '@/components/atoms/loading'
    import pageBack from '@/components/molecules/page-back'
    import dataList from '@/components/molecules/data-list'
    import notice from '@/components/molecules/notice'
    import accountsDelete from '@/components/organisms/accounts/delete'
    import accountsForm from '@/components/organisms/accounts/form'
    import AccountService from '@/services/accountService'
    import ManualService from '@/services/manualService'

    import i18n from '@/i18n'

    export default {
        name: 'accoutns-modify',
        mixins: [DateMixin, CommonMixin],
        components: {
            layoutMain,
            gxButton,
            gxIcon,
            gxIconText,
            loading,
            pageBack,
            dataList,
            notice,
            accountsDelete,
            accountsForm
        },
        data() {
            return {
                isEdit: false,
                isLoadError: false,
                isEditable: false,
                isDeletable: false,
                original_account_data: {
                    username: '',
                    email: '',
                    telephone: '',
                    password: '',
                    old_password: '',
                    confirm_password: '',
                    expire_date: '',
                    tz: "0",
                    role: Const.accounts.roleNormal,
                    notification_flg: "0",
                    notification_interval: Const.accounts.notificationIntervalDefault,
                    is_delivery: true,
                    is_active_user: true,
                },
                isLoading: true,
                terms: [
                    'username',
                    'email',
                    'telephone',
                    'password',
                    'tz',
                    'notification',
                    'is_delivery',
                    'role',
                    'is_active_user',
                ],
                formErrorMsg: {}
            }
        },
        computed: {
            ...mapGetters([
                'hasGroupAdminRole',
                'isManageableRole',
            ]),
            navs() {
                return [
                    {
                        to: '/accounts',
                        label: i18n.t('ユーザー設定')
                    }, {
                        to: '/accounts/modify',
                        label: i18n.t('ユーザー詳細')
                    }
                ]
            },
            notificationFlg() {
                const key = this.original_account_data.notification_flg
                return key ? Const.accounts.notificationFlgTypes[key] : '-'
            },
            notificationInterval() {
                const key = this.original_account_data.notification_interval
                return key ? Const.accounts.notificationIntervalTypes[key] : '-'
            },
            tzList() {
                return Const.accounts.timeZoneList
            }
        },
        methods: {
            async fetch() {
                this.isLoadError = false
                await AccountService.fetchDetail(this.$route.params.uuid).then(res => {
                    this.isEditable = res.is_editable
                    this.isDeletable = res.is_deletable
                    Object.keys(this.original_account_data).forEach(key => {
                        if (['password', 'old_password', 'confirm_password'].indexOf(key) === -1) {
                            this.$set(this.original_account_data, key, res[key])
                        }
                    })
                }).catch(err => {
                    this.isLoadError = true
                })
                this.isLoading = false
            },
            getTerm(value) {
                return Const.accounts.modifyTermsType[value]
            },
            updateValue(key, val) {
                this.$set(this.edit_account_data, key, val)
            },
            getRoleType(value) {
                return Const.accounts.roleTerms[value]
            },
            isExpired(date) {
                if (!date) return false
                const today = this.$moment.utc()
                const expiration_date = this.$moment.utc(date)
                return today >= expiration_date
            },
            startEdit() {
                this.edit_account_data = Object.assign({}, this.original_account_data)
                if (!this.edit_account_data.notification_interval) {
                    this.edit_account_data.notification_interval = Const.accounts.notificationIntervalDefault
                }
                this.formErrorMsg = {}

                this.isEdit = true
            },
            cancel() {
                this.isEdit = false
            },
            async save(input_data) {
                this.$store.dispatch('displayLoading')
                const errText = this.getErrorText(input_data)
                if (errText) {
                    alert(errText)
                    this.$store.dispatch('hideLoading')
                    return
                }

                const data = Object.assign({}, input_data)
                if (!data.password) {
                    delete data.password
                }
                if (!data.old_password) {
                    delete data.old_password
                }
                if (data.notification_flg == 0) {
                    data.notification_interval = null
                }
                await AccountService.update(this.$route.params.uuid, data).then(res => {
                    this.$store.dispatch('openToast', {type: 'success', label: i18n.t('更新しました')})
                    this.original_account_data = Object.assign({}, this.original_account_data, res)
                    if(res.id === this.$store.state.userAccount.id) {
                        this.$store.dispatch('mergeUserAccount', res)
                    }
                    this.isEdit = false
                    this.setDefaultPassword(input_data)
                }).catch(err => {
                    this.formErrorMsg = err.response.data
                    const message = err.response && err.response.data && err.response.data.length ? err.response.data.join('\n') : '更新に失敗しました'
                    this.$store.dispatch('openToast', {type: 'error', label: message})
                })
                this.$store.dispatch('hideLoading')
            },
            getErrorText(input_data) {
                if (input_data.password !== input_data.confirm_password) return '確認用パスワードが一致していません'
            },
            setDefaultPassword(input_data) {
                Object.keys(input_data).forEach(key => {
                    if (['password', 'old_password', 'confirm_password'].indexOf(key) !== -1) {
                        this.$set(input_data, key, '')
                    }
                })
            },
            deleteAccount() {
                this.$store.dispatch('openModalWithParam', {target: 'accounts-delete', param: this.$route.params.uuid})
            },
            manual(){
  		        if(this.isEdit){
                            return '/manual/wiki/User_edit_' + i18n.locale
  		                }else{
                            return '/manual/wiki/User_detail_' + i18n.locale
                }
            },          
        },
        created() {
            this.fetch()
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';

    .notification_field {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
        &__label {
            font-weight: bold;
            margin-right: 12px;
        }
    }
</style>
