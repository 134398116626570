// @TODO:多言語化対応

const convObjToArr = (obj) => {
    const res = [];
    for (let key in obj) {
      res.push(obj[key]);
    }
    return res;
  }


export const is_auto = Object.freeze({
  true: '自動',
  false: 'アップロード',
})
// 種別
export const type = Object.freeze({
  EMPTY: {
    value: null,
    str: '',
    color: 'SUCCESS',
    class: 'middle',
  },
  AUTO: {
    value: true,
    str: '自動',
    color: 'SUCCESS',
    class: 'middle',
  },
  UPLOAD: {
    value: false,
    str: 'アップロード',
    color: 'SUCCESS',
    class: 'middle',
  },
})
export const typeArray = convObjToArr(type);

// ステータス
export const status = Object.freeze({
  PROCESS: {
    value: 0,
    str: '処理中',
    color: 'SUCCESS',
    class: 'middle',
  },
  USEBLE: {
    value: 1,
    str: '利用可能',
    color: 'SUCCESS',
    class: 'middle',
  },
  ERROR: {
    value: 3,
    str: 'エラー',
    color: 'ERROR',
    class: 'middle',
  },
})
export const statusArray = convObjToArr(status);

export const uploadTerms = {
  non_field_errors: "エラー",
  comment: "コメント",
  ssl_key: "秘密鍵",
  ssl_cert: "証明書",
}

export const autoTerms = {
  non_field_errors: "エラー",
  comment: "コメント",
  hostname: "ホスト名",
  allow_naked_domain_flg: "www",
}

export const detailTermsType = Object.freeze({
  SSL:                 'SSL証明書',
  serial_number:       'シリアル番号',
  not_before:          '有効期限（開始）',
  not_after:           '有効期限（終了）',
  issuer:              '発行者',
  common_name:         '所有者',
  ssl_cert:            'certファイル',
})