export const colors = Object.freeze({
    BRAND: '#eb5b25',  // @TODO: IMAGE_COLOR_CHANGE
    PRIMARY: '#c63800',  // @TODO: IMAGE_COLOR_CHANGE
    SECONDARY: '#7948d5',
    SUCCESS: '#3d9721',  // @TODO: IMAGE_COLOR_CHANGE?
    WARNING: '#f9c52f',
    ERROR: '#c6352f',
    INFO: '#2f68f9',
    ICON: '#aaa',
    WHITE: '#fff',
    PASS: '#3d9721',  // @TODO: IMAGE_COLOR_CHANGE?
    BLOCK: '#c6352f',
    GRAY3: '#444',
    HIGH: '#aa4141',
    MIDDLE: '#c2a543',
    LOW: '#436fc2'
})

export const result = Object.freeze({
    SUCESS: 0,
    ERROR: 1
})
export const resultType = Object.freeze({
    0: 'success',
    1: 'error'
})

export const pageSizeType = Object.freeze({
    20: '20件',
    50: '50件',
    100: '100件',
    1000: '1000件'
})

export const emptySelect = Object.freeze([{value: '', str: '------------'}])

export const paths = Object.freeze({
    WEBSITE: 'Website',
    WEBSITE_WAFTRUST: 'WebsiteWafTrust',
    WEBSITE_ACCESS_CTRL: 'WebsiteAccessControl',
    WEBSITE_WAFCUS: 'WebsiteWafCustom',
    WEBSITE_WAFCUS_DETAIL: 'WebsiteWafCustomDetail',
    WEBSITE_WAFCUS_REGISTER: 'WebsiteWafCustomRegister',
})
