<template>
    <span :class="['label', type]">{{text}}</span>
</template>

<script>
    export default {
        name: 'gx-label',
        props: {
            text: {
                type: String,
                required: true,
                default: ''
            },
            type: {
                type: String,
                validator: (value) => {
                    return ['primary', 'secondary', 'high', 'middle', 'low', 'success', 'error'].indexOf(value) !== -1
                },
                default: 'primary'
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .label {
        color: $color-white;
        font-weight: bold;
        display: inline-block;
        height: 24px;
        line-height: 24px;
        min-width: 40px;
        padding: 0 8px;
        border-radius: 12px;
        text-align: center;

        &.primary {
            background-color: $color-primary;
        }
        &.secondary {
            background-color: $color-secondary;
        }
        &.high {
            background-color: $color-high;
        }
        &.middle {
            background-color: $color-middle;
        }
        &.low {
            background-color: $color-low;
        }
        &.success {
            background-color: $color-success;
        }
        &.error {
            background-color: $color-error;
        }
    }
</style>