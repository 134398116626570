<template>
    <layout-main
        :title="'利用規約'"
        :navs="navs"
        :type="'terms'"
    >
        <term-content />
    </layout-main>
</template>

<script>
    import layoutMain from '@/layouts/main'
    import termContent from '@/components/organisms/terms/content'

    import i18n from '@/i18n'

    export default {
        name: 'terms',
        components: {
            layoutMain,
            termContent
        },
        computed: {
            navs() {
                return [
                    {
                        to: '/terms',
                        label: i18n.t('利用規約')
                    }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>