<template>
    <layout-main
        :title="$t('ウェブサイト')"
        :navs="navs"
    >
        <template #page-header>
            <gx-button
                v-if="userAccount.is_website_creatable && ( !userAccount.is_support || userAccount.role == 80)"
                :tag="'router-link'"
                :text="$t('新規追加')"
                to="/website/register"
            />
        </template>
        <template #default>
            <!-- <website-list-search /> -->

            <template v-if="isLoading"><loading /></template>
            <notice v-else-if="isLoadError" :type="'error'" :label="$t('通信に失敗しました')" />
            <template v-else>
                <div class="table-wrap">
                    <website-list-table :websitesList="data.list" :sort="sort" @updateOrder="updateOrder" />
                </div>
                <pager :pager="data.pager" @changePage="changePage" @changePageSize="changePageSize" />
            </template>
            <div class="manual">
                <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
            </div>               
        </template>

        <!-- モーダル -->
        <template v-if="$store.state.isModal" #modal>
            <website-delete-modal v-if="$store.state.modalTarget == 'website-delete'" @reload="reload" />
        </template>
    </layout-main>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'

    import layoutMain from '@/layouts/main'
    import gxFormSelect from '@/components/atoms/form/select'
    import gxButton from '@/components/atoms/button'
    import gxIconText from '@/components/atoms/icon-text'
    import loading from '@/components/atoms/loading'
    import pager from '@/components/molecules/pager'
    import notice from '@/components/molecules/notice'
    // import websiteListSearch from '@/components/organisms/website/list-search'
    import websiteListTable from '@/components/organisms/website/list-table'
    import websiteDeleteModal from '@/components/organisms/website/basic-delete-modal'

    import WebsiteService from '@/services/websiteService'
    import accountService from '@/services/accountService'

    import i18n from '@/i18n'

    export default {
        name: 'website',
        components: {
            layoutMain,
            gxFormSelect,
            gxButton,
            gxIconText,
            loading,
            pager,
            notice,
            // websiteListSearch,
            websiteListTable,
            websiteDeleteModal,
        },
        data() {
            return {
                // For change group (support user only)
                selectedGroupId: undefined,
                groupList: [],

                data: {
                    list: [],
                    pager: {},
                },
                requestParams: {
                    page: 1,
                    page_size: 20
                },
                isLoading: true,
                isLoadError: false,
                sort: {
                    field: '',
                    order: ''
                }
            }
        },
        computed: {
            ...mapGetters([
                'userAccount'
            ]),
            navs() {
                return [
                    {
                        to: '/website',
                        label: i18n.t('ウェブサイト')
                    }
                ]
            },
            buttons() {
                return [{
                    text: i18n.t('有効化'),
                    size: 'large'
                }]
            },
        },
        methods: {
            ...mapActions([
                'fetchUserAccount'
            ]),
            reload() {
                this.fetchUserAccount()
                this.fetch()
            },
            async fetch() {
                this.isLoading = true
                this.isLoadError = false

                if(this.sort) {
                    let orderParam = this.sort.field
                    if(this.sort.order == 'desc') orderParam = `-${orderParam}`
                    this.requestParams.o = orderParam
                }
                await WebsiteService.fetchList(this.requestParams).then(res => {
                    this.$set(this.data, 'list', res.results ? res.results : [])
                    this.$set(this.data, 'pager', res.page ? res.page : {})
                }).catch(err => {
                    this.isLoadError = true
                })

                // Get group list and update $data.groupList
                if (this.userAccount.is_support) {
                    const fetchedGroupList = await accountService.fetchGroups({
                        current: 0,
                        count: 1000,
                    })
                    this.$data.groupList.splice(0, this.$data.groupList.length)
                    for (let i in fetchedGroupList.results) {
                        let group = fetchedGroupList.results[i]
                        this.$data.groupList.push(group)
                    }
                    if (this.$data.groupList.length > 0) {
                        const idx = this.$data.groupList.findIndex((obj, idx, arr)=>{
                            return obj.id == this.userAccount.group_id
                        })
                        if (idx >= 0) {
                            this.$data.selectedGroupId = this.$data.groupList[idx].id
                        } else {
                            this.$data.selectedGroupId = this.$data.groupList[0].id
                        }
                    }
                } else {
                    this.$data.groupList.splice(0, this.$data.groupList.length)
                }
                this.isLoading = false
            },
            updateQuery(key, val) {
                this.$set(this.requestParams, key, val)
            },
            changePage(page) {
                this.updateQuery('page', page)
                this.fetch()
            },
            changePageSize(size) {
                this.updateQuery('page_size', size)
                this.updateQuery('page', 1)
                this.fetch()
            },
            updateOrder(field, order) {
                this.sort.field = field
                this.sort.order = order
                this.fetch()
            },
            manual(){
  		        return '/manual/wiki/Website_list_' + i18n.locale
  		    },          
        },
        created() {
            this.fetch()
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .table-wrap {
        position: relative;

        .action {
            padding: 40px;
            background-color: rgba($color-parts-accent, .8);
            position: sticky;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
</style>