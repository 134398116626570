<template>
    <component :is="tag" :class="['term', `term__${type}`, {'term--half': half}]"><slot /></component>
</template>

<script>
    export default {
        name: 'search-term',
        props: {
            type: {
                type: String,
                validator: (value) => {
                    return ['title', 'data'].indexOf(value) !== -1
                },
                default: 'title'
            },
            half: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            tag() {
                return this.type == 'title' ? 'dt' : 'dd'
            }
        }
    }
</script>

<style lang="scss" scoped>
    .term {

        &:not(.term--half) {
            &.term__title:nth-of-type(odd) {
                grid-column: 1;
            }
            &.term__data:nth-of-type(odd) {
                grid-column: 2;
            }
            &.term__title:nth-of-type(even) {
                grid-column: 4;
            }
            &.term__data:nth-of-type(even) {
                grid-column: 5;
            }
        }
        &__title {
            font-weight: bold;
        }
    }
</style>