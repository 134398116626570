<script>
    const DATE_FMT = 'YYYY/MM/DD'
    const DATETIME_FMT = DATE_FMT + ' HH:mm:ss'
    const TZ_FMT = DATETIME_FMT + 'Z'

    export default {
        name: 'DateMixin',
        methods: {
            convertToRequestTime(naiveTime) {
                // タイムゾーン情報がない時刻文字列(もしくはmoment.utc)をリクエストパラメータ用に変換する
                return this.$moment.utc(naiveTime).format(DATETIME_FMT) + this.timezoneOffset
            },
        },
        computed: {
            toLocalDate() {
                // タイムゾーン付き時刻文字列(もしくはmoment)をタムゾーン付きのmomentオブジェクトに変換する
                return timeStr => {
                    const tz = this.$store.state.userAccount.tz
                    const utc = !!timeStr ? this.$moment.utc(timeStr, TZ_FMT) : this.$moment.utc()
                    return utc.utcOffset(tz)
                }
            },
            formatLocalDateTime() {
                return time => {
                    if (!time) return '---'
                    return this.toLocalDate(time).format(DATETIME_FMT)
                }
            },
            formatLocalDate() {
                return time => {
                    if (!time) return '---'
                    return this.toLocalDate(time).format(DATE_FMT)
                }
            },
            formatLocalTimeZone() {
                return time => {
                    if (!time) return '---'
                    return this.toLocalDate(time).format(TZ_FMT)
                }
            },
            timezoneOffset() {
                const offset = this.$store.state.userAccount.tz
                const sign = offset >= 0 ? '+' : '-'
                const hour = ('00' + Math.abs(offset / 60 | 0)).slice(-2)
                const min = ('00' + Math.abs(offset % 60 | 0)).slice(-2)
                return `${sign}${hour}${min}`
            }
        }
    }
</script>
