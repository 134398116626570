<template>
    <modal @cancel="closeModal" @submit="submit" :size="'large'">
        <template #title>{{$t(modalTitle)}}</template>
        <div>
            <multi-notice type="error" :terms="Const.certificate.uploadTerms" :messages="errors" />
            <validation-observer v-slot="{ invalid }">
                <gx-section class="certificate">
                    <div>
                        <gx-form-file
                            :maxsize="1024"
                            :required="!!editData.ssl_cert || isSSL"
                            :label="$t('秘密鍵')"
                            :accept="''"
                            class="file"
                            @change="updateValue('ssl_key', $event)">
                        </gx-form-file>
                        <gx-form-file
                            :maxsize="1024"
                            :required="!!editData.ssl_key || isSSL"
                            :label="$t('SSL証明書')"
                            :accept="'.pem'"
                            class="file"
                            @change="updateValue('ssl_cert', $event)"
                            :note="$t('アップロード可能なファイル拡張子：.pem')" 
                        />
                    </div>
                    <div class="item">
                        <div class="label">{{$t('コメント')}}</div>
                        <div class="data">
                            <gx-form-text
                                :name="$t('コメント')"
                                :placeholder="$t('コメント')"
                                :maxlength="100"
                                v-model="editData.comment"
                            />
                        </div>
                    </div>
                </gx-section>
                <button-wrap :buttons="buttons(invalid)" class="button-wrap" @submit="submit" @cancel="closeModal" />
            </validation-observer>
        </div>
        <div class="manual">
            <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
        </div>
    </modal>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import layoutMain from '@/layouts/main'
    import modal from '@/components/molecules/modal'

    import CommonMixin from '@/mixin/common'
    import multiNotice from '@/components/molecules/multi-notice'
    import gxSection from '@/components/atoms/section'
    import gxFormText from '@/components/atoms/form/text'
    import gxFormFile from '@/components/atoms/form/file'
    import gxButton from '@/components/atoms/button'
    import gxIconText from '@/components/atoms/icon-text'
    import gxIcon from '@/components/atoms/icon'
    import buttonWrap from '@/components/molecules/button-wrap'

    import Const from '@/static/constants'
    import {
        certificateService,
        UploadCreateParam,
    } from '@/services/certificateService'

    import i18n from '@/i18n'

    export default {
        name: 'certificate_upload',
        components: {
            layoutMain,
            modal,
            multiNotice,
            gxSection,
            gxFormText,
            gxFormFile,
            gxButton,
            gxIconText,
            gxIcon,
            buttonWrap,
        },
        props: {
            propData: {
                type: Object,
                default: () => ({})
            },
        },
        mixins: [CommonMixin],
        data() {
            return {
                Const: Const,
                editData: new UploadCreateParam({}),
                errors: {},
                isLoading: true,
                isLoadError: false,
                certificate_id: ""
            }
        },
        computed: {
            ...mapGetters([
                'userAccount'
            ]),
            uuid() {
                return this.$store.state.modalParam.uuid
            },
            isEdit() {
                return this.$store.state.modalParam.isEdit
            },
            modalTitle() {
                if (this.$store.state.modalParam.detailType){
                    return i18n.t('アップロード証明書{0}', [i18n.t(Const.website.access_detailModalType[this.$store.state.modalParam.detailType])])
                }else{
                    return i18n.t('アップロード証明書{0}', [i18n.t(Const.website.access_detailModalType["new"])])
                }
            },
        },
        methods: {
            ...mapActions([
                'fetchUserAccount'
            ]),
            reload() {
                this.fetchUserAccount()
                this.fetch()
            },
            async fetch() {
                this.isLoading = true
                if(this.isEdit){
                    // 登録データを取得する
                    this.propData = await certificateService.getUpload(this.uuid)
                    this.editData.comment = this.propData.comment
                }
                this.isLoadError = false
                this.isLoading = false
            },
            updateValue(key, val) {
                this.$set(this.editData, key, val)
            },
            async submit() {
                this.$store.dispatch('displayLoading')
                this.errors = {}
                if(this.isEdit){
                    let params = {}
                    this.certificate_id = this.propData .certificate_id
                    // 更新
                    if(this.editData.ssl_key.length != 0){
                        params = {
                            comment: this.editData.comment,
                            ssl_key: this.editData.ssl_key,
                            ssl_cert: this.editData.ssl_cert,
                        }
                    }else{
                        params = {
                            comment: this.editData.comment,
                        }
                    }
                    await certificateService.UpdateUpload(this.uuid, params)
                    .then(() => {
                        this.$emit('reload')
                        this.$store.dispatch('openModalWithParam', {target: 'certificate-detail', param: {uuid: this.certificate_id, type: "edit"}})
                    }).catch(err => {
                        if (err.response.status == 400) {
                            this.errors = err.response.data
                        } else {
                            this.$store.dispatch('openToast', {type: 'error', label: i18n.t('保存に失敗しました')})
                        }
                    })
                }else{
                    // 新規登録
                    let result = {}
                    const params = {
                        comment: this.editData.comment ? this.editData.comment : null,
                        ssl_key: this.editData.ssl_key,
                        ssl_cert: this.editData.ssl_cert,
                    }

                    try {
                        result = await certificateService.registerUpload(params)
                        this.certificate_id = result.certificate_id
                        this.$emit('reload')
                        this.$store.dispatch('openModalWithParam', {target: 'certificate-detail', param: {uuid: this.certificate_id, type: "edit"}})
                    }catch(err) {
                        if (err.response.status == 400) {
                            this.errors = err.response.data
                        } else {
                            this.$store.dispatch('openToast', {type: 'error', label: i18n.t('保存に失敗しました')})
                        }
                    }
                    
                }
                this.$store.dispatch('hideLoading')
            },
            closeModal() {
                this.$store.dispatch('closeModal')
            },
            buttons(disabled) {
                let d = disabled
                if ((!this.$data.editData.ssl_cert && !this.$data.editData.ssl_key) && !this.isEdit) {
                    d = true
                }

                return [
                    {
                        text: i18n.t('OK'),
                        type: 'primary',
                        size: 'large',
                        disabled: d,
                        onClick: 'submit'
                    },
                    {
                        text: i18n.t('キャンセル'),
                        type: 'common',
                        size: 'large',
                        onClick: 'cancel'
                    },
                ]
            },
            manual(){
  		        return '/manual/wiki/Certificate_upload_' + i18n.locale
  		    },  
        },
        mounted() {
            if (this.$route.query.event_id) {
                this.$data.editData.waf_event = this.$route.query.event_id
            }
        },
        created() {
            this.fetch()
        },
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';

    .certificate {
        background-color: $color-parts-bg;
        border-radius: 15px;

        .item {
            padding: 1em 1em;
            display:flex;
            flex-flow: column;
            flex-direction: row;
            align-items: center;
            
            .label {
                font-weight: bold;
                width: 7em;
            }
            .data {
                flex: 1;
            }
        }
        .title {
            display: flex;
            .data {
                .wrap {
                    max-width: inherit;
                }
            }
        }
    }
    .file {
        margin-left: 16px;
        margin-right: 6px;
        margin-top: 1em;
        margin-bottom: 1em;
    }
</style>
