<template>
    <search-box :noPointer="isLoading" @search="search" @clear="clear">
        <template>
            <search-terms>
                <search-term :type="'title'">{{$t('種別')}}</search-term>
                <search-term :type="'data'">
                    <gx-form-select
                        :name="$t('種別')"
                        v-model="is_auto"
                        :options="getSelectorValues(Const.certificate.typeArray)"
                        value-attr="value"
                        str-attr="str"
                    />
                </search-term>

                <search-term :type="'title'">{{$t('コメント')}}</search-term>
                <search-term :type="'data'">
                    <gx-form-input :placeholder="$t('コメント')" v-model="comment" />
                </search-term>

                <search-term :type="'title'">{{$t('ホスト名')}}</search-term>
                <search-term :type="'data'">
                    <gx-form-input :placeholder="$t('ホスト名')" v-model="hostname" />
                </search-term>

                <search-term :type="'title'">{{$t('www無し')}}</search-term>
                <search-term :type="'data'">
                    <gx-form-checkbox
                        :checked="allow_naked_domain_true"
                        @check="allow_naked_domain_true = !allow_naked_domain_true"
                    >{{$t('含む')}}</gx-form-checkbox>
                    <gx-form-checkbox
                        class="checkbox"
                        :checked="allow_naked_domain_false"
                        @check="allow_naked_domain_false = !allow_naked_domain_false"
                    >{{$t('含まない')}}</gx-form-checkbox>
                </search-term>
        </search-terms>
        </template>
    </search-box>
</template>

<script>
    import Const from '@/static/constants'

    import gxIconText from '@/components/atoms/icon-text'
    import gxFormCheckbox from '@/components/atoms/form/checkbox'
    import gxFormInput from '@/components/atoms/form/input'
    import gxFormSelect from '@/components/atoms/form/select'
    import searchTerm from '@/components/atoms/search/term'
    import dateRange from '@/components/molecules/form/date-range'
    import searchBox from '@/components/molecules/search/box'
    import searchTerms from '@/components/molecules/search/terms'
    import termList from '@/components/molecules/form/term-list'

    import {
        certificateService,
        CertificateSearchParam
    } from '@/services/certificateService'

    import DateMixin from "@/mixin/date";

    import i18n from "@/i18n"

    export default {
        name: 'certificate-search',
        mixins: [DateMixin],
        components: {
            gxIconText,
            gxFormCheckbox,
            gxFormInput,
            gxFormSelect,
            searchTerm,
            dateRange,
            searchBox,
            searchTerms,
            termList
        },
        props: {
            params: {
                type: Object,
                default: () => Object.assign({}, this.defaultForm)
            },
            isLoading: {
                type: Boolean,
                default: false
            },
            userAccount: {},
        },
        data() {
            return {
                Const: Const,
                allow_naked_domain_true: false,
                allow_naked_domain_false: false
            }
        },
        computed: {
            defaultForm() {
                return new CertificateSearchParam({})
            },
            // 種別
            is_auto: {
                set(val){
                    this.$emit("updateQuery", 'is_auto', val)
                },
                get(){
                    return this.params.is_auto
                }
            },
            // コメント
            comment: {
                set(val){
                    this.$emit("updateQuery", 'comment', val)
                },
                get(){
                    return this.params.comment
                }
            },
            // ホスト名
            hostname: {
                set(val){
                    this.$emit("updateQuery", 'hostname', val)
                },
                get(){
                    return this.params.hostname
                }
            },
            // wwww
            allow_naked_domain_flg: {
                set(val){
                    this.$emit("updateQuery", 'allow_naked_domain_flg', val)
                },
                get(){
                    return this.params.allow_naked_domain_flg
                }
            },
            severities() {
                const severities = []
                Object.keys(Const.event.severity).forEach((index) => {
                    const severity = Const.event.severity[index]
                    severities.push({
                        str: Const.event.severityType[severity],
                        // icon: Const.event.severityType[severity], // @todo 良い感じのアイコン付ける？
                        value: severity,
                        color: Const.common.colors[Const.event.severityColor[severity]]
                    })
                })
                return i18n.translateOptionValues(severities)
            },
        },
        methods: {
            getSelectorValues(options) {
                return i18n.translateOptionValues(options)
            },
            updateCheckBox(key, val) {
                if(this[key]) {
                    if(this[key].includes(val)) {
                        const index = this[key].findIndex(v => v == val)
                        this[key].splice(index, 1)
                    } else {
                        this[key].push(val)
                    }
                }
            },
            search() {
                if(this.allow_naked_domain_true && !this.allow_naked_domain_false){
                    this.$emit("updateQuery", 'allow_naked_domain_flg', true)
                }else if(!this.allow_naked_domain_true && this.allow_naked_domain_false){
                    this.$emit("updateQuery", 'allow_naked_domain_flg', false)
                }else{
                    this.$emit("updateQuery", 'allow_naked_domain_flg', [])
                }
                
                this.$emit("updateQuery", 'page', '1')
                this.$emit("search")
            },
            clear() {
                const defaultForm = Object.assign({}, this.defaultForm)
                Object.keys(defaultForm).forEach(key => {
                    if(Array.isArray(defaultForm[key])) {
                        this.$emit("updateQuery", key, [])
                    } else {
                        this.$emit("updateQuery", key, defaultForm[key])
                    }
                })
                this.allow_naked_domain_true = false
                this.allow_naked_domain_false = false
            }
        }
    }
</script>

<style lang="scss" scoped>
.label_nosupport {
    margin-left: 1em;
}
.checkbox {
    margin-left: 8px;
}
</style>
