<template>
    <div class="search-box">
        <p class="toggle">
            <gx-icon-text
                class="toggle-button"
                :text="(isShow ? $t('{0}を閉じる', [$t(toggleLabel)]) : $t('{0}を開く', [$t(toggleLabel)]))"
                :icon="isShow ? 'arrow-down' : 'arrow-up'"
                @onClick="toggleBox"
            />
        </p>
        <div v-if="isShow" class="box">
            <form @submit.prevent="search">
                <slot />
                <button-wrap class="actions" :buttons="buttons" @clear="clear" />
            </form>
        </div>
    </div>
</template>

<script>
    import gxIconText from '@/components/atoms/icon-text'
    import buttonWrap from '@/components/molecules/button-wrap'
    import i18n from '@/i18n'

    export default {
        name: 'search-box',
        components: {
            gxIconText,
            buttonWrap
        },
        props: {
            noPointer: {
                type: Boolean,
                default: false
            },
            toggleLabel: {
                type: String,
                default: '検索条件'
            },
            clearLabel: {
                type: String,
                default: '条件をクリア'
            },
            submitLabel: {
                type: String,
                default: '検索'
            },
        },
        data() {
            return {
                isShow: false
            }
        },
        computed: {
            buttons() {
                return [{
                    text: i18n.t(this.submitLabel),
                    type: 'primary',
                    noPointer: this.noPointer ? 'no-pointer' : ''
                }, {
                    text: i18n.t(this.clearLabel),
                    type: 'common',
                    onClick: "clear"
                }]
            }
        },
        methods: {
            toggleBox() {
                this.isShow = !this.isShow
            },
            search() {
                this.$emit("search")
            },
            clear() {
                this.$emit("clear")
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';

    .search-box {
        margin-bottom: 56px;
    }
    .toggle {
        margin-bottom: 8px;
    }
    .toggle-button {
        cursor: pointer;
    }
    .box {
        background-color: $color-parts-bg;
        padding: 40px;
    }
    .actions {
        margin-top: 56px;
    }
</style>