<template>
    <common-list :items="terms" class="settings">
        <template #default={item}>
            <template v-if="isEdit">
                <span class="title">
                    <gx-form-checkbox @check="data[item] = $event.target.checked" :checked="data[item]">{{$t(getTerms(item))}}</gx-form-checkbox>
                </span>
                {{$t('パス')}}
                <gx-form-text
                    :disabled="!data[item]"
                    v-model="defaultPaths[`${item}_path`]"
                    :ref="`input-${defaultPaths[item]}`"
                    :maxlength="1000"                    
                    :placeholder="'^.'"/>
            </template>
            <template v-else>
                <span class="title">
                    <gx-icon-text v-if="data[item]" :text="$t(getTerms(item))" :icon="'check_circle_fill'" class="validity" />
                    <span v-else class="invalid">{{$t(getTerms(item))}}</span>
                </span>
                <span v-if="data[item]">{{$t(defaultPaths[`${item}_path`])}}</span>
                <span v-else class="invalidpath">{{$t(defaultPaths[`${item}_path`])}}</span>
            </template>
        </template>
    </common-list>
</template>

<script>
    import Const          from '@/static/constants'
    import gxFormCheckbox from '@/components/atoms/form/checkbox'
    import gxIconText     from '@/components/atoms/icon-text'
    import gxFormText     from '@/components/atoms/form/text'
    import commonList     from '@/components/molecules/common-list'
    import i18n           from '@/i18n'
    export default {
        name: 'website-waf-trusted-settings',
        components: {
            gxFormCheckbox,
            gxIconText,
            gxFormText,            
            commonList
        },
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            terms: {
                type: Array,
                required: true
            },
            data: {
                type: Object,
                required: true
            },
            defaultPaths: {
                type: Object,
                required: true
            }
        },
        methods: {
            getTerms(value) {
                return i18n.t(Const.event.CMSType.find(obj => obj.name === value).str)
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .settings {
        width: 100%;
        
        .validity {
            font-weight: bold;

            &::before {
                color: $color-success;
            }
        }
        .invalid {
            margin-left: 26px;
            opacity: $opacity-disabled;
        }
        .title {
            width: 200px;
        }
        .invalidpath {
            opacity: $opacity-disabled;
        }
    }
</style>