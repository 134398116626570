<template>
    <div :class="['quickstatus', setType(cnt,type)]">
        <p class="unit" style="text-align: right">{{unit}}</p>
        <p class="number" style="text-align: right"><strong>{{label}}</strong></p>
        <p v-if="this.$slots.default" class="text"><slot /></p>
    </div>
</template>

<script>
import { integer } from 'vee-validate/dist/rules';

    export default {
        name: 'quickstatus',
        props: {
            type: {
                type: String,
                validator: (value) => {
                    return ['event', 'support', 'certificate', 'website'].indexOf(value) !== -1
                },
                default: 'event'
            },
            label: {
                type: String,
                required: true
            },
            unit: {
                type: String,
                required: true
            },
            cnt: {
                type: Number,
                required: true   
            }
        },
        methods: {
            setType(cnt,type){
                if(cnt ==0){
                    return 'quickstatus--' + type + '_no'
                }else{
                    return 'quickstatus--' + type 
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .quickstatus {
        padding: 0px 24px 0px 80px;
        position: relative;
        display: flex;
        min-height: 130px;
        flex-direction: column;
        justify-content: center;

        &::after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 24px;
            margin: auto;
            font-size: 3.2rem;
            line-height: 1;
            width: 3.2rem;
            height: 3.2rem;
            text-align: center;
        }
        &--event {
            border: 2px solid;
            border-color: $color-success;
            background-color: rgba($color-success, .3);

            &::after {
                @include icon(check_circle_fill);
                color: $color-success;
            }
        }
        &--website {
            border: 2px solid;
            border-color: $color-error;
            background-color: rgba($color-error, .3);

            &::after {
                @include icon(exclamation_circle_fill);
                color: $color-error;
            }
        }
        &--certificate {
            border: 2px solid;
            border-color: $color-warning;
            background-color: rgba($color-warning, .3);

            &::after {
                @include icon(exclamation_tryangle_fill);
                color: $color-warning;
            }
        }
        &--support {
            border: 2px solid;
            border-color: $color-info;
            background-color: rgba($color-info, .3);

            &::after {
                @include icon(info_circle_fill);
                color: $color-info;
            }
        }
        &--event_no {
            background-color: rgba($color-success, .1);
        }
        &--website_no {
            background-color: rgba($color-error, .1);
        }
        &--certificate_no {
            background-color: rgba($color-warning, .1);
        }        
        &--support_no {
            background-color: rgba($color-info, .1);
        }
    }
    .label {
        font-size: 1.8rem;
    }
    .number {
        font-size: 1.5em;
    }
    .text {
        margin-top: 16px;
    }
    .unit {
        font-size: 1.2em;
    }
</style>