<template>
    <div>
        <gx-tag
            class="tag"
            :text="required ? '必須' : '任意'"
            :type="required ? 'error' : 'common'" />
        <span><slot /></span>
    </div>
</template>

<script>
import gxTag from '@/components/atoms/tag'

export default {
    components: {
        gxTag,
    },
    props: {
        required: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
.tag {
    margin-right: 8px;
}
</style>