//
// 日本語
//
// 重複は後の行が勝ちますが、未確認のブラウザでの挙動が不明瞭なため、基本的には避けるようにお願いします
// @see @/pages/event/detail.vue#methods.getAttackSrcCountry
// 国名については上記をご参照ください

export default Object.freeze({
  "処理中...": "処理中...",
  "マニュアル": "マニュアル",
  "ダッシュボード": "ダッシュボード",
  "ウェブサイトが設定されていません": "ウェブサイトが設定されていません",
  "保護するウェブサイトを設定してください": "保護するウェブサイトを設定してください",
  "データが取得出来ませんでした": "データが取得出来ませんでした",
  "{0}の攻撃はありません": "{0}の攻撃はありません",
  "クイックステータス": "クイックステータス",
  "直近24時間攻撃": "直近24時間攻撃",
  "イベント一覧へ": "イベント一覧へ",
  "未クローズチケット": "未クローズチケット",
  "サポートチケット一覧へ": "サポートチケット一覧へ",
  "ウェブサイト更新失敗件数": "ウェブサイト更新失敗件数",
  "ウェブサイト一覧へ": "ウェブサイト一覧へ",
  "有効期限が近い証明書件数": "有効期限が近い証明書件数",
  "証明書一覧へ": "証明書一覧へ",
  "期間": "期間",
  "月次レポート": "月次レポート",
  "レポートダウンロード": "レポートダウンロード",
  "CPU使用率": "CPU使用率",
  "転送量": "転送量", 
  "トライアル": "トライアル", 
  "契約プラン": "契約プラン", 
  "使用量": "使用量",
  "{0}GB": "{0}GB", 
  "使用量": "使用量", 
  "超過量": "超過量", 
  "残り": "残り", 
  "超過量": "超過量", 
  "パフォーマンスモニター": "パフォーマンスモニター", 
  "脅威度の割合": "脅威度の割合",
  "WAF導入時設定情報": "WAF導入時設定情報",
  "DNSのCNAME設定用ドメイン名": "DNSのCNAME設定用ドメイン名",
  "DNSのCNAME設定用ドメイン名のIPアドレス": "DNSのCNAME設定用ドメイン名のIPアドレス",
  "実サーバーで通信を許可するIPアドレス": "実サーバーで通信を許可するIPアドレス",
  "時間別攻撃件数": "時間別攻撃件数",
  "攻撃元国別 TOP5": "攻撃元国別 TOP5",
  "攻撃元IP TOP5": "攻撃元IP TOP5",
  "タイムライン": "タイムライン",
  "攻撃元マップ": "攻撃元マップ",
  "3D": "3D",
  "2D": "2D",
  "お知らせ": "お知らせ",
  "一覧へ": "一覧へ",
  "お知らせはありません": "お知らせはありません",
  "ウェブサイト新規設定": "ウェブサイト新規設定",
  "転送量取得に失敗しました": "転送量取得に失敗しました",

  "国名": "国名",
  "件数": "件数",

  "IP": "IP",

  "脅威度": "脅威度",
  "総イベント件数": "総イベント件数",
  "{0}件": "{0}件",
  "高": "高",  // @see static/constants/event.js severityType
  "中": "中",  // @see static/constants/event.js severityType
  "低": "低",  // @see static/constants/event.js severityType

  "イベント詳細": "イベント詳細",
  "概要": "概要",
  "タイムスタンプ": "タイムスタンプ",  // @see static/constants/event.js#detailTermsType
  "脅威度": "脅威度",  // @see static/constants/event.js#detailTermsType
  "攻撃元": "攻撃元",
  "検出": "検出", 
  "攻撃元国名": "攻撃元国名",  // @see static/constants/event.js#detailTermsType
  "攻撃元IP": "攻撃元IP",  // @see static/constants/event.js#detailTermsType
  "名前": "名前",  // @see static/constants/event.js#detailTermsType
  "ホスト": "ホスト",  // @see static/constants/event.js#detailTermsType
  "検出情報": "検出情報",  // @see static/constants/event.js#detailTermsType
  "検出箇所": "検出箇所",  // @see static/constants/event.js#detailTermsType
  "検出文字列": "検出文字列",  // @see static/constants/event.js#detailTermsType
  "検出文字列（全体）": "検出文字列（全体）",  // @see static/constants/event.js#detailTermsType
  "メソッド": "メソッド",  // @see static/constants/event.js#detailTermsType
  "名前": "名前",  // @see static/constants/event.js#detailTermsType
  "ホスト": "ホスト",  // @see static/constants/event.js#detailTermsType
  "パス": "パス",  // @see static/constants/event.js#detailTermsType
  "種類": "種類",  // @see static/constants/event.js#detailTermsType
  "動作": "動作",  // @see static/constants/event.js#detailTermsType
  "条件指定": "条件指定",
  "除外対象": "除外対象",
  "除外対象詳細指定": "除外対象詳細指定",
  "検知コード": "検知コード",  // @see static/constants/event.js#detailTermsType
  "high": "high",  // @see static/constants/event.js#severityClass
  "middle": "middle",  // @see static/constants/event.js#severityClass
  "low": "low",  // @see static/constants/event.js#severityClass
  "EC-CUBE": "EC-CUBE",  // @see static/constants/event.js#CMSType
  "Wordpress": "Wordpress",  // @see static/constants/event.js#CMSType
  "Movable Type": "Movable Type",  // @see static/constants/event.js#CMSType
  "パス": "パス",  // @see static/constants/event.js#CMSType
  "ブロック": "ブロック",  // @see static/constants/event.js#actionType
  "リダイレクト": "リダイレクト",  // @see static/constants/event.js#actionType
  "検出のみ": "検出のみ",  // @see static/constants/event.js#actionType
  "リクエスト": "リクエスト",
  "ヘッダー": "ヘッダー",
  "ボディ": "ボディ",
  "レスポンス": "レスポンス",
  "イベント一覧に戻る": "イベント一覧に戻る",
  "イベント": "イベント",
  "CSVダウンロード": "CSVダウンロード",
  "パス名": "パス名",
  "URLパス": "URLパス",
  "アクセス元IP": "アクセス元IP",
  "検索条件": "検索条件",
  "条件をクリア": "条件をクリア",
  "ファイルを選択する": "ファイルを選択する",
  "検索": "検索",
  "詳細": "詳細",
  "{0}と{1}を表示": "{0}と{1}を表示",
  "{0}を開く": "{0}を開く",
  "{0}を閉じる": "{0}を閉じる",
  "{0}件中 {1}〜{2}件表示": "{0}件中 {1}〜{2}件表示",
  "1ページに": "1ページに",
  "表示": "表示",
  "------------": "------------",
  "20件": "20件",  // @see static/constants/common.js#pageSizeType
  "50件": "50件",  // @see static/constants/common.js#pageSizeType
  "100件": "100件",  // @see static/constants/common.js#pageSizeType
  "1000件": "1000件",  // @see static/constants/common.js#pageSizeType

  "ログイン用のマジックリンクを送信しました。…": "ログイン用のマジックリンクを送信しました。<br>メールに記載されているURLからログインしてください。",
  "メールが届かない場合": "メールが届かない場合",
  "ログイン用のマジックリンクを送信します。": "ログイン用のマジックリンクを送信します。",
  "グループID": "グループID",
  "ユーザーID": "ユーザーID",
  "パスワード": "パスワード",
  "送信": "送信",

  "一覧に戻る": "一覧に戻る",  // @TODO "〇〇一覧に戻る"とどっちに合わせるか

  "新規追加": "新規追加",
  "通信に失敗しました": "通信に失敗しました",
  "ユーザー詳細": "ユーザー詳細",
  "編集": "編集",
  "削除": "削除",
  "対象": "対象",
  "間隔": "間隔",
  "有効": "有効",
  "無効": "無効",
  "不明": "不明",  
  "ユーザー一覧に戻る": "ユーザー一覧に戻る",
  "更新しました": "更新しました",  // @TODO: "Done / Failed", "成功しました / 失敗しました" に一本化できそう
  "更新失敗": "更新失敗",
  "ユーザー新規追加": "ユーザー新規追加",
  "登録しました": "登録しました",  // @TODO: "Done / Failed", "成功しました / 失敗しました" に一本化できそう
  "登録に失敗しました": "登録に失敗しました",  // @TODO: "Done / Failed", "成功しました / 失敗しました" に一本化できそう
  "ユーザーを削除します": "ユーザーを削除します",
  "この操作は取り消せません": "この操作は取り消せません",
  "キャンセル": "キャンセル",
  "削除": "削除",
  "削除しました": "削除しました",  // @TODO: "Done / Failed", "成功しました / 失敗しました" に一本化できそう
  "削除に失敗しました": "削除に失敗しました",  // @TODO: "Done / Failed", "成功しました / 失敗しました" に一本化できそう
  "入力内容に不備があります": "入力内容に不備があります",
  "半角英数字/@/./+/-/_のみ使用可能 150文字以内": "半角英数字/@/./+/-/_のみ使用可能 150文字以内",  // username
  "address@test.com": "address@test.com",  // e-mail
  "00000000000": "00000000000",  // telephone
  "任意設定": "任意設定",
  "任意": "任意",
  "現在のパスワード": "現在のパスワード",
  "新しいパスワード": "新しいパスワード",
  "パスワード規約": "半角英数と.!_/`()[]+-=$#&@~が使用可能 8文字以上 128文字以内/英大文字、英小文字、数字をそれぞれ１文字以上含めてください/同一文字を３文字以上連続使用不可",
  "********": "********",
  "確認用パスワード": "確認用パスワード",
  "保存": "保存",
  "有効期限": "有効期限",
  "メールアドレス": "メールアドレス",  // @see static/constants/accounts.js#registerTermsType
  "電話番号": "電話番号",  // @see static/constants/accounts.js#registerTermsType
  "タイムゾーン": "タイムゾーン",  // @see static/constants/accounts.js#registerTermsType
  "通知": "通知",  // @see static/constants/accounts.js#registerTermsType
  "権限": "権限",  // @see static/constants/accounts.js#registerTermsType
  "状態": "状態",  // @see static/constants/accounts.js#registerTermsType
  "お知らせ配信": "お知らせ配信",  // @see static/constants/accounts.js#registerTermsType
  "グループ管理者": "グループ管理者",  // @see static/constants/accounts.js#roleTerms
  "管理者": "管理者",  // @see static/constants/accounts.js#roleTerms
  "一般ユーザー": "一般ユーザー",  // @see static/constants/accounts.js#roleTerms
  "すべてのイベント": "すべてのイベント",  // @see static/constants/accounts.js#notificationFlgTypes
  "重要度中以上のイベント": "重要度中以上のイベント",  // @see static/constants/accounts.js#notificationFlgTypes
  "重要度高のイベント": "重要度高のイベント",  // @see static/constants/accounts.js#notificationFlgTypes
  "オフ": "オフ",  // @see static/constants/accounts.js#notificationFlgTypes
  "5分": "5分",  // @see static/constants/accounts.js#notificationIntervalTypes
  "10分": "10分",  // @see static/constants/accounts.js#notificationIntervalTypes
  "30分": "30分",  // @see static/constants/accounts.js#notificationIntervalTypes
  "1時間": "1時間",  // @see static/constants/accounts.js#notificationIntervalTypes
  "3時間": "3時間",  // @see static/constants/accounts.js#notificationIntervalTypes
  "6時間": "6時間",  // @see static/constants/accounts.js#notificationIntervalTypes
  "12時間": "12時間",  // @see static/constants/accounts.js#notificationIntervalTypes
  "24時間": "24時間",  // @see static/constants/accounts.js#notificationIntervalTypes

  "グループアクセス制御": "グループアクセス制御",

  "ウェブサイト": "ウェブサイト",
  "グループ変更": "グループ変更",
  "グループ番号": "グループ番号",
  "グループ名": "グループ名",
  "選択": "選択",
  "ユーザー": "ユーザー",
  "有効化": "有効化",
  "カスタム・シグネチャ一覧に戻る": "カスタム・シグネチャ一覧に戻る",
  "ウェブサイト一覧に戻る": "ウェブサイト一覧に戻る",
  "基本設定": "基本設定",
  "アクセス制御": "アクセス制御",
  "カスタム・シグネチャ": "カスタム・シグネチャ",
  "WAF - ベース": "WAF - ベース",
  "ウェブサイト詳細": "ウェブサイト詳細",
  "ウェブサイト新規追加": "ウェブサイト新規追加",
  "サーバー情報": "サーバー情報",
  "保存しました": "保存しました",  // @TODO: "Done / Failed", "成功しました / 失敗しました" に一本化できそう
  "保存に失敗しました": "保存に失敗しました",  // @TODO: "Done / Failed", "成功しました / 失敗しました" に一本化できそう
  "保護:": "保護：",
  "{0}以外": "{0}以外",
  "動的のみ": "動的のみ",
  "正規表現": "正規表現",
  "{0}秒間に{1}回以上のリクエストでブロックする": "{0}秒間に{1}回以上のリクエストでブロックする",
  "{0}秒": "{0}秒",
  "IPアドレスで指定": "IPアドレスで指定",
  "国名で指定": "国名で指定",
  "Botで指定": "Botで指定",
  "以外を対象にする": "以外を対象にする",
  "完全一致": "完全一致",
  "動的ページのみを対象にする": "動的ページのみを対象にする",
  "正規表現を使用する": "正規表現を使用する",
  "拒否": "拒否",
  "許可": "許可",
  "安全": "安全",
  "秒数": "秒数",
  "秒間に": "秒間に",
  "回数": "回数",
  "回以上の": "回以上の",
  "リクエストでブロックする": "リクエストでブロックする",
  "ブロック時間": "ブロック時間",
  "半角整数：{0}〜{1}": "半角整数：{0}〜{1}",  // block_time
  "半角英数スペースおよび!()+,-./:;_が使用可能／部分一致／大文字と小文字を区別しない": "半角英数スペースおよび!()+,-./:;_が使用可能／部分一致／大文字と小文字を区別しない",
  "フィルタリングルール{0}": "フィルタリングルール{0}",
  "参照元": "参照元",  // @see  static/constants/website.js#access_detailTermsType
  "対象URL": "対象URL",  // @see  static/constants/website.js#access_detailTermsType
  "許可/拒否": "許可/拒否",  // @see  static/constants/website.js#access_detailTermsType
  "アクション": "アクション",  // @see  static/constants/website.js#access_detailTermsType
  "リクエストしきい値": "リクエストしきい値",  // @see  static/constants/website.js#access_detailTermsType
  "ブロック時間": "ブロック時間",  // @see  static/constants/website.js#access_detailTermsType
  "HTTPリクエストヘッダ内の": "HTTPリクエストヘッダ内の",  // @see  static/constants/website.js#access_detailTermsType
  "User-Agentフィールド": "User-Agentフィールド",  // @see  static/constants/website.js#access_detailTermsType
  "秒": "秒",
  "全て": "全て",
  "sg-admin": "sg-admin",
  "ブロック": "ブロック",
  "リダイレクト": "リダイレクト",
  "検出のみ": "検出のみ",
  "オン": "オン",
  "オフ": "オフ",
  "パターンマッチテスト": "パターンマッチテスト",
  "クリア": "クリア",
  "テスト": "テスト",
  "参照元": "参照元",
  "以外": "以外",  
  "CIDR記法で範囲指定ができます／30件まで同時指定可能／複数指定をする際は、指定ごとに改行してください": "CIDR記法で範囲指定ができます／30件まで同時指定可能／複数指定をする際は、指定ごとに改行してください",
  "並べ替え": "並べ替え",
  "テスト結果": "テスト結果",
  "国1": "国1",
  "国2": "国2",
  "国3": "国3",
  "国4": "国4",
  "国5": "国5",
  "国6": "国6",
  "国7": "国7",
  "該当フィルター": "該当フィルター",  // @see static/constants/website.js#access_filterTestTermsType
  "更新中": "更新中",  // @see static/constants/website.js#stateType
  "ドロップ": "ドロップ",  // @see static/constants/website.js#resBlockActionType
  "サーバーエラー（500）": "サーバーエラー（500）",  // @see static/constants/website.js#reqBlockActionType
  "アクセス拒否（403）": "アクセス拒否（403）",  // @see static/constants/website.js#reqBlockActionType
  "リダイレクト（302）": "リダイレクト（302）",  // @see static/constants/website.js#reqBlockActionType
  "更新ステータス": "更新ステータス",  // @see static/constants/website.js#basicTermsType
  "応答HTMLファイル": "応答HTMLファイル",  // @see static/constants/website.js#basicTermsType
  "アクション": "アクション",  // @see static/constants/website.js#basicTermsType
  "状態": "状態",  // @see static/constants/website.js#basicTermsType
  "保護": "保護",  // @see static/constants/website.js#basicTermsType
  "実サーバー": "実サーバー",  // @see static/constants/website.js#basicTermsType
  "5件まで同時指定可能／複数指定をする際は、指定ごとに改行してください": "5件まで同時指定可能／複数指定をする際は、指定ごとに改行してください",
  "実プロトコル": "実プロトコル",  // @see static/constants/website.js#basicTermsType
  "ホスト名": "ホスト名",  // @see static/constants/website.js#basicTermsType
  "プロトコル": "プロトコル",  // @see static/constants/website.js#basicTermsType
  "高度な設定": "高度な設定",  // @see static/constants/website.js#basicTermsType
  "クライアントIPアドレスをX-Forwarded-Forから取得": "クライアントIPアドレスをX-Forwarded-Forから取得",  // @see static/constants/website.js#basicTermsType
  "※本項目を編集したい場合はサポートにお問い合わせください": "※本項目を編集したい場合はサポートにお問い合わせください",  // @see static/constants/website.js#basicTermsType
  "クライアントIPアドレスの読み込み順": "クライアントIPアドレスの読み込み順",
  "左から読み込む": "左から読み込む",
  "右から読み込む": "右から読み込む",
  "フィルタリングルール一覧": "フィルタリングルール一覧",
  "フィルタリングルールが設定されていません": "フィルタリングルールが設定されていません",
  "適用するフィルタリングルールを設定してください": "適用するフィルタリングルールを設定してください",
  "追加": "追加",
  "優先度": "優先度",
  "フィルタリング新規設定": "フィルタリング新規設定",
  "ウェブサイトを削除します": "ウェブサイトを削除します",
  "フィルタリングルールを削除します": "フィルタリングルールを削除します",
  "この操作は取り消せません": "この操作は取り消せません",
  "メッセージを確認の上もう一度入力してください": "メッセージを確認の上もう一度入力してください",
  "HTTPSはファイル選択が必須です": "HTTPSはファイル選択が必須です",
  "アップロード可能なファイル拡張子：.pem": "アップロード可能なファイル拡張子：.pem",
  "HTTPをHTTPSにリダイレクトする": "HTTPをHTTPSにリダイレクトする",
  "SSL証明書の期限：{0}まで": "SSL証明書の期限：{0}まで",
  "SSL証明書未アップロード": "SSL証明書未アップロード", 
  "wwwなしのドメインにも対応する": "wwwなしのドメインにも対応する",
  "Hostヘッダを指定する": "Hostヘッダを指定する",
  "クライアントIPアドレスに基づいて実サーバーに振り分ける": "クライアントIPアドレスに基づいて実サーバーに振り分ける",
  "レスポンスタイムアウト値": "レスポンスタイムアウト値",
	"サーバー名を省略した場合、サーバー名にはホスト名が設定されます": "サーバー名を省略した場合、サーバー名にはホスト名が設定されます",
  "ON": "ON",
  "SNI": "SNI",
  "リダイレクトファイル": "リダイレクトファイル",
  "リダイレクトはファイル選択が必須です": "リダイレクトはファイル選択が必須です",
  "リダイレクトファイル設定済": "リダイレクトファイル設定済",
  "更新済み": "更新済み",
  "カスタム・シグネチャを削除します": "カスタム・シグネチャを削除します",
  "設定内容": "設定内容",
  "シグネチャID": "シグネチャID",  // @see static/constants/wafCustom#settingTerms
  "シグネチャ名": "シグネチャ名",  // @see static/constants/wafCustom#settingTerms
  "コメント": "コメント",  // @see static/constants/wafCustom#settingTerms
  "シグネチャ優先度": "シグネチャ優先度",  // @see static/constants/wafCustom#settingTerms
  "検査文字": "検査文字",  // @see static/constants/wafCustom#settingTerms
  "リクエストメソッド": "リクエストメソッド",  // @see static/constants/wafCustom#settingTerms
  "パラメータ名": "パラメータ名",  // @see static/constants/wafCustom#targetTerms
  "パラメータ値": "パラメータ値",  // @see static/constants/wafCustom#targetTerms
  "リクエストヘッダフィールド名": "リクエストヘッダフィールド名",  // @see static/constants/wafCustom#targetTerms
  "リクエストヘッダフィールド値": "リクエストヘッダフィールド値",  // @see static/constants/wafCustom#targetTerms
  "Cookie名": "Cookie名",  // @see static/constants/wafCustom#targetTerms
  "Cookie値": "Cookie値",  // @see static/constants/wafCustom#targetTerms
  "既存シグネチャ除外": "既存シグネチャ除外",  // @see static/constants/wafCustom#actionsType
  "指定しない": "指定しない",  
  "送信ファイル名": "送信ファイル名",
  "ANY": "ANY",  // @see static/constants/wafCustom#methodsType
  "GET": "GET",  // @see static/constants/wafCustom#methodsType
  "POST": "POST",  // @see static/constants/wafCustom#methodsType
  "PUT": "PUT",  // @see static/constants/wafCustom#methodsType
  "DELETE": "DELETE",  // @see static/constants/wafCustom#methodsType
  "HEAD": "HEAD",  // @see static/constants/wafCustom#methodsType
  "OPTIONS": "OPTIONS",  // @see static/constants/wafCustom#methodsType
  "PATCH": "PATCH",  // @see static/constants/wafCustom#methodsType
  "CONNECT": "CONNECT",  // @see static/constants/wafCustom#methodsType
  "TRACE": "TRACE",  // @see static/constants/wafCustom#methodsType
  "上にする": "上にする",  // @see static/constants/wafCustom#signaturePositionsType
  "下にする": "下にする",  // @see static/constants/wafCustom#signaturePositionsType
  "すべてのパラメータ": "すべてのパラメータ",  // @see static/constants/wafCustom#paramValuesType
  "パラメータを指定": "パラメータを指定",  // @see static/constants/wafCustom#paramValuesType
  "すべてのフィールド": "すべてのフィールド",  // @see static/constants/wafCustom#paramValuesType
  "フィールドを指定": "フィールドを指定",  // @see static/constants/wafCustom#paramValuesType
  "すべてのCookie": "すべてのCookie",  // @see static/constants/wafCustom#paramValuesType
  "Cookieを指定": "Cookieを指定",  // @see static/constants/wafCustom#paramValuesType
  "の": "の",
  "HTTPリクエストヘッダ内の<br>User-Agentフィールド": "HTTPリクエストヘッダ内の<br>User-Agentフィールド",
  "検査対象": "検査対象",
  "検査対象が存在しない場合も適用する": "検査対象が存在しない場合も適用する",
  "値を指定する場合、必須入力": "値を指定する場合、必須入力",
  "カスタム・シグネチャ一覧": "カスタム・シグネチャ一覧",
  "カスタム・シグネチャが設定されていません": "カスタム・シグネチャが設定されていません",
  "適用するカスタム・シグネチャを設定してください": "適用するカスタム・シグネチャを設定してください",
  "No.": "No.",
  "カスタム・シグネチャ名": "カスタム・シグネチャ名",
  "コメント": "コメント",
  "カスタム・シグネチャ新規設定": "カスタム・シグネチャ新規設定",
  "カスタム・シグネチャが{0}件登録されています。": "カスタム・シグネチャが{0}件登録されています。",
  "カスタム・シグネチャ追加": "カスタム・シグネチャ追加",
  "リクエスト防御": "リクエスト防御",
  "レスポンス保護": "レスポンス保護",
  "CMS選択": "CMS選択",
  "必須": "必須",

  "グループ設定": "グループ設定",

  "証明書一覧": "証明書一覧",
  "自動証明書": "自動証明書",
  "自動証明書{0}": "自動証明書{0}",
  "アップロード証明書": "アップロード証明書",
  "アップロード証明書{0}": "アップロード証明書{0}",
  "最大登録件数": "最大登録件数",
  "※最大{0}件まで登録可能です": "※最大{0}件まで登録可能です",
  "自動": "自動",
  "アップロード": "アップロード",
  "ホスト名なし": "ホスト名なし",
  "種別": "種別",
  "処理中": "処理中",
  "利用可能": "利用可能",
  "エラー": "エラー",
  "www無し": "www無し",
  "www無しも含む": "www無しも含む",
  "含む": "含む",
  "含まない": "含まない",
  "秘密鍵": "秘密鍵",  // @see static/constants/website.js#basicTermsType
  "SSL証明書": "SSL証明書",  // @see static/constants/website.js#basicTermsType
  "最新登録日": "最新登録日",
  "証明書発行をスケジュールしました。": "証明書発行をスケジュールしました。",
  "新しい証明書が発行されたら利用可能となります。": "新しい証明書が発行されたら利用可能となります。",
  "一日経過しても利用可能とならない場合はお問い合わせください。": "一日経過しても利用可能とならない場合はお問い合わせください。",
  "一覧画面へ": "一覧画面へ",
  "証明書選択": "証明書選択",
  "選択可能な証明書がありません": "選択可能な証明書がありません",
  "Let’s Encrypt からの証明書取得について": "Let’s Encrypt からの証明書取得について",
  "Let’s Encrypt から証明書を取得する際、DNSを利用してドメインを検証します。": "Let’s Encrypt から証明書を取得する際、DNSを利用してドメインを検証します。",
  "検証に利用するレコードをCNAMEでSiteGuardで管理しているホストゾーンに委任していただく必要があります。": "検証に利用するレコードをCNAMEでSiteGuardで管理しているホストゾーンに委任していただく必要があります。",
  "具体的には以下の設定が必要です。": "具体的には以下の設定が必要です。",
  "ホスト名 *.example.com のワイルドカード証明書を発行する場合": "ホスト名 *.example.com のワイルドカード証明書を発行する場合",
  "ホスト名 www.example.com の証明書を発行する場合": "ホスト名 www.example.com の証明書を発行する場合",
  "ホスト名 www.example.com (www無しも含む) の証明書を発行する場合": "ホスト名 www.example.com (www無しも含む) の証明書を発行する場合",
  "利用には提供元の": "利用には提供元の",
  "ポリシー": "ポリシー",
  "に同意する必要があります": "に同意する必要があります",
  "同意して利用": "同意して利用",
  "シリアル番号": "シリアル番号",
  "有効期限（開始）": "有効期限（開始）",
  "有効期限（終了）": "有効期限（終了）",
  "発行者": "発行者",
  "組織": "組織",
  "コモンネーム": "コモンネーム",
  "所有者": "所有者",
  "certファイル": "certファイル",
  "この内容で登録しました。": "この内容で登録しました。",
  
  "ウェブサイト": "ウェブサイト",
  "WordPress防御": "WordPress防御",
  "EC-CUBE防御": "EC-CUBE防御",
  "MovableType防御": "MovableType防御",
  "トラステッド・シグネチャ": "トラステッド・シグネチャ",
  "クロスサイト・スクリプティング (XSS)": "クロスサイト・スクリプティング (XSS)",
  "SQLインジェクション": "SQLインジェクション",
  "OSコマンドインジェクション": "OSコマンドインジェクション",
  "ディレクトリトラバーサル": "ディレクトリトラバーサル",
  "改行コードインジェクション": "改行コードインジェクション",
  "XPathインジェクション": "XPathインジェクション",
  "LDAPインジェクション": "LDAPインジェクション",
  "XXE": "XXE",
  "Unicodeを利用した攻撃": "Unicodeを利用した攻撃",
  "その他": "その他",
  "リクエストURLチェック": "リクエストURLチェック",
  "リクエスト形式チェック": "リクエスト形式チェック",
  "メソッドチェック": "メソッドチェック",
  "動作テストシグネチャ": "動作テストシグネチャ",
  "レスポンスチェック": "レスポンスチェック",
  "ステータスコード": "ステータスコード",

  "チケットをクローズする": "チケットをクローズする",
  "この内容でクローズしますか？": "この内容でクローズしますか？",
  "この内容で送信しますか？": "この内容で送信しますか？",
  "サポート": "サポート",
  "内容": "内容",
  "ステータス": "ステータス",
  "内部ステータス": "内部ステータス",
  "クローズを表示": "クローズを表示",
  "対応不要を表示": "対応不要を表示",
  "チケット詳細": "チケット詳細",
  "新規チケット登録": "新規チケット登録",
  "タイトル": "タイトル",
  "チケットID": "チケットID",
  "重要度": "重要度",  // @TODO: 優先度? 重要度? 緊急度?
  "イベントID": "イベントID",
  "メール宛先": "メール宛先",
  "作成日時": "作成日時",
  "更新日時": "更新日時",
  "ダウンロード": "ダウンロード",
  "設定": "設定",
  "サポートチケット": "サポートチケット",
  "タグ": "タグ",
  "タグなし": "タグなし",
  "ファイル": "ファイル",
  "メール": "メール",
  "投稿日時": "投稿日時",
  "なし": "なし",
  "コメント追加": "コメント追加",
  "一斉返信": "一斉返信",
  "設定方法": "設定方法",
  "クローズ": "クローズ",
  "シグネチャ": "シグネチャ",
  "EGSS": "EGSS",
  "誤検出": "誤検出",

  "閉じる": "閉じる",
  "更新": "更新",
  "検索結果はありません": "検索結果はありません",
  "利用規約": "利用規約",
  "プライバシーポリシー": "プライバシーポリシー",
  "個人情報保護方針(プライバシーポリシー)": "個人情報保護方針(プライバシーポリシー)",
  "お問い合わせ": "お問い合わせ",
  "&copy; EG Secure Solutions Inc.": "&copy; EG Secure Solutions Inc. 2022",
  "運営会社": "運営会社",
  "ユーザー設定": "ユーザー設定",
  "ログアウト": "ログアウト",
  "日本語": "日本語",
  "英語": "英語",
  "ページが存在しません": "ページが存在しません",


  "© EG Secure Solutions Inc.": "© EG Secure Solutions Inc. 2022",

  "": "",
})
