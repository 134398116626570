<template>
    <label :class="classes">
        <input type="radio" class="radio__input" :disabled="disabled" :checked="checked" :name="name" :value="value" @change="setInput">
        <span class="radio__label"><slot>{{text}}</slot></span>
    </label>
</template>

<script>
    export default {
        name: 'gx-form-radio',
        props: {
            text: {
                type: String,
                default: 'radio text'
            },
            disabled: {
                type: Boolean,
                default: false
            },
            checked: {
                type: Boolean,
                default: false
            },
            name: {
                type: String,
                default: ''
            },
            value: {
                type: [String, Number],
                default: ''
            }
        },
        data() {
            return {
                valid: true
            }
        },
        computed: {
            classes() {
                const classes = ['radio']
                if(this.disabled) classes.push('is-disabled')
                if(!this.valid) classes.push('is-error')
                return classes
            }
        },
        methods: {
            setInput(event) {
                this.$emit('change', event.target.value)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';

    .radio {
        cursor: pointer;

        &__input {
            display: none;

            &:checked + .radio__label::before {
                background-color: $color-white;
                border: 4px solid $color-current;
            }
        }
        &__label {

            &::before {
                content: "";
                display: inline-block;
                vertical-align: middle;
                margin: -.2em 8px 0 0;
                border: 1px solid $color-border;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                transition: $transition-common;
            }
        }
        &.is-disabled {
            opacity: $opacity-disabled;
            cursor: not-allowed;
        }
        &.is-error {

            .radio__label::before {
                border-color: $color-error;
                background-color: rgba($color-error, .1);
            }
        }
    }
</style>