import api from '@/services/api'

import Const from '@/static/constants'
// import { response } from 'express'

export const certificateService = {
    API_ENDPOINT: '/api/certificate',
    /**
     * 
     * @param {*} params required: title, status, waf_event, ticket_mail_user_set
     * @returns enum status, 
     */
    registerAuto(params) {
        return api.post(`${this.API_ENDPOINT}/auto/`, params)
            .then(response => response.data)
    },
    getAuto(certificateId) {
        return api.get(`${this.API_ENDPOINT}/auto/${certificateId}/`)
            .then(response => response.data)
    },
    updateAuto(certificateId, params) {
        return api.patch(`${this.API_ENDPOINT}/auto/${certificateId}/`, params)
            .then(response => response.data)
    },
    deleteAuto(uuid) {
        return api.delete(`${this.API_ENDPOINT}/auto/${uuid}/`)
            .then(response => response.data)
    },
    getCnt() {
        return api.get(`${this.API_ENDPOINT}/count/`)
            .then(response => response.data)
    },
    getContent(certificateid) {
        return api.get(`${this.API_ENDPOINT}/content/`, { params: {certificate_id: certificateid}})
            .then(response => response.data)
    },
    list(params) {
        return api.get(`${this.API_ENDPOINT}/display/`, {params: params})
            .then(response => response.data)
    },
    getInternalStatus(certificateId, params) {
        return api.get(`${this.API_ENDPOINT}/display/${certificateId}/`, params)
            .then(response => response.data)
    },
    registerUpload(params) { 
        return api.post(`${this.API_ENDPOINT}/upload/`, params)
            .then(response => response.data)
    },
    getUpload(certificateId, params) {
        return api.get(`${this.API_ENDPOINT}/upload/${certificateId}/`, params)
            .then(response => response.data)
    },
    UpdateUpload(certificateId, params) {
        return api.patch(`${this.API_ENDPOINT}/upload/${certificateId}/`, params)
            .then(response => response.data)
    },
    deleteUpload(uuid) {
        return api.delete(`${this.API_ENDPOINT}/upload/${uuid}/`)
            .then(response => response.data)
    },
    getExpiredCnt() {
        return api.get(`${this.API_ENDPOINT}/expired_cert/`)
            .then(response => response.data)
    },  
    selectList(params) {
        return api.get(`${this.API_ENDPOINT}/select/`, {params: params})
            .then(response => response.data)
    },
}

const search = (arr, value) => {
    let res = arr.find((obj, idx, arr)=>{
        return (obj.value == value)
    })
    return res
}

export class CertificateDetail {
    constructor(params) {
        let status = search(Const.support.statusArray, params.status)

        this.certificate_id = params.certificate_id
        this.comment = params.comment
        this.hostname = params.hostname
        this.allow_naked_domain_flg = params.allow_naked_domain_flg
        this.status = status
        this.account = params.account
    }
}

export class AutoCreateParam {
    constructor(params) {
        this.comment = params.comment ? params.comment : ""
        this.hostname = params.hostname ? params.hostname : ""
        this.allow_naked_domain_flg = params.allow_naked_domain_flg ? params.allow_naked_domain_flg : false
    }

    reset() {
        this.comment = ""
        this.ssl_key = ""
        this.ssl_cert = ""
    }
}

export class UploadCreateParam {
    constructor(params) {
        this.comment = params.comment ? params.comment : ""
        this.ssl_key = params.ssl_key ? params.ssl_key : ""
        this.ssl_cert = params.ssl_cert ? params.ssl_cert : ""
    }

    reset() {
        this.comment = ""
        this.ssl_key = ""
        this.ssl_cert = ""
    }
}

export class CertificateSearchParam {
    constructor(params) {
        this.is_auto = params.is_auto
        this.comment = params.comment
        this.hostname = params.hostname
        this.allow_naked_domain_flg = params.allow_naked_domain_flg
    }

    reset() {
        this.is_auto = 0
        this.comment = ""
        this.hostname =  ""
        this.allow_naked_domain_flg = false
    }
}