<template>
    <div :class="['notice', `notice--${type}`]">
        <p class="label"><strong>{{label}}</strong></p>
        <p v-if="this.$slots.default" class="text"><slot /></p>
    </div>
</template>

<script>
    export default {
        name: 'notice',
        props: {
            type: {
                type: String,
                validator: (value) => {
                    return ['success', 'error', 'warning', 'info'].indexOf(value) !== -1
                },
                default: 'success'
            },
            label: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .notice {
        border: 2px solid;
        padding: 24px 24px 24px 80px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &::after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 24px;
            margin: auto;
            font-size: 3.2rem;
            line-height: 1;
            width: 3.2rem;
            height: 3.2rem;
            text-align: center;
        }
        &--success {
            border-color: $color-success;
            background-color: rgba($color-success, .1);

            &::after {
                @include icon(check_circle_fill);
                color: $color-success;
            }
        }
        &--error {
            border-color: $color-error;
            background-color: rgba($color-error, .1);

            &::after {
                @include icon(exclamation_circle_fill);
                color: $color-error;
            }
        }
        &--warning {
            border-color: $color-warning;
            background-color: rgba($color-warning, .1);

            &::after {
                @include icon(exclamation_tryangle_fill);
                color: $color-warning;
            }
        }
        &--info {
            border-color: $color-info;
            background-color: rgba($color-info, .1);

            &::after {
                @include icon(info_circle_fill);
                color: $color-info;
            }
        }
    }
    .label {
        font-size: 1.8rem;
    }
    .text {
        margin-top: 16px;
    }
</style>