export const registerTermsType = Object.freeze({
    username: 'ユーザーID',
    email: 'メールアドレス',
    telephone: '電話番号',
    password: 'パスワード',
    tz: "タイムゾーン",
    notification: "通知",
    is_delivery: 'お知らせ配信',
    role: "権限",
    is_active_user: '状態',
})

export const modifyTermsType = Object.freeze({
    username: 'ユーザーID',
    email: 'メールアドレス',
    telephone: '電話番号',
    password: 'パスワード',
    tz: "タイムゾーン",
    notification: "通知",
    is_delivery: 'お知らせ配信',
    role: "権限",
    is_active_user: '状態',
})

export const roleNormal = 30
export const roleAdmin = 50
export const roleGroupAdmin = 70
export const roleSupport = 80

export const roleTerms = Object.freeze({
    [roleSupport]: 'サポート',
    [roleGroupAdmin]: 'グループ管理者',
    [roleAdmin]: '管理者',
    [roleNormal]: '一般ユーザー',
})

export const timeZoneList = Object.freeze({
    "840": "UTC + 14",
    "780": "UTC + 13",
    "765": "UTC + 12:45",
    "720": "UTC + 12",
    "660": "UTC + 11",
    "630": "UTC + 10:30",
    "600": "UTC + 10",
    "570": "UTC + 9:30",
    "540": "UTC + 9",
    "525": "UTC + 8:45",
    "480": "UTC + 8",
    "420": "UTC + 7",
    "390": "UTC + 6:30",
    "360": "UTC + 6",
    "345": "UTC + 5:45",
    "330": "UTC + 5:30",
    "300": "UTC + 5",
    "270": "UTC + 4:30",
    "240": "UTC + 4",
    "210": "UTC + 3:30",
    "180": "UTC + 3",
    "120": "UTC + 2",
    "60": "UTC + 1",
    "0": "UTC",
    "-60": "UTC - 1",
    "-120": "UTC - 2",
    "-180": "UTC - 3",
    "-210": "UTC - 3:30",
    "-240": "UTC - 4",
    "-300": "UTC - 5",
    "-360": "UTC - 6",
    "-420": "UTC - 7",
    "-480": "UTC - 8",
    "-540": "UTC - 9",
    "-570": "UTC - 9:30",
    "-600": "UTC - 10",
    "-660": "UTC - 11",
    "-720": "UTC - 12"
})

export const notificationFlgTypes = Object.freeze({
    "0": "オフ",
    "1": "オン",
})

export const notificationIntervalTypes = Object.freeze({
    "5": "5分",
    "10": "10分",
    "30": "30分",
    "60": "1時間",
    "180": "3時間",
    "360": "6時間",
    "720": "12時間",
    "1440": "24時間",
})

export const notificationIntervalDefault = "60"
