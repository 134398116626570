<template>
    <header class="header">
        <h1 class="header-logo">
            <router-link v-if = menudisplay()  to="/dashboard">
                <img src="@/assets/image/common/logo_inverse01.svg" alt="SiteGuard Could Edition" class="header-logo__image">
            </router-link>
            <router-link v-else  to="#">
                <img src="@/assets/image/common/logo_inverse01.svg" alt="SiteGuard Could Edition" class="header-logo__image">
            </router-link>
        </h1>
        <div v-if = menudisplay() class="center">
                {{userAccount.username}}
        </div>
        <div v-if = menudisplay() class="right">
            <span class="group_name" v-if="userAccount.is_support">
                {{userAccount.group_description}} (ID : {{userAccount.group_id}})
            </span>
            <gx-button
                v-if="userAccount.is_support"
                :text="$t('グループ変更')"
                :disabled="isLoading"
                :size="'medium'"
                @onClick="onGroupChangeClick"
            />

            <nav class="header-nav">
                <ul class="header-nav__list">
                    <li class="header-nav__item">
                        <gx-icon
                            :text="$t('設定')"
                            :icon="'gear'"
                            :size="2"
                            :color="'#fff'"
                            href=""
                            class="header-nav__link"
                            @onClick="onGearClick"
                        />
                        <ul v-if="showSetting" class="sub-nav">
                            <li class="sub-nav__item"><router-link to="/accounts" class="sub-nav__link">{{$t('ユーザー設定')}}</router-link></li>
                            <!-- SCE_FE-2 -->
                            <li v-if="i18n.DOES_SHOW_LANGUAGE_SELECTOR" class="sub-nav__item">
                                <gx-form-select
                                    class="sub-nav__select"
                                    value-attr="val"
                                    str-attr="disp"
                                    :options="[
                                        {disp: $t('日本語'), val: 'ja'},
                                        {disp: $t('英語'), val: 'en'},
                                    ]"
                                    v-model="i18n.locale"
                                    @input="onLanguageSelectorInput"
                                />
                            </li>
                            <!-- /SCE_FE-2 -->
                            <li class="sub-nav__item"><a @click="logout" class="sub-nav__link">{{$t('ログアウト')}}</a></li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import gxButton from '@/components/atoms/button'
    import gxIconText from '@/components/atoms/icon-text'
    import gxIcon from '@/components/atoms/icon'
    import gxFormSelect from '@/components/atoms/form/select'
    import i18n from '@/i18n'
    import LoginService from '@/services/loginService'
    import AccountService from '@/services/accountService'
    export default {
        name: 'globalHeader',
        components: {
            gxIconText,
            gxIcon,
            gxFormSelect,
            gxButton,
        },
        data() {
            return {
                userAccount: {},
                i18n: i18n,
            }
        },
        computed: {
            showSetting() {
                return this.$store.state.showHeaderMenu
            }
        },
        mounted: async function() {
            this.$data.group = undefined
            
            const userAccount = await AccountService.userInfo()
            this.$set(this.$data, 'userAccount', userAccount ? userAccount : {})
        },
        watch: {
            // ルーティングを見張ってページ遷移があったらヘッダメニューを閉じる
            "$route": function(to, from) {
                this.$store.dispatch('closeHeaderMenu')
            }
        },
        methods: {
            onGearClick() {
                this.$store.dispatch('toggleHeaderMenu')
            },
            onGroupChangeClick() {
                // @see @/layouts/main.vue
                this.$store.dispatch('openModalWithParam', {target: 'group-change-modal', param: this.$route.params.uuid})
                this.$store.dispatch('closeHeaderMenu')
            },
            onLanguageSelectorInput() {
                this.$store.dispatch('closeHeaderMenu')
            },
            async logout() {
                this.$store.dispatch('displayLoading')
                await LoginService.logout().then(res => {
                    this.$router.push('/')
                }).catch(err => {
                    console.error(err)
                    alert("通信に失敗しました")
                }).finally(()=>{
                    this.$store.dispatch('closeHeaderMenu')
                    this.$store.dispatch('hideLoading')
                })
            },
            menudisplay(){
                if(this.$route.path.includes('manual')){
                    return false
                }else{
                    return true
                }
            }            
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .header {
        background: $color-gray1;
        display: flex;
        width: 100%;
        padding: 0 16px;
        justify-content: space-between;
        align-items: center;

        &-logo {
            width: 200px;

            &__image {
                vertical-align: middle;
            }
        }
        .center {
            align-items: center;
        }        
        .right {
            display: inherit;
            align-items: center;

            .group_name {
                margin-left: 1em;
                margin-right: 1em;
                font-size: 1.2rem;
            }
        }
        &-nav {

            &__list {
                display: flex;
                height: 56px;
                line-height: 56px;
            }
            &__item {
                position: relative;
            }
            &__link {
                display: block;
                height: 100%;
                padding: 0 16px;
                text-decoration: none;
                cursor: pointer;
                transition: $transition-common;

                &:hover {
                    opacity: $opacity-hover;
                }
            }
        }
    }
    .sub-nav {
        position: absolute;
        top: 100%;
        right: 0;

        &__item {

            &:not(:first-child) {
                border-top: 1px solid $color-hr;
            }
        }
        &__link {
            background-color: $color-parts-bg;
            display: block;
            min-width: 120px;
            height: 48px;
            line-height: 48px;
            padding: 0 16px;
            text-align: center;
            text-decoration: none;
            white-space: nowrap;
        }
        &__select {
            background-color: $color-parts-bg;
            display: block;
            &:hover {
                opacity: $opacity-hover;
            }
        }
    }
</style>