<template>
    <dl class="data-list">
        <template v-for="(item, index) in data">
            <dt class="title" :key="`${index}-title`">
                <slot :name="'title'" :item="item">{{item.title}}</slot>
            </dt>
            <dd class="data" :key="`${index}-data`">
                <slot :name="'data'" :item="item">{{item.data}}</slot>
            </dd>
        </template>
    </dl>
</template>

<script>
    export default {
        name: 'data-list',
        props: {
            data: {
                type: Array,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .data-list {
        display: grid;
        grid-template: auto / 280px 1fr;

        .title {
            padding: 16px 0 16px 24px;
            min-height: 56px;
            background-color: $color-parts-bg;
            font-weight: bold;

            &:not(:nth-of-type(1)) {
                border-top: 1px solid $color-hr;
            }
        }
        .data {
            padding: 16px 24px;
            min-height: 56px;
            background-color: $color-parts-bg;

            &:not(:nth-of-type(1)) {
                border-top: 1px solid $color-hr;
            }
        }
    }
</style>
