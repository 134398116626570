import api from '@/services/api'

export default {
    API_ENDPOINT: '/api/group',
    fetchList(params) {
        return api.get(`${this.API_ENDPOINT}/`, { params: params })
            .then(response => response.data)
        },
    register(params) {
        return api.post(`${this.API_ENDPOINT}/`, params)
            .then(response => response.data)
    },
    fetchDetail(uuid) {
        return api.get(`${this.API_ENDPOINT}/`)
            .then(response => response.data)
    },
    updateBasic(uuid, params) {
        return api.patch(`${this.API_ENDPOINT}/`, params)
            .then(response => response.data)
    },
    delete(uuid) {
        return api.delete(`${this.API_ENDPOINT}/`)
            .then(response => response.data)
    },
    fetchAccessRules(group_rule_id, params) {
        return api.get(`${this.API_ENDPOINT}/`, { params: params })
            .then(response => response.data)
    },
    saveAccessRule(groupruleId, uuid, params) {
        return !params.group_rule_id
            ? this._registerAccessRule(groupruleId, params)
            : this._updateAccessRule(groupruleId, uuid, params)
    },
    _registerAccessRule(groupruleId, params) {
        return api.post(`${this.API_ENDPOINT}/`, params)
            .then(response => response.data)
    },
    _updateAccessRule(groupruleId, uuid, params) {
        return api.put(`${this.API_ENDPOINT}/${uuid}/`, params)
            .then(response => response.data)
    },
    deleteAccessRule(groupruleId, uuid) {
        return api.delete(`${this.API_ENDPOINT}/${uuid}/`)
            .then(response => response.data)
    },
    sortAccessRule(params) {
        return api.post(`${this.API_ENDPOINT}/sort/`, params)
            .then(response => response.data)
    },
}
