<script>
 import { HorizontalBar  } from 'vue-chartjs';
 
 export default {
   extends: HorizontalBar ,
   name: 'ipbar',
   props: {
    IPbardata: {
       type: Array,
       required: true,
     },
   },
   data () {
     return {
       data: {
         labels: this.getbardata('label'),    
         datasets: [
         {
             label: 'IP別攻撃元',
             data: this.getbardata('data'),
             backgroundColor: 'rgba(75, 192, 192, 0.2)',
             borderColor: 'rgba(75, 192, 192, 1)',
             borderWidth: 1
           },
         ]
       },
       options: {
        legend: {
          display: false
        },
         scales: {
           xAxes: [{
             scaleLabel: {
                display: true,
                unit: 'hour'
              },            
             ticks: {
               min: 0,
               fontColor: "white"
             }
           }],
           yAxes: [{
             ticks: {
               beginAtZero: true,
               min: 0,
               fontColor: "white"
             }
           }]
         }
       }
     }
   },
   mounted () {
     this.renderChart(this.data, this.options)
   },
   methods: {
    getbardata(type) {
      this.getvalue = []
      if(this.IPbardata.length != 0){
        for (let i = 0; i < this.IPbardata.length; i++) {
          if(type == "data"){
            this.getvalue.push(this.IPbardata[i].count)
          }else if(type == "label"){
            this.getvalue.push(this.IPbardata[i].ip)        
          }
       }
      }
      return this.getvalue
    }
  }
 }
 </script>