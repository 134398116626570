<template>
    <table-tr>
        <gx-table-cell v-if="isSorting" min :noPadding="isSorting" :class="dragHandleClasses" >
            <span class="draggable-area">
                <gx-icon
                    :text="$t('並べ替え')"
                    :icon="'drag-drop'"
                    :size="2"
                />
            </span>
            <!-- <gx-form-checkbox v-else boxOnly /> -->
        </gx-table-cell>
        <gx-table-cell center :disabled= setdisabled(item.is_active)>
            {{setenabled(item.is_active)}}
        </gx-table-cell>
        <gx-table-cell min center :disabled= setdisabled(item.is_active)>{{idx + 1}}</gx-table-cell>
        <gx-table-cell :disabled= setdisabled(item.is_active)>{{$t(sourceStr) | checkNull}}<em v-if="item.exclusion" class="excepting-label">{{$t('以外')}}</em></gx-table-cell>
        <gx-table-cell :disabled= setdisabled(item.is_active)>
            <gx-tag v-if="item.dynamic_only" :text="$t('動的のみ')" class="path-tag" />
            <gx-tag v-if="item.use_regex_path" :text="$t('正規表現')" class="path-tag" />
            {{item.url_path | abbreviation}}
        </gx-table-cell>
        <gx-table-cell center :disabled= setdisabled(item.is_active)>
            <gx-label
                :text="$t(permissionStyle.str)"
                :type="permissionStyle.color" />
        </gx-table-cell>
        <gx-table-cell center :disabled= setdisabled(item.is_active)>
            <gx-icon
                v-if="!item.permission"
                :text="$t(actionStyle.str)"
                :icon="actionStyle.icon"
                :color="colors.SUCCESS" />
        </gx-table-cell>
        <gx-table-cell :disabled= setdisabled(item.is_active)>
            {{setcomment(item.comment)}}
        </gx-table-cell>
        <gx-table-cell min>
            <span class="actions">
                <gx-button
                    class="actions__item"
                    :text="$t('詳細')"
                    :type="'common'"
                    :size="'small'"
                    :disabled="isSorting || !isEditable"
                    @onClick="showDetail"
                />
                <gx-icon
                    class="actions__item"
                    :tag="'a'"
                    :text="$t('削除')"
                    :icon="'trash'"
                    :disabled="isSorting || !isEditable"
                    @onClick="() => $emit('delete')"
                    href="#" />
            </span>
        </gx-table-cell>
    </table-tr>
</template>

<script>
    import Const from '@/static/constants'

    import gxLabel from '@/components/atoms/label'
    import gxTag from '@/components/atoms/tag'
    import gxButton from '@/components/atoms/button'
    import gxIcon from '@/components/atoms/icon'
    import gxTableCell from '@/components/atoms/table/cell'
    import tableTr from '@/components/molecules/table/tr'

    import i18n from '@/i18n'

    export default {
        name: 'website-access-row',
        components: {
            gxLabel,
            gxTag,
            gxButton,
            gxIcon,
            gxTableCell,
            tableTr
        },
        props: {
            idx: {
                type: Number,
                required: true,
            },
            item: {
                type: Object,
                required: true,
            },
            isEditable: {
                type: Boolean,
                required: true,
            },
            country: {
                type: Array,
                required: true,
            },
            handleClass: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                colors: Const.common.colors,
            }
        },
        filters: {
            abbreviation(value) {
                return value.length>50?`${value.substr(0,50)}…` : value
            },
            checkNull(value) {
                return value?value:' - '
            }
        },
        computed: {
            sourceStr() {
                if (!this.item.source_type) {
                    return "全て"
                }

                if (this.item.source_type == 1) {
                    const source_ip = this.item.source_ip;
                    let source_ip_disp;

                    if (!source_ip) {
                        source_ip_disp = source_ip;
                    } else {
                        const arr = source_ip.split('\n');
                        source_ip_disp = arr.slice(0, 3).join(', ');
                        if (arr.length > 3) {
                            source_ip_disp += ', ...'
                        }
                    }
                    return source_ip_disp
                }
                if (this.item.source_type == Const.website.access_sourceType.BOT) {
                    return Const.website.access_botTypeLabel[this.item.source_bot]
                }

                return i18n.getCountryName(this.item.source_country)
            },
            isSorting() {
                return !!this.handleClass
            },
            dragHandleClasses() {
                return this.isSorting ? ['draggable-cell', this.handleClass] : null
            },
            permissionStyle() {
                let num = Number(this.item.permission)
                if(num == 1 && Number(this.item.permission_white) == 1){
                    num = 2
                }
                return Const.website.permissionStyle[num]
            },
            actionStyle() {
                return Const.website.actionStyle[this.item.action]
            },
        },
        methods: {
            showDetail() {
                const param = {
                    detailType: 'detail',
                    rule: this.item
                }
                this.$store.dispatch('openModalWithParam', {target: 'website-access-detail', param: param})
            },
            setdisabled(is_active){
                if(is_active){
                    return false
                }else{
                    return true
                }
            },
            setenabled(bool){
                if(bool){
                    return i18n.t('ON')
                }else{
                    return i18n.t('OFF')
                }
            },
            setcomment(comment){
                if(comment.length < 26){
                    return comment
                }else{
                    return comment.substr(0,25) + "…"
                }
                
            }
        }
    }

</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';

    .actions {
        display: flex;
        justify-content: flex-end;

        &__item:not(:first-child) {
            margin-left: 24px;
        }
    }
    .excepting-label {
        font-weight: bold;
        margin-left: 16px;
    }
    .draggable-cell {
        position: relative;
        cursor: move;
    }
    .draggable-area {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 10px;
        border-right: 1px solid $color-border;
    }
    .path-tag {
        margin-right: 8px;
    }
</style>
