<template>
    <gx-section class="modal">
        <notice v-if="type == 'edit'" class="notice" :type="info" :label="$t('この内容で登録しました。')" />
    <div class="cert">
            <gx-section>
                <data-list :data="detailTerms">
                    <template #title={item}>{{$t(getTerms(item))}}</template>
                    <template #data={item}>
                        <template v-if="item == 'SSL'" class="test">
                            <gx-icon class="actions__item close" :tag="'a'" href="#" :icon="'close'" :size="2.4"  @onClick="closeModal" /> 
                        </template>
                        <template v-else-if="item == 'not_before' || item == 'not_after' ">
                            {{formatLocalDateTime(details[item])}}
                        </template>
                        <template v-else-if="item == 'issuer'">
                            <div class="action-wrap">
                                <div class="label_2col">{{$t('国名')}}</div>
                                <span class = "data_2col">{{details['issuer_country']}}</span>                                        
                            </div>
                            <div class="action-wrap">
                                <div class="label_2col">{{$t('組織')}}</div>
                                <span class = "data_2col">{{details['issuer_organization']}}</span>                                        
                            </div>
                                <div class="label_2col">{{$t('コモンネーム')}}</div>
                                <span class = "data_2col">{{details['issuer_common_name']}}</span>
                        </template>
                        <template v-else-if="item == 'common_name'">
                            <div class="action-wrap">
                                <div class="label_2col">{{$t('コモンネーム')}}</div>
                                <span class = "data_2col">{{details['common_name']}}</span>                                    
                            </div>
                        </template>
                        <template v-else-if="item == 'ssl_cert'">
                            <div>
                                {{atob(details[item])}}
                            </div>
                        </template>
                        <template v-else>
                            {{details[item]}}
                        </template>
                    </template>
                </data-list>
            </gx-section>
        </div>
    </gx-section>
    <button-wrap :buttons="buttons(invalid)" class="button-wrap" @submit="submit" @cancel="closeModal" />
</template>

<script>
    import { mapGetters } from 'vuex'

    import Const from '@/static/constants'
    import MixinCommon from '@/mixin/common'
    import MixinDate from '@/mixin/date'

    import layoutMain from '@/layouts/main'
    import gxLabel from '@/components/atoms/label'
    import gxIconText from '@/components/atoms/icon-text'
    import gxBox from '@/components/atoms/box'
    import gxButton from '@/components/atoms/button'
    import gxSection from '@/components/atoms/section'
    import gxCode from '@/components/atoms/code'
    import gxIcon from '@/components/atoms/icon'
    import gxTitle from '@/components/atoms/title'
    import loading from '@/components/atoms/loading'
    import pageBack from '@/components/molecules/page-back'
    import dataList from '@/components/molecules/data-list'
    import notice from '@/components/molecules/notice'

    import {
        certificateService,
    } from '@/services/certificateService'

    export default {
        name: 'certificate-detail',
        mixins: [MixinCommon, MixinDate],
        components: {
            layoutMain,
            gxLabel,
            gxIconText,
            gxBox,
            gxSection,
            gxCode,
            gxIcon,
            gxTitle,
            loading,
            pageBack,
            dataList,
            gxButton,
            notice
        },
        data() {
            return {
                details: {},
                countries: [],
                isLoadError: false,
                isLoading: true,
                groupid:"",
            }
        },
        computed: {
            ...mapGetters([
                'userAccount'
            ]),
            uuid() {
                return this.$store.state.modalParam.uuid
            },
            type() {
                return this.$store.state.modalParam.type
            },
            detailTerms() {
                return [
                    'SSL',                  // SSL証明書
                    'serial_number',        // シリアル番号
                    'not_before',           // 有効期限（開始）
                    'not_after',            // 有効期限（終了）
                    'issuer',               // 発行者：国
                    'common_name',          // 所有者：コモンネーム
                    'ssl_cert',             // certファイル
                ]
            }
        },
        methods: {
            reload() {
                this.fetch()
            },
            getTerms(value) {
                return Const.certificate.detailTermsType[value]
            },
            atob(value){
                return atob(value)
            },
            async fetch() {
                this.isLoading = true
                this.isLoadError = false
                this.details = await certificateService.getContent(this.uuid).catch(err => {
                    this.isLoadError = true
                    return {}
                })
                this.isLoading = false
            },
            closeModal() {
                //　ウェブサイトで開いた場合、選択可能証明書一覧を表示する
                if (this.type == "certificate" || this.type == "edit") {
                    this.$store.dispatch('closeModal')
                } else{
                    this.$store.dispatch('openModalWithParam', {target: 'website-select-certificate'})
                }
            },
        },
        created() {
            this.fetch()
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .close {
        padding-left: 95%;
    }
    @media screen and (max-width: 1560px) {
        .cert{
            white-space: pre-line;
            height: 80%;
            width: 85%;
            font-family: monospace;
            overflow-y: scroll;
        }
    }
    @media screen and (min-width: 1560px) and (max-width: 1700px) {
        .cert{
            white-space: pre-line;
            height: 80%;
            width: 70%;
            font-family: monospace;
            overflow-y: scroll;
        }
    }
    @media screen and (min-width: 1700px) {
        .cert{
            white-space: pre-line;
            height: 80%;
            width: 60%;
            font-family: monospace;
            overflow-y: scroll;
        }
    }
    .label_2col {
        font-weight: bold;
        display: inline-block;
        width:100px;
        margin-bottom: 10px;
    }
        .data_2col  {
            padding: 10px 24px;
            min-height: 56px;

        }
    .modal{
        background-color: rgba(0, 0, 0, .75);
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 100;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

</style>