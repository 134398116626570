import { render, staticRenderFns } from "./waf-custom-form.vue?vue&type=template&id=5489f906&scoped=true&"
import script from "./waf-custom-form.vue?vue&type=script&lang=js&"
export * from "./waf-custom-form.vue?vue&type=script&lang=js&"
import style0 from "./waf-custom-form.vue?vue&type=style&index=0&id=5489f906&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5489f906",
  null
  
)

export default component.exports