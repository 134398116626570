<template>
    <p class="button-wrap">
        <gx-button
            class="item"
            v-for="(button, index) in buttons"
            v-bind:key="index"
            :text="button.text"
            :type="button.type"
            :size="button.size"
            :noPointer="button.noPointer"
            :disabled="!!button.disabled"
            @onClick="button.onClick ? onClick(button.onClick) : false"
        />
    </p>
</template>

<script>
    import gxButton from '@/components/atoms/button'
    export default {
        name: 'button-wrap',
        components: {
            gxButton
        },
        props: {
            buttons: {
                type: Array,
                required: true
            }
        },
        methods: {
            onClick(event) {
                this.$emit(event)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .button-wrap {
        margin-top: 32px;
        display: flex;
        justify-content: center;
    }
    .item {
        margin: 0 20px;
    }
</style>