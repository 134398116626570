<template>
    <div class="terms-content">
        <gx-section>
            <gx-title :level="2">第1条 サービス対象製品およびサービス期間</gx-title>
            <gx-box>
                <ol class="terms-box__ol">
                    <li class="terms-box__li">本契約に定めるサポート対象のサービスおよびサービス期間は、お客様および弊社で締結したサービス規約(本サービス契約)の「ご契約内容」の項に示すものとします。</li>
                </ol>
            </gx-box>
        </gx-section>

        <gx-section>
            <gx-title :level="2">第2条 サービス・サポートの内容</gx-title>
            <gx-box>
                <ol class="terms-box__ol">
                    <li class="terms-box__li">
                        <strong class="terms-box__strong">GUARDIAXサービス</strong>
                        GUARDIAXによるWebアプリケーション、Webサーバに対するWAF(WebApplicationFirewall)防御機能を提供します。防御対象サイトはサービスお申し込み時の「GUARDIAX ご利用申請書」に記載のWebサイトとなります。追加サイトが発生する場合や、トラフィック量が変更される場合、SSL証明書が追加される場合などは価格表に従ってサービス費用の改定をご提案させて頂きます。<br class="terms-box__br">
                        本サービスは、防御対象サイトの安全性を完全に保証するものではありません。サイバー攻撃者は日々進化しており、本サービスの防御機能を突破する未知の攻撃や、現在の技術では防御不可能な新種の攻撃が発見される可能性はあります。
                    </li>
                    <li class="terms-box__li">
                        <strong class="terms-box__strong">技術サポート</strong>
                       サービスの利用に際して、利用開始時の問題、各種設定方法、エラーメッセージ、サービス仕様等に関する技術的な質問について、弊社はお客様の担当者からE-Mailにより当該問題に関する質問等を受け付け、これに対する回答をなすことにより、お客様を支援します。この場合、弊社は問題解決のため最善の努力をおこないますが、完全な問題解決をお客様に対して保証するものではありません。<br class="terms-box__br">
                       サービスの利用上の問題に対する対処法を前号に定める方法により回答します。但し、問題に対する対処方として、サービスの改定版（以下、エンハンスメントという）の提供という形式をとる場合があります。
                    </li>
                    <li class="terms-box__li">
                        <strong class="terms-box__strong">エンハンスメント</strong>
                        お客様より指摘された事項の解決に向け、弊社は、自己の単独の裁量で定めるスケジュールに基づき機能強化したエンハンスメントを、将来、一般に提供可能とすることがあります。この場合、ご契約期間であることを条件としてエンハンスメントをお客様にご提供します。本項の規定は、弊社が製品のエンハンスメントを開発・提供する義務や、お客様の特定の要求事項を満たすためのエンハンスメントを開発・提供する義務を弊社に課すものではありません。また、弊社はその単独の裁量によりエンハンスメントの価格体系を決定・変更する権限を有するものとします。エンハンスメントの程度如何により、本サービス料金以外に別途料金の支払いを受けることによって提供可能となるエンハンスメントを将来、開発・提供することがあります。
                    </li>
                </ol>
            </gx-box>
        </gx-section>

        <gx-section>
            <gx-title :level="2">第3条 サービスの終了および解除</gx-title>
            <gx-box>
                <ol class="terms-box__ol">
                    <li class="terms-box__li">お客様および弊社は契約期間終了の5営業日前までに書面にて相手方当事者に通知することにより、本サービス契約をいつでも終了させることができるものとします。</li>
                    <li class="terms-box__li">お客様および弊社が本サービス契約の条項に違反し、相手方当事者に書面による是正勧告後15日以内にそれが是正されない場合には、相手方当事者に対し、本契約の解除を行うことができるものとします。</li>
                    <li class="terms-box__li">お客様が第3条第1項の規定により本サービス契約を終了させた場合、または第3条第2項の規定に基づき契約を解除された場合には、弊社が既にお客様から受領した料金は、返還されないものとします。契約が解除された場合において、お客様に未払料金があるときは、お客様のかかる未払料金の支払義務は免責されないものとし、本サービス契約終了月の末日をもってご契約時に見積書等に記載された支払条件に基づき、弊社に現金にて支払うものとします。</li>
                </ol>
            </gx-box>
        </gx-section>

        <gx-section>
            <gx-title :level="2">第4条 責任の限定</gx-title>
            <gx-box>
                <ol class="terms-box__ol">
                    <li class="terms-box__li">弊社はお客様に対して、弊社が提供したサービスを原因として発生した損害について法律の規定する範囲内において責任を負うものとします。弊社が責任を負う場合の賠償額の上限はお客様が弊社に支払ったサービス料金の3ヶ月分を上限とします。</li>
                    <li class="terms-box__li">前項の責任はお客様が本サービスに添付されるマニュアル、第2条に定めるサービス・サポートの内容に従って利用している場合に限り生じるものとします。</li>
                </ol>
            </gx-box>
        </gx-section>

        <gx-section>
            <gx-title :level="2">第5条 一般条項</gx-title>
            <gx-box>
                <ol class="terms-box__ol">
                    <li class="terms-box__li">本サービス契約条項のうち法令に違反するものがある場合、当該条項は法令で許される範囲で強制力を有するものとし、他の条項には影響を与えないものとします。</li>
                    <li class="terms-box__li">お客様または弊社による本サービス契約下で供与される権利の放棄または不履行は、本サービス契約における他の権利の放棄とみなされません。本サービス契約の解除は、既に生じている本サービス契約に基づく不履行責任を消滅させるものではなく、本サービス契約下の当事者による義務の不履行に対する責任からその当事者を開放するものでもありません。</li>
                    <li class="terms-box__li">本サービス契約は日本国の法律に準拠するものとします。</li>
                    <li class="terms-box__li">お客様および弊社は、本サービス契約に関する訴訟については、東京地方裁判所を専属的管轄裁判所とすることに合意します。</li>
                </ol>
            </gx-box>
        </gx-section>

        <p class="terms-box__note">以上</p>

        <gx-section>
            <gx-title :level="2">備考：使用サービスライセンス等</gx-title>
            <gx-box>
                <ul class="terms-box__ul">
                    <li class="terms-box__li">This product includes GeoLite2 data created by MaxMind, available from <a href="https://www.maxmind.com" target="_blank">https://www.maxmind.com</a></li>
                </ul>
            </gx-box>
        </gx-section>
    </div>
</template>

<script>
    import gxBox from '@/components/atoms/box'
    import gxSection from '@/components/atoms/section'
    import gxTitle from '@/components/atoms/title'
    export default {
        name: 'terms-content',
        components: {
            gxBox,
            gxSection,
            gxTitle
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .terms-box {

        &__lead {
            margin-bottom: 56px;
        }
        &__ul {
            list-style: disc;
            padding-left: 1.5em;
            margin: 32px 0;
        }
        &__ol {
            list-style: decimal;
            padding-left: 1.5em;
            margin: 32px 0;
        }
        &__li {
            list-style: inherit;

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
        &__p {
            margin: 32px 0;
        }
        &__note {
            text-align: right;
        }
        &__strong {
            display: block;
            margin-top: 1em;
        }
        &__br {
            margin-top: 1em;
        }
    }
</style>