<template>
    <validation-observer ref="commentEditForm" v-slot="{ invalid }">
        <notice class="notice" :type="info" :label="$t('この内容で送信しますか？')" >
        </notice>
        <modal :size="'large'" @cancel="closeModal">
            <gx-section class="ticket_info">
                    <div v-if="data.editData.waf_event" class="item event_id">
                        <div class="label">{{$t('イベントID')}}</div>
                        <div class="data">
                            {{data.editData.waf_event}}
                        </div>
                    </div>
                    <div class="item status">
                        <div class="label">{{$t('ステータス')}}</div>
                        <div class="data">
                            <gx-form-select
                                :name="$t('ステータス')"
                                v-model="data.editData.status"
                                :options="getSelectorValues(data.support.statusArray)"
                                value-attr="value"
                                str-attr="str"
                                :disabled="!userAccount.is_support"
                                rules="required"
                                disabled=false
                                required
                            />
                        </div>
                    </div>
                    <div v-if="userAccount.is_support" class="item status">
                        <div class="label">{{$t('内部ステータス')}}</div>
                        <div class="data">
                            <gx-form-select
                                v-model="editData.internal_status"
                                :options="internalStatus"
                                value-attr="id"
                                str-attr="internal_status"
                                required
                                disabled=false
                            />
                        </div>
                    </div>
                    <div class="item email">
                        <div class="label">{{$t('メール宛先')}}</div>
                        <div class="data">
                            <ul class="selected_mail_addr_list" :v-if="!!addrList">
                                <li class="selected_mail_addr" v-for="mailAddr in addrList" :key="mailAddr.id">
                                    <gx-tag
                                        class="selected_mail_addr_tag"
                                        :text="mailAddr.username"
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="item title">
                        <div class="label">{{$t('タイトル')}}</div>
                        <div class="data">
                            <gx-form-text
                                :name="$t('タイトル')"
                                :placeholder="$t('タイトル')"
                                :maxlength="100"
                                :minlength="1"
                                v-model="editData.title"
                                disabled="true"
                                rules="required"
                                required
                            />
                        </div>
                    </div>
                    <div class="item content">
                        <div class="label">{{$t('内容')}}</div>
                        <div class="data">
                            <gx-form-textarea
                                :name="$t('内容')"
                                :placeholder="$t('内容')"
                                v-model="editData.content"
                                rules="required"
                                required
                            />
                        </div>
                    </div>
                    <div v-if="userAccount.is_support && Array.isArray(tags)" class="tag_edit">
                        <div class="label">{{$t('タグ')}}</div>
                        <div class="data">
                            <span class="selected_tags" v-for="tag in tags" :key="tag.tag_id">
                                <gx-tag :text="tag.tag_name"/>
                            </span>
                        </div>
                    </div>
                    <div v-if="(editData.ticket_file_set.length > 0)" v-for="(file, index) in editData.ticket_file_set" :key="index" class="file">
                    <div class = labels>
                    ファイル
                    </div>
                    {{file.file_name}} … {{Math.ceil(file.file.size / 1024)}}[KB]  
                </div>
                </gx-section>
                <button-wrap :buttons="buttons(invalid)" class="button-wrap" @submit="onSave" @cancel="closeModal" />
        </modal>
    </validation-observer>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import layoutMain from '@/layouts/main'

    import CommonMixin from '@/mixin/common'
    import multiNotice from '@/components/molecules/multi-notice'
    import gxSection from '@/components/atoms/section'
    import gxFormText from '@/components/atoms/form/text'
    import gxFormTextarea from '@/components/atoms/form/textarea'
    import gxFormSelect from '@/components/atoms/form/select'
    import gxTag from '@/components/atoms/tag'
    import gxFormFile from '@/components/atoms/form/file'
    import gxButton from '@/components/atoms/button'
    import gxIconText from '@/components/atoms/icon-text'
    import gxIcon from '@/components/atoms/icon'
    import buttonWrap from '@/components/molecules/button-wrap'
    import ManualService from '@/services/manualService'
    import modal from '@/components/molecules/modal'
    import notice from "@/components/molecules/notice"

    import Const from '@/static/constants'
    import {
        supportService,
        TicketCreateParam,
    } from '@/services/supportService'

    import i18n from '@/i18n'

    export default {
        name: "support-register-check-modal",
        components: {
            layoutMain,
            multiNotice,
            gxSection,
            gxFormText,
            gxFormTextarea,
            gxFormSelect,
            gxTag,
            gxFormFile,
            gxButton,
            gxIconText,
            gxIcon,
            buttonWrap,
            modal,
            notice,
        },
        mixins: [CommonMixin],
        props: {
            userAccount: {
                type: Object,
                "default": function() {
                    return {}
                }
            },
            editData: {
                type: Object,
                "default": function() {
                    return new EditComment({
                        content: "",
                        status: Const.support.status.USER.value,
                        internal_status: "",
                        ticket_file_set: [],
                        ticket_tag_set: [],
                        title: "",
                    })
                },
            },
            internalStatus: {
                type: Array,
                default: () => []
            },
            tags: {
                type: Array,
                "default": undefined,
            },
            addrList: {
                type: Array,
                default: () => []
            },
        },
        data() {
            return {
                Const: Const,
                data: {
                    support: Const.support,
                    // @TODO: イベント時のデフォルト値
                    editData: new TicketCreateParam({
                        status: Const.support.status.EGSS.value,
                    }),
                },
                isLoading: true,
                isLoadError: false,
            }
        },
        computed: {
            ...mapGetters([
                'userAccount'
            ]),
        },
        methods: {
            ...mapActions([
                'fetchUserAccount'
            ]),
            reload() {
                this.fetchUserAccount()
            },
            async onSave() {
                try {
                    this.isLoading = true
                    for (let i in this.addrList) {
                        const addr = this.addrList[i]
                        this.editData.ticket_mail_user_set.push({
                            account: addr.id,
                        })
                    }
                    await supportService.register(this.editData)
                    this.$store.dispatch('openToast', {type: 'success', label: i18n.t('保存しました')})
                    this.$router.push('/support')
                } catch(err) {
                    this.$store.dispatch('openToast', {type: 'error', label: i18n.t('保存に失敗しました')})
                    this.errors = err.response.data
                } finally {
                    this.isLoading = false
                    this.closeModal()
                }
            },
            closeModal() {
                this.$store.dispatch('closeModal')
            },        
            buttons() {
                return [
                    {
                        text: i18n.t('OK'),
                        type: 'primary',
                        size: 'large',
                        onClick: 'submit'
                    },
                    {
                        text: i18n.t('キャンセル'),
                        type: 'common',
                        size: 'large',
                        onClick: 'cancel'
                    },
                ]
            },
            getSelectorValues(options) {
                return i18n.translateOptionValues(options)
            },        
            /** 送信前の確認 **/
            async onEditCommentCheck() {
                this.$store.dispatch('openModalWithParam', {target: 'support-register-check-modal'})
            },  
            manual(){
  		        return '/manual/wiki/Support_new_' + i18n.locale
  		    },  
        },
        mounted() {
            if (this.$route.query.event_id) {
                this.$data.data.editData.waf_event = this.$route.query.event_id
            }
        },
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .notice {
        margin-bottom: $notice-margin;
    }
    .ticket_info {
        background-color: $color-parts-bg;
        border-radius: 15px;

        .item {
            padding: 0.5em 1em;
            display:flex;
            flex-flow: column;
            flex-direction: row;
            align-items: center;
            
            .label {
                font-weight: bold;
                width: 7em;
            }
            .data {
                flex: 1;
            }
        }

        .status {
            width: 300px;
        }

        .email {
            ul {
                display: inline-flex;
                flex-wrap: wrap;
                flex-direction: row;
                align-items: center;
                vertical-align: middle;

                height: 40px;

                li{
                    float: left;

                    .selected_mail_addr_tag {
                        font-size: 1em;
                    }
                }
            }
        }
        .title {
            display: flex;
            .data {
                .wrap {
                    max-width: inherit;
                }
            }
        }
        .content {
            display: flex;
            .data {
                .wrap {
                    max-width: inherit;
                    pointer-events: none;
                }
            }
        }
    }
</style>
