<template>
    <validation-observer ref="commentEditForm" v-slot="{ invalid }">
        <notice v-if="editComment.status==Const.support.status.CLOSE.value" class="notice" :type="info" :label="$t('この内容でクローズしますか？')" />
        <notice v-else class="notice" :type="info" :label="$t('この内容で送信しますか？')" />
        <multi-notice v-if="errors" type="error" :terms="terms" :messages="setmessages(errors)" :change="setinternalstatus()"  />
        <modal :size="'large'" @cancel="closeModal">
            <gx-section>
                <div class="status_title">
                    {{$t("タイトル") + " : "}}
                    {{title}}
                </div>
                <div v-if="userAccount.is_support"  class="status_edit">
                    {{$t("グループ名") + " : "}}
                    {{groupName}}
                </div>
                <div class="status_edit">
                     <gx-form-select
                        v-model="editComment.status"
                        :options="getSelectorValues(Const.support.statusArray)"
                        value-attr="value"
                        str-attr="str"
                        class="actions__item"
                        :prefix="$t('ステータス')"
                        required
                        disabled=false
                    />
                </div>
                <div v-if="userAccount.is_support" class="status_edit">
                     <gx-form-select
                        v-model="editComment.internal_status"
                        :options="internalStatus"
                        value-attr="id"
                        str-attr="internal_status"
                        class="actions__item"
                        :prefix="$t('内部ステータス')"
                        required
                        disabled=false
                    />
                </div>
                <div  class="status_edit">
                    {{$t("内容")}}
                    <div class="content_edit">
                        {{editComment.content}}
                    </div>
                </div>
                <div v-if="userAccount.is_support && Array.isArray(tags)" class="tag_edit">
                    <div v-if="tags.length > 0">
                    タグ
                    </div>
                    <div class="tag">
                        <span class="selected_tags" v-for="tag in tags" :key="tag.tag_id">
                            <gx-tag
                                :text="tag.tag_name"
                            />
                        </span>
                    </div>
                </div>
                <div v-if="(editComment.comment_file_set.length > 0)" v-for="(file, index) in editComment.comment_file_set" :key="index" class="file">
                    <div class = labels>
                    ファイル
                    </div>
                    {{file.file_name}} … {{Math.ceil(file.file.size / 1024)}}[KB]  
                    <small>
                        <gx-icon :tag="'a'" href="#" :size="1.2" :icon="'close'"/>
                    </small>
                </div>
                <button-wrap :buttons="buttons(invalid)" class="button-wrap" @submit="onEditCommentSubmit" @cancel="cancel" />
                <div class="manual">
                    <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
                </div>  
            </gx-section>
        </modal>
    </validation-observer>
</template>
<script>
    import Const from '@/static/constants'
    import i18n from '@/i18n'

    import {
        TicketTag,
        EditComment, EditCommentFile,
    } from '@/services/supportService'

    import multiNotice from '@/components/molecules/multi-notice'
    import gxSection from '@/components/atoms/section'
    import gxFormText from '@/components/atoms/form/text'
    import gxFormTextarea from '@/components/atoms/form/textarea'
    import gxFormSelect from '@/components/atoms/form/select'
    import gxFormFile from '@/components/atoms/form/file'
    import gxButton from '@/components/atoms/button'
    import gxIconText from '@/components/atoms/icon-text'
    import gxTag from '@/components/atoms/tag'
    import buttonWrap from '@/components/molecules/button-wrap'
    import modal from '@/components/molecules/modal'
    import ManualService from '@/services/manualService'
    import notice from "@/components/molecules/notice"

    export default {
        name: "support-comment-check-modal",
        components: {
            multiNotice,
            gxSection,
            gxFormText,
            gxFormTextarea,
            gxFormSelect,
            gxFormFile,
            gxButton,
            gxIconText,
            gxTag,
            buttonWrap,
            modal,
            notice,
        },
        props: {
            userAccount: {
                type: Object,
                "default": function() {
                    return {}
                }
            },
            errors: {
                type: Object,
                "default": function() {
                    return {}
                }
            },
            editComment: {
                type: Object,
                "default": function() {
                    return new EditComment({
                        content: "",
                        status: Const.support.status.USER.value,
                        comment_file_set: [],
                    })
                },
            },
            title: {
                type: Array,
                default: () => []
            },
            groupName: {
                type: Array,
                default: () => ""
            },
            tags: {
                type: Array,
                "default": undefined,
            },
            internalStatus: {
                type: Array,
                default: () => []
            },
            buttonDisabled: {
                type: Boolean,
                "default": false,
            },
        },
        data: function() {
            return {
                Const: Const,
                terms: Const.support.messageModalTerms,
                editTag: "",
            }
        },
        computed: {
        },
        methods: {
            buttons(disabled) {
                let d = disabled
                if (this.editComment.content.length <= 0) {
                    d = true
                }
                if (!d && this.$refs.commentFiles) {
                    // トータル3MB以上はNG
                    d = Math.ceil(this.$refs.commentFiles.size / 1024) > 3000
                }
                if (!d && this.buttonDisabled) {
                    d = true
                }
                return [
                    {
                        text: i18n.t('送信'),
                        type: 'primary',
                        size: 'large',
                        disabled: d,
                        onClick: 'submit',
                    }, {
                        text: i18n.t('キャンセル'),
                        type: 'common',
                        size: 'large',
                        onClick: 'cancel',
                    }
                ]
            },
            onEditCommentSubmit() {
                this.editComment.ticket_tag_set = Array.from(this.tags)
                this.$emit('submit', this.editComment)
            },
            cancel(){
                this.$emit('cancel', this.editComment)
            },            
            manual(){
                return '/manual/wiki/Support_comment_' + i18n.locale
            },   
            getSelectorValues(options){
                return i18n.translateOptionValues(options)
            }, 
            setinternalstatus(){
                if(this.editComment.status == Const.support.status.CLOSE.value){
                    this.editComment.internal_status = Const.support.internalStatus.CLOSE.value
                }
            },
            setmessages(errors){
                // 内容のエラーメッセージのみ階層が違うため、データを抽出して表示させる
                var error = errors[`comment_set`]
                if(error){
                    return error[0]
                }else{
                    return errors
                }
            }
        },
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .notice {
        margin-bottom: $notice-margin;
    }
    .setting_value ::v-deep {
        display: block;
        pointer-events: none;

        .input {
            max-width: 100%;
        }
        .field {
            max-width: 100%;
        }
    }
    .selected_tags {
        padding: 0.5em 0.25em;
    }
    .status_title{
        font-size: 1.25em;
        padding: 0.5em 0.25em;
    }
    .status_edit{
        padding: 0.5em 0.25em;
    }
    .item {
        display: inline-block;
        padding: 0.5em;
        margin: 0.25em;
        border-radius: 10px;

        .label {
            display: inline-block;
            width: 8em;
            font-weight: bold;
        }
        .data {
            display: inline-block;
            flex: 1;
        }
    }
    .file {
        font-size: .75em;
    }
    .labels {
        font-size: 1.25em;
    }
    .content_edit{
        white-space: pre-line;
        border-radius: 6px;
        border: 1px solid $color-border;
        padding: 0 16px 24px 16px;
    }
</style>