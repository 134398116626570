const IP_REGEX = String.raw`(?:(?:[1-9]?\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(?:[1-9]?\d|1\d{2}|2[0-4]\d|25[0-5])`
const HOST_REGEX = String.raw`(?!${IP_REGEX}$)(?!-)[a-z\d-]{1,63}(?<!-)(?:\.(?!-)[a-z\d-]{1,63}(?<!-))+`
const PORT_REGEX = String.raw`(?:\d|[1-9]\d{1,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])`

export const pattern = Object.freeze({
    HOST: `^${HOST_REGEX}$`,
    REALSERVERS: `^((${HOST_REGEX}|(${IP_REGEX}(?::${PORT_REGEX})?)?)\n?){0,5}$`,
    WILDCARD_HOST: String.raw`^(?:\*\.)?${HOST_REGEX}$`,
    HOST_SEARCH: String.raw`^[a-z\d.-]{0,100}$`,
    IP_ADDRESS: `^${IP_REGEX}$`,
    IP_ADDRESS_PORT: `^(${IP_REGEX}(?::${PORT_REGEX})?)$`,
    IP_ADDRESS_CIDR: String.raw`^((${IP_REGEX}(?:/(?:\d|[1-2]\d|3[0-2]))?\n?)){1,30}$`,
    IP_ADDRESS_SEARCH: String.raw`^[\d.]{0,15}$`,
    USER_NAME: String.raw`^[a-zA-Z\d_@.+\-]+$`,
    TEL: String.raw`^\d{9,13}$`,
    PASSWORD: String.raw`^[!-~]{8,128}$`,
    URL_PATH: String.raw`^(?:(?<!/)/(?:[a-zA-Z\d._\-]|%[a-fA-F\d]{2})*)+(?:(?<!/)/)?$`,
    REGEX: String.raw`^[ -~]*$`,
    USER_AGENT: String.raw`^[\w !()/.,:;+-]+$`,
    ID: String.raw`^[0-9\-]+$`,
    TAG: String.raw`^[a-zA-Z0-9_|\-:]+$`,
})

export const patternType = Object.freeze({
    HOST: '例：www.example.com',
    REALSERVERS: '例：192.168.0.1:80 もしくは 例：www.example.com',
    // TODO: フロントでの出し分けができたらコメントアウト解除
    // WILDCARD_HOST: '例：www.example.com / *.example.com',
    WILDCARD_HOST: '例：www.example.com',
    HOST_SEARCH: '例：www.example.com',
    IP_ADDRESS: '例：192.168.0.1',
    IP_ADDRESS_PORT: '例：192.168.0.1:80',
    IP_ADDRESS_CIDR: '例：192.168.1.0/24',
    IP_ADDRESS_SEARCH: '例：192.168.0.1',
    USER_NAME: '例：SG-user001',
    TEL: '例：0300000000',
    URL_PATH: '例: /hoge/fuga',
    REGEX: '例: ^.+$',
    USER_AGENT: '例: Googlebot/2.1 (+http://www.google.com/bot.html)'
})
