<template>
    <div class="table-actions">
        <!-- <p class="">
            <span class="table-actions__all-select"><gx-form-checkbox @check="allCheck">全選択</gx-form-checkbox></span>
            <span class="table-actions__selected">{{pager.count ? pager.count : 0}}件中<em class="table-actions__now">{{checkCount}}件</em>選択中</span>
        </p> -->
        <p>
            <gx-icon-text
                :tag="'a'"
                :text="downloadText"
                :icon="'download'"
                class="pointer"
                @onClick="$emit('onClick')"
            />
        </p>
    </div>
</template>

<script>
    // import gxFormCheckbox from '@/components/atoms/form/checkbox'
    import gxIconText from '@/components/atoms/icon-text'

    import i18n from '@/i18n'

    export default {
        name: 'table-actions',
        components: {
            // gxFormCheckbox,
            gxIconText
        },
        props: {
            pager: {
                type: Object,
                required: true
            },
            downloadText: {
                default: i18n.t('ダウンロード'),
                type: String,
            },
            // TODO:
            checkCount: {
                type: Number,
                required: true,
                default: 0
            }
        },
        methods: {
            allCheck(ev) {
                this.$emit("allCheck", ev.target.checked)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';

    .table-actions {
        display: flex;
        // justify-content: space-between;
        justify-content: flex-end;
        margin-bottom: 16px;

        &__all-select {
            padding-right: 16px;
            margin-right: 16px;
            border-right: 1px solid $color-border;
        }
        &__selected {

        }
        &__now {
            font-weight: bold;
            margin: 0 4px;
        }
    }
    .pointer {
        cursor: pointer;
    }
</style>
