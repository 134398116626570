<template>
    <layout-main
        :title="$t('ダッシュボード')"
        :navs="navs"
    >
        <template #default>
            <template v-if="isLoading"><loading /></template>
            <template v-else>
                <template v-if="!userAccount.has_website">
                    <div class="dashboard nodata">
                        <section class="dashboard-box news">
                            <h2 class="dashboard-box__title">
                                {{$t('お知らせ')}}
                                <router-link :to="`/news`" class="dashboard-box__title__link">{{$t('一覧へ')}}</router-link>
                            </h2>
                            <div class="dashboard-box__body">
                                <div class="dashboard-news">
                                    <news-list v-if="news.length" :list="news" class="dashboard-news__list" />
                                    <p v-else class="dashboard-news__empty">
                                        {{$t('お知らせはありません')}}
                                    </p>
                                </div>
                            </div>
                        </section>
                        <section class="dashboard-box group">
                            <template v-if="isShowedWithNoData">
                                <gx-icon-text class="dashboard-box__title toggle-button"
                                    :text="$t('{0}を閉じる', [$t('WAF導入時設定情報')])"
                                    icon="arrow-down" tag="h2" @onClick="toggleBoxWithNoData" 
                                />
                            </template>
                            <template v-else>
                                <gx-icon-text class="dashboard-box__title toggle-button"
                                    :text="$t('{0}を開く', [$t('WAF導入時設定情報')])"
                                    icon="arrow-up" tag="h2" @onClick="toggleBoxWithNoData" 
                                />
                            </template>
                            <div class="dashboard-box__body">
                                <template v-if="isShowedWithNoData">
                                    <dashboard-group-table :group="group" />
                                </template>
                            </div>
                        </section>
                    </div>
                    <initial-box
                        :label="$t('ウェブサイトが設定されていません')"
                        :lead="$t('保護するウェブサイトを設定してください')"
                        :buttons="initialButtons"
                        @onClick="toWebsite"
                    />
                </template>
                <template v-else>
                    <notice v-if="isLoadError" :type="'error'" :label="$t('データが取得出来ませんでした')" class="notice" />
                    <div class="dashboard">
                        <section class="dashboard-box group">
                            <template v-if="isShowedWithNoData">
                                <gx-icon-text class="dashboard-box__title toggle-button"
                                    :text="$t('{0}を閉じる', [$t('WAF導入時設定情報')])"
                                    icon="arrow-down" tag="h2" @onClick="toggleBoxWithNoData" 
                                />
                            </template>
                            <template v-else>
                                <gx-icon-text class="dashboard-box__title toggle-button"
                                    :text="$t('{0}を開く', [$t('WAF導入時設定情報')])"
                                    icon="arrow-up" tag="h2" @onClick="toggleBoxWithNoData" 
                                />
                            </template>
                            <div class="dashboard-box__body">
                                <template v-if="isShowedWithNoData">
                                    <dashboard-group-table :group="group" />
                                </template>
                            </div>
                        </section>

                        <section class="dashboard-box transferred">
                            <h2 class="dashboard-box__title">{{$t('転送量')}}</h2>
                            <div class="dashboard-box__body">
                                <dashboard-transferred-table 
                                    :transfer="transferred" 
                                    :plan_volume="planvolume"
                                    :plan_unit="planunit"
                                />
                            </div>
                        </section>
                        <section class="dashboard-box chart">                        
                            <h2 class="dashboard-box__title">{{$t('パフォーマンスモニター')}}</h2>
                            <notice v-if = "perfomanceErrorFlg" :type="'error'" :label="$t('データが取得出来ませんでした')"/>
                            <div class="dashboard-box chart">
                                <Chart 
                                  :width="1136"
                                  :height="600"
                                  :chartdata="chartdata"
                                />
                            </div>
                        </section>                    
                        <section class="dashboard-box attack-country">
                            <h2 class="dashboard-box__title">{{$t('攻撃元国別 TOP5')}}</h2>
                            <div class="dashboard-box__body">
                                <CountryBar
                                    :width="800"
                                    :height="330"
                                    :countrybardata="countryRankingList"
                                />
                            </div>
                        </section>
                        <section class="dashboard-box attack-ip">
                            <h2 class="dashboard-box__title">{{$t('攻撃元IP TOP5')}}</h2>
                            <div class="dashboard-box__body">
                                <IPBar
                                    :width="800"
                                    :height="330"
                                    :IPbardata="ipRankingList"
                                />
                            </div>
                        </section>
                        <section class="dashboard-box attack-map">
                            <div class="attack-map-title">
                                <h2 class="dashboard-box__title">{{$t('攻撃元マップ')}}</h2>
                                <div><gx-form-switch :off="$t('3D')" :on="$t('2D')" @change="updateValue($event ? true : false)" class="toggle" /></div>
                            </div>
                            <div class="dashboard-box__body">
                                <dashboard-world-map-2d v-if="is2D" :data="events"
                                    :width="400"
                                    :height="400"
                                />
                                <dashboard-world-map-3d v-else :data="events"
                                    :width="400"
                                    :height="400" 
                                />
                            </div>
                        </section>
                        <section class="dashboard-box news">
                            <h2 class="dashboard-box__title">
                                {{$t('お知らせ')}}
                                <router-link :to="`/news`" class="dashboard-box__title__link">{{$t('一覧へ')}}</router-link>
                            </h2>
                            <div class="dashboard-box__body">
                                <div class="dashboard-news">
                                    <news-list v-if="news.length" :list="news" class="dashboard-news__list" />
                                    <p v-else class="dashboard-news__empty">
                                        {{$t('お知らせはありません')}}
                                    </p>
                                </div>
                            </div>
                        </section>
                        <section class="dashboard-box quick">
                            <h2 class="dashboard-box__title">{{$t('クイックステータス')}}</h2>
                            <div class="dashboard-box__quickbody">
                                <router-link :to="`/event`">
                                    <quickstatus :type="'event'" :label="$t('{0}件', [countevents()])" :unit="$t('直近24時間攻撃')" :cnt="countevents()" >
                                        <div>
                                            {{$t('イベント一覧へ')}}
                                        </div>
                                    </quickstatus>
                                </router-link>

                                <router-link :to="`/support`">
                                    <quickstatus :type="'support'" :label="$t('{0}件', [Ticketcnt])" :unit="$t('未クローズチケット')" :cnt="Ticketcnt">
                                        <div>
                                            {{$t('サポートチケット一覧へ')}}
                                        </div>
                                    </quickstatus>
                                </router-link>

                                <router-link :to="`/website`">
                                    <quickstatus :type="'website'" :label="$t('{0}件', [Websitecnt])" :unit="$t('ウェブサイト更新失敗件数')" :cnt="Websitecnt" >
                                        <div>
                                            {{$t('ウェブサイト一覧へ')}}
                                        </div>
                                    </quickstatus>
                                </router-link>

                                <router-link :to="`/certificate`">
                                    <quickstatus :type="'certificate'" :label="$t('{0}件', [Certificatecnt])" :unit="$t('有効期限が近い証明書件数')" :cnt="Certificatecnt" >
                                        <div>
                                            {{$t('証明書一覧へ')}}
                                        </div>
                                    </quickstatus>
                                </router-link>
                            </div>
                        </section>
                        <section class="dashboard-box report">
                            <h2 class="dashboard-box__title">{{$t('月次レポート')}}</h2>
                            <div class="report" v-for="item in months" :key="item.id" :target="item">
                                <gx-form-radio
                                    :name="'month'"
                                    :text=item.text
                                    :value="item.value"
                                    :checked="item.value === months[0].value"
                                    @change="setreportMonth(item.value)"
                                />
                            </div>
                            <gx-icon-text
                                class="report__submit"
                                :text="$t('レポートダウンロード')" icon="download" tag="a" @onClick="downloadReport" />
                        </section>
                    </div>
                </template>
            </template>
            <div class="manual">
                <a :href=manual()  target="_blank">{{$t('マニュアル')}}</a>
            </div>     
        </template>
    </layout-main>
</template>

<script>
    import layoutMain from '@/layouts/main'
    import loading from '@/components/atoms/loading'
    import gxIconText from '@/components/atoms/icon-text'
    import gxFormSwitch from '@/components/atoms/form/switch'
    import gxFormInput from '@/components/atoms/form/input'
    import gxFormRadio from '@/components/atoms/form/radio'
    import initialBox from '@/components/molecules/initial-box'
    import notice from '@/components/molecules/notice'
    import quickstatus from '@/components/molecules/quickstatus'
    import dashboardSeverityGraf from '@/components/organisms/dashboard/severity-graf'
    import dashboardTransferredTable from '@/components/organisms/dashboard/transferred-table'
    import dashboardGroupTable from '@/components/organisms/dashboard/group-table'
    import dashboardWorldMap2d from '@/components/organisms/dashboard/world-map-2d'
    import dashboardWorldMap3d from '@/components/organisms/dashboard/world-map-3d'
    import newsList from '@/components/organisms/news/list'
    import Chart from '@/components/organisms/dashboard/chart'
    import IPBar from '@/components/organisms/dashboard/IPbar'
    import CountryBar from '@/components/organisms/dashboard/countrybar'

    import DashboardService from '@/services/dashboardService'
    import NewsService from '@/services/newsService'

    import DateMixin from '@/mixin/date'
    import dayjs from "dayjs"
    import DownloadMixin from '@/mixin/download'

    import { mapGetters } from 'vuex'
    import { supportService } from '@/services/supportService'
    import websiteService from '@/services/websiteService'
    import { certificateService } from '@/services/certificateService'

    import Const from '@/static/constants'
    import i18n from '@/i18n'
    import ManualService from '@/services/manualService'
    import M from 'minimatch'

    export default {
        name: 'dashboard',
        mixins: [DateMixin, DownloadMixin],
        components: {
            layoutMain,
            loading,
            gxIconText,
            gxFormSwitch,
            gxFormInput,
            gxFormRadio,
            initialBox,
            notice,
            quickstatus,
            dashboardSeverityGraf,
            dashboardTransferredTable,
            dashboardGroupTable,
            dashboardWorldMap2d,
            dashboardWorldMap3d,
            newsList,
            Chart,
            CountryBar,
            IPBar,
        },
        data() {
            return {
                data: {},
                performancedata: {},
                internalReportMonth: null,
                news: [],
                isLoading: true,
                isLoadError: false,
                is2D: false,
                isShowedWithNoData: true,
                isShowed: false,
                perfomanceErrorFlg: false,
                chartdata: [],
                bardata: [],
                Ticketcnt: 0,
                Websitecnt: 0,
                Certificatecnt: 0
   
            }
        },
        computed: {
            ...mapGetters([
                'userAccount'
            ]),
            now() {
                return this.userId ? this.toLocalDate() : this.$moment.utc()
            },
            navs() {
                return []
            },
            initialButtons() {
                return [
                    {
                        text: i18n.t('ウェブサイト新規設定'),
                        type: 'primary',
                        size: 'large',
                        onClick: "onClick"
                    }
                ]
            },
            events() {
                return this.data.visualize
            },
            transferred(){
                return this.data.transferred_data
            },
            planvolume(){
                return this.data.group_info.plan_volume
            },
            planunit(){
                return Const.dashboard.plan[this.data.group_info.plan_unit].label
            },
            ipRankingList() {
                return this.data.ip_ranking.length ? this.data.ip_ranking : ""
            },
            countryRankingList() {
                return this.data.country_ranking.length ? this.data.country_ranking : ""
            },
            group() {
                return this.data.group_info
            },
            userId() {
                return this.$store.state.userAccount.id
            },
            months() {
                let months = []
                months = [{
                    'value': dayjs().subtract(1, 'months').format("YYYY-MM") ,
                    'text' : dayjs().subtract(1, 'months').format("YYYY年MM月") 
                }, {
                    'value': dayjs().subtract(2, 'months').format("YYYY-MM") ,
                    'text' : dayjs().subtract(2, 'months').format("YYYY年MM月") 
                }, {
                    'value': dayjs().subtract(3, 'months').format("YYYY-MM") ,
                    'text' : dayjs().subtract(3, 'months').format("YYYY年MM月") 
                }, 
                ]
                this.reportMonth = dayjs().subtract(1, 'months').format("YYYY-MM")
                return months
            },
        },
        watch: {
            userId() {
                this.fetch()
            }
        },
        methods: {
            reload(){
                this.fetch()
            },
            async fetch() {
                this.isLoading = true
                await this.fetchEventList()
                await this.fetchNewsList()
                await this.fetchSupportList()
                await this.fetchWebsiteList()
                await this.fetchCertificateCnt()
                await this.Paformance()
                this.isLoading = false

            },
            async fetchEventList() {
                this.isLoadError = false
                const params = this.listRequestParams()
                this.data = await DashboardService.fetchEventList(params).catch(err => {
                    Object.clone(err)
                    this.isLoadError = true
                    return {}
                })
            },
            async fetchNewsList() {
                this.isLoadError = false
                const params = this.listRequestParams()
                this.news = await NewsService.fetchList(params).then(res => {
                    return res.results
                }).catch(err => {
                    Object.clone(err)
                    this.isLoadError = true
                    return []
                })
            },
            async fetchSupportList() {
                this.isLoadError = false
                let params = ["",1,1000]
                let cnt = 0
                let res = await supportService.list(params)
                for (let i in res.results) {
                    if(res.results[i].status == Const.support.status.USER.value){
                        cnt ++
                    }
                }
                this.Ticketcnt = cnt
            },
            async fetchWebsiteList() {
                this.isLoadError = false
                let params = {o:"", page:1, page_size:1000}
                let cnt = 0
                await websiteService.fetchList(params).then(res => {
                    for (let i in res.results) {
                        if(res.results[i].state == Const.website.state['FAILURE']){
                            cnt ++
                        }
                    }
                })
                this.Websitecnt = cnt
            },
            async fetchCertificateCnt() {
                this.isLoadError = false
                let res = await certificateService.getExpiredCnt()
                this.Certificatecnt = res.cert_count
            },
            setmonth(key, val) {
                this.$set(this.editData, key, val)
            },
            listRequestParams() {
                const endDate = this.now.clone().add(1, 'hours').minute(0).second(0)
                const startDate = endDate.clone().subtract(1, 'days')
                return {
                    event_start_time: this.formatLocalTimeZone(startDate),
                    event_end_time: this.formatLocalTimeZone(endDate)
                }
            },
            async downloadReport() {
                await this.download(DashboardService.downloadReport({month: this.reportMonth}), 'report')
            },
            updateValue(val) {
                this.is2D = val
            },
            manual(){
                return '/manual/wiki/Dashboard_' + i18n.locale
            },   
            toWebsite() {
                this.$router.push('/website/register')
            },
            toggleBoxWithNoData() {
                this.isShowedWithNoData = !this.isShowedWithNoData
            },
            toggleBox() {
                this.isShowed = !this.isShowed
            },
            async Paformance() {
                    this.performancedata = await DashboardService.getPerformance().catch(err => {
                        Object.clone(err)
                        this.isLoadError = true
                        return {}
                    })
                this.chartdata = this.performancedata.performance_data
                if(this.performancedata.performance_data[0].date == "1900-01-00-00"){
                    this.perfomanceErrorFlg = true
                }
            },
            setreportMonth(val){
                this.reportMonth = val
            },
            countevents(){
                var cnt = 0
                for (let event of this.events) {
                    cnt = cnt + event.count;
                }
                return cnt
            }

        },
        created() {
            if(this.userId) {
                this.fetch()
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '~@/assets/scss/_variable.scss';
    .report {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__input {
            display: flex;
            align-items: center;
            &__label {
                display: flex;
                align-items: center;
                &__text {
                    margin-right: 8px;
                }
                &__month {
                    width: 250px;
                }
            }
        }
        &__submit {
            cursor: pointer;
        }
    }

    .dashboard {
        width: 100%;
        overflow: hidden;

        display: grid;
        grid-gap: 24px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-areas:
        "news        news       news           news "
        "quick       quick      quick          report "
        "attack-map  attack-map attack-country attack-country "
        "attack-map  attack-map attack-ip      attack-ip "
        "chart       chart      chart          transferred "
        "group   group .  .  "
        ;
        @include mq(sm) {
            grid-template-areas:
                "news"
                "news"
                "quick"
                "quick"
                "report"
                "attack-map"
                "attack-country"
                "attack-ip"
                "chart"
                "chart"
                "transferred"
                "group"
            ;
        }
        @include mq(md) {
            grid-template-areas:
            "news        news       news           news "
            "quick       quick      quick          report "
            "attack-map  attack-map attack-country attack-country "
            "attack-map  attack-map attack-ip      attack-ip "
            "chart       chart      chart          transferred "
            "group   group .  .  "
            ;
        }
        @include mq(xl) {
            grid-template-areas:
            "news        news       news           news "
            "quick       quick      quick          report "
            "attack-map  attack-map attack-country attack-country "
            "attack-map  attack-map attack-ip      attack-ip "
            "chart       chart      chart          transferred "
            "group   group      .    ."
            ;
        }

        &.nodata {
            grid-template-areas:
            "news    news       news     news"
            "group   group      group    group"
            ;
        }

        &-box {
            background-color: $color-parts-bg;

            display: flex;
            flex-direction: column;
            width: 100%;
            overflow: hidden;

            &.transferred {
                grid-area: transferred;
            }
            &.group {
                grid-area: group;
            }
            &.attack-ip {
                grid-area: attack-ip;
            }
            &.attack-country {
                grid-area: attack-country;
           }
            &.attack-map {
                grid-area: attack-map;
            }
            &.news {
                grid-area: news;
                overflow: hidden;
            }
            &.quick {
                grid-area: quick;
            }
            &.chart {
                grid-area: chart;
            }
            &.report {
                grid-area: report;
            }

            &__title {
                width: 100%;
                height: 32px;
                line-height: 32px;
                padding: 0 16px;
                font-size: 1.6rem;
                font-weight: bold;
                background: $color-parts-accent;
                &__link {
                    float: right;
                }
            }
            &__body {
                flex: 1;

                display:flex;
                flex-direction: row;
                flex-wrap: nowrap;
            }
            &__quickbody {
                display:grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
            .attack-map-title {
                display: flex;
                justify-content: space-between;
                background: $color-parts-accent;
            }
        }
        &-graf {
            padding: 16px;
        }
    }
    .toggle {
        margin-right: 16px;
        vertical-align: text-top;
    }
    .dashboard-news {
        width: 100%;
        background: $color-parts-bg;

        &__empty {
            padding: 24px;
        }
    }
    .toggle-button {
        cursor: pointer;
    }
 
</style>