<template>
    <div class="no-data">
        {{$t('検索結果はありません')}}
    </div>
</template>

<script>
    export default {
        name: 'nodata-box'
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .no-data {
        background-color: $color-parts-bg;
        padding: 40px;
        text-align: center;
        font-size: 2.2rem;
        font-weight: bold;

        &::before {
            @include icon(search);
            display: block;
            margin: 0 auto 40px;
            font-size: 18rem;
            line-height: 1;
            color: $color-icon;
        }
    }
</style>