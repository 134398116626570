import api from '@/services/api'

export default {
    API_ENDPOINT: '/api/accounts/',
    userInfo() {
        return api.get(`${this.API_ENDPOINT}me/`)
            .then(response => response.data)
    },
    fetchDetail(uuid) {
        return api.get(`${this.API_ENDPOINT}${uuid}/`)
            .then(response => response.data)
    },
    fetchList(params) {
        return api.get(this.API_ENDPOINT, { params: params })
            .then(response => response.data)
            .catch(error => error.response)
    },
    regist(params) {
        return api.post(this.API_ENDPOINT, params)
            .then(response => response.data)
    },
    update(uuid, params) {
        return api.patch(`${this.API_ENDPOINT}${uuid}/`, params)
            .then(response => response.data)
    },
    delete(uuid) {
        return api.delete(`${this.API_ENDPOINT}${uuid}/`)
            .then(response => response.data)
    },
    // Groups
    fetchGroups(params) {
        return api.get(`${this.API_ENDPOINT}group/`, {params: params})
            .then(response => response.data)
    },
    changeGroups(params) {
        return api.patch(`${this.API_ENDPOINT}group/`, params)
            .then(response => response.data)
    },
}
