<template>
    <component :is="tag" :class="['icon', icon, {'is-disabled' : disabled}]" :style="styles" :disabled="disabled" @click.prevent="disabled ? false : $emit('onClick')">{{text}}</component>
</template>

<script>
    import Const from '@/static/constants'
    export default {
        name: 'gx-icon',
        props: {
            tag: {
                type: String,
                default: 'span'
            },
            text: {
                type: String,
                required: true
            },
            icon: {
                type: String,
                default: ''
            },
            size: {
                type: Number,
                default: 1.8
            },
            color: {
                type: String,
                default: Const.common.colors.ICON
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            styles() {
                return {
                    '--size': `${this.size}rem`,
                    '--color': this.color
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .icon {
        font-size: 0;
        text-decoration: none;

        &::before {
            color: var(--color);
            font-size: var(--size);
            text-decoration: none;
        }
        &.is-disabled {
            opacity: $opacity-disabled;
            cursor: not-allowed;
        }
        @each $icon, $code in $gx-icons {
            &.#{$icon}::before {
                @include icon($icon);
            }
        }
    }
</style>