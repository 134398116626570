<template>
    <body id="app">
        <router-view />
    </body>
</template>

<script>
export default {
    name: 'app',
    watch: {
        '$route'(to) {
            if (!to.meta.allowAnonymous) {
                this.$store.dispatch('fetchUserAccount')
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';
</style>
