import { render, staticRenderFns } from "./commentcheckModal.vue?vue&type=template&id=7a2dd4cd&scoped=true&"
import script from "./commentcheckModal.vue?vue&type=script&lang=js&"
export * from "./commentcheckModal.vue?vue&type=script&lang=js&"
import style0 from "./commentcheckModal.vue?vue&type=style&index=0&id=7a2dd4cd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a2dd4cd",
  null
  
)

export default component.exports