<template>
    <layout-main
        :title="$t(title)"
        :navs="navs"
    >
        <template #page-header>
            <gx-icon v-if="!userAccount.is_support || userAccount.role == 80" :tag="'a'" :text="$t('削除')" :icon="'trash'" @onClick="deleteWebsite" href="#" :disabled="!isDeletable" />
        </template>
        <template #default>
            <tab-nav :navs="tabNavs" @changeTab="changeTab" />

            <website-basic
                v-if="$route.name == paths.WEBSITE"
                :data="websiteDetail"
                :certificateList="certificateList"
                :is-loading="isDetailLoading"
                :is-load-error="isDetailLoadError"
                :is-editable="isEditable"
                @update="updateWebsiteDetail" />
            <website-access
                v-else-if="$route.name == paths.WEBSITE_ACCESS_CTRL"
                ref="access"
                :is-editable="isEditable" />
            <website-waf-custom-list
                v-else-if="$route.name == paths.WEBSITE_WAFCUS"
                :limit="limitWafcus" :is-editable="isEditable"
                :is-updated-wafcus="isUpdatedWafcus"
                @updateIsUpdatedWafcus="updateIsUpdatedWafcus"/>
            <website-waf-custom-register v-else-if="$route.name == paths.WEBSITE_WAFCUS_REGISTER" />
            <website-waf-custom-detail
                v-else-if="$route.name == paths.WEBSITE_WAFCUS_DETAIL"
                :is-editable="isEditable" />
            <website-waf-trusted v-else-if="$route.name == paths.WEBSITE_WAFTRUST" :is-editable="isEditable" />

            <!-- ページを戻るリンク -->
            <page-back
                v-if="[paths.WEBSITE_WAFCUS_DETAIL, paths.WEBSITE_WAFCUS_REGISTER].includes($route.name)"
                :to="`/website/${$route.params.uuid}/waf-custom`"
                :text="$t('カスタム・シグネチャ一覧に戻る')" />
            <page-back v-else :text="$t('ウェブサイト一覧に戻る')" :to="'/website'" />
        </template>
        <template v-if="$store.state.isModal" #modal>
            <website-delete-modal v-if="$store.state.modalTarget == 'website-delete'" />
            <website-waf-custom-delete v-else-if="$store.state.modalTarget == 'website-waf-custom-delete'" @actionPostWafcusDelete="actionPostWafcusDelete" />
            <website-access-detail-modal v-else-if="$store.state.modalTarget == 'website-access-detail'" @update="data => updateFilterData(data)" @delete="data => deleteFilterData(data)" />
            <website-access-delete-modal v-else-if="$store.state.modalTarget == 'website-access-delete'" @reload="reloadByDelete" />
        </template>
    </layout-main>
</template>

<script>
    import MixinCommon from '@/mixin/common'

    import {mapGetters} from "vuex";

    import Const from '@/static/constants'
    import layoutMain from '@/layouts/main'
    import gxIcon from '@/components/atoms/icon'
    import tabNav from '@/components/molecules/tab-nav'
    import pageBack from '@/components/molecules/page-back'
    import websiteDeleteModal from '@/components/organisms/website/basic-delete-modal'
    import websiteWafCustomDelete from '@/components/organisms/website/waf-custom-delete'
    import websiteBasic from '@/components/organisms/website/basic'
    import websiteAccess from '@/components/organisms/website/access'
    import websiteAccessDeleteModal from '@/components/organisms/website/access-delete-modal'
    import websiteAccessDetailModal from '@/components/organisms/website/access-detail-modal'
    import websiteWafCustomRegister from '@/components/organisms/website/waf-custom-register'
    import websiteWafCustomDetail from '@/components/organisms/website/waf-custom-detail'
    import websiteWafCustomList from '@/components/organisms/website/waf-custom-list'
    import websiteWafTrusted from '@/components/organisms/website/waf-trusted'

    import WebsiteService from '@/services/websiteService'
    import {
        certificateService,
    } from '@/services/certificateService'



    import i18n from '@/i18n'

    export default {
        name: 'website-modify',
        mixins: [MixinCommon],
        components: {
            layoutMain,
            gxIcon,
            tabNav,
            pageBack,
            websiteDeleteModal,
            websiteBasic,
            websiteAccess,
            websiteAccessDeleteModal,
            websiteAccessDetailModal,
            websiteWafCustomRegister,
            websiteWafCustomDetail,
            websiteWafCustomList,
            websiteWafCustomDelete,
            websiteWafTrusted
        },
        data() {
            return {
                isDetailLoading: true,
                isDetailLoadError: false,
                websiteDetail: {},
                certificateList: {},
                isUpdatedWafcus: false
            }
        },
        computed: {
            ...mapGetters([
                'userAccount'
            ]),
            title() {
                if(this.websiteDetail.hostname) {
                    return `${this.websiteDetail.hostname}`
                }
                return ""
            },
            tabNavs() {
                let tabType
                switch (this.$route.name) {
                    case Const.common.paths.WEBSITE_WAFCUS:
                    case Const.common.paths.WEBSITE_WAFCUS_DETAIL:
                    case Const.common.paths.WEBSITE_WAFCUS_REGISTER:
                        tabType = Const.common.paths.WEBSITE_WAFCUS
                        break
                    default:
                        tabType = this.$route.name
                }
                if(!this.userAccount.is_support 
                   && (   this.userAccount.role == Const.accounts.roleNormal
                       || this.userAccount.role == Const.accounts.roleAdmin
                       || this.userAccount.role == Const.accounts.roleGroupAdmin)){
                    return [{
                        label: i18n.t('基本設定'),
                        current: tabType == Const.common.paths.WEBSITE,
                        tab: Const.common.paths.WEBSITE
                    }, {
                        label: i18n.t('アクセス制御'),
                        current: tabType == Const.common.paths.WEBSITE_ACCESS_CTRL,
                        tab: Const.common.paths.WEBSITE_ACCESS_CTRL
                    }]
                }else{
                    return [{
                        label: i18n.t('基本設定'),
                        current: tabType == Const.common.paths.WEBSITE,
                        tab: Const.common.paths.WEBSITE
                    }, {
                        label: i18n.t('アクセス制御'),
                        current: tabType == Const.common.paths.WEBSITE_ACCESS_CTRL,
                        tab: Const.common.paths.WEBSITE_ACCESS_CTRL
                    }, {
                        label: i18n.t('カスタム・シグネチャ'),
                        current: tabType == Const.common.paths.WEBSITE_WAFCUS,
                        tab: Const.common.paths.WEBSITE_WAFCUS
                    }, {
                        label: i18n.t('トラステッド・シグネチャ'),
                        current: tabType == Const.common.paths.WEBSITE_WAFTRUST,
                        tab: Const.common.paths.WEBSITE_WAFTRUST
                    }]
                }

            },
            navs() {
                return [{
                    to: '/website',
                    label: i18n.t('ウェブサイト')
                }, {
                    to: '/website/modify',
                    label: i18n.t('ウェブサイト詳細')
                }]
            },
            isEditable() {
                return !this.isDetailLoading && !!this.websiteDetail.is_editable
            },
            isDeletable() {
                return !this.isDetailLoading && !!this.websiteDetail.is_deletable
            },
            limitWafcus() {
                return this.websiteDetail.limit_wafcus || 0
            },
            paths() {
                return Const.common.paths
            }
        },
        methods: {
            reload() {
                this.fetchUserAccount()
            },
            async fetchWebsiteDetail() {
                this.isDetailLoading = true
                this.isDetailLoadError = false
                await WebsiteService.fetchDetail(this.$route.params.uuid).then(res => {
                    this.websiteDetail = res
                }).catch(() => {
                    this.isDetailLoadError = true
                })
                await certificateService.selectList().then(res => {
                    this.certificateList = res.results
                }).catch(() => {
                    this.isDetailLoadError = true
                })
                this.isDetailLoading = false
            },
            updateWebsiteDetail(data) {
                if (data) {
                    this.websiteDetail = data
                } else {
                    this.fetchWebsiteDetail()
                }
            },
            changeTab(name) {
                this.$router.push({name: name, uuid: this.$route.params.uuid})
            },
            deleteWebsite() {
                this.$store.dispatch('openModalWithParam', {target: 'website-delete', param: this.$route.params.uuid})
            },
            updateIsUpdatedWafcus(bool) {
                this.isUpdatedWafcus = bool
            },
            actionPostWafcusDelete() {
                this.isUpdatedWafcus = true
            },
            updateFilterData(data) {
                this.$refs.access.updateList(data)
            },
            deleteFilterData(data) {
                this.$refs.access.deleteItem(data)
            },
            reloadByDelete() {
                // 削除処理で画面が無くなる場合、一つ前のページを表示する
                if(this.$refs.access.data.list.length == 1 && this.$refs.access.requestParams.page != 1){
                    this.$refs.access.updateQuery('page', this.$refs.access.requestParams.page - 1 )
                }
                this.$refs.access.fetch()
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
</style>