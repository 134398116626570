<template>
    <validation-observer v-slot="{ invalid }">
        <notice v-if="isFormError" class="notice" :type="'error'" :label="$t('入力内容に不備があります')" >
            {{ errors.non_field_errors && errors.non_field_errors.length > 0
            ? errors.non_field_errors.join(' / ')
            : $t('メッセージを確認の上もう一度入力してください') }}
        </notice>

        <gx-section>
            <gx-title :level="3">
                <template #default>{{$t('設定内容')}}</template>
            </gx-title>

            <data-list :data="settingTerms" class="settings">
                <template #title={item}>
                    <gx-tag
                        v-if="isEdit"
                        :text="isRequired(item) ? $t('必須') : $t('任意')"
                        :type="isRequired(item) ? 'error' : 'common'"
                    />
                    {{$t(getSettingTerms(item))}}
                    <small v-if="item == 'search_str'">（{{$t('正規表現')}}）</small>
                </template>
                <template #data={item}>
                    <template v-if="item == 'name'">
                        <gx-form-text
                            v-if="isEdit"
                            v-model="editData[item]"
                            :term="$t(getSettingTerms(item))"
                            :placeholder="'custom signature name'"
                            :maxlength="29"
                            required
                            :errors="errors[item]"
                            :ref="`input-${item}`"
                            :id="'name'"
                        />
                        <template v-else>{{propData[item]}}</template>
                    </template>

                    <template v-else-if="item == 'comment'">
                        <gx-form-textarea
                            v-if="isEdit"
                            v-model="editData[item]"
                            :placeholder="'custom signature comment'"
                            class="setting_value"
                            id="comment_input"
                            :ref="`input-${item}`"
                            :maxlength="200"
                        />
                        <template v-else>{{propData[item]}}</template>
                    </template>

                    <template v-else-if="item == 'action'">
                        <template v-if="isEdit">
                            <gx-form-select
                                v-model="editData[item]"
                                :options="actionOptions"
                                class="actions__item"
                                id="action_input"
                                @input="onActionValueChanged()"
                            />
                            <!--
                            <gx-form-select
                                v-model="editData.target_rule"
                                :options="exceptionOptions"
                                :disabled="editData[item] != actions.EXCEPTION"
                                :required="editData[item] == actions.EXCEPTION"
                                :ref="'input-target_rule'"
                                class="actions__item"
                            >
                                <template v-if="editData[item] == actions.EXCEPTION" #prefix>
                                    <gx-tag
                                        :text="$t('必須')"
                                        :type="'error'"
                                    />
                                </template>
                            </gx-form-select>
                            -->

                            <div class="exc_options" v-if="doesShowExcCondOptions()">
                                <div class="does_exc_cond exc_input">
                                    <label>{{$t('条件指定')}}</label>
                                    <!-- gx-form-switchはcheckedでは親コンポーネントの再描画を行わないので、@changeでイベントを拾って値を変更する形にする -->
                                    <gx-form-switch
                                        class="does_exc_cond_select input"
                                        id="does_exc_cond_input"
                                        :checked="doesExcCondSelect"
                                        @change="(val)=>{onDoesExcCondSelectChanged(val)}"
                                    />
                                </div>
                                <div class="exc_cond">
                                    <div class="exc_select exc_input">
                                        <label>{{$t('除外対象')}}</label>
                                        <gx-form-select
                                            :name="'除外対象'"
                                            v-model="editData['exc_select']"
                                            :options="getExcSelectValues()"
                                            class="exc_select input"
                                            id="exc_select_input"
                                        />
                                        <gx-form-text
                                            :name="(editData['exc_select'] == 1) ? $t('ID') : $t('タグ')"
                                            v-model="editData['exc_value']"
                                            :placeholder="$t('除外対象')"
                                            :patterns="(editData['exc_select'] == 1) ? 'ID' : 'TAG'"
                                            :required="isRequired('exc_value')"
                                            class="input"
                                            :errors="errors['exc_value']"
                                        />
                                    </div>
                                    <div class="exc_target_select exc_input">
                                        <label>{{$t('除外対象詳細指定')}}</label>
                                        <gx-form-select
                                            v-model="editData['exc_target_select']"
                                            :options="getExcTargetSelectValues()"
                                            class="exc_target input"
                                            id="exc_target_input"
                                        />
                                        <!-- 除外対象詳細指定が「指定なし」で非活性 -->
                                        <gx-form-text
                                            :disabled="!editData['exc_target_select']"
                                            v-model="editData['exc_target_value']"
                                            :patterns="editData['exc_use_regex_target_value'] ? 'REGEX' : ''"
                                            :placeholder="editData['exc_use_regex_target_value'] ? $t('除外対象詳細指定(例: ^.+$)') : ''"
                                            class="input text"
                                            id="exc_target_value_input"
                                            :errors="errors['exc_target_value']"
                                        />
                                        <gx-form-checkbox
                                            v-if="!doesShowOptions()"
                                            :disabled="!editData['exc_target_select']"
                                            :checked="editData['exc_use_regex_target_value']"
                                            :text="$t('正規表現を使用する')"
                                            class="input checkbox"
                                            id="exc_use_regex_target_value_input"
                                            @check="v => setInput('exc_use_regex_target_value', v.target.checked)"
                                        />
                                    </div>
                                    <div class="exc_source_ip exc_input" v-if="doesShowExcSourceIp()">
                                        <label>{{$t('アクセス元IP')}}</label>
                                        <gx-form-text
                                            :name="$t('アクセス元IP')"
                                            v-model="editData['exc_source_ip']"
                                            :patterns="'IP_ADDRESS'"
                                            :placeholder="getPatternType('IP_ADDRESS')"
                                            class="input"
                                            id="exc_source_ip_input"
                                        />
                                    </div>
                                </div>
                            </div>

                        </template>
                        <template v-else>
                            <template v-if="propData[item] == actions.EXCEPTION">
                                <div class="action">
                                    {{$t(getActionsType(propData[item]))}}<!-- ：{{$t(getExceptionsType(propData['target_rule']))}} -->
                                </div>
                                <div class="exc">
                                    <label>{{$t('除外対象')}}</label>
                                    <div class="exc_select">
                                        {{getExcSelectStr(propData['exc_select'])}}
                                    </div>
                                    <div class="exc_value">
                                        {{propData['exc_value']}}
                                    </div>
                                </div>
                                <div class="exc_target">
                                    <label>{{$t('除外対象詳細指定')}}</label>
                                    <div class="exc_target_select">
                                        {{getExcTargetSelectStr(propData['exc_target_select'])}}
                                    </div>
                                    <div class="exc_target_value">
                                        {{propData['exc_target_value']}}
                                    </div>
                                </div>
                            </template>
                            <gx-icon-text
                                v-else
                                :text="$t(getActionsType(propData[item]))"
                                :icon="getActionIcon(propData[item])"
                                :color="colors.success"
                            />
                        </template>
                    </template>

                    <template v-else-if="item == 'priority'">
                        <span class="choice" v-if="isEdit">
                            <gx-form-select
                                v-model="editData[item]"
                                :options="customSignatures"
                                :disabled="customSignatures.length <= 0"
                                class="priority__select"
                            />
                            {{$t('の')}}
                            <term-list :items="priorityPosiOptions" class="choice__radios">
                                <template #item={termItem}>
                                    <gx-form-radio
                                        :value="termItem.value"
                                        :text="$t(termItem.str)"
                                        :name="'priority_pos'"
                                        :disabled="customSignatures.length <= 0 || propData[item] == editData[item]"
                                        @change="e => setInput('priority_pos', e)"
                                    />
                                </template>
                            </term-list>
                        </span>
                        <template v-else>{{propData[item]}}</template>
                    </template>

                    <template v-else-if="item == 'url_path'">
                        <template v-if="isEdit">
                            <!-- url_path: 既存ルール例外 && 条件指定ONで必須 -->
                            <gx-form-text
                                class="setting_value url__path"
                                v-model="editData[item]"
                                :term="$t(getSettingTerms(item))"
                                :patterns="editData.use_regex_path ? 'REGEX' : 'URL_PATH'"
                                :placeholder="'/hoge/fuga or /hoge/fuga.html'"
                                :ref="`input-${item}`"
                                :maxlength="1000"
                                :id="'url_path'"
                                :note="!editData.use_regex_path ? $t('完全一致') : ''"
                                :required="editData[`${item}_check`] || isRequired('url_path')"
                                :errors="errors[item]"
                            />
                            <div class="url__annotation">
                                <gx-form-checkbox
                                    :checked="editData.use_regex_path"
                                    :text="$t('正規表現を使用する')"
                                    @check="v => setInput('use_regex_path', v.target.checked)"
                                />
                            </div>
                        </template>
                        <template v-else>
                            <gx-tag v-if="propData['use_regex_path']" :text="$t('正規表現')" class="path-tag" />
                            {{propData[item]}}
                        </template>
                    </template>

                    <template v-if="item == 'req_method'">
                        <gx-form-select
                            v-if="isEdit"
                            class="setting_value"
                            v-model="editData[item]"
                            :options="methodOptions"
                            :props-error="!!errors[item]"
                            :props-error-msg="errors[item]"
                        />
                        <template v-else>{{$t(getMethodsType(propData[item]))}}</template>
                    </template>

                    <template v-else-if="item == 'search_str'">
                        <gx-form-text
                            v-if="isEdit"
                            v-model="editData[item]"
                            :term="$t(getSettingTerms(item))"
                            :placeholder="'search_str'"
                            patterns="REGEX"
                            class="setting_value"
                            :required="isRequired('search_str')"
                            :errors="errors[item]"
                            :ref="`input-${item}`"
                            :maxlength="500"
                            :id="'search_str'"
                        />
                        <template v-else>{{propData[`${item}`]}}</template>

                        <gx-section class="target" v-if="doesShowOptions()">
                            <gx-title :level="3">
                                <template #default>{{$t('検査対象')}}</template>
                            </gx-title>

                            <div class="existence" v-if="isEdit">
                                <gx-form-checkbox
                                    :checked="editData.search_target_exists"
                                    :text="$t('検査対象が存在しない場合も適用する')"
                                    @check="v => setInput('search_target_exists', v.target.checked)"
                                />
                            </div>
                            <div class="existence" v-else>
                                <gx-icon-text 
                                    v-if="propData['search_target_exists']"
                                    :text="$t('検査対象が存在しない場合も適用する')" 
                                    :icon="'check_circle_fill'" 
                                    class="validity" 
                                />
                            </div>

                            <template v-if="errors.targets && errors.targets.length">
                                <p :key="err" v-for="err in errors.targets" class="error"><em>{{ err }}</em></p>
                            </template>

                            <common-list :masonry="false" :items="targetTerms" class="settings target">
                                <template #default={item}>
                                    <template v-if="isEdit">
                                        <gx-form-checkbox
                                            :checked="editData[`${item}_check`]"
                                            :text="$t(getTargetTerms(item))"
                                            @check="event => setInput(`${item}_check`, event.target.checked)"
                                        />

                                        <template v-if="item.endsWith('_value')">
                                            <term-list :items="getTargetValuesOptions(item)" class="choice__radios">
                                                <template #item={termItem}>
                                                    <gx-form-radio
                                                        :name="`${item}_select`"
                                                        :value="termItem.value"
                                                        :checked="editData[`${item}_select`] == termItem.value"
                                                        :disabled="!editData[`${item}_check`]"
                                                        @change="e => setInput(`${item}_select`, e)"
                                                    >
                                                        {{$t(termItem.str)}}<small v-if="termItem.value == valueSelections.SPECIFY">（{{$t('完全一致')}}）</small>
                                                    </gx-form-radio>
                                                </template>
                                            </term-list>
                                            <gx-form-text
                                                v-model="editData[`${item}s`]"
                                                class="choice__text"
                                                :term="getTargetTerms(item)"
                                                :placeholder="targetValuePlaceholders[item]"
                                                :maxlength="1999"
                                                :ref="`input-${item}`"
                                                :disabled="!editData[`${item}_check`] || editData[`${item}_select`] != valueSelections.SPECIFY"
                                                :required="editData[`${item}_check`] && editData[`${item}_select`] == valueSelections.SPECIFY"
                                                :errors="seterror(errors[`${item}s`])"
                                                :note="$t('値を指定する場合、必須入力')"
                                            />
                                        </template><!-- /xxx_value -->
                                    </template><!-- /isEdit -->
                                    <template v-else-if="propData[`${item}_check`] || (item.match(/_value$/) && !!propData[`${item}_select`])">
                                        <gx-icon-text :text="$t(getTargetTerms(item))" :icon="'check_circle_fill'" class="validity" />
                                        <span v-if="propData[`${item}_select`] == valueSelections.SPECIFY" :class="'target_value'">{{propData[`${item}s`]}}</span>
                                        <span v-else-if="propData[`${item}_select`] == valueSelections.ALL" :class="'target_value'">{{ $t(getTargetAllLabel(item)) }}</span>
                                    </template>
                                    <template v-else>
                                        <span class="disabled">{{$t(getTargetTerms(item))}}</span>
                                    </template>
                                </template>
                            </common-list>
                        </gx-section>

                        <template v-else>{{propData[item]}}</template>
                    </template>
                    <div class="manual">
                        <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
                    </div>     
                </template>
            </data-list>
        </gx-section>

        <button-wrap v-if="isEdit" :buttons="buttons(invalid)" class="button-wrap" @submit="onSave" @cancel="$emit('cancel')" />
    </validation-observer>
</template>

<script>
    import Const from '@/static/constants'

    import gxFormRadio from '@/components/atoms/form/radio'
    import gxFormCheckbox from '@/components/atoms/form/checkbox'
    import gxFormText from '@/components/atoms/form/text'
    import gxFormTextarea from '@/components/atoms/form/textarea'
    import gxFormSelect from '@/components/atoms/form/select'
    import gxFormSwitch from '@/components/atoms/form/switch'
    import gxTag from '@/components/atoms/tag'
    import gxSection from '@/components/atoms/section'
    import gxTitle from '@/components/atoms/title'
    import buttonWrap from '@/components/molecules/button-wrap'
    import dataList from '@/components/molecules/data-list'
    import commonList from '@/components/molecules/common-list'
    import termList from '@/components/molecules/form/term-list'
    import notice from '@/components/molecules/notice'
    import gxIconText from '@/components/atoms/icon-text'
    import ManualService from '@/services/manualService'

    import commonMixin from "@/mixin/common";

    import i18n from "@/i18n"

    export default {
        name: 'website-waf-custom-form',
        components: {
            gxFormRadio,
            gxFormCheckbox,
            gxFormText,
            gxFormTextarea,
            gxFormSelect,
            gxFormSwitch,
            gxTag,
            gxSection,
            gxTitle,
            gxIconText,
            buttonWrap,
            dataList,
            commonList,
            termList,
            notice
        },
        mixins: [commonMixin],
        props: {
            propData: {
                type: Object,
                default: () => { return {} }
            },
            customSignatures: {
                type: Array,
                default: () => []
            },
            errors: {
                type: Object,
                default: () => { return {} }
            },
            isEdit: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                editData: {},
                doesExcCondSelect: false,
                emptyTarget: '',
            }
        },
        created() {
            if (this.isEdit) this.editData = this.defaultEditData
            this.onActionValueChanged()
        },
        computed: {
            isNew() {
                return Object.keys(this.propData).length == 0
            },
            isFormError() {
                return Object.keys(this.errors).length > 0
            },
            targetValuePlaceholders() {
                return {
                    param_value: 'id, pass',
                    req_header_field_value: 'User-Agent, Content-Type',
                    cookie_value: 'phpsessionid, personalid'
                }
            },
            settingTerms() {
                const keys = Object.keys(Const.wafCustom.settingTerms)
                const ret = []
                for (let i in keys) {
                    if (this.doesShowSettingTerms(keys[i])) [
                        ret.push(keys[i])
                    ]
                }
                return ret
            },
            targetTerms() {
                return Object.keys(Const.wafCustom.targetTerms)
            },
            actions() {
                return Const.wafCustom.actions
            },
            actionOptions() {
                return i18n.translateOptionValues(this.fmtTypeToSelection(Const.wafCustom.actionsType))
            },
            exceptionOptions() {
                return i18n.translateOptionValues(Const.event.attackType.filter(obj => obj.name !== null))
            },
            methodOptions() {
                return i18n.translateOptionValues(this.fmtTypeToSelection(Const.wafCustom.methodsType))
            },
            priorityPosiOptions() {
                return i18n.translateOptionValues(this.fmtTypeToSelection(Const.wafCustom.signaturePositionsType))
            },
            signaturePositions() {
                return Const.wafCustom.signaturePositions
            },
            valueSelections() {
                return Const.wafCustom.valueSelections
            },
            buttons() {
                const isExc = (this.editData.action == this.actions.EXCEPTION)
                const doesExcCond = (isExc && this.doesExcCondSelect)
                const checked = this.isChecked()
                const isSearchStrInputed = !!this.editData.search_str

                // 検索文字アリでチェック入ってない または チェックアリで検索文字入ってない
                const isSearchStrNonqualified = ((isSearchStrInputed && !checked) || (checked && !isSearchStrInputed))
                // 除外かつ検索条件が非適格 または 除外以外で(検索文字入力なし または 検索条件が非適格) なら適用条件を満たさない
                const nonqualified = ((doesExcCond && isSearchStrNonqualified) || (!isExc && (!isSearchStrInputed || isSearchStrNonqualified)))

                return (disabled) => {
                    return ([
                        {
                            text: i18n.t('保存'),
                            type: 'primary',
                            size: 'large',
                            onClick: 'submit',
                            disabled: (disabled || nonqualified)
                            // disabled: disabled || (this.editData.action != Const.wafCustom.actions['EXCEPTION'] && !checked)
                        }, {
                            text: i18n.t('キャンセル'),
                            type: 'common',
                            size: 'large',
                            onClick: 'cancel'
                        }
                    ])
                }
            },
            defaultEditData() {
                const editData = Object.assign({
                    website_id: '',
                    website_wafcus_id: '',
                    name: '',
                    comment: '',
                    action: 5,
                    target_rule: null,
                    priority: 1,
                    priority_pos: '1',
                    search_str: '',
                    search_target_exists:false,
                    url_path: '',
                    use_regex_path : false,
                    param_name_check : false,
                    param_value_check : false,
                    param_values : '',
                    req_header_field_name_check : false,
                    req_header_field_value_check : false,
                    req_header_field_values : '',
                    cookie_name_check : false,
                    cookie_value_check : false,
                    cookie_values : '',
                    exc_select: null,
                    exc_value: null,
                    exc_target_select: null,
                    exc_target_value: null,
                    exc_use_regex_target_value: null,
                    exc_source_ip: null,
                }, this.propData)

                // デフォルト値の設定
                if (!this.propData.param_value_select) {
                    editData.param_value_select = this.valueSelections.ALL
                } else {
                    // selectの値がNULLでなければチェックいれる
                    editData.param_value_check = true
                }
                if (!this.propData.req_header_field_value_select) {
                    editData.req_header_field_value_select = this.valueSelections.ALL
                } else {
                    // selectの値がNULLでなければチェックいれる
                    editData.req_header_field_value_check = true
                }
                if (!this.propData.cookie_value_select) {
                    editData.cookie_value_select = this.valueSelections.ALL
                } else {
                    // selectの値がNULLでなければチェックいれる
                    editData.cookie_value_check = true
                }
                if (this.propData.action == Const.wafCustom.actions['EXCEPTION'] && !!this.propData.url_path) {
                    // 条件設定ON
                    this.doesExcCondSelect = true
                }

                // TODO: APIでnullではなく0を許容すれば不要
                editData.req_method = this.propData.req_method || 0

                return editData
            },
        },
        watch: {
            isEdit(v) {
                if (v) this.editData = this.defaultEditData
            }
        },
        methods: {
            setInput(key, value) {
                this.$set(this.editData, key, value)
            },
            getActionsType(value) {
                return Const.wafCustom.actionsType[value]
            },
            getMethodsType(value) {
                return Const.wafCustom.methodsType[value || 0]
            },
            getActionIcon(value) {
                return Const.wafCustom.actionsIcon[value]
            },
            getPatternType(value) {
                return Const.validate.patternType[value]
            },
            doesShowExcCondOptions() {
                return this.editData['action'] == Const.wafCustom.actions['EXCEPTION']
            },
            doesShowExcSourceIp() {
                // アクセス元IPアドレス表示条件
                // "「既存シグネチャ除外」を選択 かつ 条件設定が「ON」"で表示
                return (
                    (this.editData['action'] == Const.wafCustom.actions['EXCEPTION'])
                    && (this.doesExcCondSelect)
                )
            },
            doesShowSettingTerms(item) {
                if (item == "url_path" && !this.doesShowOptions()) {
                    return false
                }
                if (item == "req_method" && !this.doesShowOptions()) {
                    return false
                }
                if (item == "search_str" && !this.doesShowOptions()) {
                    return false
                }

                return true
            },
            doesShowOptions() {
                // その他の既存シグネチャ除外絡みの値で変動する設定の表示条件
                // "「既存シグネチャ除外」以外の値を選択 または (「既存シグネチャ除外」を選択 かつ 条件設定が「ON」)"で表示
                return (
                    (this.editData['action'] != Const.wafCustom.actions['EXCEPTION'])
                    || (
                        (this.editData['action'] == Const.wafCustom.actions['EXCEPTION'])
                        && (this.doesExcCondSelect)
                    )
                )
            },
            onDoesExcCondSelectChanged(val) {
                this.doesExcCondSelect = val
            },
            onActionValueChanged() {
                if (this.editData.action == Const.wafCustom.actions['EXCEPTION']) {
                    if (!this.editData.exc_select) {
                        this.editData.exc_select = Const.wafCustom.excSelectValues[0].value
                    }
                    if (!this.editData.exc_target_select) {
                        this.editData.exc_target_select = Const.wafCustom.excTargetSelectValues[0].value
                    }
                }
            },
            getExcSelectValues() {
                return Const.wafCustom.excSelectValues
            },
            getExcSelectStr(val) {
                let ret = Const.wafCustom.excSelectStrs[val]
                return ret ? ret : ""
            },
            getExcTargetSelectValues() {
                return i18n.translateOptionValues(Const.wafCustom.excTargetSelectValues)
            },
            getExcTargetSelectStr(val) {
                let ret = Const.wafCustom.excTargetSelectStrs[val]
                return ret ? ret : ""
            },
            getExcTerms() {
                return Const.wafCustom.excTerms
            },
            isChecked() {
                return this.editData.param_name_check ||
                    this.editData.param_value_check ||
                    this.editData.req_header_field_name_check ||
                    this.editData.req_header_field_value_check ||
                    this.editData.file_name_check ||
                    this.editData.cookie_name_check ||
                    this.editData.cookie_value_check
            },
            isRequired(item) {
                const isExc = (this.editData.action == this.actions.EXCEPTION)
                const doesExcCond = (isExc && this.doesExcCondSelect)
                const checked = this.isChecked()

                let ret = false
                switch(item) {
                    case 'name':
                        ret = true
                        break
                    case 'comment':
                        ret = false
                        break
                    case 'action':
                        ret = true
                        break
                    case 'exc_select':
                        ret = isExc
                        break
                    case 'exc_value':
                        ret = isExc
                        break
                    case 'exc_target_value':
                        ret = !doesExcCond
                        break
                    case 'exc_use_regex_target_value':
                        ret = !doesExcCond
                        break
                    case 'exc_source_ip':
                        ret = false
                        break
                    case 'url_path':
                        ret = doesExcCond
                        break
                    case 'req_method':
                        ret = false
                        break
                    case 'search_str':
                        ret = !doesExcCond
                        break
                    default:
                        ret = false
                        break
                }
                return ret
            },
            getTargetValuesOptions(target) {
                switch (target) {
                    case 'param_value':
                        return this.fmtTypeToSelection(Const.wafCustom.paramValuesType)
                    case 'req_header_field_value':
                        return this.fmtTypeToSelection(Const.wafCustom.reqHeaderFieldValuesType)
                    case 'cookie_value':
                        return this.fmtTypeToSelection(Const.wafCustom.cookieValuesType)
                }
                return []
            },
            getTargetAllLabel(target) {
                switch (target) {
                    case 'param_value':
                        return Const.wafCustom.paramValuesType[this.valueSelections.ALL]
                    case 'req_header_field_value':
                        return Const.wafCustom.reqHeaderFieldValuesType[this.valueSelections.ALL]
                    case 'cookie_value':
                        return Const.wafCustom.cookieValuesType[this.valueSelections.ALL]
                }
                return ''
            },
            getExceptionsType(value) {
                const obj = Const.event.attackType.find(obj => obj.value === value)
                return obj ? obj.str : null
            },
            getSettingTerms(value) {
                return Const.wafCustom.settingTerms[value]
            },
            getTargetTerms(value) {
                return Const.wafCustom.targetTerms[value]
            },
            onChangeTargetValue(target, v) {
                const key = `${target}_select`
                if (v && key in this.editData && !this.editData[key]) {
                    this.editData[`${target}_select`] = 1
                }
                this.setInput(`${target}_check`, v)
            },
            onSave() {
                const params = {
                    param_value_select: null,
                    param_values: [],
                    req_header_field_value_select: null,
                    req_header_field_values: [],
                    file_name_select: null,
                    cookie_value_select: null,
                    cookie_values: [],
                    target_rule: null,
                }
                const copyParams = [
                    'name',
                    'comment',
                    'action',
                    'search_str',
                    'search_target_exists',
                    'priority',
                    'url_path',
                    'use_regex_path',
                    'req_method',
                    'param_name_check',
                    'param_value_check',
                    'req_header_field_name_check',
                    'req_header_field_value_check',
                    'file_name_check',
                    'cookie_name_check',
                    'cookie_value_check',
                ]
                copyParams.forEach(k => params[k] = this.editData[k])

                if (this.customSignatures.length == 0) {
                    params.priority = 1
                } else if (this.editData.priority != this.propData.priority) {
                    params.priority = this.editData.priority_pos == this.signaturePositions.UP
                        ? Number(this.editData.priority)
                        : Number(this.editData.priority) + 1
                }

                params.req_method = params.req_method != 0 ? params.req_method : null  // TODO: APIで0を許容すればこの1行は不要

                const parseValues = val => val.replace(/[\x20\u3000]/g, '').split(',')
                if (params.param_value_check) {
                    params.param_value_select = this.editData.param_value_select
                    if (params.param_value_select == this.valueSelections.SPECIFY) {
                        params.param_values = parseValues(this.editData.param_values)
                    }
                } else {
                    // チェック入っていない場合はselectをNULLでリセット
                    params.param_value_select = null
                }
                if (params.req_header_field_value_check) {
                    params.req_header_field_value_select = this.editData.req_header_field_value_select
                    if (params.req_header_field_value_select == this.valueSelections.SPECIFY) {
                        params.req_header_field_values = parseValues(this.editData.req_header_field_values)
                    }
                } else {
                    // チェック入っていない場合はselectをNULLでリセット
                    params.req_header_field_value_select = null
                }
                if (params.cookie_value_check) {
                    params.cookie_value_select = this.editData.cookie_value_select
                    if (params.cookie_value_select == this.valueSelections.SPECIFY) {
                        params.cookie_values = parseValues(this.editData.cookie_values)
                    }
                } else {
                    // チェック入っていない場合はselectをNULLでリセット
                    params.cookie_value_select = null
                }
                if (params.action == this.actions.EXCEPTION) {
                    // 動作：除外設定

                    params.target_rule = this.editData.target_rule
                    params.exc_select = this.editData.exc_select ? this.editData.exc_select : null;
                    params.exc_value = this.editData.exc_value
                    params.exc_target_select = this.editData.exc_target_select ? this.editData.exc_target_select : null;
                    if (!params.exc_target_select) {
                        params.exc_target_value = null
                        params.exc_use_regex_target_value = null
                    } else {
                        params.exc_target_value = this.editData.exc_target_value
                        params.exc_use_regex_target_value = this.editData.exc_use_regex_target_value
                    }
                    params.exc_source_ip = this.editData.exc_source_ip ? this.editData.exc_source_ip : null;

                    if (!this.doesExcCondSelect) {
                        // 条件設定 OFF
                        params.exc_source_ip = null
                        params.req_method = null
                        params.search_str = null
                        params.search_target_exists = null
                        params.use_regex_path = null
                        params.url_path = null
                        params.param_name_check = null
                        params.param_value_select = null
                        params.param_values = []
                        params.file_name_check = null
                        params.req_header_field_name_check = null
                        params.req_header_field_value_select = null
                        params.req_header_field_values = []
                        params.cookie_name_check = null
                        params.cookie_value_select = null
                        params.cookie_values = []
                    } else {
                        // 条件設定 ON
                        // 正規表現：使用不可
                        params.exc_use_regex_target_value = false
                        // 検索文字が未入力の場合はNULL
                        if (typeof(params.search_str) != "string" || params.search_str.length <= 0) {
                            params.search_str = null
                        }
                    }
                } else {
                    // 動作：除外設定以外
                    params.exc_select = null
                    params.exc_value = null
                    params.exc_use_regex_target_value = null
                    params.exc_target_select = null
                    params.exc_target_value = null
                    params.exc_source_ip = null
                }
                this.$emit('save', params)
            },
            seterror(datas){
                if(datas){
                      return datas[0]
                }
            },
            manual(){
  		        return '/manual/wiki/Custom_sig_edit_' + i18n.locale
  		    },           
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .notice {
        margin-bottom: $notice-margin;
    }
    .notification_field {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
        &__label {
            font-weight: bold;
            margin-right: 12px;
        }
    }
    .actions {
        display: flex;
        justify-content: flex-end;

        &__item:not(:first-child) {
            margin-left: 24px;
        }
    }

    .exc_options {
        margin-top: 1em;
        margin-left: 0em;
        padding-left: 1em;
        padding: 2em;
        .input {
            width: 200px;
            margin-left: 1em;
        }
        label:not(.switch) {
            &:not(.switch) {
                &:not(.checkbox) {
                    display: inline-block;
                    min-width: 140px;
                }
            }
        }

        label.switch {
            width: 64px;
        }

        .exc_input {
            padding: 16px;
            border-top: 1px solid $color-hr;
        }
    }

    .setting_value ::v-deep {
        display: block;

        .input {
            max-width: 100%;
        }
        .field {
            max-width: 100%;
        }
    }
    .target_value ::v-deep {
        display: block;
        width: 100%;
        margin: 8px 0 0 24px;

        .input {
            max-width: 100%;
        }
        .field {
            max-width: 100%;
        }
    }
    .choice {
        display: flex;
        width: 100%;
        align-items: center;

        &__radios {
            margin-left: 16px;
        }
        &__text {
            margin-top: 23px;
            margin-left: 8px;
        }
    }
    .priority {
        &__select {
            margin-right: 16px;
        }
    }
    .settings {
        .validity {
            font-weight: bold;

            &::before {
                color: $color-success;
            }
        }
        .disabled {
            opacity: $opacity-disabled;
        }
        &.target {
            height: auto;
            margin-top: 1em;
            padding-top: 1em;
            .disabled {
                margin-left: 26px;
            }

            &.section {
                .titleWrap {
                    margin-top: 1em;
                    margin-bottom: 0px;
                }
            }
        }
    }
    .invalid_text {
        opacity: $opacity-disabled;
    }
    .url {
        &__path {
            margin-bottom: 16px;
        }
        &__annotation {
            margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .existence {
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    .path-tag {
        margin-right: 8px;
    }
    .error {
        display: block;
        margin-bottom: 8px;
        color: $color-error;
        font-weight: bold;
    }
    .target {
        margin-top: 2em;
        margin-bottom: 0px;
        height: auto;
    }
</style>