<template>
    <modal :size="'large'">
        <gx-section>
            <group-table
                :groups="groupList"
                :sort="sort"
                @updateOrder="onUpdateOrder(field, order)"
                @groupChange="onGroupChange"
            />
            <pager :pager="pager" @changePage="changePage" @changePageSize="changePageSize" />
        </gx-section>
    </modal>
</template>
<script>
    import Const from '@/static/constants'
    import i18n from '@/i18n'

    import groupTable from '@/components/organisms/common/groupTable'
    import gxSection from '@/components/atoms/section'
    import pager from '@/components/molecules/pager'
    import modal from '@/components/molecules/modal'

    import accountService from '@/services/accountService'

    export default {
        name: "group-change-modal",
        components: {
            gxSection,
            modal,
            groupTable,
            pager,
        },
        props: {
            userAccount: {
                type: Object,
                "default": function() {
                    return {}
                }
            },
        },
        data: function() {
            return {
                Const: Const,
                searchParam: {},
                sort: {
                    field: '',
                    order: ''
                },
                pager: {
                    page_size: 1000,
                },
                groupList: [],
                selectedGroupId: undefined,
            }
        },
        computed: {
        },
        created() {
            this.fetchGroup();
        },
        methods: {
            onUpdateOrder(field, order) {

            },
            changePage(page) {
                this.$data.pager.page = page
                this.fetchGroup()
            },
            changePageSize(size) {
                this.$data.pager.page = 1
                this.$data.pager.page_size = size
                this.fetchGroup()
            },
            async onGroupChange(groupId) {
                if (this.userAccount.is_support) {
                    try {
                        await accountService.changeGroups({
                            id: groupId,
                        })
                        this.$emit('groupChanged')
                    } catch(err) {
                        this.$emit('groupChangeFailed')
                    } finally {
                        this.closeModal()
                    }
                }
            },
            async fetchGroup() {
                if (this.userAccount.is_support) {
                    const fetchedGroupList = await accountService.fetchGroups({
                        page: this.$data.pager.page || 1,
                        page_size: this.$data.pager.page_size,
                    })

                    this.$set(this.$data, 'groupList', fetchedGroupList.results ? fetchedGroupList.results : [])
                    this.$set(this.$data, 'pager', fetchedGroupList.page ? fetchedGroupList.page : {})

                    if (this.$data.groupList.length > 0) {
                        const idx = this.$data.groupList.findIndex((obj, idx, arr)=>{
                            return obj.id == this.userAccount.group_id
                        })
                        if (idx >= 0) {
                            this.$data.selectedGroupId = this.$data.groupList[idx].id
                        } else {
                            this.$data.selectedGroupId = this.$data.groupList[0].id
                        }
                    }
                } else {
                    this.$data.groupList.splice(0, this.$data.groupList.length)
                    this.$data.selectedGroupId = undefined
                }
            },
            closeModal() {
                this.$store.dispatch('closeModal')
            }
        },
    }
</script>
<style>
</style>
