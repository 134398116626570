<!-- DEPRECATED: input→textへ移行 (vee-validate) -->
<template>
    <span :class="['wrap', {'is-disabled' : disabled}]">
        <label v-if="label || this.$slots.label" :for="id" class="label"><slot name="label">{{label}}</slot></label>
        <em v-if="!valid" class="error">{{errorText}}<br><small>{{inputRules}}</small></em>
        <span class="field-wrap">
            <span v-if="prefix || this.$slots.prefix" class="prefix"><slot name="prefix">{{prefix}}</slot></span>
            <input
                :id="id"
                :name="name"
                :class="classes"
                :type="type"
                :placeholder="placeholder"
                :value="value"
                :disabled="disabled"
                :pattern="validators"
                :max="max"
                :min="min"
                :maxlength="maxlength"
                :minlength="minlength"
                :required="required"
                @input="setInput"
                @blur="onValidator"
            >
            <span v-if="suffix || this.$slots.suffix" class="suffix"><slot name="suffix">{{suffix}}</slot></span>
        </span>
        <small v-if="note" class="note">{{note}}</small>
    </span>
</template>

<script>
    import Const from '@/static/constants'

    export default {
        name: 'gx-form-input',
        props: {
            id: {
                type: String,
                default() {
                    return `${this._uid}`
                }
            },
            name: {
                type: String,
                default: ''
            },
            label: {
                type: String,
                default: ''
            },
            prefix: {
                type: String,
                default: ''
            },
            suffix: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: 'placeholder text'
            },
            disabled: {
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                validator: (value) => {
                    return ['text', 'number', 'password', 'date', 'datetime-local', 'month', 'email', 'url', 'tel'].indexOf(value) !== -1
                },
                default: 'text'
            },
            max: {
                type: [String, Number]
            },
            min: {
                type: [String, Number]
            },
            maxlength: {
                type: Number
            },
            minlength: {
                type: Number
            },
            note: {
                type: String,
                default: ''
            },
            value: {
                type: [String, Number],
                default: ''
            },
            validator: {
                type: [String, Array],
                default: ''
            },
            required: {
                type: Boolean,
                default: false
            },
            propsError: {
                type: Boolean,
                default: false
            },
            propsErrorMsg: {
                type: [String, Array],
                default: ''
            }
        },
        data() {
            return {
                valid: !this.propsError,
                errorText: Array.isArray(this.propsErrorMsg) ? this.propsErrorMsg.join(' / ') : this.propsErrorMsg
            }
        },
        watch: {
            validators() {
                setTimeout(this.onValidator, 0)
            },
            required(v) {
                if (!v) setTimeout(this.onValidator, 0)
            },
            propsError() {
                this.valid = !this.propsError
            },
            propsErrorMsg() {
                this.errorText = Array.isArray(this.propsErrorMsg) ? this.propsErrorMsg.join(' / ') : this.propsErrorMsg
                this.valid = !this.propsError
            }
        },
        computed: {
            classes() {
                const classes = ['field']
                if(['date', 'datetime-local', 'month'].indexOf(this.type) > 0) classes.push('date')
                if(!this.valid) classes.push('is-error')
                return classes
            },
            validators() {
                let validators = ''
                if(Array.isArray(this.validator)) {
                    const validatorsArray = []
                    this.validator.forEach((item) => {
                        validatorsArray.push('(' + Const.validate.pattern[item] + ')')
                    })
                    validators = validatorsArray.join('|')
                } else {
                    validators = Const.validate.pattern[this.validator]
                }
                return validators
            },
            inputRules() {
                let rules = ''
                if(Array.isArray(this.validator)) {
                    const rulesArray = []
                    this.validator.forEach((item) => {
                        rulesArray.push(Const.validate.patternType[item])
                    })
                    rules = rulesArray.join(' もしくは ')
                } else {
                    rules = Const.validate.patternType[this.validator]
                }
                return rules
            }
        },
        methods: {
            setInput(event) {
                this.$emit('input', event.target.value)
            },
            onValidator(event) {
                const target = event ? event.target : this.$el.getElementsByClassName('field')[0]
                this.valid = target.validity.valid
                this.errorText = target.validationMessage
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    $max-width: 400px;
    .wrap {
        width: 100%;
        max-width: $max-width;
        display: inline-block;

        &.is-disabled {
            opacity: $opacity-disabled;
        }
    }
    .label {
        display: block;
        margin-bottom: 8px;
    }
    .field-wrap {
        display: flex;
        align-items: center;
    }
    .prefix {
        white-space: nowrap;
        margin-right: 8px;
    }
    .suffix {
        white-space: nowrap;
        margin-left: 8px;
    }
    .field {
        width: 0;
        height: 40px;
        line-height: 38px;
        border-radius: 6px;
        border: 1px solid $color-border;
        padding: 0 16px;
        transition: $transition-common;
        flex: 1 1 auto;

        &.is-error {
            border-color: $color-error;
            background-color: rgba($color-error, .1);
        }
        &:disabled {
            cursor: not-allowed;
        }
    }
    .note {
        display: block;
        margin-top: 8px;
        color: $color-note;
    }
    .error {
        display: block;
        margin-bottom: 8px;
        color: $color-error;
        font-weight: bold;
    }
</style>
