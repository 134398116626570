import api from '@/services/api'

export default {
    API_ENDPOINT: '/api/master/',
    fetchCountry() {
        return api.get(`${this.API_ENDPOINT}countries/`)
            .then(response => response.data)
            .catch(error => error.response)
    },
}
