<template>
    <table class="table table-draggable">
        <thead class="thead">
            <slot name="head" />
        </thead>
        <draggable class="tbody" :tag="'tbody'" :value="value" @input="setInput" :handle="handle">
            <slot name="body" />
        </draggable>
    </table>
</template>

<script>
    import Draggable from 'vuedraggable'

    export default {
        name: 'table-draggable',
        components: {
            Draggable,
        },
        props: {
            value: {
                type: Array,
                required: true,
            },
            handle: {
                type: String,
                required: false,
            }
        },
        methods: {
            setInput(val) {
                this.$emit('input', val)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';

    .table {
        width: 100%;
        border: none;
        border-spacing: 0;
        border-collapse: collapse;
    }
    .thead {
        background-color: $color-parts-accent;
    }
</style>
