<script>
import { HorizontalBar } from 'vue-chartjs';

export default {
  extends: HorizontalBar,
  name: 'countrybar',
  props: {
    countrybardata: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      data: {
        labels: this.getbardata('label'),    
        datasets: [
        {
            type: 'horizontalBar',
            label: '国別攻撃元',
            data: this.getbardata('data'),
            backgroundColor:'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1
          },
        ]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            scaleLabel: {
               display: true,
               unit: 'hour'
             },            
            ticks: {
              min: 0,
              fontColor: "white"
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              min: 0,
              fontColor: "white"
            }
          }]
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.data, this.options)
  },
  methods: {
    getbardata(type) {
      this.getvalue = []
      if(this.countrybardata.length != 0){
        for (let i = 0; i < this.countrybardata.length; i++) {
          if(type == "data"){
            this.getvalue.push(this.countrybardata[i].count)
          }else if(type == "label"){
            this.getvalue.push(this.countrybardata[i].country)        
          }
       }
      }
      return this.getvalue
    }
  }
}
</script>