<script>
    import Const from '@/static/constants'

    export default {
        name: 'CommonMixin',
        computed: {
            colors() {
                const colors = {}
                Object.keys(Const.common.colors).forEach((index) => {
                    colors[index.toLowerCase()] = Const.common.colors[index]
                })
                return colors
            }
        },
        methods: {
            scrollToTop() {
                window.scrollTo(0, 0)
            },
            formatSort(sort, field) {
                return {
                    vaild: sort.field == field,
                    field: field,
                    order: sort.field == field ? sort.order : 'desc'
                }
            },
            fmtTypeToSelection(types) {
                return Object.entries(types).map(([val, label]) => ({
                    str: label,
                    value: val
                }))
            }
        },
    }
</script>