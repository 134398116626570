import camelCase from 'lodash/camelCase'

if(process.env.NODE_ENV == 'test') {
    require('babel-plugin-require-context-hook/register')();
}

const requireModule = require.context('.', false, /\.js$/)
const modules = {}

requireModule.keys().forEach(fileName => {
    if (fileName === './index.js') return

    const moduleName = camelCase(
        fileName.replace(/(\.\/|\.js)/g, '')
    )

    modules[moduleName] = { ...requireModule(fileName) }
})

export default modules