<template>
    <layout-main
        :title="$t('サポートチケット')"
        :navs="navs"
    >
        <template #page-header>
            <gx-button
                :tag="'router-link'"
                :text="$t('新規追加')"
                to="/support/register"
            />
            <gx-button
                v-if="userAccount.is_support"
                :text="(checked.length <= 1) ? $t('コメント追加') : $t('一斉返信')"
                :disabled="checked.length <= 0"
                @onClick="onCommentAddClick"
            />
        </template>
        <div class="support-main">
            <ticket-search :params="searchParam" :userAccount="userAccount" :internalStatus="internalStatus" @updateQuery="updateQuery" @search="doSearch" />
            <loading v-if="isLoading" />
            <div v-else>
                <ticket-table
                    :checked="checked"
                    :tickets="tickets"
                    :sort="sort"
                    :internalStatus="internalStatus"
                    @updateOrder="updateOrder"
                    @onGroupChange="onGroupChange"
                />
                <pager :pager="pager" @changePage="changePage" @changePageSize="changePageSize" />
            </div>
        </div>
        <template v-if="$store.state.isModal" #modal>
            <support-commentAll-modal
                v-if="$store.state.modalTarget == 'support-commentAll-modal'"
                :user-account="userAccount"
                :edit-comment="$data.editComment"
                :tags="$data.editTags"
                :internalStatus="$data.internalStatus"
                :errors="errors"
                @submit="onEditCommentCheck"
            />
            <support-comment-check-modal
                v-if="$store.state.modalTarget == 'support-comment-check-modal'"
                :user-account="userAccount"
                :edit-comment="$data.editComment"
                :tags="$data.editTags"
                :internalStatus="$data.internalStatus"
                :errors="errors"
                @submit="onEditCommentSubmit"
                @cancel="cancel"
            />
        </template>
        <div class="manual">
            <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
        </div>
    </layout-main>
</template>
<script>
    import { diff } from 'deep-object-diff'
    import { mapGetters, mapActions } from 'vuex'

    import layoutMain from '@/layouts/main'
    import loading from '@/components/atoms/loading'
    import pager from '@/components/molecules/pager'
    import gxFormSelect from '@/components/atoms/form/select'
    import gxButton from '@/components/atoms/button'
    import gxIconText from '@/components/atoms/icon-text'

    import ticketTable from '@/components/organisms/support/table'
    import ticketSearch from '@/components/organisms/support/search'
    import supportCommentAllModal from '@/components/organisms/support/commentAllModal'
    import supportCommentCheckModal from '@/components/organisms/support/commentcheckModal'
    import ManualService from '@/services/manualService'
    import accountService from '@/services/accountService'

    import DateMixin from '@/mixin/date'

    import i18n from '@/i18n'

    import Const from '@/static/constants'
    import {
        supportService,
        TicketCreateParam, TicketSearchParam, TicketUpdateParam, BulkUpdateParam, TicketTag, TicketMailUser, TicketFile,
        EditComment,
    } from '@/services/supportService'

    export default {
        name: 'support',
        components: {
            layoutMain,
            loading,
            gxFormSelect,
            gxButton,
            gxIconText,
            ticketTable,
            ticketSearch,
            supportCommentAllModal,
            supportCommentCheckModal,
            pager,
        },
        mixins: [DateMixin],
        data() {
            return {
                searchParam: new TicketSearchParam({}),
                errors: {},
                commentErrors: {},
                sort: {
                    field: '',
                    order: ''
                },
                checked: [],
                tickets: [],
                internalStatus: {},
                isLoading: true,
                isLoadError: false,
                pager: {},
                editTags: [],
                editComment: new EditComment({
                    content: "",
                    status: Const.support.status.USER.value,
                    comment_file_set: [],
                }),
            }
        },
        computed: {
            ...mapGetters([
                'userAccount'
            ]),
            navs() {
                return [
                    {
                        to: '/support',
                        label: i18n.t('サポートチケット')
                    }
                ]
            },
            buttons() {
                return [{
                    text: i18n.t('有効化'),
                    size: 'large'
                }]
            },
        },
        methods: {
            ...mapActions([
                'fetchUserAccount'
            ]),
            reload() {
                this.fetchUserAccount()
                this.fetch()
            },
            async fetch(isSearching) {
                let _this = this;

                this.isLoading = true
                this.isLoadError = false

                try {
                    // Get ticket list
                    let params = {}

                    const p = (!isSearching) ? this.urlQuery() : this.$data.searchParam
                    for (let key in p) {
                        let v = p[key]
                        // 空文字とundefinedは値に含めない
                        if (!(typeof v == "string" && v.length <= 0) && (v != undefined)) {
                            params[key] = v
                        }
                    }

                    for (let key in params) {
                        let v = params[key]

                        if (v != undefined) {
                            // 日時の関数で空文字を渡すとinvalid dateが出るので注意
                            switch (key) {
                                case 'start_create_time':
                                    params[key] = this.convertToRequestTime(v)
                                    break
                                case 'start_update_time':
                                    params[key] = this.convertToRequestTime(v)
                                    break
                                case 'end_create_time':
                                    params[key] = this.convertToRequestTime(this.$moment.utc(v).add(1, 'days'))
                                    break
                                case 'end_update_time':
                                    params[key] = this.convertToRequestTime(this.$moment.utc(v).add(1, 'days'))
                                    break
                                default:
                                    break
                            }
                        }
                    }

                    if (this.$data.sort) {
                        let orderParam = this.$data.sort.field
                        if(this.$data.sort.order == 'desc') orderParam = `-${orderParam}`
                        params.o = orderParam
                    }
                    let res = await supportService.list(params)
                    this.$data.tickets.splice(0, this.$data.tickets.length)
                    for (let i in res.results) {
                        this.$data.tickets.push(res.results[i])
                    }
                    this.$set(this.$data, 'pager', res.page ? res.page : {})

                    this.$data.checked.splice(0, this.$data.checked.length)
                } catch(e) {
                    this.isLoadError = true
                } finally {
                    this.isLoading = false
                }
            },
            onCommentAddClick() {
                // ステータスと内部ステータスは初期値を渡す
                this.editComment.status = Const.support.status.USER.value
                this.editComment.internal_status = Const.support.internalStatus.SENTALL.value
                this.$store.dispatch('openModalWithParam', {target: 'support-commentAll-modal', param: this.$route.params.uuid})
            },
            updateQuery(key, val) {
                this.$set(this.searchParam, key, val)
            },
            doSearch() {
                let query = {}
                Object.assign(query, this.searchParam)
                if (Object.keys(diff(query, this.urlQuery())).length !== 0) {
                    this.$router.replace({path: "/support", query: query})
                }
                this.fetch(true)
            },
            urlQuery() {
                return this.$route.query
            },
            updateOrder(field, order) {
                this.sort.field = field
                this.sort.order = order
                this.fetch(true)
            },
            changePage(page) {
                this.updateQuery('page', page)
                this.fetch(true)
            },
            changePageSize(size) {
                this.updateQuery('page_size', size)
                this.updateQuery('page', 1)
                this.fetch(true)
            },
            async onRegisterButtonClick() {
                const params = new TicketCreateParam()
                params.title = "title"
                params.content = "content"
                let res = await supportService.register(params)
            },
            async onSearchButtonClick() {
                await this.fetch(true)
            },
            async onEditCommentSubmit() {
                this.isLoading = true;

                try {
                    const updateStatus = parseInt(this.$data.editComment.status,"10")
                    const updateInternalStatus = parseInt(this.$data.editComment.internal_status,"10")
                    const ticketIds = []

                    for (let i in this.$data.checked) {
                        const ticketID = this.$data.checked[i]
                        const ticket = this.$data.tickets.find((val)=>{
                            return (val.ticket_id == ticketID)
                        })
                        ticketIds.push(ticket.ticket_id)
                    }

                    const updateTags = []
                    if (this.$data.editTags.length > 0) {
                        for (let i in this.$data.editTags) {
                            updateTags.push(this.$data.editTags[i])
                        }
                    }

                    const sendData = new BulkUpdateParam({
                        ticket_id: ticketIds,
                        status: updateStatus,
                        internal_status: updateInternalStatus,
                        ticket_tag_set: updateTags,
                        comment_set: [this.$data.editComment],
                    })

                    await supportService.bulkUpdate(
                        sendData,
                    )

                    this.$store.dispatch('openToast', {type: 'success', label: i18n.t('登録しました')})

                    this.fetch()
                    this.$data.editComment =  new EditComment({
                        content: "",
                        status: Const.support.status.USER.value,
                        comment_file_set: [],
                    })
                    this.$store.dispatch('closeModal')
                } catch (err) {
                    this.$store.dispatch('openToast', {type: 'error', label: i18n.t('登録に失敗しました')})
                    this.errors = err.response.data
                    this.$set(this.$data, 'commentErrors', err.response.data)
                } finally {
                    this.isLoading = false
                }
            },
            /** 送信前の確認 **/
            async onEditCommentCheck() {
                this.$store.dispatch('openModalWithParam', {target: 'support-comment-check-modal', param: this.$route.params.uuid})
            },      
            getInternalStatusStr(value) {
                const obj = this.internalStatus.find((obj)=>{
                    return obj.id == value
                })
                return obj ? obj.internal_status : value
            },      
            cancel() {
                this.$store.dispatch('openModalWithParam', {target: 'support-commentAll-modal', param: this.$route.params.uuid})
            },
            manual(){
  		        return '/manual/wiki/Support_list_' + i18n.locale
  		    },              
        },
        async created() {  		  
            const urlQuery = JSON.parse(JSON.stringify(this.urlQuery()))
            Object.entries(urlQuery).forEach(([key, value]) => {
                this.$set(this.searchParam, key, value)
            })

            // アカウント情報を取得
            const Account = await accountService.userInfo()

  	    	if(Account.is_support && Account.role == Const.accounts.roleSupport){
                // 役職がサポートユーザーの場合、グループIDを削除する
                this.searchParam.groupname = ""
  		    }else{
                // 検索条件に、グループIDを設定する
                this.searchParam.groupname = Account.groupname
            }

            // 内部ステータス一覧を取得
            if(this.userAccount.is_support){
                let internalStatus = await supportService.getInternalStatus()
                this.$data.internalStatus = internalStatus.results
                // 空欄を追加する
                this.$data.internalStatus.push({id:undefined,internal_status:""})
                // 項目の順序を入れ替える
                this.$data.internalStatus = this.$data.internalStatus.reverse()
            }
            this.doSearch() 
        }
    }
</script>
<style lang="scss" scoped>

</style>
