//
// 英語
//
// 重複は後の行が勝ちますが、未確認のブラウザでの挙動が不明瞭なため、基本的には避けるようにお願いします
// @see @/pages/event/detail.vue#methods.getAttackSrcCountry
// 国名については上記をご参照ください

export default Object.freeze({
  "処理中...": "Loading...",
  "マニュアル": "Manual",
  "ダッシュボード": "Dash Board",
  "ウェブサイトが設定されていません": "Website isn't registered yet.",
  "保護するウェブサイトを設定してください": "Please register some website to guard.",
  "データが取得出来ませんでした": "Couldn't get any data.",
  "{0}の攻撃はありません": "{0}: No attackers.",
  "クイックステータス": "Quick Status",
  "直近24時間攻撃": "Last 24 hours attacks",
  "イベント一覧へ": "To Event List",
  "未クローズチケット": "Unclosed tickets",
  "サポートチケット一覧へ": "To Support Tickets List",
  "ウェブサイト更新失敗件数": "Website update failures",
  "ウェブサイト一覧へ": "To Website List",
  "有効期限が近い証明書件数": "Certificates nearing expiration",
  "証明書一覧へ": "To Certificate List",
  "期間": "Term",
  "月次レポート": "Monthly Report",
  "レポートダウンロード": "Download report",
  "レポートが存在しません": "Report does not exist",
  "CPU使用率": "CPU utilization",
  "転送量": "Transfer Volume", 
  "トライアル": "Trial", 
  "契約プラン": "Contract Plan", 
  "{0}GB": "{0}GB", 
  "使用量": "volume of use", 
  "残り": "remaining quantity", 
  "超過量": "surplus quantity", 
  "パフォーマンスモニター": "Performance Monitor", 
  "脅威度の割合": "Severities",
  "WAF導入時設定情報": "Setup information on introducing WAF",
  "DNSのCNAME設定用ドメイン名": "Domain name that should be set to CNAME of DNS",
  "DNSのCNAME設定用ドメイン名のIPアドレス": "IP address of domain name that should be set to CNAME of DNS",
  "実サーバーで通信を許可するIPアドレス": "IP address that allows communication with the real server",
  "時間別攻撃件数": "Attacks by time",
  "攻撃元国別 TOP5": "Attacker country TOP5",
  "攻撃元IP TOP5": "Attacker IP TOP5",
  "タイムライン": "TimeLine",
  "攻撃元マップ": "Attacker map",
  "3D": "3D",
  "2D": "2D",
  "お知らせ": "Information",
  "一覧へ": "List",
  "お知らせはありません": "No Informations",
  "ウェブサイト新規設定": "Register new Website",
  "転送量取得に失敗しました": "Failed to get transfer amount",

  "国名": "Name",
  "件数": "Count",

  "IP": "IP",

  "脅威度": "Severity",
  "総イベント件数": "Total Events",
  "{0}件": "Count: {0}",
  "高": "High",  // @see static/constants/event.js#severityType
  "中": "Middle",  // @see static/constants/event.js#severityType
  "低": "Low",  // @see static/constants/event.js#severityType

  "イベント詳細": "Event Detail",
  "概要": "About",
  "タイムスタンプ": "timestamp",  // @see static/constants/event.js#detailTermsType
  "脅威度": "severity",  // @see static/constants/event.js#detailTermsType
  "攻撃元国名": "Attacker Country",  // @see static/constants/event.js#detailTermsType
  "攻撃元IP": "Attacker IP",  // @see static/constants/event.js#detailTermsType
  "攻撃元": "Attacker",
  "検出": "Detection", 
  "検出情報": "  Detection Information",  // @see static/constants/event.js#detailTermsType
  "検出箇所": "Detection Location",  // @see static/constants/event.js#detailTermsType
  "検出文字列": "Detection String",  // @see static/constants/event.js#detailTermsType
  "検出文字列（全体）": "Detection String(whole)",  // @see static/constants/event.js#detailTermsType
  "メソッド": "Method",  // @see static/constants/event.js#detailTermsType
  "名前": "Name",  // @see static/constants/event.js#detailTermsType
  "ホスト": "Host",  // @see static/constants/event.js#detailTermsType
  "パス": "URI",  // @see static/constants/event.js#detailTermsType
  "種類": "Attack Type",  // @see static/constants/event.js#detailTermsType
  "動作": "Action",  // @see static/constants/event.js#detailTermsType
  "条件指定": "Conditions",
  "除外対象": "Exception Target",
  "除外対象詳細指定": "Exception Detail",
  "検知コード": "Detection Code",  // @see static/constants/event.js#detailTermsType
  "high": "high",  // @see static/constants/event.js#severityClass
  "middle": "middle",  // @see static/constants/event.js#severityClass
  "low": "low",  // @see static/constants/event.js#severityClass
  "EC-CUBE": "EC-CUBE",  // @see static/constants/event.js#CMSType
  "Wordpress": "Wordpress",  // @see static/constants/event.js#CMSType
  "Movable Type": "Movable Type",  // @see static/constants/event.js#CMSType
  "パス": "path",  // @see static/constants/event.js#CMSType
  "ブロック": "Block",  // @see static/constants/event.js#actionType
  "リダイレクト": "Redirect",  // @see static/constants/event.js#actionType
  "検出のみ": "Detection Only",  // @see static/constants/event.js#actionType
  "リクエスト": "Request",
  "ヘッダー": "Header",
  "ボディ": "Body",
  "レスポンス": "Response",
  "イベント一覧に戻る": "Back to Event List",
  "イベント": "Events",
  "CSVダウンロード": "Download CSV",
  "パス名": "URL",
  "URLパス": "URL Path",
  "アクセス元IP": "Refer from IP",
  "検索条件": "Query",
  "条件をクリア": "Clear Query",
  "ファイルを選択する": "Select file",
  "検索": "Search",
  "詳細": "Detail",
  "{0}と{1}を表示": "Show {0} and {1}",
  "{0}を開く": "Open {0}",
  "{0}を閉じる": "Close {0}",
  "{0}件中 {1}〜{2}件表示": "Show: {1} to {2} Total: {0}",
  "1ページに": "Show",
  "表示": "Rows",
  "------------": "------------",
  "20件": "20",  // @see static/constants/common.js#pageSizeType
  "50件": "50",  // @see static/constants/common.js#pageSizeType
  "100件": "100",  // @see static/constants/common.js#pageSizeType
  "1000件": "1000",  // @see static/constants/common.js#pageSizeType

  "ログイン用のマジックリンクを送信しました。…": "We send magic link for login to your e-mail address.<br/>Please login from the URL that wrriten in the email.",
  "メールが届かない場合": "If you don't get any e-mail from us",
  "ログイン用のマジックリンクを送信します。": "We send magic link for login to your e-mail address.",
  "グループID": "Group ID",
  "ユーザーID": "User ID",
  "パスワード": "Password",
  "送信": "Send",

  "一覧に戻る": "Back to List",  // @TODO "イベント一覧に戻る"とどっちに合わせるか

  "新規追加": "Create New",
  "通信に失敗しました": "Error",
  "ユーザー詳細": "User Detail",
  "編集": "Edit",
  "削除": "Delete",
  "対象": "Target",
  "間隔": "Interval",
  "有効": "Enable",
  "無効": "Disable",
  "不明": "Unknown",
  "ユーザー一覧に戻る": "Back to User List",
  "更新しました": "Updated.",  // @TODO: "Done / Failed", "成功しました / 失敗しました" に一本化できそう
  "更新失敗": "Update Failed",
  "ユーザー新規追加": "Register New User",
  "登録しました": "Register Succeeded",  // @TODO: "Done / Failed", "成功しました / 失敗しました" に一本化できそう
  "登録に失敗しました": "Register Failed",  // @TODO: "Done / Failed", "成功しました / 失敗しました" に一本化できそう
  "ユーザーを削除します": "Delete this User",
  "この操作は取り消せません": "This operation can't revert",
  "キャンセル": "Cancel",
   "削除": "Delete",
  "削除しました": "Deleted",  // @TODO: "Done / Failed", "成功しました / 失敗しました" に一本化できそう
  "削除に失敗しました": "Deletion Failed",  // @TODO: "Done / Failed", "成功しました / 失敗しました" に一本化できそう
  "入力内容に不備があります": "Validation Failed",
  "半角英数字/@/./+/-/_のみ使用可能 150文字以内": "Accept Alfhanumeric/@/./+/-/_ Only. Less than or equals 150 characters.",
  "address@test.com": "address@test.com",  // e-mail
  "00000000000": "00000000000",  // telephone
  "任意設定": "Optional",
  "任意": "Optional",
  "現在のパスワード": "Old Password",
  "新しいパスワード": "New Password",
  "パスワード規約": "Accept Alfhanumeric and .!_/`()[]+-=$#&@~. More than or equals 8 Characters And Less than or equals 128 Characters./Use upper-case letters , lower-case letters and Number./ The same Character cannot be used more than 3 consecutively. ",
  "********": "********",
  "確認用パスワード": "Confirmation Password",
  "保存": "Save",
  "有効期限": "Expires in",
  "メールアドレス": "Mail Address",  // @see static/constants/accounts.js#registerTermsType
  "電話番号": "Telephone No.",  // @see static/constants/accounts.js#registerTermsType
  "タイムゾーン": "Time Zone",  // @see static/constants/accounts.js#registerTermsType
  "通知": "Notification",  // @see static/constants/accounts.js#registerTermsType
  "権限": "Role",  // @see static/constants/accounts.js#registerTermsType
  "状態": "Status",  // @see static/constants/accounts.js#registerTermsType
  "お知らせ配信": "Send Information",  // @see static/constants/accounts.js#registerTermsType
  "グループ管理者": "Group Administrator",  // @see static/constants/accounts.js#roleTerms
  "管理者": "Administrator",  // @see static/constants/accounts.js#roleTerms
  "一般ユーザー": "User",  // @see static/constants/accounts.js#roleTerms
  "すべてのイベント": "All Events",  // @see static/constants/accounts.js#notificationFlgTypes
  "重要度中以上のイベント": "Severity higher than or equals Middle",  // @see static/constants/accounts.js#notificationFlgTypes
  "重要度高のイベント": "Severity equals High",  // @see static/constants/accounts.js#notificationFlgTypes
  "オフ": "OFF",  // @see static/constants/accounts.js#notificationFlgTypes
  "5分": "5Min.",  // @see static/constants/accounts.js#notificationIntervalTypes
  "10分": "10Min.",  // @see static/constants/accounts.js#notificationIntervalTypes
  "30分": "30Min.",  // @see static/constants/accounts.js#notificationIntervalTypes
  "1時間": "1Hour",  // @see static/constants/accounts.js#notificationIntervalTypes
  "3時間": "3Hour",  // @see static/constants/accounts.js#notificationIntervalTypes
  "6時間": "6Hour",  // @see static/constants/accounts.js#notificationIntervalTypes
  "12時間": "12Hour",  // @see static/constants/accounts.js#notificationIntervalTypes
  "24時間": "24Hour",  // @see static/constants/accounts.js#notificationIntervalTypes

  "グループアクセス制御": "Group Access Control",

  "ウェブサイト": "Web Site",
  "グループ変更": "Group Change",
  "グループ番号": "Group Number",
  "グループ名": "Group Name",
  "選択": "Select",
  "ユーザー": "User",
  "有効化": "Activate",
  "カスタム・シグネチャ一覧に戻る": "Back to Custom Signature List",
  "ウェブサイト一覧に戻る": "Back to Web Site List",
  "基本設定": "Basic Settings",
  "アクセス制御": "Access Control",
  "カスタム・シグネチャ": "Custom Signature",
  "WAF - ベース": "WAF - Base",
  "ウェブサイト詳細": "Web Site Detail",
  "ウェブサイト新規追加": "Create New Web Site",
  "サーバー情報": "Server Information",
  "保存しました": "Save Succeeded",  // @TODO: "Done / Failed", "成功しました / 失敗しました" に一本化できそう
  "保存に失敗しました": "Save Failed",  // @TODO: "Done / Failed", "成功しました / 失敗しました" に一本化できそう
  "保護:": "Guard:",
  "{0}以外": "Without {0}",
  "動的のみ": "Only Dinamic",
  "正規表現": "Regex",
  "{0}秒間に{1}回以上のリクエストでブロックする": "Block the Frequentry Request that {1}times in {0}Sec",
  "{0}秒": "{0}Sec.",
  "IPアドレスで指定": "Specify by IP Address",
  "国名で指定": "Specify by Country Name",
  "Botで指定": "Specify by Bot",
  "以外を対象にする": "Else",
  "完全一致": "Exact Match",
  "動的ページのみを対象にする": "Target Dinamic Page Only",
  "正規表現を使用する": "Use Regex",
  "拒否": "Reject",
  "許可": "Accept",
  "安全": "Safety",
  "秒数": "Seconds",
  "秒間に": "Sec.",
  "回数": "Count",
  "回以上の": "times over",
  "リクエストでブロックする": "Block by the Request",
  "ブロック時間": "Blocking Time",
  "半角整数：{0}〜{1}": "Integer ({0} to {1})",  // block_time
  "半角英数スペースおよび!()+,-./:;_が使用可能／部分一致／大文字と小文字を区別しない": "Alfhanumeric, Space, and Some simbols( !()+,-./:;_ ) Can Use / Partial Match / Case Insensitive",
  "フィルタリングルール{0}": "{0} Filtering Rule",
  "参照元": "Refer from",  // @see  static/constants/website.js#access_detailTermsType
  "対象URL": "Target URL",  // @see  static/constants/website.js#access_detailTermsType
  "許可/拒否": "Accept / Reject",  // @see  static/constants/website.js#access_detailTermsType
  "アクション": "Action",  // @see  static/constants/website.js#access_detailTermsType
  "リクエストしきい値": "Request threathold",  // @see  static/constants/website.js#access_detailTermsType
  "ブロック時間": "Blocking Time",  // @see  static/constants/website.js#access_detailTermsType
  "HTTPリクエストヘッダ内の": "User-Agent Field in",  // @see  static/constants/website.js#access_detailTermsType
  "User-Agentフィールド": "The HTTP Request",  // @see  static/constants/website.js#access_detailTermsType
  "秒": "Sec.",
  "全て": "All",
  "sg-admin": "sg-admin",
  "ブロック": "Block",
  "リダイレクト": "Redirect",
  "検出のみ": "Detection Only",
  "オン": "ON",
  "オフ": "OFF",
  "パターンマッチテスト": "Pattern Match Test",
  "クリア": "Clear",
  "テスト": "Test",
  "参照元": "Referrer",  
  "以外": "else",  
  "CIDR記法で範囲指定ができます／30件まで同時指定可能／複数指定をする際は、指定ごとに改行してください": "Ranges can be specified using CIDR notation./30 IPaddresses can be specified./please enter a new line for each item.",
  "並べ替え": "Rearrenge",
  "テスト結果": "Test Result",
  "国1": "Country1",
  "国2": "Country2",
  "国3": "Country3",
  "国4": "Country4",
  "国5": "Country5",
  "国6": "Country6",
  "国7": "Country7",
  "該当フィルター": "Applicable Filter",  // @see static/constants/website.js#access_filterTestTermsType
  "更新中": "Updating",  // @see static/constants/website.js#stateType
  "ドロップ": "Drop",  // @see static/constants/website.js#resBlockActionType
  "サーバーエラー（500）": "Server Error（500）",  // @see static/constants/website.js#reqBlockActionType
  "アクセス拒否（403）": "Access Deny（403）",  // @see static/constants/website.js#reqBlockActionType
  "リダイレクト（302）": "Redirect（302）",  // @see static/constants/website.js#reqBlockActionType
  "更新ステータス": "UpdateStatus",  // @see static/constants/website.js#basicTermsType
  "応答HTMLファイル": "Response HTML File",  // @see static/constants/website.js#basicTermsType
  "アクション": "Action",  // @see static/constants/website.js#basicTermsType
  "状態": "Condition",  // @see static/constants/website.js#basicTermsType  
  "保護": "Guard",  // @see static/constants/website.js#basicTermsType
  "実サーバー": "Real Server",  // @see static/constants/website.js#basicTermsType
  "5件まで同時指定可能／複数指定をする際は、指定ごとに改行してください": "5 IPaddresses or Host Names can be specified./please enter a new line for each item.",
  "実プロトコル": "Real Protocol",  // @see static/constants/website.js#basicTermsType
  "ホスト名": "Host Name",  // @see static/constants/website.js#basicTermsType
  "プロトコル": "Protocol",  // @see static/constants/website.js#basicTermsType
  "高度な設定": "Advanced settings",  // @see static/constants/website.js#basicTermsType
  "クライアントIPアドレスをX-Forwarded-Forから取得": "Get client IP address from X-Forwarded-For",  // @see static/constants/website.js#basicTermsType
  "※本項目を編集したい場合はサポートにお問い合わせください": "※Please contact support if you want to edit this item",  // @see static/constants/website.js#basicTermsType
  "クライアントIPアドレスの読み込み順": "Order in which client IP addresses are read",
  "左から読み込む": "Read from left",
  "右から読み込む": "Read from right",
  "フィルタリングルール一覧": "Filtering Rule List",
  "フィルタリングルールが設定されていません": "Filtering Rule isn't set yet",
  "適用するフィルタリングルールを設定してください": "Please Set Filtering Rule that to be Applying",
  "追加": "Add",
  "優先度": "Priority",
  "フィルタリング新規設定": "Create New Filtering Setting",
  "ウェブサイトを削除します": "Delete This WebSite",
  "フィルタリングルールを削除します": "Delete This Filtering Rule",
  "この操作は取り消せません": "This Operation Can't Cancel",
  "メッセージを確認の上もう一度入力してください": "Please Input Again",
  "HTTPSはファイル選択が必須です": "HTTPS Required File Select",
  "アップロード可能なファイル拡張子：.pem": "Ext：.pem",
  "HTTPをHTTPSにリダイレクトする": "HTTP Redirect to HTTPS",
  "SSL証明書の期限：{0}まで": "SSL Certificate Expires in：{0}",
  "SSL証明書未アップロード": "SSL Certificate Not Upload",
  "wwwなしのドメインにも対応する": "Support 'www' less Domain",
  "Hostヘッダを指定する": "Specify the Host header",
  "クライアントIPアドレスに基づいて実サーバーに振り分ける": "Allocate to real server based on client IP address",
  "レスポンスタイムアウト値": "Response Timeout value",
	"サーバー名を省略した場合、サーバー名にはホスト名が設定されます": "If the server name is omitted, the server name will be set to the host name.",
  "ON": "ON",
  "SNI": "SNI",
  "リダイレクトファイル": "Redirect File",
  "リダイレクトはファイル選択が必須です": "Redirection Rquires File Select",
  "リダイレクトファイル設定済": "Redirection File Already Setted",
  "更新済み": "Updated",
  "カスタム・シグネチャを削除します": "Delete Custom Signature",
  "設定内容": "Configuration",
  "シグネチャID": "Signature ID",  // @see static/constants/wafCustom#settingTerms
  "シグネチャ名": "Signature Name",  // @see static/constants/wafCustom#settingTerms
  "コメント": "Comment",  // @see static/constants/wafCustom#settingTerms
  "シグネチャ優先度": "Signature Priority",  // @see static/constants/wafCustom#settingTerms
  "検査文字": "Search String",  // @see static/constants/wafCustom#settingTerms
  "リクエストメソッド": "Request Method",  // @see static/constants/wafCustom#settingTerms
  "パラメータ名": "Parameter Name",  // @see static/constants/wafCustom#targetTerms
  "パラメータ値": "Parameter Value",  // @see static/constants/wafCustom#targetTerms
  "リクエストヘッダフィールド名": "Request Header Field Name",  // @see static/constants/wafCustom#targetTerms
  "リクエストヘッダフィールド値": "Request Header Field Value",  // @see static/constants/wafCustom#targetTerms
  "Cookie名": "Cookie Name",  // @see static/constants/wafCustom#targetTerms
  "Cookie値": "Cookie Value",  // @see static/constants/wafCustom#targetTerms
  "既存シグネチャ除外": "Exist Signature Exception",  // @see static/constants/wafCustom#actionsType
  "指定しない": "No Specify",  
  "送信ファイル名": "Send File Name",
  "ANY": "ANY",  // @see static/constants/wafCustom#methodsType
  "GET": "GET",  // @see static/constants/wafCustom#methodsType
  "POST": "POST",  // @see static/constants/wafCustom#methodsType
  "PUT": "PUT",  // @see static/constants/wafCustom#methodsType
  "DELETE": "DELETE",  // @see static/constants/wafCustom#methodsType
  "HEAD": "HEAD",  // @see static/constants/wafCustom#methodsType
  "OPTIONS": "OPTIONS",  // @see static/constants/wafCustom#methodsType
  "PATCH": "PATCH",  // @see static/constants/wafCustom#methodsType
  "CONNECT": "CONNECT",  // @see static/constants/wafCustom#methodsType
  "TRACE": "TRACE",  // @see static/constants/wafCustom#methodsType
  "上にする": "Up",  // @see static/constants/wafCustom#signaturePositionsType
  "下にする": "Down",  // @see static/constants/wafCustom#signaturePositionsType
  "すべてのパラメータ": "All Parameter",  // @see static/constants/wafCustom#paramValuesType
  "パラメータを指定": "Choose Parameter",  // @see static/constants/wafCustom#paramValuesType
  "すべてのフィールド": "All Field",  // @see static/constants/wafCustom#paramValuesType
  "フィールドを指定": "Choose Field",  // @see static/constants/wafCustom#paramValuesType
  "すべてのCookie": "All Cookie",  // @see static/constants/wafCustom#paramValuesType
  "Cookieを指定": "Specify Cookie",  // @see static/constants/wafCustom#paramValuesType
  "の": "Of",
  "HTTPリクエストヘッダ内の<br>User-Agentフィールド": "User-Agent Field<br>in the Request Header",
  "検査対象": "Target",
  "検査対象が存在しない場合も適用する": "Applies even when there is no object to be inspected",
  "値を指定する場合、必須入力": "Required if Specify value",
  "カスタム・シグネチャ一覧": "Cuctom Signature List",
  "カスタム・シグネチャが設定されていません": "Custom Signature isn't Set yet.",
  "適用するカスタム・シグネチャを設定してください": "Please Setting Applying Custom Signature",
  "No.": "No.",
  "カスタム・シグネチャ名": "Custom Signature Name",
  "コメント": "Comment",
  "カスタム・シグネチャ新規設定": "Create New Custom Signature",
  "カスタム・シグネチャが{0}件登録されています。": "{0} Custom Signature Registered",
  "カスタム・シグネチャ追加": "Add Custom Signature",
  "リクエスト防御": "Request Guard",
  "レスポンス保護": "Response Guard",
  "CMS選択": "CMS Select",
  "必須": "Required",

  "グループ設定": "Group Setting",

  "証明書一覧": "Certificate List",
  "自動証明書": "AutoCertificate",
  "自動証明書{0}": "AutoCertificate{0}",
  "アップロード証明書": "UploadCertificate",
  "アップロード証明書{0}": "UploadCertificate{0}",
  "最大登録件数": "Max number of Certificate",
  "※最大{0}件まで登録可能です": "※Up to {0} case can be registered",
  "自動": "Auto",
  "アップロード": "Upload",
  "ホスト名なし": "No Hostname",
  "種別": "Type",
  "処理中": "Processing",
  "利用可能": "Available",
  "エラー": "Error",
  "www無し": "No www",
  "www無しも含む": "Include no www",
  "含む": "Include",
  "含まない": "Not Include",
  "秘密鍵": "Secret Key",  // @see static/constants/website.js#basicTermsType
  "SSL証明書": "SSL Certificate",  // @see static/constants/website.js#basicTermsType
  "最新登録日": "Latest Registration",
  "証明書発行をスケジュールしました。": "Scheduled to issue certificates.",
  "新しい証明書が発行されたら利用可能となります。": "The new certificate will be available when it is issued.",
  "一日経過しても利用可能とならない場合はお問い合わせください。": "If it is not available after one day, please contact us.",
  "一覧画面へ": "Back To list",
  "証明書選択": "Select Certificate",
  "選択可能な証明書がありません": "No configurable certificate",
  "Let’s Encrypt からの証明書取得について": "Obtaining a Certificate from Let's Encrypt",
  "Let’s Encrypt から証明書を取得する際、DNSを利用してドメインを検証します。": "We obtain a certificate from Let's Encrypt and use DNS to validate the domain.",
  "検証に利用するレコードをCNAMEでSiteGuardで管理しているホストゾーンに委任していただく必要があります。": "It is necessary to delegate the records for the verification by using CNAME to our managing hosted zone .",
  "具体的には以下の設定が必要です。": "Specifically, the following settings are required.",
  "ホスト名 *.example.com のワイルドカード証明書を発行する場合": "Issuing a certificate for the host name www.example.com",
  "ホスト名 www.example.com の証明書を発行する場合": "Issuing a certificate for the host name www.example.com (includes without www)",
  "ホスト名 www.example.com (www無しも含む) の証明書を発行する場合": "Issuing a wildcard certificate,  *.example.com",
  "利用には提供元の": "You must agree to the provider's",
  "ポリシー": "policy",
  "に同意する必要があります": "to use this service.",
  "同意して利用": "Agree and use  ",
  "シリアル番号": "Serial No",
  "有効期限（開始）": "Expiration Date (start)",
  "有効期限（終了）": "Expiration Date (end)",
  "発行者": "Publisher",
  "組織": "Organization",
  "コモンネーム": "Common Name",
  "所有者": "Owner",
  "certファイル": "cert file",
  "この内容で登録しました。": "Registered with this content.",

  "ウェブサイト": "WebSite",
  "WordPress防御": "WordPress Protect",
  "EC-CUBE防御": "EC-CUBE Protect",
  "MovableType防御": "MovableType Protect",
  "トラステッド・シグネチャ": "Trusted Signature",
  "クロスサイト・スクリプティング (XSS)": "Cross-Site Scripting",
  "SQLインジェクション": "SQL Injection",
  "OSコマンドインジェクション": "OS Command Injection",
  "ディレクトリトラバーサル": "Directory Traversal",
  "改行コードインジェクション": "CRLF Injection",
  "XPathインジェクション": "XPath Injection",
  "LDAPインジェクション": "LDAP Injection",
  "XXE": "XXE",
  "Unicodeを利用した攻撃": "Attacks using Unicode",
  "その他": "Other",
  "リクエストURLチェック": "Request URL Check",
  "リクエスト形式チェック": "Request Format Check",
  "メソッドチェック": "Method Check",
  "動作テストシグネチャ": "Working Test Signature",
  "レスポンスチェック": "Response Check",
  "ステータスコード": "Status Code",
  
  "チケットをクローズする": "Close Ticket",
  "この内容でクローズしますか？": "Do you want to close with this Ticket?",
  "この内容で送信しますか？": "Do you want to send with this Comment?",
  "サポート": "Support",
  "内容": "Content",
  "ステータス": "Status",
  "内部ステータス": "Internal Status",
  "クローズを表示": "Show Closed",
  "対応不要を表示": "Show No Support",
  "チケット詳細": "Ticket Detail",
  "新規チケット登録": "Register New Ticket",
  "タイトル": "Title",
  "チケットID": "Ticket ID",
  "重要度": "Priority",  // @TODO: 優先度? 重要度? 緊急度?
  "イベントID": "Event ID",
  "メール宛先": "Mail Send To",
  "作成日時": "Created At",
  "更新日時": "Updated At",
  "ダウンロード": "Download",
  "設定": "Settings",
  "サポートチケット": "Support Ticket",
  "タグ": "Tag",
  "タグなし": "No Tag",
  "ファイル": "File",
  "メール": "Mail",
  "投稿日時": "Posted At",
  "なし": "Empty",
  "コメント追加": "Add Comment",
  "一斉返信": "Add Comments",
  "設定方法": "Settings",
  "クローズ": "Close",
  "シグネチャ": "Signature",
  "EGSS": "EGSS",
  "誤検出": "False Positives",

  "閉じる": "Close",
  "更新": "Update",
  "検索結果はありません": "No Results",
  "利用規約": "Term of Service",
  "プライバシーポリシー": "Privacy Policy",
  "個人情報保護方針(プライバシーポリシー)": "Privacy Policy",
  "お問い合わせ": "Contact Information",
  "&copy; EG Secure Solutions Inc.": "&copy; EG Secure Solutions Inc. 2022",
  "運営会社": "Operating Company",
  "ユーザー設定": "User Settings",
  "ログアウト": "Logout",
  "日本語": "Japanese",
  "英語": "English",
  "ページが存在しません": "page does not exist",

  "© EG Secure Solutions Inc.": "© EG Secure Solutions Inc. 2022",

  "": "",
})
