<template>
    <div class="pager">
        <p class="pager-display">{{$t('{0}件中 {1}〜{2}件表示', [pager.count, displayPagesStart, displayPagesEnd])}}</p>
        <ul class="pager-nav">
            <li class="pager-nav__item"><a href="#" class="pager-nav__link prev" :class="{'is-disabled': pager.previous == null}" @click.prevent="pager.previous != null ? changePage(pager.previous) : false">前のページへ</a></li>
            <li class="pager-nav__item" v-for="page in pager.page_range" :key="page">
                <a href="#" class="pager-nav__link" :class="{'is-current' : page == pager.current}" @click.prevent="changePage(page)">{{page}}</a>
            </li>
            <li class="pager-nav__item"><a href="#" class="pager-nav__link next" :class="{'is-disabled': pager.next == null}" @click.prevent="pager.next != null ? changePage(pager.next) : false">次のページへ</a></li>
        </ul>
        <p class="pager-controller">
            {{$t('1ページに')}}
            <gx-form-select class="pager-controller__select" :options="pageSize" :value="pager.page_size" @input="changePageSize" />
            {{$t('表示')}}
        </p>
    </div>
</template>

<script>
    import Const from '@/static/constants'

    import gxFormSelect from '@/components/atoms/form/select'
    import i18n from '@/i18n'

    export default {
        name: 'pager',
        components: {
            gxFormSelect
        },
        props: {
            pager: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                selectedPageSize: '20'
            }
        },
        computed: {
            pageSize() {
                const pageSize = []
                Object.keys(Const.common.pageSizeType).forEach((index) => {
                    pageSize.push({
                        str: Const.common.pageSizeType[index],
                        value: index,
                        selected: index == this.pager.page_size ? true : false
                    })
                })
                return i18n.translateOptionValues(pageSize)
            },
            displayPagesStart() {
                return (this.pager.current - 1) * this.pager.page_size + 1
            },
            displayPagesEnd() {
                return this.pager.count > (this.pager.current - 1) * this.pager.page_size + this.pager.page_size ? (this.pager.current - 1) * this.pager.page_size + this.pager.page_size : this.pager.count
            }
        },
        methods: {
            changePage(value) {
                this.$emit('changePage', value)
            },
            changePageSize(value) {
                this.selectedPageSize = value
                this.$emit('changePageSize', value)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .pager {
        display: flex;
        align-items: center;
        margin-top: 32px;
    }
    .pager-display {
        margin-right: 40px;
    }
    .pager-nav {
        display: flex;
        height: 48px;
        line-height: 46px;
        border: 1px solid $color-border;
        border-radius: 6px;
        overflow: hidden;

        &__item {
            min-width: 46px;

            &:not(:first-child) {
                border-left: 1px solid $color-border;
            }
        }
        &__link {
            $button-bg: $color-common;
            $button-hover: mix($color-common, $color-white, 85);
            $button-active: mix($button-hover, $color-common, 65);
            display: block;
            width: 100%;
            height: 100%;
            text-align: center;
            text-decoration: none;
            position: relative;
            background-color: $button-bg;

            &.prev {
                font-size: 0;

                &::before {
                    @include icon(arrow-left);
                    font-size: 1.8rem;
                }
            }
            &.next {
                font-size: 0;

                &::before {
                    @include icon(arrow-right);
                    font-size: 1.8rem;
                }
            }
            &.is-current {
                pointer-events: none;

                &::before {
                    content: "";
                    width: 100%;
                    height: 4px;
                    background: $color-current;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }
            &.is-disabled {
                opacity: $opacity-disabled;
                cursor: not-allowed;
                background-color: $button-bg;
            }
            &:hover:not(.is-disabled):not(.is-current) {
                opacity: 1;
                background-color: $button-hover;
            }
            &:active:not(.is-disabled):not(.is-current) {
                background-color: $button-active;
            }
        }
    }
    .pager-controller {
        margin-left: auto;

        &__select {
            width: auto;
            margin: 0 16px;
        }
    }
</style>