<template>
    <search-box :noPointer="isLoading" @search="search" @clear="clear">
        <template>
            <search-terms>
                <search-term :type="'title'">{{$t('期間')}}</search-term>
                <search-term :type="'data'">
                    <datetime-range :startDate="event_start_time" :endDate="event_end_time" @input="updateRangeDate" />
                </search-term>
                <search-term :type="'title'">{{$t('攻撃元IP')}}</search-term>
                <search-term :type="'data'">
                    <gx-form-text
                        v-model="attack_src_ip"
                        :errors="errors.attack_src_ip"
                        term="攻撃元IP"
                        :placeholder="'192.168.0.1'"
                        patterns="IP_ADDRESS_SEARCH"
                    />
                </search-term>
                <search-term :type="'title'">{{$t('ホスト')}}</search-term>
                <search-term :type="'data'">
                    <gx-form-text
                        v-model="req_header_host"
                        :errors="errors.req_header_host"
                        term="ホスト"
                        :placeholder="'www.example.com'"
                        patterns="HOST_SEARCH"
                    />
                </search-term>
                <search-term :type="'title'">{{$t('URLパス')}}</search-term>
                <search-term :type="'data'"><gx-form-input :placeholder="'/hoge/fuga'" v-model="req_uri" /></search-term>
                <search-term :type="'title'">{{$t('種類')}}</search-term>
                <search-term :type="'data'">
                    <gx-form-select :options="attackName()" v-model="attack_type" :value="attack_type" />
                </search-term>
                <search-term :type="'title'">{{$t('状態')}}</search-term>
                <search-term :type="'data'">
                    <term-list :items="actions">
                        <template #item={termItem}>
                            <gx-form-checkbox :value="termItem.value" :checked="res_action.includes(`${termItem.value}`)" @check="updateCheckBox('res_action', $event.target.value)" ><gx-icon-text :icon="termItem.icon" :text="termItem.str" :color="termItem.color" /></gx-form-checkbox>
                        </template>
                    </term-list>
                </search-term>
                <search-term :type="'title'"></search-term>
                <search-term :type="'data'">
                    <gx-form-checkbox
                        class="label"
                        :disabled="!has_access_control_active"
                        :checked="Boolean(has_access_control) == true"
                        @check="has_access_control = !has_access_control"
                    >{{$t('{0}と{1}を表示', [$t('アクセス制御'), $t('グループアクセス制御')])}}</gx-form-checkbox>
                </search-term>
            </search-terms>
        </template>
    </search-box>
</template>

<script>
    import Const from '@/static/constants'

    import gxIconText from '@/components/atoms/icon-text'
    import gxFormCheckbox from '@/components/atoms/form/checkbox'
    import gxFormInput from '@/components/atoms/form/input'
    import gxFormText from '@/components/atoms/form/text'
    import gxFormSelect from '@/components/atoms/form/select'
    import searchTerm from '@/components/atoms/search/term'
    import datetimeRange from '@/components/molecules/form/datetime-range'
    import searchBox from '@/components/molecules/search/box'
    import searchTerms from '@/components/molecules/search/terms'
    import termList from '@/components/molecules/form/term-list'

    import DateMixin from "@/mixin/date";

    import i18n from "@/i18n"

    export default {
        name: 'event-search',
        mixins: [DateMixin],
        components: {
            gxIconText,
            gxFormCheckbox,
            gxFormInput,
            gxFormText,
            gxFormSelect,
            searchTerm,
            datetimeRange,
            searchBox,
            searchTerms,
            termList
        },
        props: {
            params: {
                type: Object,
                default: () => Object.assign({}, this.defaultForm)
            },
            isLoading: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                errors: {},
                has_access_control_active: true,
            }
        },
        computed: {
            defaultForm() {
                return {
                    event_start_time: '',
                    event_end_time: this.toLocalDate().format('YYYY-MM-DD HH:mm'),
                    attack_src_ip: '',
                    attack_type: '',
                    has_access_control: false,
                    severity: [],
                    req_header_host: '',
                    req_uri: '',
                    res_action: []
                }
            },
            event_start_time: {
                set(val){
                    this.$emit("updateQuery", 'event_start_time', val)
                },
                get(){
                    return this.params.event_start_time
                }
            },
            event_end_time: {
                set(val){
                    this.$emit("updateQuery", 'event_end_time', val)
                },
                get(){
                    return this.params.event_end_time
                }
            },
            attack_src_ip: {
                set(val){
                    this.$emit("updateQuery", 'attack_src_ip', val)
                },
                get(){
                    return this.params.attack_src_ip
                }
            },
            attack_type: {
                set(val){
                    this.$emit("updateQuery", 'attack_type', val)
                    this.has_access_control = false
                    this.has_access_control_active = (val == '')
                },
                get(){
                    return this.params.attack_type
                }
            },
            has_access_control: {
                set(val){
                    this.$emit("updateQuery", 'has_access_control', val)
                },
                get(){
                    return this.params.has_access_control
                }
            },
            severity: {
                set(val){
                    this.$emit("updateQuery", 'severity', val)
                },
                get(){
                    return this.params.severity
                }
            },
            req_header_host: {
                set(val){
                    this.$emit("updateQuery", 'req_header_host', val)
                },
                get(){
                    return this.params.req_header_host
                }
            },
            req_uri: {
                set(val){
                    this.$emit("updateQuery", 'req_uri', val)
                },
                get(){
                    return this.params.req_uri
                }
            },
            res_action: {
                set(val){
                    this.$emit("updateQuery", 'res_action', val)
                },
                get(){
                    return this.params.res_action
                }
            },
            actions() {
                const actions = []
                Object.keys(Const.event.action).forEach((index) => {
                    const action = Const.event.action[index]
                    actions.push({
                        str: Const.event.actionType[action],
                        icon: Const.event.actionIcon[action],
                        value: action,
                        color: Const.common.colors[Const.event.actionColor[action]]
                    })
                })
                return i18n.translateOptionValues(actions)
            }
        },
        methods: {
            attackName() {
                const attacks = []
                attacks.push(...Const.common.emptySelect, ...Const.event.attackType)            
                return i18n.translateOptionValues(attacks)
            },
            updateRangeDate(val, key) {
                if(key === "startDate") {
                    this.event_start_time = val
                } else if(key === "endDate") {
                    this.event_end_time = val
                }
            },
            updateCheckBox(key, val) {
                if(this[key]) {
                    if(this[key].includes(val)) {
                        const index = this[key].findIndex(v => v == val)
                        this[key].splice(index, 1)
                    } else {
                        this[key].push(val)
                    }
                }
            },
            search() {
                this.$emit("updateQuery", 'page', '1')
                this.$emit("search")
            },
            clear() {
                const defaultForm = Object.assign({}, this.defaultForm)
                Object.keys(defaultForm).forEach(key => {
                    if(Array.isArray(defaultForm[key])) {
                        this.$emit("updateQuery", key, [])
                    } else {
                        this.$emit("updateQuery", key, defaultForm[key])
                    }
                })
                this.has_access_control_active = true
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
