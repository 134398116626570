<template>
    <gx-section>
        <gx-title :level="2">
            <template #default>{{$t('カスタム・シグネチャ追加')}}</template>
        </gx-title>

        <notice v-if="isLoadError" :type="'error'" :label="$t('通信に失敗しました')" />

        <website-waf-custom-form
            :custom-signatures="customSignatures"
            :errors="formErrorMsg"
            is-edit
            @save="save"
            @cancel="cancel"
            @invalid="scrollToTop"
        />
    </gx-section>
</template>

<script>
    import MixinCommon from '@/mixin/common'

    import gxSection from '@/components/atoms/section'
    import gxTitle from '@/components/atoms/title'
    import notice from '@/components/molecules/notice'
    import websiteWafCustomForm from '@/components/organisms/website/waf-custom-form'
    import WebsiteService from '@/services/websiteService'

    import i18n from '@/i18n'

    export default {
        name: 'website-waf-custom-regsiter',
        mixins: [MixinCommon],
        components: {
            gxSection,
            gxTitle,
            notice,
            websiteWafCustomForm
        },
        data() {
            return {
                formErrorMsg: {},
                customSignatures: [],
                isLoadError: false
            }
        },
        methods: {
            getCustomSignatures() {
                this.isLoadError = false
                WebsiteService.fetchPriority(this.$route.params.uuid).then(res => {
                    this.customSignatures = res.map(item => ({
                        str: item.name,
                        value: item.priority
                    }))
                }).catch(() => {
                    this.isLoadError = true
                })
            },
            async save(register_custom_signature) {

                this.$store.dispatch('displayLoading')
                await WebsiteService.registWafCus(this.$route.params.uuid, register_custom_signature).then(res => {
                    this.formErrorMsg = {}
                    this.$store.dispatch('openToast', {type: 'success', label: i18n.t('保存しました')})
                    this.$router.go(-1)
                    this.$emit('updateCustomType', 'list')
                }).catch(err => {
                    if (err.response.status === 400) {
                        this.formErrorMsg = err.response.data
                        this.scrollToTop()
                    } else {
                        this.$store.dispatch('openToast', {type: 'error', label: i18n.t('保存に失敗しました')})
                    }
                }).finally(() => {
                    this.$store.dispatch('hideLoading')
                })
            },
            cancel() {
                this.$router.go(-1)
                this.$emit('updateCustomType', 'list')
            }
        },
        created() {
            this.getCustomSignatures()
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .actions {
        display: flex;
        justify-content: flex-end;

        &__item:not(:first-child) {
            margin-left: 24px;
        }
    }
</style>
