<template>
    <layout-main
        :title="news.title || ''"
        :title-sub="released_at"
        :navs="navs"
        :type="'terms'"
    >
        <template #default>
            <template v-if="isLoading"><loading /></template>
            <notice v-else-if="isLoadError" :type="'error'" :label="$t('データが取得出来ませんでした')" />
            <template v-else>
                <gx-box v-if="news" v-html="news.body" class="news__body"></gx-box>
            </template>
            <page-back :text="$t('一覧に戻る')" @onClick="$router.push('/news')" />
        </template>
    </layout-main>
</template>

<script>
    import MixinDate from '@/mixin/date'

    import layoutMain from '@/layouts/main'
    import gxBox from '@/components/atoms/box'
    import loading from '@/components/atoms/loading'
    import pageBack from '@/components/molecules/page-back'
    import notice from '@/components/molecules/notice'

    import i18n from '@/i18n'

    import NewsService from '@/services/newsService'

    export default {
        name: 'news-detail',
        mixins: [MixinDate],
        components: {
            layoutMain,
            gxBox,
            loading,
            pageBack,
            notice
        },
        data() {
            return {
                isLoadError: false,
                isLoading: true,
                news: {}
            }
        },
        computed: {
            uuid() {
                return this.$route.params.id
            },
            released_at() {
                return this.news.released_at ? this.formatLocalDateTime(this.news.released_at) : ''
            },
            navs() {
                return [
                    {
                        to: '/news',
                        label: i18n.t('お知らせ')
                    },
                    {
                        to: `/news/${this.uuid}`,
                        label: this.news.title ? `${this.released_at}：${this.news.title}` : ''
                    }
                ]
            }
        },
        methods: {
            async fetch() {
                this.isLoading = true
                this.news = await NewsService.fetchDetail(this.uuid).catch(() => {
                    this.isLoadError = true
                    return []
                })
                this.isLoading = false
            }
        },
        created() {
            this.fetch()
        }
    }
</script>

<style lang="scss">
    .terms {
        width: 100%;
    }
</style>

<style lang="scss" scoped>
.news__body {
    ::v-deep {
        ol, ul {
            padding-left: 20px;
        }
        ol {
            list-style-type: decimal;
        }
        ul {
            list-style-type: disc;
        }
        li {
            list-style: inherit;
        }
    }
}
</style>