<template>
    <component :is="tag" :disabled="disabled" :class="classes" :to="to" @click="disabled ? false : $emit('onClick')">{{text}}</component>
</template>

<script>
    export default {
        name: 'gx-button',
        props: {
            tag: {
                type: String,
                default: 'button'
            },
            text: {
                type: String,
                default: 'button text'
            },
            type: {
                type: String,
                validator: (value) => {
                    return ['primary', 'secondary', 'common', 'error'].indexOf(value) !== -1
                },
                default: 'primary'
            },
            to: {
                type: String,
                default: ''
            },
            size: {
                type: String,
                validator: (value) => {
                    return ['large', 'medium', 'small'].indexOf(value) !== -1
                },
                default: 'medium'
            },
            noPointer: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            classes() {
                return ['button', this.size, this.type, this.noPointer, {'is-disabled' : this.disabled}]
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    $types: (
        primary : $button-primary-color-background,
        secondary : $color-secondary,
        common : $button-common-color-background,
        error : $color-error,
    );

    .button {
        display: block;
        text-align: center;
        text-decoration: none;
        position: relative;
        transition: $transition-common;
        
        overflow: hidden;
        white-space: pre;

        @each $type, $type-color in $types {
            &.#{$type} {
                $button-hover: mix($type-color, $color-white, 85);
                $button-active: mix($button-hover, $type-color, 65);

                @if $type == common {
                    background-color: $type-color;
                    border: 1px solid $color-border;
                } @else if $type == primary {
                    background-color: $type-color;
                    border: 1px solid $color-border;
                } @else {
                    background-color: $type-color;
                    border: 1px solid $type-color;
                }

                &.is-disabled {
                    opacity: $opacity-disabled;
                    cursor: not-allowed;
                    background-color: $type-color;
                }
                &:hover:not(.is-disabled) {
                    opacity: 1;
                    background-color: $button-hover;
                }
                &:active:not(.is-disabled) {
                    background-color: $button-active;
                }
            }
        }
        &.large {
            width: 240px;
            height: 48px;
            line-height: 46px;
            font-size: 1.6rem;
        }
        &.medium {
            width: 160px;
            height: 40px;
            line-height: 38px;
            font-size: 1.6rem;
        }
        &.small {
            width: 80px;
            height: 24px;
            line-height: 22px;
            font-size: 1.2rem;
        }
        &.no-pointer {
            pointer-events: none;
        }
    }
</style>