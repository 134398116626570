<template>
    <div class="dashboard-graf">
        <div class="chart-wrap">
            <p class="chart-title">{{$t('総イベント件数')}}<br><strong class="num">{{$t('{0}件', [events.length])}}</strong></p>
            <dashboard-severity-chart v-if="events.length" class="chart" :chartData="chartData" :width="320" :height="320" />
            <div v-else class="chart is-empty"></div>
        </div>
        <div class="chart-labels">
            <p class="title">{{$t('脅威度')}}</p>
            <ul class="list">
                <li class="item" v-for="(severity, index) in severities" :key="index"><gx-label :text="$t(severity.str)" :type="$t(severity.tag)" /></li>
            </ul>
        </div>
    </div>
</template>

<script>
    import Const from '@/static/constants'
    import MixinCommon from '@/mixin/common'

    import gxLabel from '@/components/atoms/label'
    import dashboardSeverityChart from '@/components/organisms/dashboard/severity-chart'

    export default {
        name: 'dashboard-severity-graf',
        mixins: [MixinCommon],
        components: {
            gxLabel,
            dashboardSeverityChart
        },
        props: {
            events: {
                type: Array,
                default: () => { return [] }
            }
        },
        computed: {
            severities() {
                const severities = []
                Object.keys(Const.event.severity).forEach(index => {
                    const severity = Const.event.severity[index]
                    severities.push({
                        str: Const.event.severityType[severity],
                        tag: Const.event.severityClass[severity]
                    })
                })
                return severities
            },
            dataCollection() {
                const counter = []
                Object.keys(Const.event.severity).forEach(index => {
                    const severity = Const.event.severity[index]
                    const filterEvents = this.events.filter(event => event.severity == severity)
                    counter.push(filterEvents.length)
                })
                return counter
            },
            severityColors() {
                const colors = []
                Object.keys(Const.event.severity).forEach(index => {
                    const severity = Const.event.severity[index]
                    colors.push(this.colors[Const.event.severityClass[severity]])
                })
                return colors
            },
            chartData() {
                return {
                        labels: Object.values(Const.event.severityType),
                        datasets: [{
                            data: this.dataCollection,
                            backgroundColor: this.severityColors,
                            borderColor: 'transparent'
                        }]
                    }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .dashboard-graf {
        width: 100%;
        padding: 16px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    .chart-wrap {
        position: relative;
    }
    .chart-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        margin-top: 4px;

        .num {
            font-weight: bold;
        }
    }
    .chart {
        position: relative;

        width: 100%;

        &.is-empty {
            width: 320px;
            height: 320px;
            border-radius: 50%;
            background: radial-gradient(transparent 40%, $color-bg 40%);
        }
    }
    .chart-labels {
        margin: 0 0 8px 24px;

        .title {
            font-size: 1.4rem;
            margin-bottom: 16px;
        }
        .list {

        }
        .item:not(:last-child) {
            margin-bottom: 16px;
        }
    }
</style>