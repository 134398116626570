<template>
    <gx-section>
        <gx-title :level="2">
            <template #default>{{$t('サーバー情報')}}
            </template>
  
            <template #actions>
                <gx-button
                    v-if="!isEdit && !isLoadError"
                    :text="$t('編集')"
                    :size="'small'"
                    :type="'common'"
                    :disabled="!isEditable"
                    @onClick="onEdit" />
            </template>
        </gx-title>

        <template v-if="isLoading"><loading /></template>
        <notice v-else-if="isLoadError" :type="'error'" :label="$t('通信に失敗しました')" />
        <website-basic-form
            v-else
            :prop-data="basicData"
            :certificateList="certificateList"
            :errors="errors"
            :is-edit="isEdit"
            @save="save"
            @cancel="cancel" />
    </gx-section>
    <div class="manual">
                <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
            </div>                         
</template>

<script>
    import MixinCommon from '@/mixin/common'

    import i18n from '@/i18n'

    import Const from '@/static/constants'
    import gxButton from '@/components/atoms/button'
    import gxSection from '@/components/atoms/section'
    import gxTitle from '@/components/atoms/title'
    import gxIconText from '@/components/atoms/icon-text'
    import loading from '@/components/atoms/loading'
    import notice from '@/components/molecules/notice'
    import websiteBasicForm from '@/components/organisms/website/basic-form'
    import WebsiteService from "@/services/websiteService"

    export default {
        name: 'website-basic',
        mixins: [MixinCommon],
        components: {
            gxButton,
            gxSection,
            gxTitle,
            gxIconText,
            loading,
            notice,
            websiteBasicForm
        },
        props: {
            data: { type: Object, required: true },
            certificateList: { type: Object, required: true },
            isLoading: { type: Boolean, required: true },
            isLoadError: { type: Boolean, required: true },
            isEditable: { type: Boolean, required: true },
        },
        data() {
            return {
                errors: {},
                isEdit: false,
                editParams: {
                    username: "",
                    protocol: Const.website.protocol.HTTP,
                    certificate: "",
                    certificate_name: "",
                    ssl_certificate_expire_date: null,
                    hostname: "",
                    real_protocol: Const.website.protocol.HTTPS,
                    real_server: "",
                    real_ip_header_xff: false,
                    real_ip_recursive: false,
                    redirect_http_to_https_flg: false,
                    allow_naked_domain_flg: false,
                    proxy_ssl_server_name_flg: true,
                    use_host_header: false,
                    host_header: '',
                    use_ip_hash: false,
                    proxy_ssl_name: '',
                    protection: 0,
                    action: null,
                    res_block_action: null,
                    req_block_action: null,
                    proxy_read_timeout: 60,
                    res_html: "",
                    state: 0,
                },
            }
        },
        computed: {
            queryParams() {
                const data = {}
                Object.keys(this.editParams).forEach(key => {
                    data[key] = this.data[key]
                })
                return data
            },
            basicData() {
                return this.isEdit ? this.editParams : this.queryParams
            }
        },
        methods: {
            onEdit() {
                this.editParams = Object.assign({}, this.queryParams)
                this.isEdit = true
            },
            cancel() {
                this.errors = {}
                this.isEdit = false
            },
            async save(params) {
                this.$store.dispatch('displayLoading')
                this.errors = {}
                await WebsiteService.updateBasic(this.$route.params.uuid, params)
                    .then(res => {
                        this.$store.dispatch('openToast', {type: 'success', label: '更新しました'})
                        this.$emit('update', res)
                        this.isEdit = false
                    }).catch(err => {
                        if (err.response.status == 400) {
                            this.errors = err.response.data
                        } else {
                            this.$store.dispatch('openToast', {type: 'error', label: '更新に失敗しました'})
                        }
                    })
                this.$store.dispatch('hideLoading')
            },
            manual(){
  		        return '/manual/wiki/Website_detail_' + i18n.locale
  		    },      
        },
        mounted() {
            this.$emit('update')  // fetch with no arguments
        }
    }
</script>
