<template>
    <dl :class="['terms', {'terms--half': half}]">
        <slot />
    </dl>
</template>

<script>
    export default {
        name: 'search-terms',
        props: {
            half: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .terms {
        display: grid;
        grid-template: auto / 120px 1fr 64px 120px 1fr;
        grid-gap: 20px 0;
        align-items: center;

        &--half {
            grid-template: auto / 120px 1fr;
        }
    }
</style>