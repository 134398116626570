export const severity = Object.freeze({
    HIGH: 4,
    MIDDLE: 2,
    LOW: 1
})
export const severityType = Object.freeze({
    4: '高',
    2: '中',
    1: '低'
})
export const severityColor = Object.freeze({
    4: 'SUCCESS',
    2: 'SUCCESS',
    1: 'SUCCESS'
})
export const severityClass = Object.freeze({
    4: 'high',
    2: 'middle',
    1: 'low'
})
export const protocolType = Object.freeze({
    1: 'HTTP',
    2: 'HTTPS'
})
export const action = Object.freeze({
    BLOCK: 1,
    // REDIRECT: 2,
    DETECTION: 3,
})
export const actionType = Object.freeze({
    1: 'ブロック',
    // 2: 'リダイレクト',
    3: '検出のみ'
})
export const actionIcon = Object.freeze({
    1: 'block',
    // 2: 'redirect',
    3: 'search'
})
export const actionColor = Object.freeze({
    1: 'SUCCESS',
    // 2: 'SUCCESS',
    3: 'SUCCESS'
})

export const attackType = Object.freeze([
    {value:  101, str: 'カスタム・シグネチャ'},
    {value:  201, str: 'アクセス制御'},
    {value:  401, str: 'グループアクセス制御'},
    {value:  801, str: 'WordPress防御'},
    {value:  802, str: 'EC-CUBE防御'},
    {value:  803, str: 'MovableType防御'},
    {value: 1001, str: 'クロスサイト・スクリプティング (XSS)'},
    {value: 1002, str: 'SQLインジェクション'},
    {value: 1003, str: 'OSコマンドインジェクション'},
    {value: 1004, str: 'ディレクトリトラバーサル'},
    {value: 1005, str: '改行コードインジェクション'},
    {value: 1006, str: 'XPathインジェクション'},
    {value: 1007, str: 'LDAPインジェクション'},
    {value: 1008, str: 'XXE'},
    {value: 1095, str: 'Unicodeを利用した攻撃'},
    {value: 1099, str: 'その他'},
    {value: 1901, str: 'リクエストURLチェック'},
    {value: 1920, str: 'リクエスト形式チェック'},
    {value: 1930, str: 'メソッドチェック'},
    {value: 1940, str: '動作テストシグネチャ'},
    {value: 1950, str: 'レスポンスチェック'},
    {value: 1970, str: 'ステータスコード'},
])

export const CMSType = Object.freeze([
    {str: 'EC-CUBE', name: 'eccube', check: false, path: "^."},
    {str: 'Wordpress', name: 'wordpress', check: false, path: "^."},
    {str: 'Movable Type', name: 'movabletype', check: false, path: "^."},
])

export const detailTermsType = Object.freeze({
    timestamp:            'タイムスタンプ',
    attack_src:           '攻撃元',
    attack_type:          '種類',
    detection_code:       '検出情報',
    matched_at:           '検出箇所',
    matched_strings:      '検出文字列',
    detection_strings:    '検出文字列（全体）',
    req_header_host:      'ホスト',
    req_method:           'メソッド',
    req_protocol:         'プロトコル',
    req_uri:              'パス',
    res_action:           '状態',
    sig:                  'シグネチャ',
    event_id:             'イベントID'
})

