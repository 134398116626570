<template>
    <div class="login-box">
        <p class="login-box__lead">{{$t('ログイン用のマジックリンクを送信します。')}}</p>

        <notice v-for="err in errors" class="notice" :type="'error'" :label="err" />

        <form @submit.prevent="submit" @invalid.capture.prevent="onInvalid" class="login-box__form">
            <ul class="form-items">
              <li class="form-item">
                <gx-form-input
                    :label="$t('グループID')"
                    id="login-group"
                    class="form-item__input"
                    :placeholder="'SG-group001'"
                    v-model="groupname"
                    required
                    :ref="'input-groupname'"
                    :propsError="groupnameErrors.length > 0"
                    :propsErrorMsg="arr2str(groupnameErrors)"
                />
              </li>
                <li class="form-item">
                    <gx-form-input
                        :label="$t('ユーザーID')"
                        id="login-user"
                        class="form-item__input"
                        :placeholder="'SG-user001'"
                        v-model="username"
                        required
                        :ref="'input-username'"
                        :propsError="usernameErrors.length > 0"
                        :propsErrorMsg="arr2str(usernameErrors)"
                    />
                </li>
                <li class="form-item">
                    <gx-form-input
                        :label="$t('パスワード')"
                        id="login-pass"
                        class="form-item__input"
                        :placeholder="'******'"
                        :type="'password'"
                        v-model="password"
                        required
                        :ref="'input-password'"
                        :propsError="passwordErrors.length > 0"
                        :propsErrorMsg="arr2str(passwordErrors)"
                    />
                </li>
            </ul>
            <p class="form-buttons"><gx-button :text="$t('送信')" /></p>
        </form>
    </div>
</template>

<script>
    import gxButton from '@/components/atoms/button'
    import gxIconText from '@/components/atoms/icon-text'
    import gxFormInput from '@/components/atoms/form/input'
    import notice from '@/components/molecules/notice'
    import animationBackground from '@/components/atoms/effect/animationBackground'
    import LoginService from '@/services/loginService'
    export default {
        name: 'login',
        layout: 'single',
        components: {
            gxButton,
            gxIconText,
            gxFormInput,
            animationBackground,
            notice
        },
        data() {
            return {
                groupname: "",
                username: "",
                password: "",
                errors: [],
                groupnameErrors: [],
                usernameErrors: [],
                passwordErrors: [],
            }
        },
        methods: {
            arr2str: arr => arr.join('<br>'),
            onInvalid() {
                Object.keys(this.$refs).forEach((ref) => {
                    if(ref.indexOf('input-') > -1 && this.$refs[ref].onValidator) {
                        this.$refs[ref].onValidator()
                    }
                })
            },
            async submit() {
                this.setErrors({})
                this.$store.dispatch('displayLoading')

                await LoginService.login(this.groupname, this.username, this.password).then(() => {
                    this.$router.replace('/login/confirm')
                }).catch(err => {
                    if(err.response && err.response.data) {
                        this.setErrors(err.response.data)
                    }
                }).finally(() => {
                    this.$store.dispatch('hideLoading')
                })
            },
            stringToArray(value) {
                if (!value) return []
                return typeof value == 'string' ? [value] : value
            },
            setErrors(data) {
                this.errors = this.stringToArray(data.non_field_errors)
                this.groupnameErrors = this.stringToArray(data.groupname)
                this.usernameErrors = this.stringToArray(data.username)
                this.passwordErrors = this.stringToArray(data.password)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '~@/assets/scss/_variable.scss';

    .login-box {
        position: relative;
        background-color: $color-parts-bg;
        width: 400px;
        padding: 20px;
        border-radius: 10px;
        z-index: 1;

        &__lead {
            margin-bottom: 40px;
        }
        &__error {
            color: $color-error;
        }
        &__form {
            margin-bottom: 40px;
        }
        &__note {
            text-align: center;
        }
    }
    .form-items {
        margin-bottom: 32px;
    }
    .form-item {

        &:not(:first-child) {
            margin-top: 24px;
        }
        &__label {
            display: block;
            margin-bottom: 8px;
        }
        &__input {
            display: block;
        }
    }
    .form-buttons {
        display: flex;
        justify-content: center;
    }
    .notice {
        margin-bottom: $notice-margin;
    }
</style>