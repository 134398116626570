<template>
    <div class="initial-box">
        <p class="label"><strong>{{label}}</strong></p>
        <p class="lead">{{lead}}</p>
        <button-wrap class="button-wrap" :buttons="buttons" @onClick="onClick" />
    </div>
</template>

<script>
    import buttonWrap from '@/components/molecules/button-wrap'
    export default {
        name: 'initial-box',
        components: {
            buttonWrap
        },
        props: {
            label: {
                type: String,
                required: true
            },
            lead: {
                type: String,
                default: ''
            },
            buttons: {
                type: Array,
                default: () => []
            }
        },
        methods: {
            onClick() {
                this.$emit("onClick")
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';

    .initial-box {
        background: $color-parts-bg;
        padding: 40px;
        text-align: center;
    }
    .label {
        font-size: 2.2rem;
    }
    .lead {
        margin-top: 40px;
    }
    .button-wrap {
        margin-top: 56px;
    }
</style>