<template>
    <layout-main
        :title="$t('ウェブサイト新規追加')"
        :navs="navs"
    >
        <template #default>
            <gx-section>
                <gx-title :level="2">{{$t('サーバー情報')}}</gx-title>
                <website-basic-form :isEdit="true" :errors="errors" @save="save" @cancel="locationToWebsiteSearch" />
            </gx-section>
            <div class="manual">
                <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
            </div>               
        </template>
    </layout-main>
</template>

<script>
    import layoutMain from '@/layouts/main'
    import gxSection from '@/components/atoms/section'
    import gxTitle from '@/components/atoms/title'
    import gxIconText from '@/components/atoms/icon-text'
    import websiteBasicForm from '@/components/organisms/website/basic-form'
    import {mapGetters} from 'vuex'
    import WebsiteService from "@/services/websiteService"

    import i18n from '@/i18n'

    export default {
        name: 'website-register',
        components: {
            layoutMain,
            gxSection,
            gxTitle,
            gxIconText,
            websiteBasicForm
        },
        data() {
            return {
                errors: {}
            }
        },
        computed: {
            ...mapGetters([
                'userAccount'
            ]),
            navs() {
                return [{
                    to: '/website',
                    label: i18n.t('ウェブサイト')
                }, {
                    to: '/website/register',
                    label: i18n.t('新規追加')
                }]
            },
            // buttons() {
            //     return [{
            //         text: i18n.t('キャンセル'),
            //         type: 'common',
            //         size: 'large',
            //         onClick: "cancel"
            //     }, {
            //         text: i18n.t('保存'),
            //         type: 'primary',
            //         size: 'large',
            //         disabled: !this.userAccount.is_website_creatable,
            //     }]
            // },
            basicTerms() {
                return [
                    'username',
                    'protocol',
                    'hostname',
                    'real_protocol',
                    'real_server',
                    'protection',
                    'action',
                    'res_block_action',
                    'req_block_action'
                ]
            },
        },
        methods: {
            locationToWebsiteSearch() {
                this.$router.push('/website')
            },
            async save(params) {
                this.$store.dispatch('displayLoading')
                this.errors = {}

                await WebsiteService.register(params)
                    .then(() => {
                        this.$store.dispatch('openToast', {type: 'success', label: i18n.t('保存しました')})
                        this.locationToWebsiteSearch()
                    }).catch(err => {
                        if (err.response.status == 400) {
                            this.errors = err.response.data
                        } else {
                            this.$store.dispatch('openToast', {type: 'error', label: i18n.t('保存に失敗しました')})
                        }
                    })
                this.$store.dispatch('hideLoading')
            },
            manual(){
  		        return '/manual/wiki/Website_edit_' + i18n.locale
  		    },             
        }
    }
</script>