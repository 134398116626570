<template>
    <gx-section>
        <template v-if="isLoading"><loading /></template>
        <notice v-else-if="isLoadError" :type="'error'" :label="$t('通信に失敗しました')" />
        <template v-else>
            <gx-title :level="2">
                <template #default>
                    <template>{{wafCustomData.name}} {{isEdit ? $t('編集') : $t('詳細')}}</template>
                </template>
                <template v-if="!isEdit" #actions>
                    <span class="actions">
                        <gx-button :text="$t('編集')" :size="'small'" @onClick="startEdit" class="actions__item" :disabled="!isEditable" />
                        <gx-icon :tag="'a'" :text="$t('削除')" :icon="'trash'" class="actions__item" @onClick="deleteItem(wafcusId)" :disabled="!isEditable" href="#" />
                    </span>
                </template>
            </gx-title>

            <website-waf-custom-form
                :prop-data="wafCustomData"
                :custom-signatures="customSignatures"
                :errors="formErrorMsg"
                :is-edit="isEdit"
                @save="save"
                @cancel="cancel"
                @invalid="scrollToTop"
            />
            <div class="manual">
                <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
            </div>               
        </template>
    </gx-section>
</template>

<script>
    import Const from '@/static/constants'
    import MixinCommon from '@/mixin/common'

    import gxIcon from '@/components/atoms/icon'
    import gxSection from '@/components/atoms/section'
    import gxButton from '@/components/atoms/button'
    import gxTitle from '@/components/atoms/title'
    import gxIconText from '@/components/atoms/icon-text'
    import loading from '@/components/atoms/loading'
    import notice from '@/components/molecules/notice'
    import websiteWafCustomForm from '@/components/organisms/website/waf-custom-form'
    import WebsiteService from '@/services/websiteService'
    import ManualService from '@/services/manualService'

    import i18n from '@/i18n'

    export default {
        name: 'website-waf-custom-detail',
        mixins: [MixinCommon],
        components: {
            gxIcon,
            gxSection,
            gxButton,
            gxTitle,
            gxIconText,
            loading,
            notice,
            websiteWafCustomForm
        },
        props: {
            isEditable: {
                type: Boolean,
                required: true,
            }
        },
        data() {
            return {
                isEdit: false,
                wafCustomData: {},
                formErrorMsg: {},
                customSignatures: [],
                isLoading: false,
                isLoadError: false,
            }
        },
        computed: {
            websiteId() {
                return this.$route.params.uuid
            },
            wafcusId() {
                return this.$route.params.wafcus_id
            },
            signaturePositions() {
                return Const.wafCustom.signaturePositions
            },
            isDisablePriority() {
                return this.customSignatures.length === 1
            },
        },
        methods: {
            assignWafCus(res) {
                Object.keys(res).forEach(key => {
                    if (['param_values', 'req_header_field_values', 'cookie_values'].includes(key)) {
                        this.$set(this.wafCustomData, key, res[key].join(', '))
                    } else {
                        this.$set(this.wafCustomData, key, res[key])
                    }
                })
            },
            async fetchWafCus() {
                this.isLoading = true
                this.isLoadError = false
                await WebsiteService.fetchWafCusDetail(this.websiteId, this.wafcusId).then(this.assignWafCus)
                .catch(() => {
                    this.isLoadError = true
                }).finally(() => {
                    this.isLoading = false
                })
            },
            getCustomSignatures() {
                WebsiteService.fetchPriority(this.websiteId).then(res => {
                    this.customSignatures = res.map(item => ({
                        str: item.name,
                        value: item.priority
                    }))
                }).catch(() => {
                    this.isLoadError = true
                })
            },
            startEdit() {
                this.isEdit = true
            },
            deleteItem(uuid) {
                this.$store.dispatch('openModalWithParam', {target: 'website-waf-custom-delete', param: uuid})
            },
            cancel() {
                this.isEdit = false
            },
            // FIXME: waf-custom-detail.vueのフォーム内でリクエストする
            async save(update_custom_signature) {
                this.$store.dispatch('displayLoading')
                await WebsiteService.updateWafCus(this.websiteId, this.wafcusId, update_custom_signature).then(res => {
                    this.formErrorMsg = {}
                    this.$store.dispatch('openToast', {type: 'success', label: i18n.t('更新しました')})
                    this.$router.push({name: Const.common.paths.WEBSITE_WAFCUS, params: { uuid: this.$route.params.uuid }})
                    this.assignWafCus(res)
                }).catch(err => {
                    if (err.response.status === 400) {
                        this.formErrorMsg = err.response.data
                        this.scrollToTop()
                    } else {
                        this.$store.dispatch('openToast', {type: 'error', label: i18n.t('更新に失敗しました')})
                    }
                }).finally(() => {
                    this.$store.dispatch('hideLoading')
                })
            },
            manual(){
  		        if(this.isEdit){
  		            return '/manual/wiki/Custom_sig_edit_' + i18n.locale
  		        }else{
  		            return '/manual/wiki/Custom_sig_detail_' + i18n.locale
                }
            },
        },
        created() {
            this.fetchWafCus()
            this.getCustomSignatures()
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .actions {
        display: flex;
        justify-content: flex-end;

        &__item:not(:first-child) {
            margin-left: 24px;
        }
    }
</style>
