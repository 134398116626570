<template>
	<div class="animation-background">
		<div class="slot">
			<slot>
				<!-- DEFALUT -->
				<div class="default-content" @mousemove="onMouseMove">
					<ul class="squares" ref="squares">
						<li v-for="(item, idx) in squareElements" :key="idx"
							:style="`
								width: ${item.size}px;
								height: ${item.size}px;
								left: ${item.left}%;
								animation-delay: ${item.delay}s;
							`"
							:class="`depth${item.depth}`"
						/>
					</ul>
 				</div>
			</slot>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'animation-background',
		data() {
			return {
				squareSize: 80,
				squareElements: [],
				width: 0,
			}
		},
		mounted() {
			this.$data.width = this.$el.clientWidth
			const squareNum = this.getRandomInt(10, 30)

			for (let i = 0; i < squareNum; i++) {
				const depth = this.getRandomInt(0, 4)
				const delay = Math.round(Math.random() * 600) / 10
				const size = Math.abs(this.$data.squareSize * (1.0 + (depth - 2) / 5))
				const initialLeft = Math.round(Math.random() * 100)

				this.$data.squareElements.push({
					depth: depth,
					delay: delay,
					size: size,
					initialLeft: initialLeft,
					left: initialLeft,
					maxPanning: (depth + 0.5) * 1.5
				})
			}
		},
		methods: {
			onMouseMove(evt) {
				const ratio = (this.width - evt.clientX) / this.width - 0.5

				for (let i in this.$data.squareElements) {
					const element = this.$data.squareElements[i]
					const target = element.initialLeft - ratio * element.maxPanning

					setTimeout(() => {
						element.left = element.initialLeft - ratio * element.maxPanning
					}, 100);
				}
			},
			getRandomInt(min, max) {
				return Math.round(Math.random() * (max - min) + min)
			}
		}
	}
</script>
<style lang="scss" scoped>
	.animation-background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;

		background: linear-gradient(#f7f2ff, #a86c27);

		.slot {
			position: absolute;
			width: 100%;
			height: 100%;
			overflow: hidden;

			.default-content {
				position: absolute;
				width: 100%;
				height: 100%;
				overflow: hidden;

				ul.squares {
					position: absolute;
					width: 100%;
					height: 100%;
					overflow: hidden;

					li {
						position: absolute;
						display: block;
						list-style: none;
						background: rgba(255, 255, 255, 0.2);
						animation: animate 25s linear infinite;
						bottom: -90px;
						
						&.depth0 {
							filter: blur(10px);
						}
						&.depth1 {
							filter: blur(5px);
						}
						&.depth2 {
							filter: blur(0px);
						}
						&.depth3 {
							filter: blur(5px);
						}
						&.depth4 {
							filter: blur(10px);
						}
					}
				}
			}
		}
	}
	@keyframes animate {
		0%{
			transform: translateY(10vh) rotate(0deg);
			opacity: 1;
			border-radius: 0;
		}

		100%{
			transform: translateY(-120vh) rotate(720deg);
			opacity: 0;
			border-radius: 50%;
		}
	}
</style>
