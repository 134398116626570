<template>
    <search-box :noPointer="isLoading" @search="search" @clear="clear">
        <template>
            <search-terms>
                <search-term :type="'title'">{{$t('チケットID')}}</search-term>
                <search-term :type="'data'">
                    <gx-form-input :placeholder="$t('チケットID')" v-model="ticket_id" />
                </search-term>

                <search-term :type="'title'">{{$t('タイトル')}}</search-term>
                <search-term :type="'data'">
                    <gx-form-input :placeholder="$t('タイトル')" v-model="title" />
                </search-term>

                <search-term :type="'title'">{{$t('作成日時')}}</search-term>
                <search-term :type="'data'">
                    <date-range :startDate="start_create_time" :endDate="end_create_time" @input="updateRangeCreateDate" />
                </search-term>

                <search-term :type="'title'">{{$t('更新日時')}}</search-term>
                <search-term :type="'data'">
                    <date-range :startDate="start_update_time" :endDate="end_update_time" @input="updateRangeUpdateDate" />
                </search-term>

                <search-term v-if="userAccount.is_support" :type="'title'">{{$t('内部ステータス')}}</search-term>
                <search-term v-if="userAccount.is_support" :type="'data'">
                    <gx-form-select
                        :name="$t('内部ステータス')"
                        v-model="internal_status"
                        :options="internalStatus"
                        value-attr="id"
                        str-attr="internal_status"
                    />
                </search-term>

                <search-term :type="'title'">{{$t('ステータス')}}</search-term>
                <search-term :type="'data'">
                    <gx-form-select
                        :name="$t('ステータス')"
                        v-model="status"
                        :options="getSelectorValues(Const.support.statusArray)"
                        value-attr="value"
                        str-attr="str"
                    />
                </search-term>

                <search-term :type="'title'"></search-term>
                <search-term :type="'data'">
                    <gx-form-checkbox
                        v-if="userAccount.is_support"
                        class="label"
                        :disabled="select_internal_status_flg"
                        v-bind:checked="internalstatus_close"
                        @check="internalstatus_close = !internalstatus_close"
                    >{{$t('クローズを表示')}}</gx-form-checkbox>
                    <gx-form-checkbox
                        v-if="userAccount.is_support"
                        class="label_nosupport"
                        :disabled="select_internal_status_flg"
                        v-bind:checked="internalstatus_nosupport"
                        @check="internalstatus_nosupport = !internalstatus_nosupport"
                    >{{$t('対応不要を表示')}}</gx-form-checkbox>
                </search-term>

                <search-term :type="'title'"></search-term>
                <search-term :type="'data'">
                    <gx-form-checkbox
                        class="label"
                        :disabled="select_status_flg"
                        v-bind:checked="status_close"
                        @check="status_close = !status_close"
                    >{{$t('クローズを表示')}}</gx-form-checkbox>
                </search-term>

                <search-term v-if="userAccount.is_support" :type="'title'">{{$t('グループID')}}</search-term>
                <search-term v-if="userAccount.is_support" :type="'data'"><gx-form-input :placeholder="$t('グループID')" v-model="groupname" /></search-term>

                <search-term v-if="userAccount.is_support" :type="'title'">{{$t('タグ')}}</search-term>
                <search-term v-if="userAccount.is_support" :type="'data'"><gx-form-input :placeholder="$t('タグ')" v-model="tag" /></search-term>

                <search-term v-if="userAccount.is_support" :type="'title'">{{$t('グループ名')}}</search-term>
                <search-term v-if="userAccount.is_support" :type="'data'"><gx-form-input :placeholder="$t('グループ名')" v-model="group_description" /></search-term>
            </search-terms>
        </template>
    </search-box>
</template>

<script>
    import Const from '@/static/constants'

    import gxIconText from '@/components/atoms/icon-text'
    import gxFormCheckbox from '@/components/atoms/form/checkbox'
    import gxFormInput from '@/components/atoms/form/input'
    import gxFormSelect from '@/components/atoms/form/select'
    import searchTerm from '@/components/atoms/search/term'
    import dateRange from '@/components/molecules/form/date-range'
    import searchBox from '@/components/molecules/search/box'
    import searchTerms from '@/components/molecules/search/terms'
    import termList from '@/components/molecules/form/term-list'

    import {
        supportService,
        TicketCreateParam, TicketSearchParam, TicketTag, TicketMailUser, TicketFile,
    } from '@/services/supportService'

    import DateMixin from "@/mixin/date";

    import i18n from "@/i18n"

    export default {
        name: 'ticket-search',
        mixins: [DateMixin],
        components: {
            gxIconText,
            gxFormCheckbox,
            gxFormInput,
            gxFormSelect,
            searchTerm,
            dateRange,
            searchBox,
            searchTerms,
            termList
        },
        props: {
            params: {
                type: Object,
                default: () => Object.assign({}, this.defaultForm)
            },
            internalStatus: {
                type: Array,
                default: () => []
            },            
            isLoading: {
                type: Boolean,
                default: false
            },
            userAccount: {},
        },
        data() {
            return {
                Const: Const,
                select_status_flg: false,
                select_internal_status_flg: false
            }
        },
        computed: {
            defaultForm() {
                return new TicketSearchParam({})
            },
            // チケットID
            ticket_id: {
                set(val){
                    this.$emit("updateQuery", 'ticket_id', val)
                },
                get(){
                    return this.params.ticket_id
                }
            },
            // タイトル
            title: {
                set(val){
                    this.$emit("updateQuery", 'title', val)
                },
                get(){
                    return this.params.title
                }
            },
            // 内部ステータス
            internal_status: {
                set(val){
                    this.internalstatus_close = false
                    this.internalstatus_nosupport = false
                    this.$emit("updateQuery", 'internal_status', val)
                    if(val == ""
                    ){
                        this.select_internal_status_flg = false
                    }else{
                        this.select_internal_status_flg = true
                    }
                },
                get(){
                    return this.params.internal_status
                }
            },
            // 内部ステータス(クローズを表示)
            internalstatus_close: {
                set(val){
                    this.$emit("updateQuery", 'internalstatus_close', val)
                },
                get(){
                    return this.params.internalstatus_close
                }
            },
            // 内部ステータス(対応不要を表示)
            internalstatus_nosupport: {
                set(val){
                    this.$emit("updateQuery", 'internalstatus_nosupport', val)
                },
                get(){
                    return this.params.internalstatus_nosupport
                }
            },
            // ステータス
            status: {
                set(val){
                    this.status_close = false
                    this.$emit("updateQuery", 'status', [val])
                    if(val == Const.support.statusArray[1].value || 
                       val == Const.support.statusArray[2].value ||
                       val == Const.support.statusArray[3].value
                    ){
                        this.select_status_flg = true
                    }else{
                        this.select_status_flg = false
                    }
                },
                get(){
                    return this.params.status
                }
            },
            // 内部ステータス(クローズを表示)
            status_close: {
                set(val){
                    this.$emit("updateQuery", 'status_close', val)
                },
                get(){
                    return this.params.status_close
                }
            },

            // 作成日時(始)
            start_create_time: {
                set(val){
                    this.$emit("updateQuery", 'start_create_time', val)
                },
                get(){
                    return this.params.start_create_time
                }
            },
            // 作成日時(終)
            end_create_time: {
                set(val){
                    this.$emit("updateQuery", 'end_create_time', val)
                },
                get(){
                    return this.params.end_create_time
                }
            },
            // 更新日時(始)
            start_update_time: {
                set(val){
                    this.$emit("updateQuery", 'start_update_time', val)
                },
                get(){
                    return this.params.start_update_time
                }
            },
            // 更新日時(終)
            end_update_time: {
                set(val){
                    this.$emit("updateQuery", 'end_update_time', val)
                },
                get(){
                    return this.params.end_update_time
                }
            },
            // グループID
            groupname: {
                set(val){
                    this.$emit("updateQuery", 'groupname', val)
                },
                get(){
                    return this.params.groupname
                }
            },
            // タグ
            tag: {
                set(val){
                    this.$emit("updateQuery", 'tag', val)
                },
                get(){
                    return this.params.tag
                }
            },
            // グループ名
            group_description: {
                set(val){
                    this.$emit("updateQuery", 'group_description', val)
                },
                get(){
                    return this.params.group_description
                }
            },
            severities() {
                const severities = []
                Object.keys(Const.event.severity).forEach((index) => {
                    const severity = Const.event.severity[index]
                    severities.push({
                        str: Const.event.severityType[severity],
                        // icon: Const.event.severityType[severity], // @todo 良い感じのアイコン付ける？
                        value: severity,
                        color: Const.common.colors[Const.event.severityColor[severity]]
                    })
                })
                return i18n.translateOptionValues(severities)
            },
        },
        methods: {
            getSelectorValues(options) {
                return i18n.translateOptionValues(options)
            },
            updateRangeCreateDate(val, key) {
                if(key === "startDate") {
                    this.start_create_time = val
                } else if(key === "endDate") {
                    this.end_create_time = val
                }
            },
            updateRangeUpdateDate(val, key) {
                if(key === "startDate") {
                    this.start_update_time = val
                } else if(key === "endDate") {
                    this.end_update_time = val
                }
            },
            updateCheckBox(key, val) {
                if(this[key]) {
                    if(this[key].includes(val)) {
                        const index = this[key].findIndex(v => v == val)
                        this[key].splice(index, 1)
                    } else {
                        this[key].push(val)
                    }
                }
            },
            search() {
                this.$emit("updateQuery", 'page', '1')
                this.$emit("search")
            },
            clear() {
                const defaultForm = Object.assign({}, this.defaultForm)
                Object.keys(defaultForm).forEach(key => {
                    if(Array.isArray(defaultForm[key])) {
                        this.$emit("updateQuery", key, [])
                    } else {
                        this.$emit("updateQuery", key, defaultForm[key])
                    }
                })
                // クローズを表示関連のフラグを初期化する
                this.select_status_flg = false
                this.select_internal_status_flg = false
            }
        }
    }
</script>

<style lang="scss" scoped>
.label_nosupport {
    margin-left: 1em;
}
</style>
