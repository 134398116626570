<template>
    <tr :class="['tr', `t${type}__tr`]">
        <slot />
    </tr>
</template>

<script>
    import gxTableCell from '@/components/atoms/table/cell'

    export default {
        name: 'table-tr',
        components: {
            gxTableCell
        },
        props: {
            type: {
                type: String,
                validator: (value) => {
                    return ['head', 'body'].indexOf(value) !== -1
                },
                default: 'body'
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';

    .tbody__tr {
        background-color: $color-parts-bg;
        border-bottom: 1px solid $color-hr;
    }
</style>