<template>
    <gx-section>
        <template v-if="isLoading || isLoadError || !wafcusData.list.length">
            <gx-title :level="2">{{$t('カスタム・シグネチャ一覧')}}</gx-title>
            <template v-if="isLoading"><loading /></template>
            <notice v-else-if="isLoadError" :type="'error'" :label="$t('通信に失敗しました')" />
            <initial-box
                v-else-if="!wafcusData.list.length"
                :label="$t('カスタム・シグネチャが設定されていません')"
                :lead="$t('適用するカスタム・シグネチャを設定してください')"
                :buttons="initialButtons"
                @onClick="addSignature"
            />
        </template>
        <template v-else>
            <gx-title :level="2">
                <template #default>{{$t('カスタム・シグネチャ一覧')}}</template>
                <template #actions>
                    <gx-button :text="$t('追加')" :size="'small'" @onClick="addSignature" :disabled="!isEditable" />
                </template>
            </gx-title>

            <table-base>
                <template #head>
                    <table-tr type="head">
                        <gx-table-cell :type="'th'">{{$t('No.')}}</gx-table-cell>
                        <gx-table-cell :type="'th'">{{$t('カスタム・シグネチャ名')}}</gx-table-cell>
                        <gx-table-cell :type="'th'">{{$t('状態')}}</gx-table-cell>
                        <gx-table-cell :type="'th'">{{$t('除外対象')}}</gx-table-cell>
                        <gx-table-cell :type="'th'">{{$t('コメント')}}</gx-table-cell>
                        <gx-table-cell :type="'th'"></gx-table-cell>
                    </table-tr>
                </template>
                <template #body>
                    <table-tr v-for="wafcus in wafcusData.list" :key="wafcus.website_wafcus_id">
                        <gx-table-cell center min>{{wafcus.priority}}</gx-table-cell>
                        <gx-table-cell center class='custom_signature'>{{wafcus.name}}</gx-table-cell>
                        <gx-table-cell>{{getActionsType(wafcus.action)}}</gx-table-cell>
                        <gx-table-cell v-if="wafcus.exc_select != null">{{getExcType(wafcus.exc_select)}} :  {{wafcus.exc_value}} </gx-table-cell>
                        <gx-table-cell v-else></gx-table-cell>
                        <gx-table-cell>{{wafcus.comment}}</gx-table-cell>
                        <gx-table-cell min>
                            <span class="actions">
                                <gx-button
                                    :text="$t('詳細')"
                                    :type="'common'"
                                    :size="'small'"
                                    class="actions__item"
                                    @onClick="selectSignature(wafcus.website_wafcus_id)"
                                />
                                <gx-icon :tag="'a'" :text="$t('削除')" :icon="'trash'" class="actions__item" @onClick="deleteItem(wafcus.website_wafcus_id)" :disabled="!isEditable" href="#" />
                            </span>
                        </gx-table-cell>
                    </table-tr>
                </template>
            </table-base>
        </template>
        <div class="manual">
                <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
            </div>   
    </gx-section>
</template>

<script>
    import Const from '@/static/constants'
    import gxIcon from '@/components/atoms/icon'
    import gxSection from '@/components/atoms/section'
    import gxButton from '@/components/atoms/button'
    import gxTitle from '@/components/atoms/title'
    import gxTableCell from '@/components/atoms/table/cell'
    import gxIconText from '@/components/atoms/icon-text'
    import loading from '@/components/atoms/loading'
    import tableBase from '@/components/molecules/table/base'
    import tableTr from '@/components/molecules/table/tr'
    import notice from '@/components/molecules/notice'
    import initialBox from '@/components/molecules/initial-box'
    import WebsiteService from '@/services/websiteService'
    import ManualService from '@/services/manualService'

    import i18n from '@/i18n'

    export default {
        name: 'website-waf-custom-register',
        components: {
            gxIcon,
            gxSection,
            gxButton,
            gxTitle,
            gxTableCell,
            gxIconText,
            loading,
            tableBase,
            tableTr,
            notice,
            initialBox
        },
        props: {
            isUpdatedWafcus: {
                type: Boolean,
                default: false
            },
            isEditable: {
                type: Boolean,
                required: true,
            },
            limit: {
                type: Number,
                required: true,
            }
        },
        data() {
            return {
                wafcusData: {
                    list: []
                },
                isLoading: true,
                isLoadError: false
            }
        },
        computed: {
            initialButtons() {
                return [
                    {
                        text: i18n.t('カスタム・シグネチャ新規設定'),
                        type: 'primary',
                        size: 'large',
                        onClick: "onClick",
                    }
                ]
            }
        },
        watch: {
            isUpdatedWafcus(bool) {
                if (bool) {
                    this.fetchWafcusList()
                    this.$emit('updateIsUpdatedWafcus', false)
                }
            }
        },
        methods: {
            async fetchWafcusList() {
                this.isLoading = true
                this.isLoadError = false
                await WebsiteService.fetchWafCusList(this.$route.params.uuid).then(res => {
                    this.$set(this.wafcusData, 'list', res ? res : [])
                }).catch(err => {
                    this.isLoadError = true
                }).finally(() => {
                    this.isLoading = false
                })
            },
            addSignature() {
                if (this.wafcusData.list.length < this.limit) {
                    this.$router.push({
                        name: Const.common.paths.WEBSITE_WAFCUS_REGISTER,
                        params: { uuid: this.$route.params.uuid }
                    })
                } else {
                    alert(i18n.t('カスタム・シグネチャが{0}件登録されています。', [this.limit]))
                }
            },
            deleteItem(wafcus_id) {
                this.$store.dispatch('openModalWithParam', {target: 'website-waf-custom-delete', param: wafcus_id})
            },
            selectSignature(wafcus_id) {
                this.$router.push({
                    name: Const.common.paths.WEBSITE_WAFCUS_DETAIL,
                    params: { uuid: this.$route.params.uuid, wafcus_id }
                })
            },
            getActionsType(val){
                return i18n.t(Const.wafCustom.actionsType[val])
            },
            getExcType(val){
                for(var i = 0; i < Const.wafCustom.excSelectValues.length; i++ ){
                    if(Const.wafCustom.excSelectValues[i].value == val){
                        return i18n.t(Const.wafCustom.excSelectValues[i].str)
                    }
                }
            },
            manual(){
  		        return '/manual/wiki/Custom_sig_list_' + i18n.locale
  		    },     
        },
        created() {
            this.fetchWafcusList()
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .actions {
        display: flex;
        justify-content: flex-end;

        &__item:not(:first-child) {
            margin-left: 24px;
        }
    }
    .custom_signature {
        min-width: 196px;
    }
</style>