<template>
    <layout-main :title="$t('イベント')" :navs="navs">
        <template #default>
            <event-search :params="formParams" @updateQuery="updateQuery" @search="search" />
            <template v-if="isLoading"><loading /></template>
            <notice v-else-if="isLoadError" :type="'error'" :label="$t('データが取得出来ませんでした')" />
            <template v-else>
                <div class="table-wrap" v-if="events.length">
                    <table-actions :pager="pager" :checkCount="data.checked.length" :downloadText="$t('CSVダウンロード')" @allCheck="allCheck" @onClick="downloadCsv"/>
                    <event-table :events="events" :checked="data.checked" :sort="sort" @updateOrder="updateOrder" @updateCheckBox="updateCheckBox"/>
                    <pager :pager="pager" @changePage="changePage" @changePageSize="changePageSize" />
                </div>
                <nodata-box v-else />
                <div class="manual">
                    <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
                </div>
            </template>
        </template>
    </layout-main>
</template>

<script>
import { diff } from 'deep-object-diff'
import layoutMain from '@/layouts/main'
import loading from '@/components/atoms/loading'
import tableActions from '@/components/molecules/table/actions'
import pager from '@/components/molecules/pager'
import nodataBox from '@/components/molecules/nodata-box'
import notice from '@/components/molecules/notice'
import eventSearch from '@/components/organisms/event/search'
import eventTable from '@/components/organisms/event/table'
import gxIconText from '@/components/atoms/icon-text'
import EventService from '@/services/eventService'

import DateMixin from '@/mixin/date'
import DownloadMixin from '@/mixin/download'

import i18n from '@/i18n'
import ManualService from '@/services/manualService'

export default {
    name: 'event',
    watch: {
        $route: "fetchEventList",
        userId() {
            this.setInitialData()
            this.fetchEventList()
        }
    },
    mixins: [DateMixin, DownloadMixin],
    components: {
        layoutMain,
        loading,
        tableActions,
        pager,
        nodataBox,
        notice,
        eventSearch,
        eventTable,
        gxIconText
    },
    data() {
        return {
            data: {
                results: [],
                page: {},
                checked: []
            },
            formParams: {
                event_start_time: '',
                event_end_time: '',
                attack_src_ip: '',
                attack_type: '',
                has_access_control: '',
                severity: [],
                req_header_host: '',
                req_uri: '',
                res_action: [],
                page: '1',
                page_size: '20'
            },
            sort: {
                field: '',
                order: '',
            },
            queryParams: {},
            isLoading: true,
            isLoadError: false,
            params: {}
        }
    },
    computed: {
        navs() {
            return [
                {
                    to: '/event',
                    label: i18n.t('イベント')
                }
            ]
        },
        events() {
            return this.data.results
        },
        pager() {
            return this.data.page
        },
        urlQuery() {
            // 文字列 → 配列へ変換のため、$route.queryのgetterを用意(checkboxの単数選択時を考慮)
            return Object.entries(this.$route.query).reduce((acc, [key, value]) => {
                if (key == 'severity' || key == 'res_action')
                    acc[key] = this.$route.query[key] instanceof Array ? this.$route.query[key] : [this.$route.query[key]]
                else acc[key] = value
                return acc
            }, {})
        },
        userId() {
            return this.$store.state.userAccount.id
        }
    },
    methods: {
        setInitialData() {
            const vue = this
            if (Object.entries(this.urlQuery).some(([k, v]) => k in vue.formParams && !!v)) return
            
            if (!this.formParams.event_end_time) {
                this.formParams.event_end_time = this.toLocalDate().format('YYYY-MM-DD HH:mm')
            }
        },
        async fetchEventList() {
            // checkの初期化
            this.allCheck(false)
            this.isLoading = true
            this.isLoadError = false
            this.queryParams = {}
            const params = JSON.parse(JSON.stringify(this.formParams))

            Object.keys(this.formParams).filter(key => this.formParams[key]).forEach(key => {
                const val = params[key]
                if(key === 'event_start_time') {
                    this.queryParams[key] = this.convertToRequestTime(val)
                } else if(key === 'event_end_time') {
                    this.queryParams[key] = this.convertToRequestTime(this.$moment.utc(val).add(1, 'minutes'))
                } else  {
                    this.queryParams[key] = val
                }
            })

            const data = await EventService.fetchList(this.queryParams).catch(err => {
                this.isLoadError = true
                return {}
            })
            this.$set(this.data, 'results', data && data.results ? data.results : [])
            this.$set(this.data, 'page', data && data.page ? data.page : {})
            this.isLoading = false
        },
        updateQuery(key, val) {
            this.$set(this.formParams, key, val)
        },
        search() {
            let query = {}
            Object.keys(this.formParams).forEach(key => {
                if (this.formParams[key]) {
                    if (key == 'has_access_control') {
                        query[key] = String(this.formParams[key])
                    } else {
                        query[key] = this.formParams[key]
                    }
                }
            })
            if (Object.keys(diff(query, this.urlQuery)).length !== 0) this.$router.replace({query: query})
        },
        changePage(page) {
            this.$set(this.formParams, 'page', page)
            this.search()
        },
        changePageSize(size) {
            this.$set(this.formParams, 'page_size', size)
            this.$set(this.formParams, 'page', '1')
            this.search()
        },
        updateCheckBox(val) {
            if(this.data.checked.includes(val)) {
                const index = this.data.checked.findIndex(v => v == val)
                this.data.checked.splice(index, 1)
            } else {
                this.data.checked.push(val)
            }
        },
        updateOrder(field, order) {
            this.sort.field = field
            this.sort.order = order

            this.updateQuery('o', order === 'desc' ? `-${field}` : field)
            this.search()
        },
        async downloadCsv() {
            await this.download(EventService.downloadCsv(this.queryParams),'CSV')
        },
        manual(){
  		    return '/manual/wiki/Event_list_' + i18n.locale
  		},
        allCheck(flag) {
            this.data.checked.splice(0, this.data.checked.length)
            if(flag) {
                this.events.forEach(event => {
                    this.data.checked.push(event.event_id)
                })
            }
        }
    },
    created() {
        if(this.$route.query.o) {
            if(this.$route.query.o.charAt(0) === '-') {
                this.sort.field = this.$route.query.o.slice(1)
                this.sort.order = 'desc'
            } else {
                this.sort.field = this.$route.query.o
                this.sort.order = 'asc'
            }
        }

        const urlQuery = JSON.parse(JSON.stringify(this.urlQuery))
        Object.entries(urlQuery).forEach(([key, value]) => {
            this.$set(this.formParams, key, value)
        })
        if(this.userId) {
            this.setInitialData()
            this.fetchEventList()
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';

</style>
