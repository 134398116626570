<template>
    <ul class="tab-nav">
        <li class="item" v-for="nav in navs">
            <component
                :is="nav.to ? 'router-link' : 'span'"
                :to="nav.to ? nav.to : false"
                :class="['link', {'is-current': nav.current}]"
                @click.prevent="click(nav.tab)"
            >
                {{nav.label}}
            </component>
        </li>
    </ul>
</template>

<script>
    export default {
        name: 'tab-nav',
        props: {
            navs: {
                type: Array,
                required: true
            }
        },
        methods: {
            click(value) {
                this.$emit('changeTab', value)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .tab-nav {
        display: flex;
        border-left: 1px solid $color-border;
        border-bottom: 1px solid $color-border;
        margin-bottom: 56px;
    }
    .item {
        border-top: 1px solid $color-border;
        border-right: 1px solid $color-border;
        margin-bottom: -1px;
    }
    .link {
        $button-color: $color-common;
        $button-hover: mix($button-color, $color-white, 85);
        $button-active: mix($button-hover, $button-color, 65);
        transition: $transition-common;
        min-width: 180px;
        height: 40px;
        line-height: 40px;
        padding: 0 16px;
        text-decoration: none;
        display: block;
        font-size: 1.6rem;
        text-align: center;
        background-color: $button-color;
        cursor: pointer;

        &.is-current {
            font-weight: bold;
            position: relative;
            border-bottom: 1px solid $color-bg;
            pointer-events: none;

            &::after {
                content: "";
                width: 100%;
                height: 4px;
                background-color: $color-current;
                position: absolute;
                top: -1px;
                left: 0;
            }
        }
        &:hover:not(.is-disabled) {
            opacity: 1;
            background-color: $button-hover;
        }
        &:active:not(.is-disabled) {
            background-color: $button-active;
        }
    }
</style>