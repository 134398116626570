<template>
    <table-base>
        <template #head>
            <table-tr type="head">
                <gx-table-cell :type="'th'" >{{$t('有効')}}</gx-table-cell>
                <gx-table-cell :type="'th'" :sort="formatSort(sort, 'username')" @onSort="onSort">{{$t('ユーザーID')}}</gx-table-cell>
                <gx-table-cell :type="'th'" :sort="formatSort(sort, 'email')" @onSort="onSort">{{$t('メールアドレス')}}</gx-table-cell>
                <gx-table-cell :type="'th'" :sort="formatSort(sort, 'telephone')" @onSort="onSort">{{$t('電話番号')}}</gx-table-cell>
                <gx-table-cell :type="'th'" :sort="formatSort(sort, 'role')" @onSort="onSort">{{$t('権限')}}</gx-table-cell>
                <gx-table-cell :type="'th'" :sort="formatSort(sort, 'expire_date')" @onSort="onSort">{{$t('有効期限')}}</gx-table-cell>
                <gx-table-cell :type="'th'"></gx-table-cell>
                <gx-table-cell :type="'th'"></gx-table-cell>
            </table-tr>
        </template>
        <template #body>
            <table-tr v-for="account in accounts" :key="account.id">
                <gx-table-cell :disabled= setdisabled(account.is_active_user) v-if="userAccount.id == account.id" class="currentusertd" center >{{setenabled(account.is_active_user)}}</gx-table-cell>
                <gx-table-cell :disabled= setdisabled(account.is_active_user) v-else >{{setenabled(account.is_active_user)}}</gx-table-cell>
                <gx-table-cell :disabled= setdisabled(account.is_active_user)>{{account.username}}</gx-table-cell>
                <gx-table-cell :disabled= setdisabled(account.is_active_user)>{{account.email}}</gx-table-cell>
                <gx-table-cell :disabled= setdisabled(account.is_active_user) center>{{account.telephone}}</gx-table-cell>
                <gx-table-cell :disabled= setdisabled(account.is_active_user) center>{{$t(getRoleType(account.role))}}</gx-table-cell>
                <gx-table-cell :disabled= setdisabled(account.is_active_user) min right>
                    <gx-icon-text v-if="isExpired(account.expire_date)" :icon="'exclamation_tryangle_fill'" :color="colors.warning" text="" />
                    <template>{{formatLocalDateTime(account.expire_date)}}</template>
                </gx-table-cell>
                <gx-table-cell min>
                    <span class="actions">
                        <gx-button
                            :tag="'router-link'"
                            :text="$t('詳細')"
                            :type="'common'"
                            :size="'small'"
                            :to="`/accounts/${account.id}`"
                            class="actions__item"
                        />
                    </span>
                </gx-table-cell>
                <gx-table-cell min>
                    <gx-icon v-if="account.is_deletable" :tag="'a'" :text="$t('削除')" :icon="'trash'" class="actions__item" @onClick="deleteItem(account.id)" href="#" />
                </gx-table-cell>
            </table-tr>
        </template>
    </table-base>
</template>

<script>
    import Const from '@/static/constants'
    import MixinCommon from '@/mixin/common'
    import MixinDate from '@/mixin/date'
    import { mapGetters } from 'vuex'

    import gxIcon from '@/components/atoms/icon'
    import gxIconText from '@/components/atoms/icon-text'
    import gxTableCell from '@/components/atoms/table/cell'
    import gxButton from '@/components/atoms/button'
    import tableBase from '@/components/molecules/table/base'
    import tableTr from '@/components/molecules/table/tr'

    export default {
        name: 'accounts-table',
        mixins: [MixinCommon, MixinDate],
        components: {
            gxIcon,
            gxIconText,
            gxTableCell,
            gxButton,
            tableBase,
            tableTr
        },
        props: {
            accounts: {
                type: Array,
                default: () => []
            },
            sort: {
                type: Object,
                default: () => {}
            }
        },
        computed: {
            ...mapGetters([
                'hasGroupAdminRole',
                'userAccount',
            ]),
        },
        methods: {
            isExpired(date) {
                if (!date) return false
                const today = this.$moment.utc()
                const expiration_date = this.$moment.utc(date)
                return today >= expiration_date
            },
            deleteItem(id) {
                this.$store.dispatch('openModalWithParam', {target: 'accounts-delete', param: id})
            },
            getRoleType(value) {
                return Const.accounts.roleTerms[value]
            },
            onSort(field, order) {
                this.$emit('updateOrder', field, order)
            },
            setdisabled(is_active){
                if(is_active){
                    return false
                }else{
                    return true
                }
            },
            setenabled(bool){
                if(bool){
                    return 'ON'
                }else{
                    return 'OFF'
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';

    .actions {
        display: flex;
        justify-content: flex-end;

        &__item:not(:first-child) {
            margin-left: 24px;
        }
    }
    .currentusertd {
        border-left: 4px solid $color-primary;
    }
</style>
