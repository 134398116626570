<template>
    <div class="login-box">
        <p class="login-box__lead" v-html="$t('ログイン用のマジックリンクを送信しました。…')"></p>
        <p class="login-box__note"><gx-icon-text :tag="'a'" :text="$t('メールが届かない場合')" :icon="'new_window'" :posi="'right'" href="https://siteguard.jp-secure.com/inquiry" target="_blank" /></p>
    </div>
</template>

<script>
    import gxIconText from '@/components/atoms/icon-text'
    export default {
        name: 'login-confirm',
        layout: 'single',
        components: {
            gxIconText,
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';

    .login-box {
        z-index: 1;
        background-color: $color-parts-bg;
        padding: 20px;
        border-radius: 10px;

        &__lead {
            margin-bottom: 40px;
        }
        &__note {
            text-align: center;
        }
    }
</style>