<script>
 import { Doughnut  } from 'vue-chartjs';
 
 export default {
   extends: Doughnut ,
   name: 'transferredgraph',
   props: {
    transferreddata: {
       type: Array,
       required: true,
     },
     plandata: {
       type: Array,
       required: true,
     },
   },
   data () {
     return {
      data: {
        datasets: [
          {
            backgroundColor: this.getcolor(), // データセットの円弧の塗りつぶし色
            borderColor: "#FFFFFF", // データセットの円弧の境界線の色データセットの円弧の塗りつぶし色
            borderWidth: 0, // データセットの円弧の境界線の太さ
            // hoverBackgroundColor: "#ececec", // マウスオーバー時の円弧の塗りつぶし色
            // hoverBorderColor: "#ececec", // マウスオーバー時の境界線の色
            // hoverBorderWidth: 2, // マウスオーバー時の境界線の太さ
            data: this.getbardata('volume') // データ値
          }
        ],
        labels:  this.getlabel() // 凡例とツールチップに表示するラベル
      },
      options: {
        responsive: true, // コンテナサイズが変更された際に、チャートキャンバスサイズを変更します
        responsiveAnimationDuration: 5, // サイズ変更イベント後に新しいサイズにアニメーションするのに要する時間（ミリ秒）
        maintainAspectRatio: true, // サイズ変更の際に、元のキャンバスのアスペクト比(width / height)を維持します。
        // onResize(chart, size): void {
        //   // サイズ変更が発生したときに呼び出されます。チャートインスタンスと新しいサイズの2つの引数を渡します。
        // },
        layout: {
          // レイアウトに関する設定
          // See https://misc.0o0o.org/chartjs-doc-ja/configuration/layout.html
          padding: 0 // グラフの内側に追加するパディング
        },
        title: {
          // タイトル
          // See https://misc.0o0o.org/chartjs-doc-ja/configuration/title.html
          display: true, // タイトルを表示します。
          position: "top", // タイトルの位置
          fontColor: "white",
          fontSize: 24, // タイトルのフォントサイズ
          padding: 10, // タイトルテキストの上下に追加するピクセル数
          text: this.getbardata("month")
        },
        legend: {
          // 凡例に関する設定
          // See https://misc.0o0o.org/chartjs-doc-ja/configuration/legend.html
          display: true, // 凡例を表示します。
          position: "bottom", // 凡例の位置
          labels:{
            fontColor: "white",
          }
        },
        tooltips: {
          // ツールチップに関する設定
          // See https://misc.0o0o.org/chartjs-doc-ja/configuration/tooltip.html
          display: false, // キャンバス上でツールチップを有効にします
          enabled: false
        }, 
        elements: {
          // 要素に関する設定
          // See https://misc.0o0o.org/chartjs-doc-ja/configuration/elements.html
          point: {
            // 点に関する設定
          },
          line: {
            // 線に関する設定
          },
          rectangle: {
            // 矩形に関する設定
          },
          arc: {
            // 円弧に関する設定
          }
        },
        animation: {
          // アニメーションに関する設定
          // See https://misc.0o0o.org/chartjs-doc-ja/configuration/animations.html
          duration: 1000, // アニメーションにかける時間（ミリ秒）
          easing: "easeOutQuart", // 使用するイージング(easing)(訳注:アニメーションの効果
          // onProgress: null, // アニメーションの各ステップで呼び出されるコールバック
          // onComplete: null, // アニメーションの最後に呼び出されるコールバック
          animateRotate: true, // （円グラフ）trueの場合、グラフは回転アニメーションをします。
          animateScale: true // （円グラフ）trueの場合、中央から外側に向かってグラフが拡大するアニメーションをします。
        },
        rotation: -0.5 * Math.PI, // （円グラフ）弧を描画する開始角度
        circumference: 2 * Math.PI // （円グラフ）弧全体の角度
        // cutoutPercentage: 50, // （ドーナツ）中央部から切り取られるグラフの割合
        // startAngle: -0.5 * Math.PI // (鶏頭図のみ) データセットの最初の項目の円弧を描画する開始角度
      }
     }
   },
   mounted () {
     this.renderChart(this.data, this.options)
   },
   methods: {
    getbardata(type) {
      this.getvalue = []
      if(this.transferreddata != undefined){
        if(type == "volume"){
          // 転送量が超過した場合は、データの入力順を逆にする
          if(this.plandata - this.transferreddata.volume > 0){
            this.getvalue.push(this.transferreddata.volume)
            this.getvalue.push(this.plandata - this.transferreddata.volume)
          }else{
            this.getvalue.push(this.transferreddata.volume)
          }
        }else if(type == "month"){
          this.getvalue.push(this.transferreddata.month)        
        }
      }
      return this.getvalue
    },
    getcolor(){
      // 転送量が超過した場合はグラフの色を変える
      if(this.plandata - this.transferreddata.volume > 0){
        return ["#777777","rgba(75, 192, 192)"]
      }else{
        return ["#777777"]
      }
    },
    getlabel(){
      // 転送量が超過した場合は凡例のラベルを変える
      if(this.plandata - this.transferreddata.volume > 0){
        return ["使用量","残り"]
      }else{
        return []
      }
    }
  }
 }
 </script>