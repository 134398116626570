<template>
    <div ref="world_map" class="world-map"></div>
</template>

<script>
    import Datamap from 'datamaps'
    import countries from 'i18n-iso-countries'
    import Const from '@/static/constants'
    import MixinCommon from '@/mixin/common'

    export default {
        name: 'world-map',
        mixins: [MixinCommon,],
        props: {
            data: {
                type: Array,
                default: () => []
            }
        },
        computed: {
            tokyo() {
                return {
                    lat: 36,
                    lon: 140
                }
            },
            worldMapObj() {
                return new Datamap({
                    element: this.$refs.world_map,
                    fills: {
                        defaultFill: this.colors.success,
                        circle: 'rgba(170, 65, 65, .6)',
                        point: 'rgba(170, 65, 65)'
                    },
                    geographyConfig: {
                        borderColor: this.colors.gray3,
                        popupOnHover: true,
                        popupTemplate: this.geographyPopupTemplate,
                        highlightOnHover: true
                    },
                    bubblesConfig: {
                        borderWidth: 0,
                        popupOnHover: true,
                        popupTemplate: this.bubblesPopupTemplate,
                        highlightOnHover: false,
                        fillOpacity: 1,
                        fillKey: 'circle'
                    },
                    arcConfig: {
                        strokeColor: this.colors.high,
                        strokeWidth: 2,
                        arcSharpness: 0.5
                    }
                })
            },
            datacollection() {
                const totalsCountry = {}
                this.data.forEach(item => {
                    if (!item.country) return 
                    const countryCode2 = countries.alpha3ToAlpha2(item.country)
                    if (!countryCode2 || !this.getGeography(countryCode2)) {
                        return
                    }
                    if (Object.keys(totalsCountry).indexOf(countryCode2) === -1) totalsCountry[countryCode2] = 0
                    totalsCountry[countryCode2] = item.count
                })
                return Object.entries(totalsCountry).map(([country, count]) => ({
                    lat: this.getGeography(country).lat,
                    lon: this.getGeography(country).lon,
                    radius: this.getRadius(count),
                    country: this.getGeography(country).name
                }))
            },
            arcData() {
                return this.datacollection.map(item => ({
                    origin: {
                        latitude: item.lat,
                        longitude: item.lon
                    },
                    destination: {
                        latitude: this.tokyo.lat,
                        longitude: this.tokyo.lon
                    }
                }))
            },
            bubbleData() {
                const circle = this.datacollection.map(item => ({
                    latitude: item.lat,
                    longitude: item.lon,
                    radius: item.radius,
                    country: item.country,
                    fillKey: 'circle'
                }))
                const point = this.datacollection.map(item => ({
                    latitude: item.lat,
                    longitude: item.lon,
                    radius: 2,
                    country: item.country,
                    fillKey: 'point'
                }))
                point.push({
                    latitude: this.tokyo.lat,
                    longitude: this.tokyo.lon,
                    radius: 2,
                    country: 'JAPAN',
                    fillKey: 'point'
                })
                return circle.concat(point)
            }
        },
        methods: {
            geographyPopupTemplate(geography, data) {
                const country = this.getGeography(countries.alpha3ToAlpha2(geography.id)).name
                return `<div class="hoverinfo" style="background-color: rgba(68, 68, 68, 0.6);">${country}</div>`
            },
            bubblesPopupTemplate(geography, data) {
                return `<div class="hoverinfo" style="background-color: rgba(68, 68, 68, 0.6);">${data.country}</div>`
            },
            getGeography(value) {
                return Const.geography.geography[value]
            },
            getRadius(value) {
                if (value < 10) return 5
                else if (value < 20) return 25
                else return 40
            }
        },
        mounted() {
            this.worldMapObj.arc(this.arcData)
            this.worldMapObj.bubbles(this.bubbleData)
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .world-map {
        width: 100%;
        min-height: 716px;
        background-color: $color-black;
        text-align: center;
        position: relative;
    }
</style>
