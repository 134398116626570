<template>
    <div>
        <gx-section v-if="isLoading || isLoadError || !data.list.length">
            <gx-title :level="2">{{$t('フィルタリングルール一覧')}}</gx-title>
            <template v-if="isLoading"><loading /></template>
            <notice v-else-if="isLoadError" :type="'error'" :label="$t('通信に失敗しました')" />
            <initial-box
                v-else-if="!data.list.length"
                :label="$t('フィルタリングルールが設定されていません')"
                :lead="$t('適用するフィルタリングルールを設定してください')"
                :buttons="initialButtons"
                @onClick="addNewItem"
            />
        </gx-section>
        <template v-else>
            <!-- <gx-section half>
                <website-access-pattern-test :country="country" @search="patternTest" @clear="patternTestClear" />

                <template v-if="!!Object.keys(testResult).length">
                    <gx-title :level="3">テスト結果</gx-title>
                    <website-access-pattern-test-result :result="testResult" />
                </template>
            </gx-section> -->

            <gx-section>
                <gx-title :level="2">
                    <template #default>{{$t('フィルタリングルール一覧')}}</template>
                    <template v-if="!isSorting" #actions>
                        <gx-button :text="$t('並べ替え')" :type="'common'" :size="'small'" @onClick="onSort" :disabled="sortableRules.length <= 1 || !isEditable" />
                        <gx-button :text="$t('追加')" :size="'small'" @onClick="addNewItem" :disabled="!isEditable" />
                    </template>
                </gx-title>
                <p v-if="isSorting" class="sorting-note">{{$t('ドラッグアンドドロップで優先度を入れ替えてください。')}}</p>
                <table-draggable v-model="sortingRules" :handle="`.${dragHandleClass}`" ghost-class="tr--ghost">
                    <template #head>
                        <table-tr type="head">
                            <gx-table-cell v-if="isSorting" :type="'th'"></gx-table-cell>
                            <gx-table-cell :type="'th'" min>{{$t('有効')}}</gx-table-cell>
                            <gx-table-cell :type="'th'" min>{{$t('優先度')}}</gx-table-cell>
                            <gx-table-cell :type="'th'">{{$t('参照元')}}</gx-table-cell>
                            <gx-table-cell :type="'th'">{{$t('対象URL')}}</gx-table-cell>
                            <gx-table-cell :type="'th'">{{$t('許可/拒否')}}</gx-table-cell>
                            <gx-table-cell :type="'th'">{{$t('状態')}}</gx-table-cell>
                            <gx-table-cell :type="'th'">{{$t('コメント')}}</gx-table-cell>
                            <gx-table-cell :type="'th'"></gx-table-cell>
                        </table-tr>
                    </template>
                    <template #body>
                        <website-access-row
                            v-for="(item, index) in rules"
                            :key="item.internalId || item.rule_id"
                            :idx="index"
                            :is-editable="isEditable"
                            :country="country"
                            :item="item"
                            :handle-class="isSorting ? dragHandleClass : null"
                            @delete="() => deleteItem(item)"
                        />
                    </template>
                </table-draggable>

                <button-wrap v-if="isSorting" :buttons="buttons" @save="saveSort" @cancel="cancelSort" />
                <!-- <p v-else class="batch-operation">
                    選択したフィルターを<gx-form-select class="select" :options="operations" :value="1" is-auto-width />にする
                    <gx-button :text="'更新'" :type="'common'" :size="'small'" class="button" />
                </p> -->
                <pager :pager="data.pager" @changePage="changePage" @changePageSize="changePageSize" />
            </gx-section>
        </template>
        <div class="manual">
                <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
            </div>   
    </div>
</template>

<script>
    import MixinCommon from '@/mixin/common'

    import gxSection from '@/components/atoms/section'
    import gxButton from '@/components/atoms/button'
    import gxTitle from '@/components/atoms/title'
    import gxTableCell from '@/components/atoms/table/cell'
    import gxIconText from '@/components/atoms/icon-text'
    // import gxFormSelect from '@/components/atoms/form/select'
    import loading from '@/components/atoms/loading'

    import notice from '@/components/molecules/notice'
    import tableDraggable from '@/components/molecules/table/draggable'
    import tableTr from '@/components/molecules/table/tr'
    import initialBox from '@/components/molecules/initial-box'
    import buttonWrap from '@/components/molecules/button-wrap'
    import pager from '@/components/molecules/pager'

    import websiteAccessRow from '@/components/organisms/website/access-row'
    // import websiteAccessPatternTest from '@/components/organisms/website/access-pattern-test'
    // import websiteAccessPatternTestResult from '@/components/organisms/website/access-pattern-test-result'

    import masterService from "@/services/masterService";
    import websiteService from "@/services/websiteService";
    import ManualService from '@/services/manualService'

    import i18n from '@/i18n'

    export default {
        name: 'website-access',
        mixins: [MixinCommon],
        components: {
            gxSection,
            gxButton,
            gxTitle,
            gxTableCell,
            gxIconText,
            // gxFormSelect,
            loading,
            notice,
            tableDraggable,
            tableTr,
            buttonWrap,
            pager,
            initialBox,
            websiteAccessRow,
            // websiteAccessPatternTest,
            // websiteAccessPatternTestResult
        },
        props: {
            isEditable: { type: Boolean, required: true },
        },
        data() {
            return {
                websiteId: this.$route.params.uuid,
                data: {
                    list: [],
                    pager: {},
                },
                requestParams: {
                    page: 1,
                    page_size: 20
                },
                addIp: false,
                addAttack: false,
                isSorting: false,
                scaningData: {
                    scaning: true
                },
                country: [],
                sortingRules: [],
                internalIdCounter: 0,
                dragHandleClass: 'handle',
                isLoading: true,
                isLoadError: false,
                usersetPageSize: 20,
                testResult: {}
            }
        },
        computed: {
            rules() {
                return this.isSorting ? this.sortingRules : this.data.list
            },
            sortableRules() {
                return this.data.list.filter(obj => !!obj.rule_id)
            },
            initialButtons() {
                return [
                    {
                        text: i18n.t('フィルタリング新規設定'),
                        type: 'primary',
                        size: 'large',
                        onClick: "onClick",
                        disabled: !this.isEditable
                    }
                ]
            },
            buttons() {
                return [{
                    text: i18n.t('保存'),
                    size: 'medium',
                    onClick: 'save'
                }, {
                    text: i18n.t('キャンセル'),
                    type: 'common',
                    size: 'medium',
                    onClick: 'cancel'
                }]
            },
            operations() {
                return [{
                    value: 0,
                    str: i18n.t('無効')
                }, {
                    value: 1,
                    str: i18n.t('有効')
                }]
            }
        },
        methods: {
            async fetch() {
//                this.$store.dispatch('displayLoading')
                this.isLoading = true
                this.isLoadError = false
                await this._fetchCountry()
                await this._fetchAccessRules()
//                this.$store.dispatch('hideLoading')
            },
            async _fetchCountry() {
                masterService.fetchCountry()
                    .then(res => this.country = res)
                    .catch(() => this.isLoadError = true)
            },
            async _fetchAccessRules() {
                await websiteService.fetchAccessRules(this.websiteId, this.requestParams)
                    .then(res => {
                        this.data.list = res.results
                        this.data.pager = res.page
                        this.isLoading = false
                    })
                    .catch(() => this.isLoadError = true)
            },
            async deleteItem(data) {
                this.$store.dispatch('openModalWithParam', {target: 'website-access-delete', param: {uuid: this.websiteId, ruleId: data.rule_id}})
            },
            updateList(data) {
                let idx = this.data.list.findIndex(item => item.rule_id === data.rule_id)
                if (idx > -1) {
                    this.data.list.splice(idx, 1, data)
                } else {
                    this.data.list.push(data)
                }
                this.fetch()
            },
            addNewItem() {
                const param = { detailType: 'new' }
                this.$store.dispatch('openModalWithParam', {target: 'website-access-detail', param: param})
            },
            async onSort() {
                this.data.usersetPageSize = this.requestParams.page_size
                await this.changePageSize(1000)                
                this.sortingRules = await this.sortableRules.slice()
                this.isSorting = true
            },
            cancelSort() {
                this.changePageSize(this.data.usersetPageSize)
                this.isSorting = false
                this.fetch()
            },
            saveSort() {
                this.$store.dispatch('displayLoading')
                const params = {
                    'items': this.sortingRules.map(obj => obj.rule_id)
                }
                websiteService.sortAccessRule(this.websiteId, params)
                    .then(() => {
                        this.data.list = this.sortingRules.slice()
                        this.$store.dispatch('openToast', {type: 'success', label: i18n.t('更新しました')})
                    })
                    .catch(err => {
                        const message = err.response && err.response.data && err.response.data.length ? err.response.data.join('\n') : i18n.t('更新に失敗しました')
                        this.$store.dispatch('openToast', {type: 'error', label: message})
                    })
                    .finally(() => {
                        this.isSorting = false
                        this.$store.dispatch('hideLoading')
                    })
                this.changePageSize(this.data.usersetPageSize)
                this.fetch()
            },
            updateQuery(key, val) {
                this.$set(this.requestParams, key, val)
            },
            changePage(page) {
                this.updateQuery('page', page)
                this.fetch()
            },
            async changePageSize(size) {
                this.updateQuery('page_size', size)
                this.updateQuery('page', 1)
                await  this.fetch()
            },
            manual(){
  		        return '/manual/wiki/Access_control_list_' + i18n.locale
  		    },       
            // patternTest(params) {
            //     console.log('patternTest', params)
            //     this.testResult = {
            //         source: '162.0.0.1',
            //         url_path: 'https://sample.jp/aaa',
            //         result: 0, // 0:拒否　1:許可
            //         filter: 3
            //     }
            // },
            // patternTestClear() {
            //     console.log('patternTestClear')
            //     this.testResult = {}
            // }
        },
        created() {
            this.fetch()
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';

    .sorting-note {
        margin-bottom: 24px;
    }
    .table-draggable {
        .tr--ghost {
            opacity: $opacity-hover;
        }
    }
    .batch-operation {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 32px;

        .select {
            margin: 0 8px;
        }
        .button {
            margin-left: 32px;
        }
    }
</style>