<script>
    import Const from '@/static/constants'

    export default {
        name: 'WebsiteMixin',

        methods: {
            getProtocolType(protocol) {
                return Const.website.protocolType[protocol]
            },
            getActionIcon(website) {
                let icon = {
                    'text': '',
                    'icon': '',
                    'color': Const.common.colors.SUCCESS,
                }

                if (website.protection == Const.website.protection.OFF) {
                    return icon
                }

                if (website.action == Const.website.action.DETECTION) {
                    icon['icon'] = 'search'
                    icon['text'] = '検出のみ'
                } else if (website.action == Const.website.action.REDIRECT) {
                    icon['icon'] = 'redirect'
                    icon['text'] = 'リダイレクト'
                } else {
                    icon['icon'] = 'block'
                    icon['text'] = 'ブロック'
                }
                return icon
            }
        }
    }
</script>
