<template>
    <table-base>
        <template #head>
            <table-tr type="head">
                <gx-table-cell :type="'th'" min :sort="formatSort(sort, 'id')" @onSort="onSort">{{$t('グループ番号')}}</gx-table-cell>
                <gx-table-cell :type="'th'" min :sort="formatSort(sort, 'groupname')" @onSort="onSort">{{$t('グループID')}}</gx-table-cell>
                <gx-table-cell :type="'th'" min :sort="formatSort(sort, 'group_description')" @onSort="onSort">{{$t('グループ名')}}</gx-table-cell>
                <gx-table-cell :type="'th'" min></gx-table-cell>
            </table-tr>
        </template>
        <template #body>
            <table-tr v-for="group in groups" :key="group.id">
                <gx-table-cell center>{{group.id}}</gx-table-cell>
                <gx-table-cell center>{{group.groupname}}</gx-table-cell>
                <gx-table-cell center>{{group.group_description}}</gx-table-cell>
                <gx-table-cell min>
                    <gx-button
                        :text="$t('選択')"
                        :type="'common'"
                        :size="'small'"
                        @onClick="onGroupChangeButtonClick(group.id)"
                    />
                </gx-table-cell>
            </table-tr>
        </template>
    </table-base>
</template>

<script>
    import Const from '@/static/constants'
    import MixinCommon from '@/mixin/common'
    import MixinDate from '@/mixin/date'

    import gxTableCell from '@/components/atoms/table/cell'
    import gxFormCheckbox from '@/components/atoms/form/checkbox'
    import gxButton from '@/components/atoms/button'
    import gxIcon from '@/components/atoms/icon'
    import gxLabel from '@/components/atoms/label'
    import tableBase from '@/components/molecules/table/base'
    import tableTr from '@/components/molecules/table/tr'

    export default {
        name: 'group-table',
        mixins: [MixinCommon, MixinDate],
        components: {
            gxTableCell,
            gxFormCheckbox,
            gxButton,
            gxIcon,
            gxLabel,
            tableBase,
            tableTr
        },
        mounted(){
        },
        data() {
            return {
                Const: Const,
            }
        },
        props: {
            groups: {
                type: Array,
                default: () => []
            },
            sort: {
                type: Object,
                default: () => {}
            }
        },
        filters: {
            abbreviation(value) {
                return value && value.length > 16 ? `${value.substr(0,15)}…` : value
            }
        },
        methods: {
            onSort(field, order) {
                this.$emit('updateOrder', field, order)
            },
            onGroupChangeButtonClick(groupId) {
                this.$emit('groupChange', groupId)
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>

