<template>
    <div  class="tablebody">
    <table-base>
        <template #head>
            <table-tr type="head">
                <gx-table-cell :type="'th'" min :sort="formatSort(sort, 'is_auto')" @onSort="onSort">{{$t('種別')}}</gx-table-cell>
                <gx-table-cell :type="'th'" min :sort="formatSort(sort, 'hostname')" @onSort="onSort">{{$t('ホスト名')}}</gx-table-cell>
                <gx-table-cell :type="'th'" min :sort="formatSort(sort, 'allow_naked_domain_flg')" @onSort="onSort">{{$t('www無し')}}</gx-table-cell>
                <gx-table-cell :type="'th'" min>{{$t('有効期限')}}</gx-table-cell>
                <gx-table-cell :type="'th'" min :sort="formatSort(sort, 'comment')" @onSort="onSort">{{$t('コメント')}}</gx-table-cell>
                <gx-table-cell :type="'th'" min class="close"><gx-icon :tag="'a'" href="#" :text="'閉じる'" :icon="'close'" :size="2.4"  @onClick="closeModal" /></gx-table-cell>
            </table-tr>
        </template>
        <template #body>
            <table-tr v-for="certificate in certificates" :key="certificate.certificate_id">
                <gx-table-cell center>
                    {{$t(getType(certificate.is_auto))}}
                </gx-table-cell>
                <gx-table-cell center>{{certificate.hostname}}</gx-table-cell>
                <gx-table-cell center>
                    <gx-icon v-if="certificate.is_auto && certificate.allow_naked_domain_flg"
                        :icon="allowNakedDomainStyles[certificate.allow_naked_domain_flg].icon"
                        :color="getColor(allowNakedDomainStyles[certificate.allow_naked_domain_flg].color)" 
                    />
                </gx-table-cell>
                <gx-table-cell center :class=getOverdue(certificate.expiration_date)>{{formatLocalDateTime(certificate.expiration_date)}}</gx-table-cell>
                <gx-table-cell center  class="comment">{{certificate.comment}}</gx-table-cell>
                <gx-table-cell min>
                    <span class="actions">
                        <gx-button
                            v-if ="!certificate.is_auto"
                            :text="$t('詳細')"
                            :type="'common'"
                            :size="'small'"
                            @onClick="certificateDetail(certificate.certificate_id)"
                        />
                    </span>
                    <span class="actions">
                        <gx-button
                            :text="$t('選択')"
                            :type="'common'"
                            :size="'small'"
                            :disabled="getOverdue(certificate.expiration_date) == 'overdue'"
                            @onClick="selectButtonClick(certificate.certificate_id)"
                        />
                    </span>
                </gx-table-cell>
            </table-tr>
        </template>
    </table-base>
</div>
</template>

<script>
    import Const from '@/static/constants'
    import MixinCommon from '@/mixin/common'
    import MixinDate from '@/mixin/date'

    import gxTableCell from '@/components/atoms/table/cell'
    import gxFormCheckbox from '@/components/atoms/form/checkbox'
    import gxButton from '@/components/atoms/button'
    import gxIcon from '@/components/atoms/icon'
    import gxLabel from '@/components/atoms/label'
    import tableBase from '@/components/molecules/table/base'
    import tableTr from '@/components/molecules/table/tr'
    import certificateDetailModal from '@/components/organisms/certificate/detail-modal'

    export default {
        name: 'group-table',
        mixins: [MixinCommon, MixinDate],
        components: {
            gxTableCell,
            gxFormCheckbox,
            gxButton,
            gxIcon,
            gxLabel,
            tableBase,
            tableTr,
            certificateDetailModal
        },
        computed: {
            allowNakedDomainStyles: () => Const.website.allowNakedDomainStyle,
        },
        mounted(){
        },
        data() {
            return {
                Const: Const,
            }
        },
        props: {
            certificates: {
                type: Array,
                default: () => []
            },
            sort: {
                type: Object,
                default: () => {}
            }
        },
        filters: {
            abbreviation(value) {
                return value && value.length > 16 ? `${value.substr(0,15)}…` : value
            }
        },
        methods: {
            onSort(field, order) {
                this.$emit('updateOrder', field, order)
            },
            selectButtonClick(certificateId) {
                this.$emit('selectCertificate', certificateId)
            },
            getType(is_auto) {
                return Const.certificate.is_auto[is_auto]
            }, 
            getColor(color) {
                return Const.common.colors[color.toUpperCase()]
            }, 
            getOverdue(expirationdate){
                let today = new Date().toLocaleDateString("ja-JP", {year: "numeric",month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit", millisecond: "3-digit"})
                let month  = new Date()
                month = month.setDate(month.getDate() + 30)
                month = new Date(month).toLocaleDateString("ja-JP", {year: "numeric",month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit", millisecond: "3-digit"})

                if (this.formatLocalDateTime(expirationdate) < today){
                    return 'overdue'
                }else if (this.formatLocalDateTime(expirationdate) < month){
                    return 'neardue'
                }
            },
            closeModal(){
                this.$emit('closeModal')
            },
            async certificateDetail(certificate_id){
                console.log(this)
                this.$store.dispatch('openModalWithParam', {target: 'certificate-detail', param: {uuid: certificate_id, type: "website"}})
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import '~@/assets/scss/_variable.scss';
    .tablebody{
        width:85%;
        height:auto;
        overflow-y:auto;
    }
    .actions {
        display: flex;
        justify-content: flex-end;

        &__item:not(:first-child) {
            margin-left: 24px;
        }
    }
    .close{
        padding-left: 10%;
    }
    .overdue{
        color: $color-error;
    }
    .neardue{
        color: $color-warning;
    }
    .comment{
        word-break:break-word;
    }
</style>

