import api from '@/services/api'

export default {
    API_ENDPOINT: '/api/website',
    fetchList(params) {
        return api.get(`${this.API_ENDPOINT}/`, { params: params })
            .then(response => response.data)
    },
    register(params) {
        return api.post(`${this.API_ENDPOINT}/`, params)
            .then(response => response.data)
    },
    fetchDetail(uuid) {
        return api.get(`${this.API_ENDPOINT}/${uuid}/`)
            .then(response => response.data)
    },
    updateBasic(uuid, params) {
        return api.patch(`${this.API_ENDPOINT}/${uuid}/`, params)
            .then(response => response.data)
    },
    delete(uuid) {
        return api.delete(`${this.API_ENDPOINT}/${uuid}/`)
            .then(response => response.data)
    },
    fetchWafCusList(websiteId, params) {
        return api.get(`${this.API_ENDPOINT}/${websiteId}/wafcus/`, { params: params })
            .then(response => response.data)
    },
    registWafCus(websiteId, params) {
        return api.post(`${this.API_ENDPOINT}/${websiteId}/wafcus/`, params)
            .then(response => response.data)
    },
    fetchPriority(websiteId) {
        return api.get(`${this.API_ENDPOINT}/${websiteId}/wafcus/priority/`)
            .then(response => response.data)
    },
    fetchWafCusDetail(websiteId, uuid) {
        return api.get(`${this.API_ENDPOINT}/${websiteId}/wafcus/${uuid}/`)
            .then(response => response.data)
    },
    updateWafCus(websiteId, uuid, params) {
        return api.put(`${this.API_ENDPOINT}/${websiteId}/wafcus/${uuid}/`, params)
            .then(response => response.data)
    },
    deleteWafCus(websiteId, uuid) {
        return api.delete(`${this.API_ENDPOINT}/${websiteId}/wafcus/${uuid}/`)
            .then(response => response.data)
    },
    fetchAccessRules(websiteId, params) {
        return api.get(`${this.API_ENDPOINT}/${websiteId}/filter/`, { params: params })
            .then(response => response.data)
    },
    saveAccessRule(websiteId, uuid, params) {
        return !params.rule_id
            ? this._registerAccessRule(websiteId, params)
            : this._updateAccessRule(websiteId, uuid, params)
    },
    _registerAccessRule(websiteId, params) {
        return api.post(`${this.API_ENDPOINT}/${websiteId}/filter/`, params)
            .then(response => response.data)
    },
    _updateAccessRule(websiteId, uuid, params) {
        return api.put(`${this.API_ENDPOINT}/${websiteId}/filter/${uuid}/`, params)
            .then(response => response.data)
    },
    deleteAccessRule(websiteId, uuid) {
        return api.delete(`${this.API_ENDPOINT}/${websiteId}/filter/${uuid}/`)
            .then(response => response.data)
    },
    sortAccessRule(websiteId, params) {
        return api.post(`${this.API_ENDPOINT}/${websiteId}/filter/sort/`, params)
            .then(response => response.data)
    },
    fetchWafTrustedDetail(websiteId, uuid) {
        return api.get(`${this.API_ENDPOINT}/${websiteId}/waftrust/`)
            .then(response => response.data)
    },
    updateWafTrustedDetail(websiteId, params) {
        return api.put(`${this.API_ENDPOINT}/${websiteId}/waftrust/`, params)
            .then(response => response.data)
    },
}
