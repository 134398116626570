import api from '@/services/api'

export default {
    API_ENDPOINT: '/api/event/',
    fetchList(params) {
        return api.get(this.API_ENDPOINT, {params: params})
            .then(response => response.data)
            .catch(error => error.response)
    },
    fetchDetail(uuid) {
        return api.get(`${this.API_ENDPOINT}${uuid}/`)
            .then(response => response.data)
            .catch(error => error.response)
    },
    downloadCsv(params) {
        return api.get(`${this.API_ENDPOINT}download/`, {
            params: params,
            responseType: 'arraybuffer'
        }).then(response => response)
          .catch(error => error.response)
    }
}