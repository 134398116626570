<template>
    <div ref="world_map" class="world-map">
        <transition name="fade">
            <div v-if="showPopup" class="popup">
                {{ country }}
            </div>
        </transition>
    </div>
</template>

<script>
    // giojsのコンフィグにホイール周りの設定がないので改造した物を読む
    import gio from '@/static/lib/gio.module.js'
    import countries from 'i18n-iso-countries'
    import Const from '@/static/constants'

    export default {
        name: 'world-map',
        props: {
            data: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                country: 'JAPAN',
                showPopup: false
            }
        },
        computed: {
            worldMapObj() {
                const GioCon = gio.Controller(this.$refs.world_map, this.mapConfig)

                GioCon.onCountryPicked(this.picked)
                GioCon.init()

                return GioCon
            },
            mapConfig() {
                return {
                    "control": {
                        "stats": false,
                        "disableUnmentioned": false,
                        "lightenMentioned": true,
                        "inOnly": false,
                        "outOnly": false,
                        "initCountry": "JP",
                        "halo": true,
                        "transparentBackground": false,
                        "autoRotation": false,
                        "rotationRatio": 1
                    },
                    "color": {
                        "surface": 38231,
                        "selected": 54396,
                        "in": 12976128,
                        "out": 12976128,
                        "halo": 38231,
                        "background": 0
                    },
                    "brightness": {
                        "ocean": 0.49,
                        "mentioned": 0.49,
                        "related": 0.48
                    }
                }
            },
            datacollection() {
                const totalsCountry = {}
                this.data.forEach(item => {
                    if (!item.country) return 
                    if (Const.dashboard.excludedCountryCode.find(obj => obj.alpha3 === item.country)) {
                        return
                    }
                    const countryCode2 = countries.alpha3ToAlpha2(item.country)
                    if (Object.keys(totalsCountry).indexOf(countryCode2) === -1) totalsCountry[countryCode2] = 0
                        totalsCountry[countryCode2] = item.count
                })
                return totalsCountry
            },
            attackData() {
                return Object.entries(this.datacollection).map(([country, count]) => ({
                    e: country,
                    i: "JP",
                    v: count * count * 1000
                }))
            }
        },
        methods: {
            picked(selectedCountry) {
                this.country = selectedCountry.name
                this.showPopup = true
                const self = this
                setTimeout( () => {self.showPopup = false} , 3000 )
            }
        },
        mounted() {
            this.worldMapObj.addData(this.attackData)
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .world-map {
        position: relative;
        width: 100%;
        min-height: 716px;
        max-height: 716px;
        background-color: $color-black;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
    .popup {
        position: absolute;
        left: 35%;
        top: 40%;
        width: 400px;
        height: 50px;
        color: $color-white;
        background-color: rgba(68, 68, 68, 0.6);
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
