export const basicTermsType = Object.freeze({
    username: 'ユーザーID',
    protocol: 'プロトコル',
    ssl_key: 'SSL証明書',
    ssl_cert: '秘密鍵',
    hostname: 'ホスト名',
    real_protocol: '実プロトコル',
    real_server: '実サーバー',
    protection: '保護',
    action: '状態',
    block_action: 'アクション',
    res_html: '応答HTMLファイル',
    advance_settings: '高度な設定',
    state: '更新ステータス',
})

export const protection = Object.freeze({
    ON: true,
    OFF: false
})
export const protectionType = Object.freeze({
    true: '有効',
    false: '無効'
})

const switchBaseStyle = Object.freeze({
    true: {
        label: 'ON',
        str: '有効',
        value: true,
        icon: 'check_circle_fill',
        color: 'success'
    },
    false: {
        label: 'OFF',
        str: '無効',
        value: false,
        icon: 'close',
        color: 'warning'
    }
})

export const redirectHttpToHttpsStyle = switchBaseStyle

export const allowNakedDomainStyle = switchBaseStyle

export const realIpHeaderXffStyles = switchBaseStyle

export const sniSwitchStyle = switchBaseStyle

export const setHeaderRealHostSwitchStyle = switchBaseStyle

export const protectionStyle = switchBaseStyle

export const protocol = Object.freeze({
    HTTP: 1,
    HTTPS: 2
})
export const protocolType = Object.freeze({
    1: 'HTTP',
    2: 'HTTPS'
})

export const sub_domain = Object.freeze({
    WWW: "www.",
})

export const action = Object.freeze({
    BLOCK: 1,
    // REDIRECT: 2,
    DETECTION: 3,
    CLOSE: 4

})
export const actionType = Object.freeze({
    1: 'ブロック',
    // 2: 'リダイレクト',
    3: '検出のみ',
    4: 'オフ'
})
export const actionStyle = Object.freeze({
    1: {
        label: 'BLOCK',
        str: 'ブロック',
        value: 1,
        icon: 'block',
        color: 'success'
    },
    // 2: {
    //     label: 'REDIRECT',
    //     str: 'リダイレクト',
    //     value: 2,
    //     icon: 'redirect',
    //     color: 'success'
    // },
    3: {
        label: 'DETECTION',
        str: '検出のみ',
        value: 3,
        icon: 'search',
        color: 'success'
    // },
    // 4: {
    //     label: 'OFF',
    //     str: 'オフ',
    //     value: 4,
    //     icon: 'close',
    //     color: 'warning'
    }
})

export const permissionStyle = Object.freeze({
    2: {
        label: 'WHITE',
        str: '安全',
        value: 2,
        color: 'low'
    },
    1: {
        label: 'ALLOW',
        str: '許可',
        value: 1,
        color: 'success'
    },
    0: {
        label: 'DENY',
        str: '拒否',
        value: 0,
        color: 'error'
    },
})

export const blockAction = Object.freeze({
    SERVER_ERROR: 1,
    ACCESS_DENIED: 2,
    REDIRECT: 3
})
export const reqBlockActionType = Object.freeze({
    1: 'サーバーエラー（500）',
    2: 'アクセス拒否（403）',
    3: 'リダイレクト（302）'
})

export const IPRecursiveType = Object.freeze([
    {str: '左から読み込む', value: true},
    {str: '右から読み込む', value: false},
])

export const resBlockActionType = Object.freeze({
    1: 'ドロップ',
})

export const stateType = Object.freeze({  // ウェブサイト更新ステータス
    100: '更新中',
    200: '更新済み',
    300: '更新失敗',
})

export const state = Object.freeze({  // ウェブサイト更新ステータス
    PENDING: 100,
    SUCCESS: 200,
    FAILURE: 300,
})

export const access_sourceType = Object.freeze({
    ALL: null,
    IP: 1,
    COUNTRY: 2,
    BOT: 3,
})

export const access_sourceTypeLabel = Object.freeze({
    // null: '全て'  // PLEASE ADD THIS CASE IN THE SOURCE
    1: 'IPアドレス',
    2: '国名',
    3: 'Bot',
})

export const access_botType = Object.freeze({
	  All: 1,
	  GOOGLE: 2,
	  BING: 3,
})

export const access_botTypeLabel = Object.freeze({
	  1: 'GoogleBot, BingBot',
	  2: 'GoogleBot',
	  3: 'BingBot',
})

export const access_detailTermsType = Object({
    source: '参照元',
    url: '対象URL',
    permission: '許可/拒否',
    action: '状態',
    request_threshold: 'リクエストしきい値',
    block_time: 'ブロック時間',
    user_agent: ['HTTPリクエストヘッダ内の', 'User-Agentフィールド'],
    comment: 'コメント',
})

export const access_detailModalType = Object.freeze({
    detail: '詳細',
    new: '追加',
    edit: '編集'
})

export const access_filterTestTermsType = Object.freeze({
    source: '参照元',
    url: '対象URL',
    result: 'テスト結果',
    filter: '該当フィルター'
})
