<template>
  <div class="main">
    <div class="contents">
        <animation-background />

        <h1 class="single-logo"><router-link to="/"><img src="@/assets/image/common/logo_inverse01.svg" alt="SiteGuard Could Edition"></router-link></h1>
        <router-view />
        <global-footer-login class="global-footer-login" />
    </div>
    <loading v-if="$store.state.isLoading" :type="'page'" />
    <div v-if="$store.state.toastLabel" class="toast_wrap">
        <notice :type="$store.state.toastType" :label="$store.state.toastLabel" class="toast" />
    </div>
  </div>
</template>

<script>
    import loading from '@/components/atoms/loading'
    import notice from '@/components/molecules/notice'
    import globalFooterLogin from '@/components/molecules/global/footer-login'
    import animationBackground from '@/components/atoms/effect/animationBackground'

    export default {
        name: 'layouts-single',
        components: {
            loading,
            notice,
            animationBackground,
            globalFooterLogin
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .main {
        min-height: 100vh;
        min-width: 100vw;
        margin: 0;
    }
    .contents {
        z-index: 1;

        position: absolute;

        display: grid;
        align-content: space-around;
        justify-items: center;

        height: 100%;
        width: 100%;
    }
    h1 {
        &.single-logo {
            position: fixed;
            z-index: 9999;

            height: calc(15px + 36px + 15px);
            width: 100%;
            padding: 15px 15px;

            background-color: $color-black;
            opacity: 0.9;
        }
    }
    .global-footer-login {
        position: fixed;
        z-index: 9999;

        width: 100%;
        bottom: 0;

        background-color: $color-black;
        opacity: 0.9;
    }
    .toast_wrap {
        position: fixed;
        top: 0;
        left: 0;
        
        display: flex;
        flex-direction: row;
        align-content: space-around;
        justify-content: center;

        z-index: 100;
        width: 100%;
        height: 0;
        overflow: visible;

        .toast {
            margin-top: 5px;
        }
    }
</style>