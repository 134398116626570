<template>
    <table-base>
        <template #head>
            <table-tr type="head">
                <gx-table-cell :type="'th'" :sort="formatSort(sort, 'timestamp')" @onSort="onSort">{{$t('タイムスタンプ')}}</gx-table-cell>
                <gx-table-cell :type="'th'" :sort="formatSort(sort, 'attack_src_ip')" @onSort="onSort">{{$t('攻撃元IP')}}</gx-table-cell>
                <gx-table-cell :type="'th'" :sort="formatSort(sort, 'req_header_host')"  @onSort="onSort">{{$t('ホスト')}}</gx-table-cell>
                <gx-table-cell :type="'th'" :sort="formatSort(sort, 'req_uri')" @onSort="onSort">{{$t('パス')}}</gx-table-cell>
                <gx-table-cell :type="'th'" :sort="formatSort(sort, 'attack_type')" @onSort="onSort">{{$t('種類')}}</gx-table-cell>
                <gx-table-cell :type="'th'" min :sort="formatSort(sort, 'res_action')" @onSort="onSort">{{$t('状態')}}</gx-table-cell>
                <gx-table-cell :type="'th'"></gx-table-cell>
            </table-tr>
        </template>
        <template #body>
            <table-tr v-for="event in events" :key="event.event_id">
                <!-- <gx-table-cell min><gx-form-checkbox :value="event.event_id" :checked="checked.includes(event.event_id)" @check="updateCheckBox($event.target.value)" boxOnly>選択する</gx-form-checkbox></gx-table-cell> -->
                <gx-table-cell center>{{formatLocalDateTime(event.timestamp)}}</gx-table-cell>
                <gx-table-cell>{{event.attack_src_ip}}</gx-table-cell>
                <gx-table-cell>{{event.req_header_host}}</gx-table-cell>
                <gx-table-cell class="path">{{event.req_uri}}</gx-table-cell>
                <gx-table-cell center class="type">{{$t(getAttackType(event.attack_type))}}</gx-table-cell>
                <gx-table-cell center><gx-icon :text="$t(getActionType(event.res_action))" :icon="getActionIcon(event.res_action)" :color="colors.success" /></gx-table-cell>
                <gx-table-cell min>
                    <gx-button
                        :tag="'router-link'"
                        :text="$t('詳細')"
                        :type="'common'"
                        :size="'small'"
                        :to="`/event/${event.event_id}`"
                    />
                </gx-table-cell>
            </table-tr>
        </template>
    </table-base>
</template>

<script>
    import Const from '@/static/constants'
    import MixinCommon from '@/mixin/common'
    import MixinDate from '@/mixin/date'

    import gxTableCell from '@/components/atoms/table/cell'
    import gxFormCheckbox from '@/components/atoms/form/checkbox'
    import gxButton from '@/components/atoms/button'
    import gxIcon from '@/components/atoms/icon'
    import gxLabel from '@/components/atoms/label'
    import tableBase from '@/components/molecules/table/base'
    import tableTr from '@/components/molecules/table/tr'

    export default {
        name: 'event-table',
        mixins: [MixinCommon, MixinDate],
        components: {
            gxTableCell,
            gxFormCheckbox,
            gxButton,
            gxIcon,
            gxLabel,
            tableBase,
            tableTr
        },
        props: {
            events: {
                type: Array,
                default: () => []
            },
            checked: {
                type: Array,
                default: () => []
            },
            sort: {
                type: Object,
                default: () => {}
            }
        },
        methods: {
            getAttackType(value) {
                if(Const.event.attackType.find(obj => obj.value === value) === undefined){
                    return "不明"
                }else{
                    return Const.event.attackType.find(obj => obj.value === value).str
                }
            },
            getActionType(value) {
                return Const.event.actionType[value]
            },
            getActionIcon(value) {
                return Const.event.actionIcon[value]
            // },
            // updateCheckBox(val) {
            //     this.$emit("updateCheckBox", val)
            },
            onSort(field, order) {
                this.$emit('updateOrder', field, order)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .path{
        word-break:break-word;
    }
    .type{
        white-space: nowrap;
    }
</style>

