/**
 * 特定のビジネスロジックに依存しないバリデーションルール設定
 */
import Const from '@/static/constants'

const regex_s = {
    validate: (value, patterns) => {
        const re = new RegExp(patterns.map(v => `(?:${Const.validate.pattern[v]})`).join('|'))
        return re.test(value)
    },
}

export { regex_s }