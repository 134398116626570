<template>
    <component :is="type" :class="styles" >
        <slot />
        <!-- // @TODO: IMAGE_COLOR_CHANGE -->
        <gx-icon v-if="sort" class="sort-button" :icon="sortIcon" :color="sort.vaild ?'#eb5b25':'#aaa'" :text="'並べ替え'" @onClick="onSort" />
    </component>
</template>

<script>
    import gxIcon from '@/components/atoms/icon'

    export default {
        name: 'gx-table-cell',
        components: {
            gxIcon
        },
        props: {
            type: {
                type: String,
                validator: (value) => {
                    return ['th', 'td'].indexOf(value) !== -1
                },
                default: 'td'
            },
            center: {
                type: Boolean,
                default: false
            },
            right: {
                type: Boolean,
                default: false
            },
            min: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            sort: {
                type: Object,
                default: () => {}
            },
            noPadding: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            styles() {
                const styles = ['cell']
                if(this.center) styles.push('cell--center')
                if(this.right) styles.push('cell--right')
                if(this.min) styles.push('cell--min')
                if(this.disabled) styles.push('cell--disabled')
                if(this.noPadding) styles.push('cell--no-padding')
                return styles
            },
            sortIcon() {
                if(this.sort.vaild && this.sort.order == 'asc') {
                    return 'sort_up'
                } else {
                    return 'sort_down'
                }
            }
        },
        methods: {
            onSort() {
                this.$emit('onSort', this.sort.field, this.sort.order == 'desc' ? 'asc' : 'desc')
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';

    .cell {
        padding: 16px;
        position: relative;

        &--center {
            text-align: center;
        }
        &--right {
            text-align: right;
        }
        &--min {
            width: 1px;
            white-space: nowrap;
        }
        &--disabled {
            opacity: $opacity-disabled;
        }
        &--no-padding {
            padding: 0;
        }
    }
    .th {

    }
    .td {
        height: 56px;
    }
    .sort-button {
        vertical-align: middle;
        margin: -.2em 0 0 4px;
        cursor: pointer;
    }
</style>
