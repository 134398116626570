<template>
    <validation-observer ref="observer" v-slot="{ invalid }">
        <notice v-if="isFormError || propErrors.non_field_errors" class="notice" :type="'error'" :label="$t('入力内容に不備があります')" >{{ propErrors.non_field_errors ? propErrors.non_field_errors.join(' / ') : 'メッセージを確認の上もう一度入力してください' }}</notice>

        <!-- ブラウザのオートコンプリート対策 -->
        <p style="display:block;width:0px;height:0px;overflow:hidden;">IDダミー： <input type="id" name="id" value=""></p>
        <p style="display:block;width:0px;height:0px;overflow:hidden;">パスワードダミー： <input type="password" name="password" value=""></p>
        <!-- /ブラウザのオートコンプリート対策 -->
        
        <data-list :data="terms">
            <template #title={item}>
                <term-title :required="requiredItem(item)">{{$t(getTerm(item))}}</term-title>
            </template>
            <template #data={item}>
                <gx-form-text
                    v-if="item == 'username'"
                    :id="'username'"
                    :term="$t(getTerm(item))"
                    :placeholder="'sg-admin'"
                    :name="item"
                    v-model="accountData.username"
                    :patterns="'USER_NAME'"
                    required
                    :maxlength="150"
                    :note="$t('半角英数字/@/./+/-/_のみ使用可能 150文字以内')"
                    :ref="`input-${item}`"
                    :errors="propErrors.username" />

                <gx-form-text
                    v-else-if="item == 'email'"
                    :id="item"
                    :term="$t(getTerm(item))"
                    :placeholder="$t('address@test.com')"
                    :name="item"
                    :type="'email'"
                    :patterns="'EMAIL'"
                    v-model="accountData.email"
                    required
                    :ref="`input-${item}`"
                    :errors="propErrors.email" />

                <gx-form-text
                    v-else-if="item == 'telephone'"
                    :id="item"
                    :term="$t(getTerm(item))"
                    :placeholder="$t('00000000000')"
                    :name="item"
                    v-model="accountData.telephone"
                    :patterns="'TEL'"
                    :type="'tel'"
                    :note="$t('任意設定')"
                    :ref="`input-${item}`"
                    :errors="propErrors.telephone" />

                <template v-else-if="item == 'password'">
                    <ul class="form-items">
                        <li v-if="formType == 'update'" class="form-item">
                            <gx-form-text
                                :id="`old_${item}`"
                                :placeholder="'******'"
                                :name="`old_${item}`"
                                v-model="accountData.old_password"
                                :rules="'required_if:password'"
                                :type="'password'"
                                :label="$t('現在のパスワード')"
                                :patterns="'PASSWORD'"
                                :minlength="8"
                                :maxlength="128"
                                :autocomplete="false"
                                :errors="propErrors.old_password" />
                        </li>
                        <li class="form-item">
                            <gx-form-text
                                :id="item"
                                :type="'password'"
                                :placeholder="'********'"
                                :name="item"
                                v-model="accountData.password"
                                :term="$t(getTerm(item))"
                                :label="formType == 'update' ? $t('新しいパスワード') : ''"
                                :required="isNew"
                                :patterns="'PASSWORD'"
                                :minlength="8"
                                :maxlength="128"
                                :note="$t('パスワード規約')"
                                :ref="`input-${item}`"
                                :autocomplete="false"
                                :errors="propErrors.password" />
                        </li>
                        <li class="form-item">
                            <gx-form-text
                                :id="`confirm_${item}`"
                                :type="'password'"
                                :placeholder="$t('********')"
                                :name="`confirm_${item}`"
                                v-model="accountData.confirm_password"
                                :label="$t('確認用パスワード')"
                                :rules="{confirmed: accountData.password ? 'password' : null, required_if: 'password'}"
                                :patterns="'PASSWORD'"
                                :minlength="8"
                                :maxlength="128"
                                :ref="`input-${item}`"
                                :autocomplete="false"
                                :errors="propErrors.confirm_password" />
                        </li>
                    </ul>
                </template>

                <gx-form-select
                    v-else-if="item == 'tz'"
                    :id="item"
                    :name="item"
                    v-model="accountData.tz"
                    :options="tzOptions" />

                <template v-else-if="item == 'notification'">
                    <div class="notification_field">
                        <gx-form-select
                            name="notification_flg"
                            v-model="accountData.notification_flg"
                            :prefix="$t('対象')"
                            :options="notificationFlgOptions"
                            :value="accountData.notification_flg"
                            required />
                    </div>
                    <div class="notification_field">
                        <gx-form-select
                            name="notification_interval"
                            class="notification_field__select"
                            :rules="{required: accountData.notification_flg != 0}"
                            v-model="accountData.notification_interval"
                            :prefix="$t('間隔')"
                            :disabled="accountData.notification_flg == 0"
                            :options="notificationIntervalOptions"
                            :value="accountData.notification_interval" />
                    </div>
                </template>

                <gx-form-select
                    v-else-if="item == 'role' && hasGroupAdminRole"
                    v-model="accountData.role"
                    :id="item"
                    :name="item"
                    :options="roleOptions"
                    :value="accountData.role"
                    :props-error="!!propErrors.role"
                    :props-error-msg="propErrors.role" />

                <template v-else-if="item == 'is_active_user'">
                    <gx-form-toggle
                        :name="item"
                        :right="{label: $t('無効'), value: false}"
                        :left="{label: $t('有効'), value: true}"
                        :propValue="accountData.is_active_user"
                        @change="v => accountData.is_active_user = v" />
                </template>
                <template v-else-if="item == 'is_delivery'">
                     <gx-form-toggle
                         :name="item"
                         :right="{label: $t('無効'), value: 0}"
                         :left="{label: $t('有効'), value: 1}"
                         :propValue="accountData.is_delivery"
                         @change="v => accountData.is_delivery = v" />
                 </template>
            </template>
        </data-list>
        <button-wrap :buttons="buttons(invalid)" class="button-wrap" @submit="submit" @cancel="$emit('cancel')" />
    </validation-observer>
</template>

<script>
    import Const from '@/static/constants'
    import gxFormText from '@/components/atoms/form/text'
    import gxFormSelect from '@/components/atoms/form/select'
    import GxFormToggle from "@/components/atoms/form/toggle";
    import buttonWrap from '@/components/molecules/button-wrap'
    import notice from '@/components/molecules/notice'
    import termTitle from '@/components/molecules/form/term-title'
    import dataList from '@/components/molecules/data-list'
    import { mapGetters } from 'vuex'

    import i18n from '@/i18n'

    export default {
        name: 'accounts-form',
        components: {
            gxFormText,
            gxFormSelect,
            GxFormToggle,
            buttonWrap,
            notice,
            termTitle,
            dataList
        },
        watch: {
            'accountData.password'(val, oldVal) {
                if (!!val === !!oldVal) this.$refs.observer.validate()
            }
        },
        props: {
            formType: {
                type: String,
                validator: (value) => {
                    return ['register', 'update'].indexOf(value) !== -1
                },
                default: 'register'
            },
            isFormError: {
                type: Boolean,
                default: false
            },
            propData: {
                type: Object,
                default: () => ({})
            },
            propErrors: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            const hasData = Object.keys(this.propData).length > 0
            return {
                accountData: {
                    username: hasData ? this.propData.username : '',
                    email: hasData ? this.propData.email : '',
                    telephone: hasData ? this.propData.telephone : '',
                    password: '',
                    old_password: hasData ? this.propData.old_password : '',
                    confirm_password: '',
                    tz: hasData ? this.propData.tz : '540',
                    notification_flg: hasData ? this.propData.notification_flg : '0',
                    notification_interval: (hasData ? this.propData.notification_interval : null) || Const.accounts.notificationIntervalDefault,
                    role: hasData ? this.propData.role : Const.accounts.roleNormal,
                    is_active_user: hasData ? this.propData.is_active_user : true,
                    is_delivery: hasData ? this.propData.is_delivery : 1
                },
                notificationFlgOptions: i18n.translateOptionValues(
                    Object.keys(Const.accounts.notificationFlgTypes).map(key => {
                        return {
                            value: key,
                            str: Const.accounts.notificationFlgTypes[key]
                        }
                    })
                ),
                notificationIntervalOptions: i18n.translateOptionValues(
                    Object.keys(Const.accounts.notificationIntervalTypes).map(key => {
                        return {
                            value: key,
                            str: Const.accounts.notificationIntervalTypes[key]
                        }
                    })
                )
            }
        },
        computed: {
            ...mapGetters([
                'userAccount',
                'hasGroupAdminRole',
                'isManageableRole',
            ]),
            terms() {
                const terms = [
                    'username',
                    'email',
                    'telephone',
                    'password',
                    'tz',
                    'notification',
                    'is_delivery',
                ]
                if (this.hasGroupAdminRole && this.propData.role != Const.accounts.roleGroupAdmin) terms.push('role')
                if (this.propData && this.isManageableRole(this.propData.role)) terms.push('is_active_user')
                return terms
            },
            notificationFlg() {
                const key = this.accountData.notification_flg
                return key ? Const.accounts.notificationFlgTypes[key] : '-'
            },
            notificationInterval() {
                const key = this.accountData.notification_interval
                return key ? Const.accounts.notificationIntervalTypes[key] : '-'
            },
            buttons() {
                return disabled => {
                    return [{
                        text: i18n.t('保存'),
                        type: 'primary',
                        size: 'large',
                        disabled: disabled,
                        onClick: 'submit',
                    }, {
                        text: i18n.t('キャンセル'),
                        type: 'common',
                        size: 'large',
                        onClick: 'cancel'
                    }]
                }
            },
            tzOptions() {
                return i18n.translateOptionValues(Object.keys(Const.accounts.timeZoneList).map(key => {
                    return {
                        value: key,
                        str: Const.accounts.timeZoneList[key]
                    }
                }))
            },
            roleOptions() {
                return i18n.translateOptionValues(Object.keys(Const.accounts.roleTerms)
                    .filter(key => this.isManageableRole(key))
                    .map(key => {
                        return {
                            value: key,
                            str: Const.accounts.roleTerms[key]
                        }
                    })
                )
            },
            isNew() {
                return this.formType === 'register'
            }
        },
        methods: {
            requiredItem(item) {
                switch (item) {
                    case 'username':
                    case 'email':
                    case 'tz':
                    case 'notification':
                    case 'role':
                    case 'is_active_user':
                        return true
                    case 'is_delivery':
                        return true
                    case 'password':
                        return this.isNew
                    default:
                        return false
                }
            },
            getTerm(value) {
                return Const.accounts.registerTermsType[value]
            },
            submit() {
                if (!this.accountData.password) {
                    delete this.accountData.password
                    delete this.accountData.old_password
                    delete this.accountData.confirm_password
                }
                this.$emit('save', this.accountData)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '~@/assets/scss/_variable.scss';
    .form-items {
        width: 100%;
    }
    .form-item:not(:first-child) {
        margin-top: 24px;
    }

    .notice {
        margin-bottom: $notice-margin;
    }
    .notification_field {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
        &__label {
            font-weight: bold;
            margin-right: 12px;
        }
    }
</style>