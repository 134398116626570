<template>
    <layout-main
        :title="$t('チケット詳細')"
        :navs="navs"
        class="support_ticket__detail"
        ref="main"
    >
        <loading v-if="isLoading" />

        <div v-else>
            <div class="ticket_info">
                <div class="summary top">
                    <div v-if="userAccount.is_support" class="item group_description">
                        <div class="label">{{$t("グループ名")}}</div>
                        <div class="data">{{$data.data.group_description}}</div>
                    </div>
                    <div v-if="userAccount.is_support" class="item group">
                        <div class="label">{{$t("グループID")}}</div>
                        <div class="data" @click="onGroupChange($data.data.group,$data.data.groupname)">{{$data.data.groupname}}</div>
                    </div>
                    <div class="item user_name">
                        <div class="label">{{$t("ユーザーID")}}</div>
                        <div class="data">{{$data.data.username ? $data.data.username : $t('UNKNOWN_USER')}}</div>
                    </div>
                    <div class="item created_at">
                        <div class="label">{{$t("作成日時")}}</div>
                        <div class="data">{{formatLocalDateTime($data.data.created_at)}}</div>
                    </div>
                    <div class="item updated_at">
                        <div class="label">{{$t("更新日時")}}</div>
                        <div class="data">{{formatLocalDateTime($data.data.updated_at)}}</div>
                    </div>
                    <div class="item title">
                        <div class="label">{{$t("タイトル")}}</div>
                        <div class="data">{{$data.data.title}}</div>
                    </div>
                    <div class="ticket_data">
                        <div class="item ticket_id">
                            <div class="label">{{$t("チケットID")}}</div>
                            <div class="data">{{$data.data.ticket_id}}</div>
                        </div>
                        <div class="item status">
                            <div class="label">{{$t("ステータス")}}</div>
                            <div class="data">{{$t($data.data.status.str)}}</div>
                        </div>
                        <div v-if="userAccount.is_support && $data.data.waf_event" class="item event_id">
                            <div class="label">{{$t("イベントID")}}</div>
                            <div class="data"><a :href=seteventpath()>{{$data.data.waf_event}}</a></div>
                        </div>
                    </div>
                    <div v-if="userAccount.is_support" class="item status">
                            <div class="label">{{$t("内部ステータス")}}</div>
                            <div class="data">{{$t(this.getInternalStatusStr($data.data.internal_status))}}</div>
                        </div>
                    <div v-if="userAccount.is_support" class="item tag">
                        <span class="label">{{$t("タグ")}}</span>
                        <span class="data" v-if="$data.data.ticket_tag_set.length > 0">
                            <gx-tag
                                v-for="(tag, idx) in $data.data.ticket_tag_set"
                                :key="idx"
                                :text="tag.tag_name"
                                type="common"                                    
                            />
                        </span>
                        <span v-else>
                            <gx-tag :text="$t('タグなし')" />
                        </span>
                    </div>
                </div>
                <div class="item content">
                    <div class="label">{{$t("内容")}}</div>
                    <div class="data">{{$data.data.content}}</div>
                </div>
                <div class="summary bottom">
                    <div class="ticket_data">
                        <div class="item files">
                            <div class="label">{{$t("ファイル")}}</div>
                            <div class="data">
                                <div v-if="($data.data.ticket_file_set.length <= 0)">
                                    {{$t("なし")}}
                                </div>
                                <div v-else :key="index" class="file">
                                    <div v-for="(file, index) in $data.data.ticket_file_set">
                                        <a class="file" @click="onFileClick(file.file_id, file.file_name)">
                                            {{file.file_name}}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="comments">
                <div v-for="comment in $data.data.comment_set" :key="comment.comment_id">
                    <div :class=chengeclass(comment.is_support)>
                        <div class="comment_info top">
                            <div class="item created_at">
                                <div class="label">{{$t('投稿日時')}}</div>
                                <div class="data">{{formatLocalDateTime(comment.created_at)}}</div>
                            </div>
                            <div class="item user_name">
                                <div class="label">{{$t('ユーザーID')}}</div>
                                <div class="data">{{comment.username ? comment.username : $t('UNKNOWN_USER')}}</div>
                            </div>
                            <div class="item files" v-if="Array.isArray(comment.comment_file_set) && (comment.comment_file_set.length > 0)">
                                <div class="label">{{$t('ファイル')}}</div>
                                <div class="data">
                                    <a class="file"
                                        v-for="file in comment.comment_file_set"
                                        :key="file.file_id"
                                        @click="onFileClick(file.file_id, file.file_name)"
                                    >
                                        {{file.file_name}}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="comment_info middle">
                                <div v-if=comment.was_edited class="item edit">
                                    編集済み
                                </div>
                                <div v-if="comment.is_support && userAccount.is_support" class="item updatecomment">
                                    <gx-icon :tag="'a'" 
                                        :text="$t('コメント編集')" 
                                        :icon="'pencil'" 
                                        class="actions__item" 
                                        @onClick="onCommentUpdate(comment)" 
                                        href="#"
                                    />
                                </div>
                            </div>
                        <div class="item content">
                            <div class="data">{{comment.content}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class = comment>
                <supportComment
                    :user-account="userAccount"
                    :edit-comment="$data.editComment"
                    :tags="$data.data.ticket_tag_set"
                    :internalStatus="$data.internalStatus"
                    :errors="errors"
                    @submit="onEditCommentCheck"
                    @close="ticketclose"
                />
            </div>
        </div>
        <div class="manual">
            <router-link target="_blank" :to="{path: manual()}">{{$t('マニュアル')}}</router-link>
        </div>        
        <template v-if="$store.state.isModal" #modal>
            <support-comment-modal
                v-if="$store.state.modalTarget == 'support-comment-modal'"
                :user-account="userAccount"
                :edit-comment="$data.editComment"
                :tags="$data.data.ticket_tag_set"
                :internalStatus="$data.internalStatus"
                :errors="errors"
                @submit="onEditCommentCheck"
                @close="ticketclose"
            />
            <support-comment-check-modal
                v-if="$store.state.modalTarget == 'support-comment-check-modal'"
                :user-account="userAccount"
                :edit-comment="$data.editComment"
                :title="$data.data.title"
                :group-name="$data.data.group_description"
                :tags="$data.data.ticket_tag_set"
                :internalStatus="$data.internalStatus"
                :errors="errors"
                @submit="onEditCommentSubmit"
                @cancel="cancel"
            />
            <support-comment-update-modal
                v-if="$store.state.modalTarget == 'support-comment-update-modal'"
                :user-account="userAccount"
                :edit-comment="$data.updateComment"
                :tags="$data.data.ticket_tag_set"
                :internalStatus="$data.internalStatus"
                :errors="errors"
                @submit="onEditCommentUpdateSubmit"
                @close="ticketclose"
            />            
        </template>
    </layout-main>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import MixinDate from '@/mixin/date'
    import MixinCommon from '@/mixin/common'
    import DownloadMixin from '@/mixin/download'
    import ManualService from '@/services/manualService'

    import loading from '@/components/atoms/loading'
    import layoutMain from '@/layouts/main'
    import gxSection from '@/components/atoms/section'
    import gxButton from '@/components/atoms/button'
    import gxIcon from '@/components/atoms/icon'
    import gxIconText from '@/components/atoms/icon-text'
    import gxTag from '@/components/atoms/tag'
    import gxFormText from '@/components/atoms/form/text'
    import gxFormTextarea from '@/components/atoms/form/textarea'
    import gxFormSelect from '@/components/atoms/form/select'
    import gxFormFile from '@/components/atoms/form/file'
    import buttonWrap from '@/components/molecules/button-wrap'
    import notice from '@/components/molecules/notice'

    import supportComment from '@/components/organisms/support/comment'
    import supportCommentCheckModal from '@/components/organisms/support/commentcheckModal'
    import supportCommentUpdateModal from '@/components/organisms/support/commentModal'
    import accountService from '@/services/accountService'
    import i18n from '@/i18n'

    import Const from '@/static/constants'
    import {
        supportService,
        TicketDetail,
        TicketCreateParam,
        TicketSearchParam,
        TicketUpdateParam,
        TicketTag,
        TicketMailUser,
        TicketFile,
        TicketComment,
        EditComment,
        UpdateComment,
        EditCommentFile,
    } from '@/services/supportService'

    export default {
        name: 'support-detail',
        mixins: [MixinCommon, MixinDate, DownloadMixin],
        components: {
            loading,
            layoutMain,
            gxButton,
            gxIcon,
            gxIconText,
            gxFormText,
            gxFormTextarea,
            gxFormSelect,
            gxFormFile,
            gxTag,
            buttonWrap,
            notice,
            gxSection,
            supportComment,
            supportCommentCheckModal,
            supportCommentUpdateModal,
        },
        data: function() {
            return {
                data: {
                    status: {},
                    ticket_file_set: [],
                },
                errors: {},
                commentErrors: {},
                editComment: new EditComment({
                    content: "",
                    status: Const.support.status.USER.value,
                    comment_file_set: [],
                }),
                updateComment: new UpdateComment({
                    comment_id: "",
                    content: "",
                    status: Const.support.status.USER.value,
                    comment_file_set: [],
                }),
                support: Const.support,
                requestParams: {
                },
                isLoading: true,
                isLoadError: false,
                sort: {
                    field: '',
                    order: ''
                },
                isShowed: false,
                Const: Const,
                old_file_id: ""
            }
        },
        computed: {
            ...mapGetters([
                'userAccount'
            ]),
            navs() {
                return [
                    {
                        to: '/support',
                        label: i18n.t('サポートチケット')
                    },
                    {
                        to: '/support/detail',
                        label: i18n.t('チケット詳細')
                    }
                ]
            },
            buttons() {
                return (disabled) => {
                    let d = disabled
                    if (this.$data.editComment.content.length <= 0) {
                        d = true
                    }
                    if (this.$refs.commentFiles) {
                        // トータル3MB以上はNG
                        d = Math.ceil(this.$refs.commentFiles.size / 1024) > 3000
                    }
                    return [
                    {
                        text: i18n.t('クローズ'),
                        type: 'primary',
                        size: 'large',
                        disabled: false,
                        onClick: 'close',
                    },
                    {
                        text: i18n.t('送信'),
                        type: 'primary',
                        size: 'large',
                        disabled: d,
                        onClick: 'submit',
                    },
                    ]
                }
            },
        },
        methods: {
            ...mapActions([
                'fetchUserAccount'
            ]),
            reload() {

                this.fetchUserAccount()
                this.fetch()
            },
            async fetch() {
                this.isLoading = true
                this.isLoadError = false
                this.$data.data = new TicketDetail(await supportService.fetchDetail(this.$route.params.id))
                this.$data.editComment.status = this.$data.data.status.value
                this.$data.editComment.ticket_tag_set = this.$data.data.ticket_tag_set
                this.$data.editComment.internal_status = this.$data.data.internal_status

                // 内部ステータス一覧を取得
                if(this.userAccount.is_support){
                    let internalStatus = await supportService.getInternalStatus()
                    this.$data.internalStatus = internalStatus.results
                    // 空欄を追加する
                    this.$data.internalStatus.push({id:undefined,internal_status:""})
                    // 項目の順序を入れ替える
                    this.$data.internalStatus = this.$data.internalStatus.reverse()
                }

                this.isLoading = false
            },
            //内部ステータスのIDから、値を取得する
            getInternalStatusStr(value) {
                for (let i in this.$data.internalStatus) {
                    if(this.$data.internalStatus[i].id == value){
                        return this.$data.internalStatus[i].internal_status
                    }
                }
            },            
            onCommentAddClick() {
                this.errors = {}
                this.$data.editComment.comment_file_set = []
                this.$data.editComment.content = ""
                this.$data.editComment.ticket_tag_set = []
                this.$store.dispatch('openModalWithParam', {target: 'support-comment-modal', param: this.$route.params.uuid})
            },
            cancel() {
                this.$store.dispatch('closeModal')
            },
            onCommentUpdate(comment) {
                this.$data.updateComment.comment_id = comment.commnet_id
                this.$data.updateComment.comment_file_set = comment.comment_file_set
                this.$data.updateComment.content = comment.content
                this.$data.updateComment.ticket_tag_set = comment.ticket_tag_set
                this.$data.updateComment.status = this.$data.data.status.value
                this.$data.updateComment.internal_status = this.editComment.internal_status
                // 添付ファイルがある場合、file_idを取得する
                if(comment.comment_file_set.length != 0){
                    this.old_file_id = comment.comment_file_set[0].file_id 
                }
                this.$store.dispatch('openModalWithParam', {target: 'support-comment-update-modal', param: this.$route.params.uuid})
            },
            async onFileClick(file_id, file_name) {
                const file = await supportService.downloadFile(file_id)
                this.downloadRawData(file, file_name)
            },
            async ticketclose(){
                this.$data.editComment.status = Const.support.status.CLOSE.value
                this.$data.editComment.internal_status = Const.support.internalStatus.CLOSE.value
                this.$store.dispatch('openModalWithParam', {target: 'support-comment-check-modal', param: this.$route.params.uuid})
            },
            async onEditCommentSubmit() {
                this.isLoading = true
                 const sendData = new TicketUpdateParam({
                    status: this.$data.editComment.status,
                    internal_status: this.$data.editComment.internal_status,
                    ticket_tag_set: this.$data.editComment.ticket_tag_set,
                    comment_set: [this.$data.editComment],
                })
                try {
                    await supportService.updateDetail(
                        this.$route.params.id,
                        sendData,
                    )
                    
                    this.$data.editComment =  new EditComment({
                        content: "",
                        status: Const.support.status.USER.value,
                        comment_file_set: [],
                        is_support:""
                    })

                    this.$store.dispatch('openToast', {type: 'success', label: i18n.t('登録しました')})

                    this.fetch()

                    this.$refs['main'].$el.scroll(0, 0)

                    this.$store.dispatch('closeModal')
                } catch (err) {
                    this.$store.dispatch('openToast', {type: 'error', label: i18n.t('登録に失敗しました')})
                    this.errors = err.response.data
                    this.$set(this.$data, 'commentErrors', err.response.data)
                    this.$store.dispatch('closeModal')
                } finally {
                    this.isLoading = false
                }
            },
            /** 送信前の確認 **/
            async onEditCommentCheck() {
                this.errors = []
                this.$store.dispatch('openModalWithParam', {target: 'support-comment-check-modal', param: this.$route.params.uuid})
            },
            /** コメント更新処理 **/            
            async onEditCommentUpdateSubmit() {
                this.isLoading = true
                // 添付ファイルが更新されていない場合、file_idのみ送信する
                if( this.$data.updateComment.comment_file_set.length != 0 && 
                    this.old_file_id == this.$data.updateComment.comment_file_set[0].file_id
                ){
                    delete this.$data.updateComment.comment_file_set[0].file_name
                }
                const sendData = new TicketUpdateParam({
                    status: this.$data.updateComment.status,
                    internal_status: this.$data.updateComment.internal_status,
                    ticket_tag_set: this.$data.updateComment.ticket_tag_set,
                    comment_set: [  this.$data.updateComment],
                })
                try {
                    await supportService.updateComment(
                        this.$route.params.id,
                        sendData,
                    )
                    this.$data.updateComment =  new UpdateComment({
                        comment_id: "",
                        content: "",
                        status: Const.support.status.USER.value,
                        internal_status: Const.support.internalStatus.UNTREATED.value,
                        comment_file_set: [],
                        is_support:""
                    })

                    this.$store.dispatch('openToast', {type: 'success', label: i18n.t('登録しました')})

                    this.fetch()

                    this.$refs['main'].$el.scroll(0, 0)

                    this.$store.dispatch('closeModal')
                } catch (err) {
                    this.$store.dispatch('openToast', {type: 'error', label: i18n.t('登録に失敗しました')})
                    this.errors = err.response.data
                    this.$set(this.$data, 'commentErrors', err.response.data)
                } finally {
                    this.isLoading = false
                }
            },
            chengeclass(status){
                if(status){
                    return "support"
                }else{
                    return 'user'
                }
            },
            async onGroupChange(groupId,groupName) {
  		        this.$emit('groupChange', groupId)
	            try {
	                await accountService.changeGroups({
	                    id: groupId,
	                    group_description:groupName,
	                })
	                this.$emit('groupChanged')
	                this.$store.dispatch('closeHeaderMenu')
	            } catch(err) {
	                this.$emit('groupChangeFailed')
	            } finally {
	                location.reload()
  		        }
  		    },
            seteventpath(){
                return "/event/" + this.$data.data.waf_event
            },
            manual(){
                return '/manual/wiki/Support_detail_' + i18n.locale
            },       
        },
        created() {
            this.fetch()
        },
        mounted: function() {
            if (typeof this.$redrawVueMasonry === 'function') {
                this.$redrawVueMasonry()
            }
        },
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .close {
        margin-left: 90%;
    }
    .support_ticket__detail {
        width: 100%;

        .item {
            display: inline-block;
            padding: 0.5em;
            margin: 0.25em;
            border-radius: 10px;

            .label {
                display: inline-block;
                width: 8em;
                font-weight: bold;
            }
            .data {
                display: inline-block;
                flex: 1;
            }
        }

        .ticket_info {
            width: 100%;
            padding: 5px;
            background-color: $color-parts-bg;
            border-radius: 10px;
            
            .title {
                width: 100%;

                .data {
                    font-weight: bold;
                }
            }

            .summary {
                width: 100%;

                &.top {
                    .ticket_data {
                        .ticket_id {
                            border-style: solid;
                            border-width: 1px;
                            border-color: $color-border;
                        }
                        .status {
                            border-style: solid;
                            border-width: 1px;
                            border-color: $color-border;
                        }
                        .event_id {
                            border-style: solid;
                            border-width: 1px;
                            border-color: $color-border;
                        }
                    }
                }
                &.bottom {
                    .item {
                        padding: 0 0 0 1em;
                    }
                    .ticket_data {
                        .user_name {

                        }
                        .created_at {

                        }
                        .files {
                            display: block;
                        }
                    }
                }
            }

            .content {
                min-height: 240px;

                .data {
                    white-space: pre-line;
                }
            }
        }

        .comments {
            margin-top: 1em;

            .comment {
                margin-top: 1em;
                border-radius: 10px;
                background-color: $color-parts-bg;

                .comment_info {
                    &.top {
                        .created_at {
                            padding: 5px;
                        }
                    }
                    &.bottom {
                        .user_name {

                        }
                        .status {

                        }
                    }
                }
            }
            .support {
                margin-top: 1em;
                border-radius: 10px;
                background-color: #666;
                margin-left: 120px;
                .comment_info {
                    &.top {
                        .created_at {
                            padding: 5px,0px;
                        }
                    }
                    &.middle {
                        text-align: right;
                    }
                }
            }
            .user {
                margin-top: 1em;
                border-radius: 10px;
                background-color: #222;
                margin-right: 120px;
//                text-align: right;
                .label {
                    display: inline-block;
                    width: 8em;
                    font-weight: bold;
                }
                .comment_info {
                    &.top {
                        .created_at {
                            padding: 5px;
                        }
                    }
                    &.bottom {
                        .user_name {

                        }
                        .status {

                        }
                    }
                }
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            border-radius: 0;
            margin: 0;

            .label {
                display: block;
            }
            .data {
                display: block;
                overflow-wrap: anywhere;
                flex: 1;
                background-color: $color-parts-accent;
                border-style: solid;
                border-width: 1px;
                border-radius: 10px;
                border-color: $color-border;
                padding: 10px;
                white-space: pre-line;
            }
        }
        .comment{
            margin-top: 60px;
            background-color: #444;
            border-radius: 10px;
            padding-top: 10px;
        }
    }
</style>