import api from '@/services/api'

import Const from '@/static/constants'
// import { response } from 'express'

export const supportService = {
    API_ENDPOINT: '/api/support',
    /**
     * 
     * @param {*} params required: title, status, waf_event, ticket_mail_user_set
     * @returns enum status, 
     */
    register(params) {
        return api.post(`${this.API_ENDPOINT}/`, params)
            .then(response => response.data)
    },
    list(params) {
        return api.get(`${this.API_ENDPOINT}/`, {params: params})
            .then(response => response.data)
    },
    fetchDetail(ticketId, params) {
        return api.get(`${this.API_ENDPOINT}/${ticketId}/`, params)
            .then(response => response.data)
    },
    updateDetail(ticketId, params) {
        return api.patch(`${this.API_ENDPOINT}/${ticketId}/`, params)
            .then(response => response.data)
    },
    bulkUpdate(params) {
        return api.post(`${this.API_ENDPOINT}/bulkupdate/`, params)
            .then(response => response.data)
    },
    getMailAddressList(params) {
        return api.get(`${this.API_ENDPOINT}/mailaddress/`, params)
            .then(response => response.data)
    },
    getInternalStatus(params) {
        return api.get(`${this.API_ENDPOINT}/internal_status/`, params)
            .then(response => response.data)
    },
    downloadFile(fileId) {
        return api.get(`${this.API_ENDPOINT}/download/`, {responseType: 'arraybuffer', params: {file: fileId}})
            .then(response => response.data)
    },
    updateComment(ticketId,params) {
        return api.patch(`${this.API_ENDPOINT}/${ticketId}/update_comment/`, params)
            .then(response => response.data)
    }
}

const search = (arr, value) => {
    let res = arr.find((obj, idx, arr)=>{
        return (obj.value == value)
    })
    return res
}

export class TicketDetail {
    constructor(params) {
        let status = search(Const.support.statusArray, params.status)

        this.ticket_id = params.ticket_id
        this.created_at = params.created_at
        this.updated_at = params.updated_at
        this.status = status
        this.internal_status = params.internal_status
        this.title = params.title
        this.content = params.content
        this.username = params.username
        this.account = params.account
        this.group = params.group
        this.groupname = params.groupname
        this.group_description = params.group_description
        this.waf_event = params.waf_event
        this.ticket_no = params.ticket_no

        this.ticket_tag_set = []
        if (Array.isArray(params.ticket_tag_set)) {
            for (let i in params.ticket_tag_set) {
                const tag = new TicketTag(params.ticket_tag_set[i])
                this.ticket_tag_set.push(tag)
            }
        }
        
        this.ticket_file_set = []
        if (Array.isArray(params.ticket_file_set)) {
            for (let i in params.ticket_file_set) {
                const file = new TicketFile(params.ticket_file_set[i])
                this.ticket_file_set.push(file)
            }
        }

        this.ticket_mail_user_set = Array.isArray(params.ticket_mail_user_set) ? params.ticket_mail_user_set : []
        
        this.comment_set = []
        if (Array.isArray(params.comment_set)) {
            for (let i in params.comment_set) {
                const comment = new TicketComment(params.comment_set[i])
                this.comment_set.push(comment)
            }
        }
    }
}

export class TicketCreateParam {
    constructor(params) {
        this.status = params.status ? params.status : Const.support.status.USER.value
        this.internal_status = params.internal_status ? params.internal_status : Const.support.internalStatus.UNTREATED.value
        this.title = params.title ? params.title : ""
        this.content = params.content ? params.content : ""
        this.waf_event = params.waf_event ? params.waf_event : null
        this.ticket_tag_set = Array.isArray(params.ticket_tag_set) ? params.ticket_tag_set : []
        this.ticket_mail_user_set = Array.isArray(params.ticket_mail_user_set) ? params.ticket_mail_user_set : []
        this.ticket_file_set = Array.isArray(params.ticket_file_set) ? params.ticket_file_set : []
    }

    reset() {
        this.status = Const.support.status.USER.value
        this.internal_status = Const.support.internalStatus.UNTREATED.value
        this.title = ""
        this.content = ""
        this.waf_evet = ""
        this.ticket_tag_set = []
        this.ticket_mail_user_set = []
        this.ticket_file_set = []
    }
}

export class TicketSearchParam {
    constructor(params) {
        this.start_create_time = params.start_create_time
        this.end_create_time = params.end_create_time
        this.start_update_time = params.start_update_time
        this.end_update_time = params.end_update_time
        this.title = params.title
        this.status = params.status
        this.status_close = params.status_close
        this.internal_status = params.internal_status
        this.internalstatus_close = params.internalstatus_close
        this.internalstatus_nosupport = params.internalstatus_nosupport
        this.groupname = params.groupname
        this.group_description = params.group_description
        this.tag = params.tag
        this.o = params.o
        this.ticket_id = params.ticket_id
        this.page = params.page ? params.page : 1
        this.page_size = params.page_size ? params.page_size : 20
    }

    reset() {
        this.start_create_time = undefined
        this.end_create_time = undefined
        this.start_update_time = undefined
        this.end_update_time = undefined
        this.title = ""
        this.status =  []
        this.status_close = false
        this.internal_status = undefined
        this.internalstatus_close = false
        this.internalstatus_nosupport = false
        this.groupname = ""
        this.group_description = ""
        this.tag = ""
        this.o = ""
        this.ticket_id = ""
        this.page = 0
        this.page_size = 20
    }
}

export class TicketUpdateParam {
    constructor(params) {
        this.status = params.status ? params.status : Const.support.status.USER.value
        this.internal_status = params.internal_status ? params.internal_status : Const.support.internalStatus.UNTREATED.value
        // this.title = title ? title : ""
        // this.content = content ? content : ""
        // this.waf_event = waf_event ? waf_event : ""
        this.ticket_tag_set = Array.isArray(params.ticket_tag_set) ? params.ticket_tag_set : []
        // this.ticket_mail_user_set = Array.isArray(ticket_mail_user_set) ? ticket_mail_user_set : []
        // this.ticket_file_set = Array.isArray(ticket_file_set) ? ticket_file_set : []
        this.comment_set = Array.isArray(params.comment_set) ? params.comment_set : []
    }

    reset() {
        this.status = Const.support.status.USER.value
        this.internal_status = Const.support.internalStatus.UNTREATED.value
        // this.title = ""
        // this.content = ""
        // this.waf_evet = ""
        this.ticket_tag_set = []
        // this.ticket_mail_user_set = []
        // this.ticket_file_set = []
        this.comment_set = []
    }

    toJSON() {
        return {
            status: this.status,
            internal_status: this.internal_status,
            ticket_tag_set: this.ticket_tag_set,
            comment_set: this.comment_set,
        }
    }
}
export class BulkUpdateParam {
    constructor(params) {
        this.ticket_id = params.ticket_id ? params.ticket_id : []
        this.status = params.status ? params.status : Const.support.status.USER.value
        this.internal_status = params.internal_status ? params.internal_status : Const.support.internalStatus.SENTALL.value
        this.ticket_tag_set = params.ticket_tag_set ? params.ticket_tag_set : []
        this.comment_set = params.comment_set ? params.comment_set : []
    }
}
  
export class TicketTag {
    constructor(params) {
        this.tag_name = params.tag_name ? params.tag_name : ""
    }
}

export class TicketMailUser {
    constructor(account) {
        this.account = account
    }
}

export class TicketFile {
    constructor(params) {
        this.file_name = params.file_name
        this.file_id = params.file_id
    }
}

export class TicketComment {
    constructor(params) {
        this.commnet_id = params.comment_id ? params.comment_id : ""
        this.was_edited = params.was_edited ? params.was_edited : ""
        this.username = params.username
        this.created_at = params.created_at
        this.content = params.content ? params.content : ""
        this.is_support = params.is_support
        this.status = search(Const.support.statusArray, params.status)
        this.comment_file_set = []
        if (Array.isArray(params.comment_file_set)) {
            for (let i in params.comment_file_set) {
                const file = new TicketFile(params.comment_file_set[i])
                this.comment_file_set.push(file)
            }
        }
    }
}

export class EditComment {
    constructor() {
        this.content = ""
        this.comment_file_set = []
    }

    toJSON() {
        return {
            content: this.content,
            comment_file_set: this.comment_file_set,
        }
    }    
}

export class UpdateComment {
    constructor() {
        this.comment_id = ""
        this.content = ""
        this.comment_file_set = []
    }

    toJSON() {
        return {
            comment_id: this.comment_id,
            content: this.content,
            comment_file_set: this.comment_file_set,
        }
    }    
}


export class EditCommentFile {
    constructor(params) {
        this.fileReader = new FileReader()

        this.file = params.file ? params.file : undefined
        this.file_name = params.file_name ? params.file_name : undefined
        this.file_content = params.file_content ? params.file_content : undefined
    }

    async setFile(file) {
        const _this = this

        this.file = file
        this.file_name = file.name

        await new Promise((resolve, reject)=>{
            const onFileLoadEnd = function(evt) {
                _this.file_content = _this.fileReader.result
                resolve()
            }

            try {
                this.fileReader.onloadend = onFileLoadEnd
                this.fileReader.readAsDataURL(file)
            } catch(err) {
                reject(err)
            }
        })
    }

    toJSON() {
        return {
            file_name: this.file_name,
            file_content: this.file_content,
        }
    }
}
