import './locale'

import { extend } from 'vee-validate'

import * as DefaultRules from 'vee-validate/dist/rules'
import { regex_s } from "@/validations/common"

// デフォルトルール
Object.keys(DefaultRules).forEach(rule => {
    extend(rule, DefaultRules[rule]);
})

// カスタムルール
extend('regex_s', regex_s)
