<template>
    <modal @cancel="closeModal" @deleteItem="deleteItem">
        <template #title>{{$t('ウェブサイトを削除します')}}</template>
        <template><p>{{$t('この操作は取り消せません')}}</p></template>
        <template #footer><button-wrap :buttons="buttons" @cancel="closeModal" @deleteItem="deleteItem" /></template>
    </modal>
</template>

<script>
    import buttonWrap from '@/components/molecules/button-wrap'
    import modal from '@/components/molecules/modal'
    import WebsiteService from '@/services/websiteService'

    import i18n from '@/i18n'

    export default {
        name: 'website-delete-modal',
        components: {
            buttonWrap,
            modal
        },
        computed: {
            buttons() {
                return [{
                    text: i18n.t('削除'),
                    type: 'error',
                    onClick: 'deleteItem'
                }, {
                    text: i18n.t('キャンセル'),
                    type: 'common',
                    onClick: 'cancel'
                }]
            },
            uuid() {
                return this.$store.state.modalParam
            }
        },
        methods: {
            async deleteItem() {
                this.$store.dispatch('displayLoading')
                await WebsiteService.delete(this.uuid).then(() => {
                    this.$store.dispatch('openToast', {type: 'success', label: i18n.t('削除しました')})
                    this.closeModal()
                    if(this.$route.path === '/website' || this.$route.path === '/website/') {
                        this.$emit('reload')
                    } else {
                        this.$router.push('/website')
                    }
                }).catch(err => {
                    const message = err && err.response && err.response.data && err.response.data.length ? err.response.data.join('\n') : i18n.t('削除に失敗しました')
                    this.$store.dispatch('openToast', {type: 'error', label: message})
                })
                this.$store.dispatch('hideLoading')
            },
            closeModal() {
                this.$store.dispatch('closeModal')
            }
        }
    }
</script>

<style>

</style>