<template>
    <table-base>
        <template #head>
            <table-tr type="head">
                <gx-table-cell :type="'th'" :sort="formatSort(sort, 'username')" @onSort="onSort">{{$t('ユーザーID')}}</gx-table-cell>
                <gx-table-cell :type="'th'" :sort="formatSort(sort, 'hostname')" @onSort="onSort">{{$t('ホスト')}}</gx-table-cell>
                <gx-table-cell :type="'th'" :sort="formatSort(sort, 'protocol')" @onSort="onSort">{{$t('プロトコル')}}</gx-table-cell>
                <gx-table-cell :type="'th'" :sort="formatSort(sort, 'action')" @onSort="onSort">{{$t('状態')}}</gx-table-cell>
                <gx-table-cell :type="'th'">{{$t('更新ステータス')}}</gx-table-cell>
                <gx-table-cell :type="'th'"></gx-table-cell>
            </table-tr>
        </template>
        <template #body>
            <table-tr v-for="website in websites" :key="website.website_id">
                {{setActionIcon(website)}}
                <gx-table-cell center>{{website.username}}</gx-table-cell>
                <gx-table-cell center>
                    <div v-for="hostname in getDisplayedHostName(website.hostname, website.allow_naked_domain_flg)" :key="hostname">
                        {{hostname}}
                    </div>
                </gx-table-cell>
                <gx-table-cell center>
                    <div v-for="protocol in getDisplayedProtocolType(website.protocol, website.redirect_http_to_https_flg)" :key="protocol">
                        {{protocol}}
                    </div>
                </gx-table-cell>
                <gx-table-cell center><gx-icon :text="$t(icon.text)" :icon="icon.icon" :color="colors.success" /></gx-table-cell>
                <gx-table-cell center>
                    {{ $t(getStateType(website.state)) }}
                </gx-table-cell>
                <gx-table-cell min>
                    <span class="actions">
                        <gx-button
                            :tag="'router-link'"
                            :text="$t('詳細')"
                            :type="'common'"
                            :size="'small'"
                            :to="`/website/${website.website_id}`"
                            class="actions__item"
                        />
                        <gx-icon
                            v-if="!userAccount.is_support || userAccount.role == 80"
                            :tag="'a'"
                            :text="$t('削除')"
                            :icon="'trash'"
                            class="actions__item"
                            @onClick="deleteItem(website.website_id)"
                            href="#"
                            :disabled="!isDeletable(website)"
                        />
                    </span>
                </gx-table-cell>
            </table-tr>
        </template>
    </table-base>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    
    import Const from '@/static/constants'
    import MixinCommon from '@/mixin/common'
    import MixinWebsite from '@/mixin/website'

    import gxTableCell from '@/components/atoms/table/cell'
    import gxButton from '@/components/atoms/button'
    import gxIcon from '@/components/atoms/icon'
    import tableBase from '@/components/molecules/table/base'
    import tableTr from '@/components/molecules/table/tr'

    export default {
        name: 'website-list-table',
        mixins: [MixinCommon, MixinWebsite],
        components: {
            gxTableCell,
            gxButton,
            gxIcon,
            tableBase,
            tableTr
        },
        computed: {
            ...mapGetters([
                'userAccount'
            ]),
            websites() {
                return this.websitesList.filter(v => v);
            },
            isDeletable: () => (website) => website.is_deletable
        },
        props: {
            websitesList: {
                type: Array,
                default: () => []
            },
            sort: {
                type: Object,
                default: () => {}
            },
        },
        methods: {
            deleteItem(uuid) {
                this.$store.dispatch('openModalWithParam', {target: 'website-delete', param: uuid})
            },
            setActionIcon(website) {
                this.icon = this.getActionIcon(website)
            },
            onSort(field, order) {
                this.$emit('updateOrder', field, order)
            },
            getDisplayedHostName(hostname, allow_naked_domain_flg) {
                if (allow_naked_domain_flg && hostname.startsWith(Const.website.sub_domain.WWW)) {
                    return [
                        hostname,
                        hostname.slice(Const.website.sub_domain.WWW.length)
                    ]
                } else {
                    return [hostname]
                }
            },
            getDisplayedProtocolType(protocol, redirect_http_to_https_flg) {
                if (redirect_http_to_https_flg) {
                    return [
                        this.getProtocolType(Const.website.protocol.HTTP),
                        this.getProtocolType(Const.website.protocol.HTTPS),
                    ]
                } else {
                    return [this.getProtocolType(protocol)]
                }
            },
            getStateType: (value) => Const.website.stateType[value],
        }
    }
</script>

<style lang="scss" scoped>
    .actions {
        display: flex;
        justify-content: flex-end;

        &__item:not(:first-child) {
            margin-left: 24px;
        }
    }
</style>
