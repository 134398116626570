<template>
    <p class="page-back"><gx-icon-text :tag="to ? 'router-link' : 'span'" :icon="'arrow-left'" :text="text" :to="to ? to : ''" @onClick="$emit('onClick')" class="page-back__link" /></p>
</template>

<script>
    import gxIconText from '@/components/atoms/icon-text'

    import i18n from '@/i18n'

    export default {
        name: 'page-back',
        components: {
            gxIconText
        },
        props: {
            text: {
                type: String,
                default: i18n.t('一覧に戻る')
            },
            to: {
                type: String,
                default: ''
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';
    .page-back {
        margin-top: 56px;

        &__link {
            text-decoration: underline;
            color: $color-font;
            transition: $transition-common;
            cursor: pointer;

            &:hover {
                color: $color-font;
                opacity: $opacity-hover;
                text-decoration: none;
            }
        }
    }
</style>