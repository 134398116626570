import Vue from 'vue';
import Vuex from 'vuex';

import Const from '@/static/constants'
import AccountService from '@/services/accountService'
import { state } from '@/static/constants/website';

Vue.use(Vuex);

const createStore = () => {
    return new Vuex.Store({
        state: {
            isModal: false,
            modalTarget: '',
            modalParam: null,
            isLoading: false,
            toastLabel: '',
            toastType: '',
            userAccount: {
                id: null,
                group_id: null,
                username: '',
                record_name: '',
                email: '',
                telephone: '',
                tz: 0,
                expire_date: null,
                role: 0,
                has_website: false,
                is_account_creatable: false,
                is_website_creatable: false,
                is_custom_signature_creatable: false,
                is_support: false,
                errors: [],
            },
            showHeaderMenu: false,
        },
        getters: {
            userAccount: state => state.userAccount,
            hasAdminRole: state => state.userAccount.role >= Const.accounts.roleAdmin,
            hasGroupAdminRole: state => state.userAccount.role >= Const.accounts.roleGroupAdmin,
            isManageableRole: state => role => state.userAccount.role > role,
            showHeaderMenu: state => state.showHeaderMenu
        },
        actions: {
            openModal({commit}, target) {
                commit('openModal', {target: target, param: null})
            },
            openModalWithParam({commit}, {target, param}) {
                commit('openModal', {target: target, param: param})
            },
            closeModal({commit}) {
                commit('closeModal')
            },
            displayLoading({commit}) {
                commit('displayLoading')
            },
            hideLoading({commit}) {
                commit('hideLoading')
            },
            fetchUserAccount({commit}) {
                AccountService.userInfo().then(res => {
                    if(res.id) {
                        commit('setUserAccount', res)
                    }
                })
            },
            openToast({commit}, {type, label}) {
                commit('setToast', {type, label})
                setTimeout(() => {
                    commit('closeToast')
                }, 2500)
            },
            mergeUserAccount({commit, getters}, userAccount) {
                userAccount = Object.assign(getters.userAccount, userAccount)
                commit('setUserAccount', userAccount)
            },
            clearUserAccount({commit}) {
                commit('initUserAccount')
            },
            openHeaderMenu({commit}) {
                commit('openHeaderMenu')
            },
            closeHeaderMenu({commit}) {
                commit('closeHeaderMenu')
            },
            toggleHeaderMenu({commit}) {
                commit('toggleHeaderMenu')
            },
        },
        mutations: {
            openModal(state, {target, param}) {
                Vue.set(state, "isModal", true)
                Vue.set(state, "modalTarget", target)
                Vue.set(state, "modalParam", param)
            },
            closeModal(state) {
                Vue.set(state, "isModal", false)
                Vue.set(state, "modalTarget", '')
                Vue.set(state, "modalParam", null)
            },
            displayLoading(state) {
                Vue.set(state, "isLoading", true)
            },
            hideLoading(state) {
                Vue.set(state, "isLoading", false)
            },
            setUserAccount(state, userAccount) {
                Object.keys(state.userAccount).forEach(key => {
                    Vue.set(state.userAccount, key, userAccount[key])
                })
            },
            setToast(state, {type, label}) {
                Vue.set(state, "toastType", type)
                Vue.set(state, "toastLabel", label)
            },
            closeToast(state) {
                Vue.set(state, "toastLabel", '')
            },
            initUserAccount(state) {
                Object.keys(state.userAccount).forEach(key => {
                    switch (key) {
                        case 'id':
                        case 'expire_date':
                            Vue.set(state.userAccount, key, null)
                            break
                        case 'username':
                        case 'email':
                        case 'telephone':
                            Vue.set(state.userAccount, key, '')
                            break
                        default:
                            Vue.set(state.userAccount, key, 0)
                            break
                    }
                })
            },
            openHeaderMenu(state) {
                Vue.set(state, 'showHeaderMenu', true)
            },
            closeHeaderMenu(state) {
                Vue.set(state, 'showHeaderMenu', false)
            },
            toggleHeaderMenu(state) {
                Vue.set(state, 'showHeaderMenu', !state.showHeaderMenu)
            },
        }
    })
}

export { createStore }
