import Vue from 'vue'
import Router from 'vue-router'
//import routes from 'vue-auto-routing'
import Const from '@/static/constants'
import { createRouterLayout } from 'vue-router-layout'
import accounts from './pages/accounts'
import accounts_register from './pages/accounts/register.vue'
import accounts_modify from './pages/accounts/modify.vue'
import dashboard from './pages/dashboard'
import event from './pages/event'
import event_detail from './pages/event/detail.vue'
import Login from './pages/login'
import LoginConfirm from './pages/login/confirm.vue'
import terms from './pages/terms'
import loginTerms from './pages/terms/login.vue'
import group from './pages/group'
import website from './pages/website'
import website_modify from './pages/website/modify.vue'
import website_register from './pages/website/register.vue'
import support from './pages/support'
import certificate from './pages/certificate'
import support_detail from './pages/support/detail.vue'
import support_register from './pages/support/register.vue'
import news from './pages/news'
import news_detail from './pages/news/detail.vue'
import manual from './pages/manual'

Vue.use(Router)

const RouterLayout = createRouterLayout(layout => {
    return Promise.resolve(require('@/layouts/' + layout + '.vue').default)
})

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: RouterLayout,
           // children: routes
            children: [
                {
                    path: '/',
                    redirect: 'login',
                },
                {
                    path: '/login/',
                    component: Login,
                    meta: { allowAnonymous: true },
                },
                {
                    path: '/login/confirm',
                    component: LoginConfirm,
                    meta: { allowAnonymous: true },
                },
                {
                    path: '/login/terms',
                    component: loginTerms,
                    meta: { allowAnonymous: true },
                },
                {
                    path: '/accounts',
                    component: accounts,
                },
                {
                    path: '/accounts/register',
                    component: accounts_register,
                },
                {
                    path: '/accounts/:uuid',
                    component: accounts_modify,
                },
                {
                    path: '/dashboard',
                    component: dashboard,
                },
                {
                    path: '/event',
                    component: event,
                },
                {
                    path: '/event/:uuid',
                    component: event_detail,
                },
                {
                    path: '/terms',
                    component: terms
                },
                {
                    path: '/group',
                    component: group,
                },
                {
                    path: '/website',
                    component: website,
                },
                {
                    path: '/website/register',
                    component: website_register,
                },
                {
                    path: '/website/:uuid',
                    component: website_modify,
                    children: [
                        {
                            path: '',
                            component: website_modify,
                            name: Const.common.paths.WEBSITE
                        },
                        {
                            path: 'access-control',
                            component: website_modify,
                            name: Const.common.paths.WEBSITE_ACCESS_CTRL,
                        },
                        {
                            path: 'waf-custom',
                            children: [
                                {
                                    path: '',
                                    component: website_modify,
                                    name: Const.common.paths.WEBSITE_WAFCUS,
                                },
                                {
                                    path: 'register',
                                    component: website_modify,
                                    name: Const.common.paths.WEBSITE_WAFCUS_REGISTER,
                                },
                                {
                                    path: ':wafcus_id',
                                    component: website_modify,
                                    name: Const.common.paths.WEBSITE_WAFCUS_DETAIL,
                                },
                            ]
                        },
                        {
                            path: 'waf-trust',
                            component: website_modify,
                            name: Const.common.paths.WEBSITE_WAFTRUST
                        },
                    ]
                },
                {
                    path: '/certificate',
                    component: certificate,
                },
                {
                    path: '/support',
                    component: support,
                },
                {
                    path: '/support/register',
                    component: support_register,
                },
                {
                    path: '/support/:id',
                    component: support_detail,
                },
                {
                    path: '/news',
                    component: news,
                },
                {
                    path: '/news/:id',
                    component: news_detail,
                },     
                {
                    path: '/manual/wiki/:id',
                    name: 'manual',
                    component: manual,
                },             
                { path: '*', redirect: '/' }
            ]
        },      
    ],
    linkExactActiveClass: '',
    linkActiveClass: 'is-current'
})
