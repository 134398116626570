<template>
    <nav class="breadcrumb" v-if="origin && origin.to">
        <ol class="breadcrumb__list">
            <li class="breadcrumb__item"><router-link :to="origin.to" class ="breadcrumb__link">{{origin.label}}</router-link></li>
            <li v-for="(nav, index) in navs" :key="index" class="breadcrumb__item">
                <em v-if="index >= navs.length - 1" class="breadcrumb__current">{{nav.label}}</em>
                <router-link v-else :to="nav.to" class="breadcrumb__link">{{nav.label}}</router-link>
            </li>
        </ol>
    </nav>
</template>

<script>
    export default {
        name: 'breadcrumb',
        props: {
            origin: {
                type: Object,
                default: () => {}
            },
            navs: {
                type: Array,
                default: () => []
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variable.scss';

    .breadcrumb {
        border-bottom: 1px solid $color-hr;
        padding: 5px 0 5px 0;

        &__list {
            display: flex;
        }
        &__item {
            white-space: nowrap;

            &:not(:first-child) {
                margin-left: 4px;

                &::before {
                    @include iconInline(arrow-right);
                    color: $color-icon;
                    font-size: 1.6rem;
                    vertical-align: middle;
                    margin-right: 4px;
                }
            }
            &:last-child {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        &__link {
            text-decoration: none;
        }
        &__current {
            font-weight: bold;
        }
    }
</style>