<template>
    <validation-provider
        v-slot="{ errors }"
        :vid="name"
        :name="term || label || name"
        :rules="rules"
        tag="span"
        :class="['wrap', {'is-disabled' : disabled}]">
        <label v-if="label || $slots.label" :for="id" class="label"><slot name="label">{{label}}</slot></label>
        <em v-if="setErrors(errors) && errorText" class="error">
            {{errorText}}<br><small>{{ examples }}</small>
        </em>
        <span class="field-wrap">
            <span v-if="prefix || $slots.prefix" class="prefix"><slot name="prefix">{{prefix}}</slot></span>
            <input
                :id="id"
                :name="name"
                class="field"
                :class="{date: ['date', 'month'].indexOf(type) > 0, 'is-error': errorText}"
                :type="type"
                :placeholder="placeholder"
                :value="value"
                :disabled="disabled"
                :autocomplete="autocomplete ? 'on' : 'off'"
                :max="disabled ? undefined : max"
                :min="disabled ? undefined : min"
                :maxlength="disabled ? undefined : maxlength"
                :minlength="disabled ? undefined : minlength"
                :pattern="disabled ? undefined : patternRegex"
                :required="!disabled && required"
                @keypress="keyHandler"
                @paste="handlePaste"
                @input="setInput"
                @focus="onFocus"
                @blur="onBlur">
            <span v-if="suffix || $slots.suffix" class="suffix"><slot name="suffix">{{suffix}}</slot></span>
        </span>
        <small v-if="note" class="note">{{note}}</small>
    </validation-provider>
</template>

<script>
import Const from '@/static/constants'

export default {
    name: 'gx-form-input',
    props: {
        id: {
            type: String,
            default() {
                return `${this._uid}`
            }
        },
        rules: {
            type: [String, Object],
            default: () => ({})
        },
        autocomplete: {
            type: Boolean,
            default: true
        },
        name: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        term: {
            type: String,
            default: ''
        },
        prefix: {
            type: String,
            default: ''
        },
        suffix: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'placeholder text'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            validator: (value) => {
                return ['text', 'number', 'password', 'date', 'month', 'email', 'url', 'tel'].indexOf(value) !== -1
            },
            default: 'text'
        },
        max: {
            type: [String, Number]
        },
        min: {
            type: [String, Number]
        },
        maxlength: {
            type: Number
        },
        minlength: {
            type: Number
        },
        note: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number],
            default: ''
        },
        patterns: {
            type: [String, Array],
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        errors: {
            type: [String, Array],
            default: ''
        },
    },
    data() {
        return {
            formErrors: [],
            validatorId: null,
            focus: false,
            errorText: ''
        }
    },
    created() {
        this.parseTempErrorText()
    },
    watch: {
        errors(val) {
            if (val.length) {
                this.parseTempErrorText()
            }
        },
        formErrors() {
            this.cancelValidate()
            if (this.focus) {
                // 入力中値が切り替わりすぎないようエラーの更新を遅延させる
                this.validatorId = setTimeout(this.parseErrorText, 200)
            } else {
                this.parseErrorText()
            }
        }
    },
    computed: {
        examples() {
            let examples = ''
            if(Array.isArray(this.patterns)) {
                const rulesArray = []
                this.patterns.forEach((item) => {
                    rulesArray.push(Const.validate.patternType[item])
                })
                examples = rulesArray.join(' もしくは ')
            } else {
                examples = Const.validate.patternType[this.patterns]
            }
            return examples
        },
        patternRegex() {
            if (this.type === 'number') {
                return '\\d*'
            }
            let validators = ''
            if(Array.isArray(this.patterns)) {
                const validatorsArray = []
                this.patterns.forEach((item) => {
                    validatorsArray.push('(' + Const.validate.pattern[item] + ')')
                })
                validators = validatorsArray.join('|')
            } else {
                validators = Const.validate.pattern[this.patterns]
            }

            return validators
        },
        setErrors() {
            return errors => {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.formErrors = errors
                return true
            }
        }
    },
    methods: {
        setInput(event) {
            this.$emit('input', event.target.value)
        },
        cancelValidate() {
            if (this.validatorId) {
                clearTimeout(this.validatorId)
                this.validatorId = null
            }
        },
        onFocus() {
            this.focus = true
        },
        onBlur() {
            this.cancelValidate()
            this.focus = false
            this.parseErrorText()
        },
        parseTempErrorText() {
            this.cancelValidate()
            this.parseErrors(this.errors)
        },
        parseErrorText() {
            this.parseErrors(this.formErrors)
        },
        parseErrors(errors) {
            this.errorText = Array.isArray(errors) ? errors.join(' / ') : errors
        },
        // HACK: input[type=number]に数字以外の値を入力できないようにする for FireFox
        keyHandler(event) {
            if (this.type === 'number' && !/\d/.test(event.key)) {
                event.preventDefault()
            }
        },
        handlePaste(event) {
            if (this.type === 'number' && !/^\d+$/s.test(event.clipboardData.getData('text'))) {
                event.preventDefault()
            } else {
                return true
            }
        },

    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variable.scss';
$max-width: 400px;
.wrap {
    width: 100%;
    max-width: $max-width;
    display: inline-block;

    &.is-disabled {
        opacity: $opacity-disabled;
    }
}
.label {
    display: block;
    margin-bottom: 8px;
}
.field-wrap {
    display: flex;
    align-items: center;
}
.prefix {
    white-space: nowrap;
    margin-right: 8px;
}
.suffix {
    white-space: nowrap;
    margin-left: 8px;
}
.field {
    width: 0;
    height: 40px;
    line-height: 38px;
    border-radius: 6px;
    border: 1px solid $color-border;
    padding: 0 16px;
    transition: $transition-common;
    flex: 1 1 auto;

    &.is-error {
        border-color: $color-error;
        background-color: rgba($color-error, .1);
    }
    &:disabled {
        cursor: not-allowed;
    }
}
.note {
    display: block;
    margin-top: 8px;
    color: $color-note;
}
.error {
    display: block;
    margin-bottom: 8px;
    color: $color-error;
    font-weight: bold;
}
</style>
