<template>
    <table-base>
        <template #head>
            <table-tr type="head">
                <gx-table-cell v-if="userAccount.is_support" :type="'th'">
                    <gx-form-checkbox
                        ref="allCheckBox"
                        :checked="allCheck"
                        @check="updateAllCheckBox"
                        boxOnly
                    />
                </gx-table-cell>
                <gx-table-cell
                    :type="'th'"
                    :sort="formatSort(sort, 'created_at')"
                    min
                    @onSort="onSort"
                >
                    {{$t('作成日時')}}
                </gx-table-cell>
                <gx-table-cell
                    :type="'th'"
                    :sort="formatSort(sort, 'updated_at')"
                    min
                    @onSort="onSort"
                >
                    {{$t('更新日時')}}
                </gx-table-cell>
                <gx-table-cell v-if="userAccount.is_support"
                    :type="'th'"
                    :sort="formatSort(sort, 'internal_status')"
                    min
                    @onSort="onSort"
                >
                    {{$t('内部ステータス')}}
                </gx-table-cell>
                <gx-table-cell
                    :type="'th'"
                    :sort="formatSort(sort, 'status')"
                    min
                    @onSort="onSort"
                >
                    {{$t('ステータス')}}
                </gx-table-cell>
                <gx-table-cell
                    v-if="userAccount.is_support"
                    :sort="formatSort(sort, 'group_description')"
                    :type="'th'"
                    min
                    @onSort="onSort"
                >
                    {{$t('グループ名')}}
                </gx-table-cell>
                <gx-table-cell
                    v-if="userAccount.is_support"
                    :sort="formatSort(sort, 'groupname')"
                    :type="'th'"
                    min
                    @onSort="onSort"
                >
                    {{$t('グループID')}}
                </gx-table-cell>
                <gx-table-cell
                    :type="'th'"
                    :sort="formatSort(sort, 'title')"
                    min
                    @onSort="onSort"
                >
                    {{$t('タイトル')}}
                </gx-table-cell>
                <gx-table-cell
                    :type="'th'"
                    :sort="formatSort(sort, 'ticket_id')"
                    min
                    @onSort="onSort"
                >
                    {{$t('チケットID')}}
                </gx-table-cell>
                <gx-table-cell :type="'th'" min></gx-table-cell>
            </table-tr>
        </template>
        <template #body>
            <table-tr v-for="ticket in tickets" :key="ticket.ticket_id">
                <gx-table-cell min  v-if="userAccount.is_support">
                    <gx-form-checkbox
                        :value="ticket.ticket_id"
                        :checked="checked.includes(ticket.ticket_id)"
                        @check="updateCheckBox($event.target.value)"
                        boxOnly
                        :disabled=setdisabled(ticket.status)
                    >
                        {{$t('選択')}}
                    </gx-form-checkbox>
                </gx-table-cell>
                <gx-table-cell center :disabled= setdisabled(ticket.status)>{{formatLocalDateTime(ticket.created_at)}}</gx-table-cell>
                <gx-table-cell center :disabled= setdisabled(ticket.status)>{{formatLocalDateTime(ticket.updated_at)}}</gx-table-cell>
                <gx-table-cell v-if="userAccount.is_support" center :disabled= setdisabled(ticket.status)>{{$t(getInternalStatusStr(ticket.internal_status))}}</gx-table-cell>
                <gx-table-cell center :disabled= setdisabled(ticket.status)>{{$t(getStatusStr(ticket.status))}}</gx-table-cell>
                <gx-table-cell v-if="userAccount.is_support" center :disabled= setdisabled(ticket.status)>{{ticket.group_description}}</gx-table-cell>
                <gx-table-cell v-if="userAccount.is_support" center>
                    <span class="groupname" @click="onGroupChange(ticket.group,ticket.groupname)">{{ticket.groupname}}</span>
                </gx-table-cell>
                <gx-table-cell center :disabled= setdisabled(ticket.status)>{{ticket.title}}</gx-table-cell>
                <gx-table-cell center :disabled= setdisabled(ticket.status)>{{ticket.ticket_id}}</gx-table-cell>
                <gx-table-cell min>
                    <gx-button
                        :tag="'router-link'"
                        :text="$t('詳細')"
                        :type="'common'"
                        :size="'small'"
                        :to="`/support/${ticket.ticket_id}`"
                    />
                </gx-table-cell>
            </table-tr>
        </template>
    </table-base>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    
    import Const from '@/static/constants'
    import MixinCommon from '@/mixin/common'
    import MixinDate from '@/mixin/date'

    import gxTableCell from '@/components/atoms/table/cell'
    import gxFormCheckbox from '@/components/atoms/form/checkbox'
    import gxButton from '@/components/atoms/button'
    import gxIcon from '@/components/atoms/icon'
    import gxLabel from '@/components/atoms/label'
    import tableBase from '@/components/molecules/table/base'
    import tableTr from '@/components/molecules/table/tr'

    import accountService from '@/services/accountService'

    export default {
        name: 'ticket-table',
        mixins: [MixinCommon, MixinDate],
        components: {
            gxTableCell,
            gxFormCheckbox,
            gxButton,
            gxIcon,
            gxLabel,
            tableBase,
            tableTr,
            accountService
        },
        computed: {
            ...mapGetters([
                'userAccount'
            ]),
        },
        data() {
            return {
                allCheck: false,
                Const: Const,
            }
        },
        props: {
            tickets: {
                type: Array,
                default: () => []
            },
            internalStatus: {
                type: Array,
                default: () => []
            },
            checked: {
                type: Array,
                default: () => []
            },
            sort: {
                type: Object,
                default: () => {}
            }
        },
        filters: {
            abbreviation(value) {
                return value && value.length > 16 ? `${value.substr(0,15)}…` : value
            }
        },
        methods: {
            getInternalStatusStr(value) {
                const obj = this.internalStatus.find((obj)=>{
                    return obj.id == value
                })
                return obj ? obj.internal_status : value
            },
            getStatusStr(value) {
                const obj = Const.support.statusArray.find((obj)=>{
                    return obj.value == value
                })
                return obj ? obj.str : value
            },
            onSort(field, order) {
                this.$emit('updateOrder', field, order)
            },
            setdisabled(status){
                if(status == Const.support.status.CLOSE.value){
                    return true
                }else{
                    return false
                }
                
            },
            isCheckedAll() {
                let ret = true
                for (let i in this.tickets) {
                    const t = this.tickets[i]
                    const exists = this.checked.find((obj, idx, arr)=>{
                        return obj == t.ticket_id
                    })
                    if (!exists && (t.status != Const.support.status.CLOSE.value)) {
                        ret = false
                    }
                }
                return ret
            },
            isClosedAll() {
                let ret = true
                for (let i in this.tickets) {
                    const t = this.tickets[i]
                    if (t.status != Const.support.status.CLOSE.value) {
                        ret = false
                    }
                }
                return ret
            },
            updateCheckBox(val) {
                if(this.checked.includes(val)) {
                    const index = this.checked.findIndex(v => v == val)
                    this.checked.splice(index, 1)
                    this.$emit('checkBoxChanged', this.checked)
                } else {
                    this.checked.push(val)
                    this.$emit('checkBoxChanged', this.checked)
                }
                
                this.$data.allCheck = this.isCheckedAll()

                if (this.isClosedAll()) {
                    this.$data.allCheck = false
                }

                // this.$data.allCcheckの内容が反映されない場合があるので、ズレていたら合わせる
                const input = this.$refs.allCheckBox.$el.querySelector('input.checkbox__input')
                if (input) {
                    if (input.checked != this.$data.allCheck) {
                        input.checked = this.$data.allCheck
                    }
                }
            },
            updateAllCheckBox(evt) {
                for (let i in this.tickets) {
                    const t = this.tickets[i]
                    const isClose = (t.status == Const.support.status.CLOSE.value)
                    const idx = this.checked.findIndex((obj, idx, arr)=>{
                        return obj == t.ticket_id
                    })

                    if (!isClose) {
                        if (evt.target.checked) {
                            if (idx < 0) {
                                this.checked.push(t.ticket_id)
                            }
                        } else {
                            if (idx >= 0) {
                                this.checked.splice(idx, 1)
                            }                            
                        }
                    } else {
                        if (idx >= 0) {
                            this.checked.splice(idx, 1)
                        }
                    }
                }

                this.$data.allCheck = this.isCheckedAll()

                this.$emit('checkBoxChanged', this.checked)
            },
            async onGroupChange(groupId,groupName) {
  		        this.$emit('groupChange', groupId)
  		        if (this.userAccount.is_support) {
  		            try {
  		                await accountService.changeGroups({
  		                    id: groupId,
  		                    group_description:groupName,
  		                })
  		                this.$emit('groupChanged')
  		                this.$store.dispatch('closeHeaderMenu')
  		            } catch(err) {
  		                this.$emit('groupChangeFailed')
  		            } finally {
  		                location.reload()
  		            }
  		        }
  		    },
        }
    }
</script>

<style lang="scss" scoped>
    .groupname{
        text-decoration:underline;
        cursor: pointer;
    }
</style>

